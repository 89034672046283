/// <reference path="./custom.d.ts" />
/* eslint-disable */

import * as url from "url";
// Because the lib_api package is not compile directly from an react app it will try to use a different fetch from window fetch.
// So portableFetch is replaced by window.fetch
// import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "http://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = window.fetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AbandonDto
 */
export interface AbandonDto {
    /**
     * 
     * @type {boolean}
     * @memberof AbandonDto
     */
    abandonImmediat: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AbandonDto
     */
    applicationDate: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbandonDto
     */
    decisionAbandon: string;
    /**
     * 
     * @type {boolean}
     * @memberof AbandonDto
     */
    isAbandon: boolean;
}
/**
 * 
 * @export
 * @interface AccessoiresDto
 */
export interface AccessoiresDto {
    /**
     * 
     * @type {boolean}
     * @memberof AccessoiresDto
     */
    autoRadioVisible: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AccessoiresDto
     */
    nombreAntennes: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoiresDto
     */
    objetsVisibles: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoiresDto
     */
    observations: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessoiresDto
     */
    posteCbVisible: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessoiresDto
     */
    telephonePortable: boolean | null;
}
/**
 * 
 * @export
 * @interface Acheteur
 */
export interface Acheteur {
    /**
     * 
     * @type {string}
     * @memberof Acheteur
     */
    nom: string | null;
    /**
     * 
     * @type {string}
     * @memberof Acheteur
     */
    prenom: string | null;
    /**
     * 
     * @type {string}
     * @memberof Acheteur
     */
    raisonSociale: string | null;
}
/**
 * 
 * @export
 * @interface ActionsRequisesDto
 */
export interface ActionsRequisesDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ActionsRequisesDto
     */
    actionsRequises: Array<ActionsRequisesDtoActionsRequisesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum ActionsRequisesDtoActionsRequisesEnum {
    'PROLONGER_MEF' = 'PROLONGER_MEF',
    'CLASSER_MANUELLEMENT' = 'CLASSER_MANUELLEMENT',
    'MODIFIER_COORDONNEES_PROPRIETAIRE' = 'MODIFIER_COORDONNEES_PROPRIETAIRE',
    'CORRIGER_DONNEES_NOTIFICATION_MANUELLE' = 'CORRIGER_DONNEES_NOTIFICATION_MANUELLE',
    'RENSEIGNER_SORTIE_VEHICULE' = 'RENSEIGNER_SORTIE_VEHICULE',
    'SAISIE_VHU' = 'SAISIE_VHU',
    'SAISIE_NOTIFICATION_MANUELLE' = 'SAISIE_NOTIFICATION_MANUELLE',
    'MAIN_LEVEE' = 'MAIN_LEVEE',
    'TRAITE_EN_FACTURATION' = 'TRAITE_EN_FACTURATION',
    'NON_TRAITE_EN_FACTURATION' = 'NON_TRAITE_EN_FACTURATION',
    'ANNULATION_SORTIE_VEHICULE' = 'ANNULATION_SORTIE_VEHICULE',
    'EXPORT_DONNEES_DOSSIER' = 'EXPORT_DONNEES_DOSSIER',
    'SUPPRESSION_DOSSIER' = 'SUPPRESSION_DOSSIER',
    'UPDATE_ENTITES_RATTACHEMENT_ADMIN' = 'UPDATE_ENTITES_RATTACHEMENT_ADMIN',
    'UPDATE_ENTITES_RATTACHEMENT_FO_AF' = 'UPDATE_ENTITES_RATTACHEMENT_FO_AF',
    'MODIFICATION_SORTIE_VEHICULE' = 'MODIFICATION_SORTIE_VEHICULE',
    'UPDATE_DONNEES_VEHICULE' = 'UPDATE_DONNEES_VEHICULE',
    'UPDATE_CLASSEMENT' = 'UPDATE_CLASSEMENT',
    'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE' = 'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE',
    'ENREGISTRER_ENTREE' = 'ENREGISTRER_ENTREE',
    'CONTROLER_DONNEES_VEHICULE' = 'CONTROLER_DONNEES_VEHICULE',
    'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE' = 'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE',
    'RELANCE_VENTE_VOL' = 'RELANCE_VENTE_VOL',
    'RELANCE_VENTE_IMMO_PJ' = 'RELANCE_VENTE_IMMO_PJ',
    'RELANCE_VENTE_OPPOSITION' = 'RELANCE_VENTE_OPPOSITION',
    'RELANCE_VENTE_DOUBLON' = 'RELANCE_VENTE_DOUBLON',
    'RELANCE_VENTE_MANQUANT' = 'RELANCE_VENTE_MANQUANT',
    'RECUPERATION_AR_PND_AUTOMATIQUE' = 'RECUPERATION_AR_PND_AUTOMATIQUE',
    'CONTROLE_BLOCAGE_ARGOS' = 'CONTROLE_BLOCAGE_ARGOS',
    'CONTROLE_BLOCAGE_ANTAI' = 'CONTROLE_BLOCAGE_ANTAI',
    'CONTROLE_BLOCAGE_DNID' = 'CONTROLE_BLOCAGE_DNID',
    'RECUPERATION_COURRIER_AUTOMATIQUE' = 'RECUPERATION_COURRIER_AUTOMATIQUE',
    'RENSEIGNER_INFORMATIONS_SIV' = 'RENSEIGNER_INFORMATIONS_SIV',
    'CONFIRMER_VOL_VVNR' = 'CONFIRMER_VOL_VVNR',
    'VALIDATION_ACTIONS_VVNR_HORS_SIF' = 'VALIDATION_ACTIONS_VVNR_HORS_SIF'
}

/**
 * 
 * @export
 * @interface AgentDto
 */
export interface AgentDto {
    /**
     * 
     * @type {string}
     * @memberof AgentDto
     */
    communeFonction: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgentDto
     */
    grade: AgentDtoGradeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AgentDto
     */
    matricule: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgentDto
     */
    nom: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AgentDtoGradeEnum {
    'APJA' = 'APJA',
    'APJ' = 'APJ',
    'OPJ' = 'OPJ'
}

/**
 * 
 * @export
 * @interface AgentFoDto
 */
export interface AgentFoDto {
    /**
     * 
     * @type {string}
     * @memberof AgentFoDto
     */
    nigend: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgentFoDto
     */
    nomAgent: string | null;
}
/**
 * 
 * @export
 * @interface AlienationAcheteurDto
 */
export interface AlienationAcheteurDto {
    /**
     * 
     * @type {string}
     * @memberof AlienationAcheteurDto
     */
    nom: string | null;
    /**
     * 
     * @type {string}
     * @memberof AlienationAcheteurDto
     */
    prenom: string | null;
    /**
     * 
     * @type {string}
     * @memberof AlienationAcheteurDto
     */
    raisonSociale: string | null;
}
/**
 * 
 * @export
 * @interface AlienationActiveSolutionDto
 */
export interface AlienationActiveSolutionDto {
    /**
     * 
     * @type {boolean}
     * @memberof AlienationActiveSolutionDto
     */
    apiClientEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AlienationActiveSolutionDto
     */
    contournementImportEnabled: boolean;
}
/**
 * 
 * @export
 * @interface AlienationInformationDto
 */
export interface AlienationInformationDto {
    /**
     * 
     * @type {AlienationAcheteurDto}
     * @memberof AlienationInformationDto
     */
    acheteur: AlienationAcheteurDto | null;
    /**
     * 
     * @type {string}
     * @memberof AlienationInformationDto
     */
    dateDeclarationEnlevement: string | null;
    /**
     * 
     * @type {string}
     * @memberof AlienationInformationDto
     */
    dateRemiseAuDomaine: string | null;
    /**
     * 
     * @type {string}
     * @memberof AlienationInformationDto
     */
    dateTransmissionPhotos: string | null;
    /**
     * 
     * @type {string}
     * @memberof AlienationInformationDto
     */
    decision: AlienationInformationDtoDecisionEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AlienationInformationDto
     */
    idAlienation: string | null;
    /**
     * 
     * @type {string}
     * @memberof AlienationInformationDto
     */
    statut: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AlienationInformationDtoDecisionEnum {
    'EN_ATTENTE' = 'EN_ATTENTE',
    'REFUSE_OU_ECHEC_DE_VENTE' = 'REFUSE_OU_ECHEC_DE_VENTE',
    'REFUSE_SCELLE' = 'REFUSE_SCELLE',
    'EN_VENTE' = 'EN_VENTE',
    'VENDU' = 'VENDU',
    'A_RESTITUER' = 'A_RESTITUER',
    'RESTITUE' = 'RESTITUE',
    'ENLEVE' = 'ENLEVE',
    'BLOCAGE_VENTE_AF' = 'BLOCAGE_VENTE_AF',
    'BLOCAGE_VENTE_FO' = 'BLOCAGE_VENTE_FO',
    'BLOCAGE_VENTE_FO_VOL' = 'BLOCAGE_VENTE_FO_VOL',
    'BLOCAGE_VENTE_ADMIN_IMMO_PJ' = 'BLOCAGE_VENTE_ADMIN_IMMO_PJ',
    'BLOCAGE_VENTE_ADMIN_OPPOSITION' = 'BLOCAGE_VENTE_ADMIN_OPPOSITION',
    'BLOCAGE_VENTE_ADMIN_DOUBLON' = 'BLOCAGE_VENTE_ADMIN_DOUBLON'
}

/**
 * 
 * @export
 * @interface ArgosInformationDto
 */
export interface ArgosInformationDto {
    /**
     * 
     * @type {string}
     * @memberof ArgosInformationDto
     */
    dateFinAttenteAssureur: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArgosInformationDto
     */
    dateMiseADispositionArgos: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArgosInformationDto
     */
    delaiAssureurEcoule: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArgosInformationDto
     */
    identifieVvnr: boolean | null;
    /**
     *
     * @type {InfosAssureurDto}
     * @memberof ArgosInformationDto
     */
    infosAssureurs: InfosAssureurDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArgosInformationDto
     */
    nonPrisEnChargeArgos: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArgosInformationDto
     */
    volConfirme: boolean | null;
}
/**
 * 
 * @export
 * @interface AuthRedirectDto
 */
export interface AuthRedirectDto {
    /**
     * 
     * @type {string}
     * @memberof AuthRedirectDto
     */
    redirectUri: string;
}
/**
 * 
 * @export
 * @interface AutocompleteUniteFOResultDto
 */
export interface AutocompleteUniteFOResultDto {
    /**
     * 
     * @type {string}
     * @memberof AutocompleteUniteFOResultDto
     */
    field: AutocompleteUniteFOResultDtoFieldEnum;
    /**
     * 
     * @type {ReferenceDtoUniteFOResponseDto}
     * @memberof AutocompleteUniteFOResultDto
     */
    reference: ReferenceDtoUniteFOResponseDto;
}

/**
    * @export
    * @enum {string}
    */
export enum AutocompleteUniteFOResultDtoFieldEnum {
    'CODE' = 'CODE',
    'UNITE' = 'UNITE',
    'ABREVIATION' = 'ABREVIATION'
}

/**
 * 
 * @export
 * @interface AutoriteFourriereDto
 */
export interface AutoriteFourriereDto {
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereDto
     */
    cedex: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereDto
     */
    codeInsee: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereDto
     */
    codePostal: string;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereDto
     */
    commentaires: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereDto
     */
    complementAdresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereDto
     */
    dateConfirmationUtilisateur: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AutoriteFourriereDto
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereDto
     */
    idHermes: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereDto
     */
    idHermesUtilisateur: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereDto
     */
    libelleComplet: string;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereDto
     */
    libelleDepartement: string;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereDto
     */
    numeroDepartement: string;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereDto
     */
    numeroVoie: string | null;
    /**
     * 
     * @type {TypeAutoriteFourriereDto}
     * @memberof AutoriteFourriereDto
     */
    type: TypeAutoriteFourriereDto;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereDto
     */
    ville: string;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereDto
     */
    voie: string;
}
/**
 * 
 * @export
 * @interface AutoriteFourriereRequestDto
 */
export interface AutoriteFourriereRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereRequestDto
     */
    cedex: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereRequestDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereRequestDto
     */
    commentaires: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereRequestDto
     */
    complement: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereRequestDto
     */
    idCorrelationType: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereRequestDto
     */
    idHermes: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereRequestDto
     */
    idHermesUtilisateur: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereRequestDto
     */
    insee: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereRequestDto
     */
    libelle: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereRequestDto
     */
    libelleDepartement: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereRequestDto
     */
    numeroDepartement: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereRequestDto
     */
    numeroVoie: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereRequestDto
     */
    ville: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereRequestDto
     */
    voie: string | null;
}
/**
 * 
 * @export
 * @interface AutoriteFourriereViolationDto
 */
export interface AutoriteFourriereViolationDto {
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereViolationDto
     */
    formField: AutoriteFourriereViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof AutoriteFourriereViolationDto
     */
    violationCode: AutoriteFourriereViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AutoriteFourriereViolationDtoFormFieldEnum {
    'NUMERO_DEPARTEMENT' = 'NUMERO_DEPARTEMENT',
    'LIBELLE_DEPARTEMENT' = 'LIBELLE_DEPARTEMENT',
    'TYPE' = 'TYPE',
    'LIBELLE' = 'LIBELLE',
    'CODE_POSTAL' = 'CODE_POSTAL',
    'VILLE' = 'VILLE',
    'VOIE' = 'VOIE',
    'ID_HERMES' = 'ID_HERMES',
    'ID_HERMES_UTILISATEUR' = 'ID_HERMES_UTILISATEUR'
}
/**
    * @export
    * @enum {string}
    */
export enum AutoriteFourriereViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'LIBELLE_ALREADY_EXISTS' = 'LIBELLE_ALREADY_EXISTS',
    'TYPE_NOT_FOUND' = 'TYPE_NOT_FOUND',
    'LIBELLE_BAD_FORMAT' = 'LIBELLE_BAD_FORMAT',
    'CODE_POSTAL_BAD_FORMAT' = 'CODE_POSTAL_BAD_FORMAT',
    'ID_HERMES_BAD_FORMAT' = 'ID_HERMES_BAD_FORMAT',
    'ID_HERMES_UNICITY_VIOLATION' = 'ID_HERMES_UNICITY_VIOLATION',
    'ID_HERMES_UTILISATEUR_UNICITY_VIOLATION' = 'ID_HERMES_UTILISATEUR_UNICITY_VIOLATION',
    'INVALID_TYPE' = 'INVALID_TYPE',
    'TYPE_UNICITY' = 'TYPE_UNICITY'
}

/**
 * 
 * @export
 * @interface AutoriteMainleveeDto
 */
export interface AutoriteMainleveeDto {
    /**
     * 
     * @type {string}
     * @memberof AutoriteMainleveeDto
     */
    description: string | null;
}
/**
 * 
 * @export
 * @interface AutoritePrescriptriceDto
 */
export interface AutoritePrescriptriceDto {
    /**
     * 
     * @type {string}
     * @memberof AutoritePrescriptriceDto
     */
    nomAutorite: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoritePrescriptriceDto
     */
    qualiteAutorite: AutoritePrescriptriceDtoQualiteAutoriteEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AutoritePrescriptriceDtoQualiteAutoriteEnum {
    'OPJ' = 'OPJ',
    'APJ' = 'APJ',
    'APJA' = 'APJA'
}

/**
 * 
 * @export
 * @interface BonEnlevementGardienDto
 */
export interface BonEnlevementGardienDto {
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienDto
     */
    adresse: string;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienDto
     */
    codePostal: string;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienDto
     */
    commune: string;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienDto
     */
    complementAdresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienDto
     */
    dateBon: string;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienDto
     */
    idCorrelationCentreVhu: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BonEnlevementGardienDto
     */
    isCarteGriseRetiree: boolean;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienDto
     */
    motifAbsenceImmatriculation: string | null;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienDto
     */
    numeroAgrement: string;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienDto
     */
    numeroSiren: string;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienDto
     */
    prefectureAgrement: string;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienDto
     */
    raisonSociale: string;
}
/**
 * 
 * @export
 * @interface BonEnlevementGardienRequestDto
 */
export interface BonEnlevementGardienRequestDto {
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienRequestDto
     */
    adresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienRequestDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienRequestDto
     */
    commune: string | null;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienRequestDto
     */
    complementAdresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienRequestDto
     */
    idCorrelationCentreVhu: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BonEnlevementGardienRequestDto
     */
    isCarteGriseRetiree: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienRequestDto
     */
    motifAbsenceImmatriculation: string | null;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienRequestDto
     */
    numeroAgrement: string | null;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienRequestDto
     */
    numeroSiren: string | null;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienRequestDto
     */
    prefectureAgrement: string | null;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienRequestDto
     */
    raisonSociale: string | null;
}
/**
 * 
 * @export
 * @interface BonEnlevementGardienViolationDto
 */
export interface BonEnlevementGardienViolationDto {
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienViolationDto
     */
    formField: BonEnlevementGardienViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof BonEnlevementGardienViolationDto
     */
    violationCode: BonEnlevementGardienViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum BonEnlevementGardienViolationDtoFormFieldEnum {
    'ID_CORRELATION_CENTRE_VHU' = 'ID_CORRELATION_CENTRE_VHU',
    'FLAG_CARTE_GRISE_RETIREE' = 'FLAG_CARTE_GRISE_RETIREE',
    'MOTIF_ABSENCE_IMMATRICULATION' = 'MOTIF_ABSENCE_IMMATRICULATION',
    'RAISON_SOCIALE' = 'RAISON_SOCIALE',
    'NUMERO_SIREN' = 'NUMERO_SIREN',
    'NUMERO_VOIE' = 'NUMERO_VOIE',
    'ADRESSE' = 'ADRESSE',
    'COMPLEMENT_ADRESSE' = 'COMPLEMENT_ADRESSE',
    'CODE_POSTAL' = 'CODE_POSTAL',
    'COMMUNE' = 'COMMUNE',
    'NUMERO_AGREMENT' = 'NUMERO_AGREMENT',
    'PREFECTURE_AGREMENT' = 'PREFECTURE_AGREMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum BonEnlevementGardienViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'CODE_POSTAL_WRONG_FORMAT' = 'CODE_POSTAL_WRONG_FORMAT',
    'NUMERO_SIREN_WRONG_FORMAT' = 'NUMERO_SIREN_WRONG_FORMAT',
    'UNKNOWN_CENTRE_VHU' = 'UNKNOWN_CENTRE_VHU'
}

/**
 * 
 * @export
 * @interface BrancheDto
 */
export interface BrancheDto {
    /**
     * 
     * @type {string}
     * @memberof BrancheDto
     */
    branche: BrancheDtoBrancheEnum;
    /**
     * 
     * @type {string}
     * @memberof BrancheDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BrancheDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof BrancheDto
     */
    nature: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BrancheDtoBrancheEnum {
    'ADMINISTRATIVE' = 'ADMINISTRATIVE',
    'NORMALE' = 'NORMALE',
    'INERTE' = 'INERTE',
    'MAITRE_LIEU' = 'MAITRE_LIEU',
    'VOL' = 'VOL'
}

/**
 * 
 * @export
 * @interface CamundaProcessInstanceDto
 */
export interface CamundaProcessInstanceDto {
    /**
     * 
     * @type {string}
     * @memberof CamundaProcessInstanceDto
     */
    processInstanceId: string;
}
/**
 * 
 * @export
 * @interface CarrosserieClassementManuelDto
 */
export interface CarrosserieClassementManuelDto {
    /**
     * 
     * @type {string}
     * @memberof CarrosserieClassementManuelDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CarrosserieClassementManuelDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof CarrosserieClassementManuelDto
     */
    libelle: string;
}
/**
 * 
 * @export
 * @interface CarrosserieDto
 */
export interface CarrosserieDto {
    /**
     * 
     * @type {string}
     * @memberof CarrosserieDto
     */
    code: string;
    /**
     * 
     * @type {GenreDto}
     * @memberof CarrosserieDto
     */
    genre: GenreDto;
    /**
     * 
     * @type {string}
     * @memberof CarrosserieDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CarrosserieDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof CarrosserieDto
     */
    libelle: string;
}
/**
 * 
 * @export
 * @interface CentreVHUDto
 */
export interface CentreVHUDto {
    /**
     * 
     * @type {string}
     * @memberof CentreVHUDto
     */
    adresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentreVHUDto
     */
    adresseMail: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentreVHUDto
     */
    codePostal: string;
    /**
     * 
     * @type {string}
     * @memberof CentreVHUDto
     */
    commune: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentreVHUDto
     */
    complementAdresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentreVHUDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CentreVHUDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof CentreVHUDto
     */
    numeroAgrementVHU: string;
    /**
     * 
     * @type {string}
     * @memberof CentreVHUDto
     */
    prefecture: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentreVHUDto
     */
    raisonSociale: string;
    /**
     * 
     * @type {string}
     * @memberof CentreVHUDto
     */
    siren: string;
    /**
     * 
     * @type {string}
     * @memberof CentreVHUDto
     */
    telephone: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CentreVHUDto
     */
    valid: boolean;
}
/**
 * 
 * @export
 * @interface CentreVHURequestDto
 */
export interface CentreVHURequestDto {
    /**
     * 
     * @type {string}
     * @memberof CentreVHURequestDto
     */
    adresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentreVHURequestDto
     */
    adresseMail: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentreVHURequestDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentreVHURequestDto
     */
    commune: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentreVHURequestDto
     */
    complementAdresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentreVHURequestDto
     */
    numeroAgrementVHU: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentreVHURequestDto
     */
    prefecture: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentreVHURequestDto
     */
    raisonSociale: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentreVHURequestDto
     */
    siren: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentreVHURequestDto
     */
    telephone: string | null;
}
/**
 * 
 * @export
 * @interface CentreVHUViolationDto
 */
export interface CentreVHUViolationDto {
    /**
     * 
     * @type {string}
     * @memberof CentreVHUViolationDto
     */
    formField: CentreVHUViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof CentreVHUViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof CentreVHUViolationDto
     */
    violationCode: CentreVHUViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CentreVHUViolationDtoFormFieldEnum {
    'RAISON_SOCIALE' = 'RAISON_SOCIALE',
    'SIREN' = 'SIREN',
    'ADRESSE' = 'ADRESSE',
    'COMPLEMENT_ADRESSE' = 'COMPLEMENT_ADRESSE',
    'CODE_POSTAL' = 'CODE_POSTAL',
    'COMMUNE' = 'COMMUNE',
    'TELEPHONE' = 'TELEPHONE',
    'EMAIL' = 'EMAIL',
    'NUMERO_AGREMENT_VHU' = 'NUMERO_AGREMENT_VHU',
    'PREFECTURE_DELIVRANTE' = 'PREFECTURE_DELIVRANTE'
}
/**
    * @export
    * @enum {string}
    */
export enum CentreVHUViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'RAISON_SOCIALE_BAD_FORMAT' = 'RAISON_SOCIALE_BAD_FORMAT',
    'CODE_POSTAL_BAD_FORMAT' = 'CODE_POSTAL_BAD_FORMAT',
    'SIREN_BAD_FORMAT' = 'SIREN_BAD_FORMAT',
    'COMMUNE_BAD_FORMAT' = 'COMMUNE_BAD_FORMAT',
    'TELEPHONE_BAD_FORMAT' = 'TELEPHONE_BAD_FORMAT',
    'NUMERO_AGREMENT_BAD_FORMAT' = 'NUMERO_AGREMENT_BAD_FORMAT',
    'NUMERO_AGREMENT_ALREADY_USED' = 'NUMERO_AGREMENT_ALREADY_USED',
    'EMAIL_BAD_FORMAT' = 'EMAIL_BAD_FORMAT',
    'INVALID_TYPE' = 'INVALID_TYPE'
}

/**
 * 
 * @export
 * @interface ClassementManuelRequestDto
 */
export interface ClassementManuelRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ClassementManuelRequestDto
     */
    carrosserieId: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassementManuelRequestDto
     */
    dangereux: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ClassementManuelRequestDto
     */
    datePremiereImmatriculation: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassementManuelRequestDto
     */
    energieId: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassementManuelRequestDto
     */
    genreId: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClassementManuelRequestDto
     */
    puissanceCv: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassementManuelRequestDto
     */
    reparable: boolean | null;
}
/**
 * 
 * @export
 * @interface ClassementManuelViolationDto
 */
export interface ClassementManuelViolationDto {
    /**
     * 
     * @type {string}
     * @memberof ClassementManuelViolationDto
     */
    formField: ClassementManuelViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof ClassementManuelViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ClassementManuelViolationDto
     */
    violationCode: ClassementManuelViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ClassementManuelViolationDtoFormFieldEnum {
    'DANGEREUX' = 'DANGEREUX',
    'REPARABLE' = 'REPARABLE',
    'ETRANGER' = 'ETRANGER',
    'PREMIERE_IMMATRICULATION' = 'PREMIERE_IMMATRICULATION',
    'PUISSANCE' = 'PUISSANCE',
    'ENERGIE' = 'ENERGIE',
    'CARROSSERIE' = 'CARROSSERIE',
    'GENRE' = 'GENRE',
    'CLASSEMENT' = 'CLASSEMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum ClassementManuelViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'GIVEN_SIV_VALUE_DIFFER_FROM_DOSSIER' = 'GIVEN_SIV_VALUE_DIFFER_FROM_DOSSIER',
    'NOT_FOUND_IN_REFERENTIAL' = 'NOT_FOUND_IN_REFERENTIAL'
}

/**
 * 
 * @export
 * @interface ComputedResponseDto
 */
export interface ComputedResponseDto {
    /**
     * 
     * @type {AutoriteFourriereDto}
     * @memberof ComputedResponseDto
     */
    autoriteFourriereDto: AutoriteFourriereDto | null;
    /**
     * 
     * @type {BrancheDto}
     * @memberof ComputedResponseDto
     */
    currentBrancheWorkflow: BrancheDto | null;
    /**
     * 
     * @type {string}
     * @memberof ComputedResponseDto
     */
    dateAvantClassementDestruction: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputedResponseDto
     */
    dateFinMefAdministrative: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputedResponseDto
     */
    dateNewBranche: string | null;
    /**
     * 
     * @type {FourriereDto}
     * @memberof ComputedResponseDto
     */
    fourriereDto: FourriereDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof ComputedResponseDto
     */
    impossibiliteNotifier: boolean | null;
    /**
     * 
     * @type {Set<string>}
     * @memberof ComputedResponseDto
     */
    listActions: Array<ComputedResponseDtoListActionsEnum> | null;
    /**
     * 
     * @type {Set<string>}
     * @memberof ComputedResponseDto
     */
    listActionsRequises: Array<ComputedResponseDtoListActionsRequisesEnum> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ComputedResponseDto
     */
    listAvailableFiles: Array<ComputedResponseDtoListAvailableFilesEnum> | null;
    /**
     * 
     * @type {string}
     * @memberof ComputedResponseDto
     */
    statutConsultable: ComputedResponseDtoStatutConsultableEnum | null;
    /**
     * 
     * @type {UniteFOResponseDto}
     * @memberof ComputedResponseDto
     */
    uniteFODto: UniteFOResponseDto | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ComputedResponseDtoListActionsEnum {
    'PROLONGER_MEF' = 'PROLONGER_MEF',
    'CLASSER_MANUELLEMENT' = 'CLASSER_MANUELLEMENT',
    'MODIFIER_COORDONNEES_PROPRIETAIRE' = 'MODIFIER_COORDONNEES_PROPRIETAIRE',
    'CORRIGER_DONNEES_NOTIFICATION_MANUELLE' = 'CORRIGER_DONNEES_NOTIFICATION_MANUELLE',
    'RENSEIGNER_SORTIE_VEHICULE' = 'RENSEIGNER_SORTIE_VEHICULE',
    'SAISIE_VHU' = 'SAISIE_VHU',
    'SAISIE_NOTIFICATION_MANUELLE' = 'SAISIE_NOTIFICATION_MANUELLE',
    'MAIN_LEVEE' = 'MAIN_LEVEE',
    'TRAITE_EN_FACTURATION' = 'TRAITE_EN_FACTURATION',
    'NON_TRAITE_EN_FACTURATION' = 'NON_TRAITE_EN_FACTURATION',
    'ANNULATION_SORTIE_VEHICULE' = 'ANNULATION_SORTIE_VEHICULE',
    'EXPORT_DONNEES_DOSSIER' = 'EXPORT_DONNEES_DOSSIER',
    'SUPPRESSION_DOSSIER' = 'SUPPRESSION_DOSSIER',
    'UPDATE_ENTITES_RATTACHEMENT_ADMIN' = 'UPDATE_ENTITES_RATTACHEMENT_ADMIN',
    'UPDATE_ENTITES_RATTACHEMENT_FO_AF' = 'UPDATE_ENTITES_RATTACHEMENT_FO_AF',
    'MODIFICATION_SORTIE_VEHICULE' = 'MODIFICATION_SORTIE_VEHICULE',
    'UPDATE_DONNEES_VEHICULE' = 'UPDATE_DONNEES_VEHICULE',
    'UPDATE_CLASSEMENT' = 'UPDATE_CLASSEMENT',
    'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE' = 'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE',
    'ENREGISTRER_ENTREE' = 'ENREGISTRER_ENTREE',
    'CONTROLER_DONNEES_VEHICULE' = 'CONTROLER_DONNEES_VEHICULE',
    'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE' = 'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE',
    'RELANCE_VENTE_VOL' = 'RELANCE_VENTE_VOL',
    'RELANCE_VENTE_IMMO_PJ' = 'RELANCE_VENTE_IMMO_PJ',
    'RELANCE_VENTE_OPPOSITION' = 'RELANCE_VENTE_OPPOSITION',
    'RELANCE_VENTE_DOUBLON' = 'RELANCE_VENTE_DOUBLON',
    'RELANCE_VENTE_MANQUANT' = 'RELANCE_VENTE_MANQUANT',
    'RECUPERATION_AR_PND_AUTOMATIQUE' = 'RECUPERATION_AR_PND_AUTOMATIQUE',
    'CONTROLE_BLOCAGE_ARGOS' = 'CONTROLE_BLOCAGE_ARGOS',
    'CONTROLE_BLOCAGE_ANTAI' = 'CONTROLE_BLOCAGE_ANTAI',
    'CONTROLE_BLOCAGE_DNID' = 'CONTROLE_BLOCAGE_DNID',
    'RECUPERATION_COURRIER_AUTOMATIQUE' = 'RECUPERATION_COURRIER_AUTOMATIQUE',
    'RENSEIGNER_INFORMATIONS_SIV' = 'RENSEIGNER_INFORMATIONS_SIV',
    'CONFIRMER_VOL_VVNR' = 'CONFIRMER_VOL_VVNR',
    'VALIDATION_ACTIONS_VVNR_HORS_SIF' = 'VALIDATION_ACTIONS_VVNR_HORS_SIF'
}
/**
    * @export
    * @enum {string}
    */
export enum ComputedResponseDtoListActionsRequisesEnum {
    'PROLONGER_MEF' = 'PROLONGER_MEF',
    'CLASSER_MANUELLEMENT' = 'CLASSER_MANUELLEMENT',
    'MODIFIER_COORDONNEES_PROPRIETAIRE' = 'MODIFIER_COORDONNEES_PROPRIETAIRE',
    'CORRIGER_DONNEES_NOTIFICATION_MANUELLE' = 'CORRIGER_DONNEES_NOTIFICATION_MANUELLE',
    'RENSEIGNER_SORTIE_VEHICULE' = 'RENSEIGNER_SORTIE_VEHICULE',
    'SAISIE_VHU' = 'SAISIE_VHU',
    'SAISIE_NOTIFICATION_MANUELLE' = 'SAISIE_NOTIFICATION_MANUELLE',
    'MAIN_LEVEE' = 'MAIN_LEVEE',
    'TRAITE_EN_FACTURATION' = 'TRAITE_EN_FACTURATION',
    'NON_TRAITE_EN_FACTURATION' = 'NON_TRAITE_EN_FACTURATION',
    'ANNULATION_SORTIE_VEHICULE' = 'ANNULATION_SORTIE_VEHICULE',
    'EXPORT_DONNEES_DOSSIER' = 'EXPORT_DONNEES_DOSSIER',
    'SUPPRESSION_DOSSIER' = 'SUPPRESSION_DOSSIER',
    'UPDATE_ENTITES_RATTACHEMENT_ADMIN' = 'UPDATE_ENTITES_RATTACHEMENT_ADMIN',
    'UPDATE_ENTITES_RATTACHEMENT_FO_AF' = 'UPDATE_ENTITES_RATTACHEMENT_FO_AF',
    'MODIFICATION_SORTIE_VEHICULE' = 'MODIFICATION_SORTIE_VEHICULE',
    'UPDATE_DONNEES_VEHICULE' = 'UPDATE_DONNEES_VEHICULE',
    'UPDATE_CLASSEMENT' = 'UPDATE_CLASSEMENT',
    'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE' = 'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE',
    'ENREGISTRER_ENTREE' = 'ENREGISTRER_ENTREE',
    'CONTROLER_DONNEES_VEHICULE' = 'CONTROLER_DONNEES_VEHICULE',
    'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE' = 'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE',
    'RELANCE_VENTE_VOL' = 'RELANCE_VENTE_VOL',
    'RELANCE_VENTE_IMMO_PJ' = 'RELANCE_VENTE_IMMO_PJ',
    'RELANCE_VENTE_OPPOSITION' = 'RELANCE_VENTE_OPPOSITION',
    'RELANCE_VENTE_DOUBLON' = 'RELANCE_VENTE_DOUBLON',
    'RELANCE_VENTE_MANQUANT' = 'RELANCE_VENTE_MANQUANT',
    'RECUPERATION_AR_PND_AUTOMATIQUE' = 'RECUPERATION_AR_PND_AUTOMATIQUE',
    'CONTROLE_BLOCAGE_ARGOS' = 'CONTROLE_BLOCAGE_ARGOS',
    'CONTROLE_BLOCAGE_ANTAI' = 'CONTROLE_BLOCAGE_ANTAI',
    'CONTROLE_BLOCAGE_DNID' = 'CONTROLE_BLOCAGE_DNID',
    'RECUPERATION_COURRIER_AUTOMATIQUE' = 'RECUPERATION_COURRIER_AUTOMATIQUE',
    'RENSEIGNER_INFORMATIONS_SIV' = 'RENSEIGNER_INFORMATIONS_SIV',
    'CONFIRMER_VOL_VVNR' = 'CONFIRMER_VOL_VVNR',
    'VALIDATION_ACTIONS_VVNR_HORS_SIF' = 'VALIDATION_ACTIONS_VVNR_HORS_SIF'
}
/**
    * @export
    * @enum {string}
    */
export enum ComputedResponseDtoListAvailableFilesEnum {
    'DECISION_CLASSEMENT' = 'DECISION_CLASSEMENT',
    'DECISION_DESTRUCTION' = 'DECISION_DESTRUCTION',
    'CONSTAT_ABANDON' = 'CONSTAT_ABANDON',
    'DECISION_REMISE_DOMAINE' = 'DECISION_REMISE_DOMAINE',
    'BON_ENLEVEMENT_DESTRUCTION' = 'BON_ENLEVEMENT_DESTRUCTION',
    'FICHE_DESCRIPTIVE' = 'FICHE_DESCRIPTIVE',
    'PRESCRIPTION_MEF' = 'PRESCRIPTION_MEF',
    'MAIN_LEVEE_FO' = 'MAIN_LEVEE_FO',
    'MAIN_LEVEE_USAGER' = 'MAIN_LEVEE_USAGER',
    'COURRIER_NOTIFICATION' = 'COURRIER_NOTIFICATION',
    'AR_PND_NOTIFICATION' = 'AR_PND_NOTIFICATION'
}
/**
    * @export
    * @enum {string}
    */
export enum ComputedResponseDtoStatutConsultableEnum {
    'A_REMPLIR' = 'A_REMPLIR',
    'ENREGISTRE' = 'ENREGISTRE',
    'NON_GERE' = 'NON_GERE',
    'NOTIFICATION_ENVOYEE' = 'NOTIFICATION_ENVOYEE',
    'NOTIFIE' = 'NOTIFIE',
    'A_NOTIFIER' = 'A_NOTIFIER',
    'EN_COURS_DE_NOTIFICATION' = 'EN_COURS_DE_NOTIFICATION',
    'A_REMETTRE_AUX_DOMAINES' = 'A_REMETTRE_AUX_DOMAINES',
    'REMIS_AU_DOMAINE' = 'REMIS_AU_DOMAINE',
    'MIS_EN_VENTE' = 'MIS_EN_VENTE',
    'VENDU' = 'VENDU',
    'A_RESTITUER' = 'A_RESTITUER',
    'VENTE_BLOQUEE' = 'VENTE_BLOQUEE',
    'A_DETRUIRE' = 'A_DETRUIRE',
    'SORTI' = 'SORTI',
    'DETRUIT' = 'DETRUIT'
}

/**
 * 
 * @export
 * @interface ComputedSummaryResponseDto
 */
export interface ComputedSummaryResponseDto {
    /**
     * 
     * @type {BrancheDto}
     * @memberof ComputedSummaryResponseDto
     */
    brancheDto: BrancheDto | null;
    /**
     * 
     * @type {FourriereDto}
     * @memberof ComputedSummaryResponseDto
     */
    fourriereDto: FourriereDto | null;
    /**
     * 
     * @type {Set<string>}
     * @memberof ComputedSummaryResponseDto
     */
    listActionsRequises: Array<ComputedSummaryResponseDtoListActionsRequisesEnum> | null;
    /**
     * 
     * @type {string}
     * @memberof ComputedSummaryResponseDto
     */
    statutConsultable: ComputedSummaryResponseDtoStatutConsultableEnum | null;
    /**
     * 
     * @type {string}
     * @memberof ComputedSummaryResponseDto
     */
    uniteFo: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComputedSummaryResponseDto
     */
    uniteFoAbreviation: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ComputedSummaryResponseDtoListActionsRequisesEnum {
    'PROLONGER_MEF' = 'PROLONGER_MEF',
    'CLASSER_MANUELLEMENT' = 'CLASSER_MANUELLEMENT',
    'MODIFIER_COORDONNEES_PROPRIETAIRE' = 'MODIFIER_COORDONNEES_PROPRIETAIRE',
    'CORRIGER_DONNEES_NOTIFICATION_MANUELLE' = 'CORRIGER_DONNEES_NOTIFICATION_MANUELLE',
    'RENSEIGNER_SORTIE_VEHICULE' = 'RENSEIGNER_SORTIE_VEHICULE',
    'SAISIE_VHU' = 'SAISIE_VHU',
    'SAISIE_NOTIFICATION_MANUELLE' = 'SAISIE_NOTIFICATION_MANUELLE',
    'MAIN_LEVEE' = 'MAIN_LEVEE',
    'TRAITE_EN_FACTURATION' = 'TRAITE_EN_FACTURATION',
    'NON_TRAITE_EN_FACTURATION' = 'NON_TRAITE_EN_FACTURATION',
    'ANNULATION_SORTIE_VEHICULE' = 'ANNULATION_SORTIE_VEHICULE',
    'EXPORT_DONNEES_DOSSIER' = 'EXPORT_DONNEES_DOSSIER',
    'SUPPRESSION_DOSSIER' = 'SUPPRESSION_DOSSIER',
    'UPDATE_ENTITES_RATTACHEMENT_ADMIN' = 'UPDATE_ENTITES_RATTACHEMENT_ADMIN',
    'UPDATE_ENTITES_RATTACHEMENT_FO_AF' = 'UPDATE_ENTITES_RATTACHEMENT_FO_AF',
    'MODIFICATION_SORTIE_VEHICULE' = 'MODIFICATION_SORTIE_VEHICULE',
    'UPDATE_DONNEES_VEHICULE' = 'UPDATE_DONNEES_VEHICULE',
    'UPDATE_CLASSEMENT' = 'UPDATE_CLASSEMENT',
    'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE' = 'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE',
    'ENREGISTRER_ENTREE' = 'ENREGISTRER_ENTREE',
    'CONTROLER_DONNEES_VEHICULE' = 'CONTROLER_DONNEES_VEHICULE',
    'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE' = 'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE',
    'RELANCE_VENTE_VOL' = 'RELANCE_VENTE_VOL',
    'RELANCE_VENTE_IMMO_PJ' = 'RELANCE_VENTE_IMMO_PJ',
    'RELANCE_VENTE_OPPOSITION' = 'RELANCE_VENTE_OPPOSITION',
    'RELANCE_VENTE_DOUBLON' = 'RELANCE_VENTE_DOUBLON',
    'RELANCE_VENTE_MANQUANT' = 'RELANCE_VENTE_MANQUANT',
    'RECUPERATION_AR_PND_AUTOMATIQUE' = 'RECUPERATION_AR_PND_AUTOMATIQUE',
    'CONTROLE_BLOCAGE_ARGOS' = 'CONTROLE_BLOCAGE_ARGOS',
    'CONTROLE_BLOCAGE_ANTAI' = 'CONTROLE_BLOCAGE_ANTAI',
    'CONTROLE_BLOCAGE_DNID' = 'CONTROLE_BLOCAGE_DNID',
    'RECUPERATION_COURRIER_AUTOMATIQUE' = 'RECUPERATION_COURRIER_AUTOMATIQUE',
    'RENSEIGNER_INFORMATIONS_SIV' = 'RENSEIGNER_INFORMATIONS_SIV',
    'CONFIRMER_VOL_VVNR' = 'CONFIRMER_VOL_VVNR',
    'VALIDATION_ACTIONS_VVNR_HORS_SIF' = 'VALIDATION_ACTIONS_VVNR_HORS_SIF'
}
/**
    * @export
    * @enum {string}
    */
export enum ComputedSummaryResponseDtoStatutConsultableEnum {
    'A_REMPLIR' = 'A_REMPLIR',
    'ENREGISTRE' = 'ENREGISTRE',
    'NON_GERE' = 'NON_GERE',
    'NOTIFICATION_ENVOYEE' = 'NOTIFICATION_ENVOYEE',
    'NOTIFIE' = 'NOTIFIE',
    'A_NOTIFIER' = 'A_NOTIFIER',
    'EN_COURS_DE_NOTIFICATION' = 'EN_COURS_DE_NOTIFICATION',
    'A_REMETTRE_AUX_DOMAINES' = 'A_REMETTRE_AUX_DOMAINES',
    'REMIS_AU_DOMAINE' = 'REMIS_AU_DOMAINE',
    'MIS_EN_VENTE' = 'MIS_EN_VENTE',
    'VENDU' = 'VENDU',
    'A_RESTITUER' = 'A_RESTITUER',
    'VENTE_BLOQUEE' = 'VENTE_BLOQUEE',
    'A_DETRUIRE' = 'A_DETRUIRE',
    'SORTI' = 'SORTI',
    'DETRUIT' = 'DETRUIT'
}

/**
 * 
 * @export
 * @interface ConditionsReactivateAutoriteFourriereDto
 */
export interface ConditionsReactivateAutoriteFourriereDto {
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateAutoriteFourriereDto
     */
    alreadyExistsLibelle: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateAutoriteFourriereDto
     */
    alreadyExistsType: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateAutoriteFourriereDto
     */
    autoriteFourriereValid: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateAutoriteFourriereDto
     */
    reactivationAvailable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateAutoriteFourriereDto
     */
    typeValid: boolean;
}
/**
 * 
 * @export
 * @interface ConditionsReactivateCentreVHUDto
 */
export interface ConditionsReactivateCentreVHUDto {
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateCentreVHUDto
     */
    alreadyExists: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateCentreVHUDto
     */
    centreVHUValid: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateCentreVHUDto
     */
    reactivationAvailable: boolean;
}
/**
 * 
 * @export
 * @interface ConditionsReactivateFaqDomainDto
 */
export interface ConditionsReactivateFaqDomainDto {
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateFaqDomainDto
     */
    alreadyExists: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateFaqDomainDto
     */
    faqDomainValid: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateFaqDomainDto
     */
    reactivationAvailable: boolean;
}
/**
 * 
 * @export
 * @interface ConditionsReactivateFaqDto
 */
export interface ConditionsReactivateFaqDto {
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateFaqDto
     */
    alreadyExists: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateFaqDto
     */
    faqValid: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateFaqDto
     */
    reactivationAvailable: boolean;
}
/**
 * 
 * @export
 * @interface ConditionsReactivateFourriereDto
 */
export interface ConditionsReactivateFourriereDto {
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateFourriereDto
     */
    alreadyExists: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateFourriereDto
     */
    fourriereValid: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateFourriereDto
     */
    reactivationAvailable: boolean;
}
/**
 * 
 * @export
 * @interface ConditionsReactivateMotifMefDto
 */
export interface ConditionsReactivateMotifMefDto {
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateMotifMefDto
     */
    alreadyExist: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateMotifMefDto
     */
    motifMefValid: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateMotifMefDto
     */
    reactivationAvailable: boolean | null;
}
/**
 * 
 * @export
 * @interface ConditionsReactivateMotifSortieDto
 */
export interface ConditionsReactivateMotifSortieDto {
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateMotifSortieDto
     */
    alreadyExist: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateMotifSortieDto
     */
    motifSortieValid: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateMotifSortieDto
     */
    reactivationAvailable: boolean | null;
}
/**
 * 
 * @export
 * @interface ConditionsReactivateTypeAutoriteFourriereDto
 */
export interface ConditionsReactivateTypeAutoriteFourriereDto {
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateTypeAutoriteFourriereDto
     */
    alreadyExist: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateTypeAutoriteFourriereDto
     */
    reactivationAvailable: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateTypeAutoriteFourriereDto
     */
    typeValid: boolean | null;
}
/**
 * 
 * @export
 * @interface ConditionsReactivateUniteFoDto
 */
export interface ConditionsReactivateUniteFoDto {
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateUniteFoDto
     */
    reactivationAvailable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateUniteFoDto
     */
    uniteAbreviationUnique: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateUniteFoDto
     */
    uniteCodeUnique: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateUniteFoDto
     */
    uniteParentValid: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateUniteFoDto
     */
    uniteUniteUnique: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsReactivateUniteFoDto
     */
    uniteValid: boolean;
}
/**
 * 
 * @export
 * @interface ControleDonneesVehiculeBlocageVenteRequestDto
 */
export interface ControleDonneesVehiculeBlocageVenteRequestDto {
    /**
     * 
     * @type {ControleDonneesVehiculeRequestDto}
     * @memberof ControleDonneesVehiculeBlocageVenteRequestDto
     */
    nouvellesDonneesVehicule: ControleDonneesVehiculeRequestDto;
    /**
     * 
     * @type {string}
     * @memberof ControleDonneesVehiculeBlocageVenteRequestDto
     */
    statut: string;
}
/**
 * 
 * @export
 * @interface ControleDonneesVehiculeRequestDto
 */
export interface ControleDonneesVehiculeRequestDto {
    /**
     * 
     * @type {RequestHeaders}
     * @memberof ControleDonneesVehiculeRequestDto
     */
    headers: RequestHeaders | null;
    /**
     * 
     * @type {string}
     * @memberof ControleDonneesVehiculeRequestDto
     */
    newGenre: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControleDonneesVehiculeRequestDto
     */
    newMarque: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControleDonneesVehiculeRequestDto
     */
    newMarqueNonReferencee: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ControleDonneesVehiculeRequestDto
     */
    newModele: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControleDonneesVehiculeRequestDto
     */
    newModeleNonReference: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControleDonneesVehiculeRequestDto
     */
    newPaysEtranger: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ControleDonneesVehiculeRequestDto
     */
    newPlaqueImmatriculation: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControleDonneesVehiculeRequestDto
     */
    newPlaqueImmatriculationVisible: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControleDonneesVehiculeRequestDto
     */
    newSansPlaque: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ControleDonneesVehiculeRequestDto
     */
    newVin: string | null;
}
/**
 * 
 * @export
 * @interface ControleDonneesVehiculeViolationDto
 */
export interface ControleDonneesVehiculeViolationDto {
    /**
     * 
     * @type {string}
     * @memberof ControleDonneesVehiculeViolationDto
     */
    formField: ControleDonneesVehiculeViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof ControleDonneesVehiculeViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ControleDonneesVehiculeViolationDto
     */
    violationCode: ControleDonneesVehiculeViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ControleDonneesVehiculeViolationDtoFormFieldEnum {
    'NEW_MARQUE' = 'NEW_MARQUE',
    'NEW_MODELE' = 'NEW_MODELE',
    'NEW_GENRE' = 'NEW_GENRE',
    'NEW_PAYS_ETRANGER' = 'NEW_PAYS_ETRANGER',
    'NEW_SANS_PLAQUE' = 'NEW_SANS_PLAQUE',
    'NEW_PLAQUE' = 'NEW_PLAQUE',
    'NEW_PLAQUE_VISIBLE' = 'NEW_PLAQUE_VISIBLE',
    'NEW_VIN' = 'NEW_VIN'
}
/**
    * @export
    * @enum {string}
    */
export enum ControleDonneesVehiculeViolationDtoViolationCodeEnum {
    'MISSING_VALUE' = 'MISSING_VALUE',
    'BAD_FORMAT_IMMATRICULATION' = 'BAD_FORMAT_IMMATRICULATION',
    'IMMATRICULATION_ALREADY_EXISTS' = 'IMMATRICULATION_ALREADY_EXISTS',
    'BAD_FORMAT_IDENTIFICATION' = 'BAD_FORMAT_IDENTIFICATION',
    'BAD_VIN' = 'BAD_VIN',
    'VIN_ALREADY_EXISTS' = 'VIN_ALREADY_EXISTS',
    'BAD_NUMERO_SERIE_DICEM' = 'BAD_NUMERO_SERIE_DICEM',
    'FORBIDDEN_PLAQUE' = 'FORBIDDEN_PLAQUE',
    'MARQUE_NOT_FOUND' = 'MARQUE_NOT_FOUND',
    'MODELE_NOT_FOUND' = 'MODELE_NOT_FOUND',
    'FORBIDDEN_MARQUE' = 'FORBIDDEN_MARQUE',
    'FORBIDDEN_MODELE' = 'FORBIDDEN_MODELE',
    'GENRE_NOT_FOUND' = 'GENRE_NOT_FOUND',
    'WRONG_MODELE' = 'WRONG_MODELE'
}

/**
 * 
 * @export
 * @interface ConventionnementSwaPartDto
 */
export interface ConventionnementSwaPartDto {
    /**
     * 
     * @type {string}
     * @memberof ConventionnementSwaPartDto
     */
    dateDebut: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConventionnementSwaPartDto
     */
    dateFin: string | null;
}
/**
 * 
 * @export
 * @interface CoordonneesProprietaireRequestDto
 */
export interface CoordonneesProprietaireRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireRequestDto
     */
    boitePostale: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireRequestDto
     */
    codeCedex: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireRequestDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireRequestDto
     */
    complementAdresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireRequestDto
     */
    extension: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireRequestDto
     */
    libelleCedex: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireRequestDto
     */
    libelleCommune: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireRequestDto
     */
    libelleVoie: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireRequestDto
     */
    lieuDit: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireRequestDto
     */
    nom: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireRequestDto
     */
    numero: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireRequestDto
     */
    pays: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireRequestDto
     */
    pointRemise: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireRequestDto
     */
    prenom: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireRequestDto
     */
    raisonSociale: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireRequestDto
     */
    typeVoie: string | null;
}
/**
 * 
 * @export
 * @interface CoordonneesProprietaireResponseDto
 */
export interface CoordonneesProprietaireResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireResponseDto
     */
    boitePostale: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireResponseDto
     */
    codeCedex: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireResponseDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireResponseDto
     */
    complementAdresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireResponseDto
     */
    extension: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireResponseDto
     */
    libelleCedex: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireResponseDto
     */
    libelleCommune: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireResponseDto
     */
    libelleVoie: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireResponseDto
     */
    lieuDit: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireResponseDto
     */
    nom: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireResponseDto
     */
    numero: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireResponseDto
     */
    pays: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireResponseDto
     */
    pointRemise: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireResponseDto
     */
    prenom: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireResponseDto
     */
    raisonSociale: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireResponseDto
     */
    typeVoie: string | null;
}
/**
 * 
 * @export
 * @interface CoordonneesProprietaireViolationDto
 */
export interface CoordonneesProprietaireViolationDto {
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireViolationDto
     */
    formField: CoordonneesProprietaireViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesProprietaireViolationDto
     */
    violationCode: CoordonneesProprietaireViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CoordonneesProprietaireViolationDtoFormFieldEnum {
    'PRENOM' = 'PRENOM',
    'NOM' = 'NOM',
    'RAISON_SOCIALE' = 'RAISON_SOCIALE',
    'POINT_REMISE' = 'POINT_REMISE',
    'NUMERO' = 'NUMERO',
    'EXTENSION' = 'EXTENSION',
    'TYPEVOIE' = 'TYPEVOIE',
    'LIBELLE_VOIE' = 'LIBELLE_VOIE',
    'COMPLEMENT_ADRESSE' = 'COMPLEMENT_ADRESSE',
    'LIEUDIT' = 'LIEUDIT',
    'BOITE_POSTALE' = 'BOITE_POSTALE',
    'CODE_POSTAL' = 'CODE_POSTAL',
    'LIBELLE_COMMUNE' = 'LIBELLE_COMMUNE',
    'CODE_CEDEX' = 'CODE_CEDEX',
    'LIBELLE_CEDEX' = 'LIBELLE_CEDEX',
    'PAYS' = 'PAYS'
}
/**
    * @export
    * @enum {string}
    */
export enum CoordonneesProprietaireViolationDtoViolationCodeEnum {
    'CODE_POSTAL_WRONG_FORMAT' = 'CODE_POSTAL_WRONG_FORMAT'
}

/**
 * 
 * @export
 * @interface CouleurDto
 */
export interface CouleurDto {
    /**
     * 
     * @type {number}
     * @memberof CouleurDto
     */
    code: number | null;
    /**
     * 
     * @type {string}
     * @memberof CouleurDto
     */
    couleur: string;
    /**
     * 
     * @type {string}
     * @memberof CouleurDto
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CountDossiersResultDto
 */
export interface CountDossiersResultDto {
    /**
     * 
     * @type {number}
     * @memberof CountDossiersResultDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface CreateDossierFromForceOrdreDto
 */
export interface CreateDossierFromForceOrdreDto {
    /**
     * 
     * @type {FicheAgentFoRequestDto}
     * @memberof CreateDossierFromForceOrdreDto
     */
    ficheAgentFoRequest: FicheAgentFoRequestDto | null;
    /**
     * 
     * @type {FicheConditionsRequestDto}
     * @memberof CreateDossierFromForceOrdreDto
     */
    ficheConditionsRequest: FicheConditionsRequestDto | null;
    /**
     * 
     * @type {FicheDetailsVehiculeFoRequestDto}
     * @memberof CreateDossierFromForceOrdreDto
     */
    ficheDetailsVehiculeRequest: FicheDetailsVehiculeFoRequestDto | null;
    /**
     * 
     * @type {FicheEtatGeneralFoRequestDto}
     * @memberof CreateDossierFromForceOrdreDto
     */
    ficheEtatGeneralRequest: FicheEtatGeneralFoRequestDto | null;
    /**
     * 
     * @type {FicheFourriereFoRequestDto}
     * @memberof CreateDossierFromForceOrdreDto
     */
    ficheFourriereRequest: FicheFourriereFoRequestDto | null;
    /**
     * 
     * @type {FicheVehiculeFoRequestDto}
     * @memberof CreateDossierFromForceOrdreDto
     */
    ficheVehiculeRequest: FicheVehiculeFoRequestDto | null;
}
/**
 * 
 * @export
 * @interface CreateDossierFromGardienFourriereDto
 */
export interface CreateDossierFromGardienFourriereDto {
    /**
     * 
     * @type {FicheFourriereRequestDto}
     * @memberof CreateDossierFromGardienFourriereDto
     */
    ficheFourriereRequest: FicheFourriereRequestDto | null;
    /**
     * 
     * @type {FicheInfractionRequestDto}
     * @memberof CreateDossierFromGardienFourriereDto
     */
    ficheInfractionRequest: FicheInfractionRequestDto | null;
    /**
     * 
     * @type {FicheVehiculeRequestDto}
     * @memberof CreateDossierFromGardienFourriereDto
     */
    ficheVehiculeRequest: FicheVehiculeRequestDto | null;
}
/**
 * 
 * @export
 * @interface CreateExportResponseDto
 */
export interface CreateExportResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CreateExportResponseDto
     */
    exportId: string;
}
/**
 * 
 * @export
 * @interface CreateUserRequestDto
 */
export interface CreateUserRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequestDto
     */
    actif: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDto
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDto
     */
    nom: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDto
     */
    prenom: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDto
     */
    profile: CreateUserRequestDtoProfileEnum | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDto
     */
    uniteRattachement: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateUserRequestDtoProfileEnum {
    'AUTORITE_FOURRIERE' = 'AUTORITE_FOURRIERE',
    'DSR_BLR' = 'DSR_BLR',
    'FORCE_DE_L_ORDRE' = 'FORCE_DE_L_ORDRE',
    'GARDIEN_FOURRIERE' = 'GARDIEN_FOURRIERE',
    'ADMIN_FONCTIONNEL' = 'ADMIN_FONCTIONNEL',
    'CSU' = 'CSU'
}

/**
 * 
 * @export
 * @interface CreateUserResultDto
 */
export interface CreateUserResultDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserResultDto
     */
    username: string;
}
/**
 * 
 * @export
 * @interface CreateViolationDto
 */
export interface CreateViolationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateViolationDto
     */
    formField: CreateViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof CreateViolationDto
     */
    violationCode: CreateViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateViolationDtoFormFieldEnum {
    'PRENOM' = 'PRENOM',
    'NOM' = 'NOM',
    'EMAIL' = 'EMAIL',
    'ENTITE_RATTACHEMENT' = 'ENTITE_RATTACHEMENT',
    'PROFILE' = 'PROFILE',
    'ACTIF' = 'ACTIF'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'BAD_FORMAT_EMAIL' = 'BAD_FORMAT_EMAIL',
    'INVALID_ENTITE_RATTACHEMENT' = 'INVALID_ENTITE_RATTACHEMENT'
}

/**
 * 
 * @export
 * @interface DescriptionDto
 */
export interface DescriptionDto {
    /**
     * 
     * @type {string}
     * @memberof DescriptionDto
     */
    couleur: string | null;
    /**
     * 
     * @type {GenreSimplifieDto}
     * @memberof DescriptionDto
     */
    genreSimplifie: GenreSimplifieDto | null;
    /**
     * 
     * @type {MarqueDto}
     * @memberof DescriptionDto
     */
    marque: MarqueDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof DescriptionDto
     */
    marqueNonReferencee: boolean | null;
    /**
     * 
     * @type {ModeleDto}
     * @memberof DescriptionDto
     */
    modele: ModeleDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof DescriptionDto
     */
    modeleNonReference: boolean | null;
    /**
     * 
     * @type {ProprietaireDto}
     * @memberof DescriptionDto
     */
    proprietaire: ProprietaireDto | null;
    /**
     * 
     * @type {string}
     * @memberof DescriptionDto
     */
    type: string | null;
}
/**
 * 
 * @export
 * @interface DetailedEventDto
 */
export interface DetailedEventDto {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof DetailedEventDto
     */
    content: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof DetailedEventDto
     */
    date: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailedEventDto
     */
    details: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DetailedEventDto
     */
    inducedStatutDossier: DetailedEventDtoInducedStatutDossierEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedEventDto
     */
    isIncident: boolean;
    /**
     * 
     * @type {string}
     * @memberof DetailedEventDto
     */
    label: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DetailedEventDtoInducedStatutDossierEnum {
    'A_REMPLIR' = 'A_REMPLIR',
    'ENREGISTRE' = 'ENREGISTRE',
    'NON_GERE' = 'NON_GERE',
    'NOTIFICATION_ENVOYEE' = 'NOTIFICATION_ENVOYEE',
    'NOTIFIE' = 'NOTIFIE',
    'A_NOTIFIER' = 'A_NOTIFIER',
    'EN_COURS_DE_NOTIFICATION' = 'EN_COURS_DE_NOTIFICATION',
    'A_REMETTRE_AUX_DOMAINES' = 'A_REMETTRE_AUX_DOMAINES',
    'REMIS_AU_DOMAINE' = 'REMIS_AU_DOMAINE',
    'MIS_EN_VENTE' = 'MIS_EN_VENTE',
    'VENDU' = 'VENDU',
    'A_RESTITUER' = 'A_RESTITUER',
    'VENTE_BLOQUEE' = 'VENTE_BLOQUEE',
    'A_DETRUIRE' = 'A_DETRUIRE',
    'SORTI' = 'SORTI',
    'DETRUIT' = 'DETRUIT'
}

/**
 * 
 * @export
 * @interface DetailedInformationMessageDto
 */
export interface DetailedInformationMessageDto {
    /**
     * 
     * @type {boolean}
     * @memberof DetailedInformationMessageDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof DetailedInformationMessageDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedInformationMessageDto
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedInformationMessageDto
     */
    id: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof DetailedInformationMessageDto
     */
    profiles: Array<DetailedInformationMessageDtoProfilesEnum>;
    /**
     * 
     * @type {string}
     * @memberof DetailedInformationMessageDto
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedInformationMessageDto
     */
    title: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DetailedInformationMessageDtoProfilesEnum {
    'AUTORITE_FOURRIERE' = 'AUTORITE_FOURRIERE',
    'FORCE_DE_L_ORDRE' = 'FORCE_DE_L_ORDRE',
    'GARDIEN_FOURRIERE' = 'GARDIEN_FOURRIERE',
    'BORD_DE_ROUTE' = 'BORD_DE_ROUTE'
}

/**
 * 
 * @export
 * @interface DonneesAControlerResponseDto
 */
export interface DonneesAControlerResponseDto {
    /**
     * 
     * @type {DonneesFd}
     * @memberof DonneesAControlerResponseDto
     */
    donneesFd: DonneesFd | null;
    /**
     * 
     * @type {DonneesSiv}
     * @memberof DonneesAControlerResponseDto
     */
    donneesSiv: DonneesSiv | null;
}
/**
 * 
 * @export
 * @interface DonneesFd
 */
export interface DonneesFd {
    /**
     * 
     * @type {GenreSimplifieDto}
     * @memberof DonneesFd
     */
    genreSimplifie: GenreSimplifieDto | null;
    /**
     * 
     * @type {MarqueDto}
     * @memberof DonneesFd
     */
    marque: MarqueDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof DonneesFd
     */
    marqueNonReferencee: boolean | null;
    /**
     * 
     * @type {ModeleDto}
     * @memberof DonneesFd
     */
    modele: ModeleDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof DonneesFd
     */
    modeleNonReference: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DonneesFd
     */
    paysEtranger: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DonneesFd
     */
    plaqueImmatriculation: string | null;
    /**
     * 
     * @type {string}
     * @memberof DonneesFd
     */
    plaqueImmatriculationVisible: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DonneesFd
     */
    sansPlaque: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DonneesFd
     */
    vin: string | null;
}
/**
 * 
 * @export
 * @interface DonneesSiv
 */
export interface DonneesSiv {
    /**
     * 
     * @type {MarqueDto}
     * @memberof DonneesSiv
     */
    marque: MarqueDto | null;
    /**
     * 
     * @type {ModeleDto}
     * @memberof DonneesSiv
     */
    modele: ModeleDto | null;
    /**
     * 
     * @type {string}
     * @memberof DonneesSiv
     */
    plaqueImmatriculation: string | null;
    /**
     * 
     * @type {string}
     * @memberof DonneesSiv
     */
    vin: string | null;
}
/**
 * 
 * @export
 * @interface DossierBodyResponseDto
 */
export interface DossierBodyResponseDto {
    /**
     * 
     * @type {AlienationInformationDto}
     * @memberof DossierBodyResponseDto
     */
    alienationInformationDto: AlienationInformationDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierBodyResponseDto
     */
    anonymise: boolean | null;
    /**
     * 
     * @type {ArgosInformationDto}
     * @memberof DossierBodyResponseDto
     */
    argosInformation: ArgosInformationDto | null;
    /**
     * 
     * @type {FicheFourriereDto}
     * @memberof DossierBodyResponseDto
     */
    ficheFourriere: FicheFourriereDto | null;
    /**
     * 
     * @type {FicheInfractionDto}
     * @memberof DossierBodyResponseDto
     */
    ficheInfraction: FicheInfractionDto | null;
    /**
     * 
     * @type {FicheVehiculeDto}
     * @memberof DossierBodyResponseDto
     */
    ficheVehicule: FicheVehiculeDto | null;
    /**
     * 
     * @type {SortieVehiculeResponseDto}
     * @memberof DossierBodyResponseDto
     */
    sortieVehicule: SortieVehiculeResponseDto | null;
    /**
     * 
     * @type {TraitementResponseDto}
     * @memberof DossierBodyResponseDto
     */
    traitement: TraitementResponseDto | null;
}
/**
 * 
 * @export
 * @interface DossierCreationFoViolationsDto
 */
export interface DossierCreationFoViolationsDto {
    /**
     * 
     * @type {Array<FicheConditionsViolationDto>}
     * @memberof DossierCreationFoViolationsDto
     */
    ficheConditionsViolationDtoList: Array<FicheConditionsViolationDto> | null;
    /**
     * 
     * @type {Array<FicheDetailsFoViolationDto>}
     * @memberof DossierCreationFoViolationsDto
     */
    ficheDetailsVehiculeViolationDtoList: Array<FicheDetailsFoViolationDto> | null;
    /**
     * 
     * @type {Array<FicheEtatGeneralFoViolationDto>}
     * @memberof DossierCreationFoViolationsDto
     */
    ficheEtatGeneralViolationDtoList: Array<FicheEtatGeneralFoViolationDto> | null;
    /**
     * 
     * @type {Array<FicheFourriereFoViolationDto>}
     * @memberof DossierCreationFoViolationsDto
     */
    ficheFourriereViolationDtoList: Array<FicheFourriereFoViolationDto> | null;
    /**
     * 
     * @type {Array<FicheVehiculeFoViolationDto>}
     * @memberof DossierCreationFoViolationsDto
     */
    ficheVehiculeViolationDtoList: Array<FicheVehiculeFoViolationDto> | null;
}
/**
 * 
 * @export
 * @interface DossierDtoAdminFonctionnel
 */
export interface DossierDtoAdminFonctionnel {
    /**
     * 
     * @type {Array<string>}
     * @memberof DossierDtoAdminFonctionnel
     */
    actionsDisponibles: Array<DossierDtoAdminFonctionnelActionsDisponiblesEnum> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DossierDtoAdminFonctionnel
     */
    actionsRequises: Array<DossierDtoAdminFonctionnelActionsRequisesEnum> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDtoAdminFonctionnel
     */
    anonymise: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    currentBranche: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    dateDerniereMAJ: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    dateEntree: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    dateSortie: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    immatriculation: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    immatriculationVisible: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDtoAdminFonctionnel
     */
    interdictionCirculer: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    libelleComplet: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    libelleMarque: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    libelleModele: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    libelleNatureMEF: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    libelleUniteFoParent: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DossierDtoAdminFonctionnel
     */
    listAvailableFiles: Array<DossierDtoAdminFonctionnelListAvailableFilesEnum> | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    raisonSociale: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    status: DossierDtoAdminFonctionnelStatusEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    statutVega: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    unite: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDtoAdminFonctionnel
     */
    vin: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDtoAdminFonctionnel
     */
    volConfirme: boolean | null;
}

/**
    * @export
    * @enum {string}
    */
export enum DossierDtoAdminFonctionnelActionsDisponiblesEnum {
    'PROLONGER_MEF' = 'PROLONGER_MEF',
    'CLASSER_MANUELLEMENT' = 'CLASSER_MANUELLEMENT',
    'MODIFIER_COORDONNEES_PROPRIETAIRE' = 'MODIFIER_COORDONNEES_PROPRIETAIRE',
    'CORRIGER_DONNEES_NOTIFICATION_MANUELLE' = 'CORRIGER_DONNEES_NOTIFICATION_MANUELLE',
    'RENSEIGNER_SORTIE_VEHICULE' = 'RENSEIGNER_SORTIE_VEHICULE',
    'SAISIE_VHU' = 'SAISIE_VHU',
    'SAISIE_NOTIFICATION_MANUELLE' = 'SAISIE_NOTIFICATION_MANUELLE',
    'MAIN_LEVEE' = 'MAIN_LEVEE',
    'TRAITE_EN_FACTURATION' = 'TRAITE_EN_FACTURATION',
    'NON_TRAITE_EN_FACTURATION' = 'NON_TRAITE_EN_FACTURATION',
    'ANNULATION_SORTIE_VEHICULE' = 'ANNULATION_SORTIE_VEHICULE',
    'EXPORT_DONNEES_DOSSIER' = 'EXPORT_DONNEES_DOSSIER',
    'SUPPRESSION_DOSSIER' = 'SUPPRESSION_DOSSIER',
    'UPDATE_ENTITES_RATTACHEMENT_ADMIN' = 'UPDATE_ENTITES_RATTACHEMENT_ADMIN',
    'UPDATE_ENTITES_RATTACHEMENT_FO_AF' = 'UPDATE_ENTITES_RATTACHEMENT_FO_AF',
    'MODIFICATION_SORTIE_VEHICULE' = 'MODIFICATION_SORTIE_VEHICULE',
    'UPDATE_DONNEES_VEHICULE' = 'UPDATE_DONNEES_VEHICULE',
    'UPDATE_CLASSEMENT' = 'UPDATE_CLASSEMENT',
    'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE' = 'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE',
    'ENREGISTRER_ENTREE' = 'ENREGISTRER_ENTREE',
    'CONTROLER_DONNEES_VEHICULE' = 'CONTROLER_DONNEES_VEHICULE',
    'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE' = 'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE',
    'RELANCE_VENTE_VOL' = 'RELANCE_VENTE_VOL',
    'RELANCE_VENTE_IMMO_PJ' = 'RELANCE_VENTE_IMMO_PJ',
    'RELANCE_VENTE_OPPOSITION' = 'RELANCE_VENTE_OPPOSITION',
    'RELANCE_VENTE_DOUBLON' = 'RELANCE_VENTE_DOUBLON',
    'RELANCE_VENTE_MANQUANT' = 'RELANCE_VENTE_MANQUANT',
    'RECUPERATION_AR_PND_AUTOMATIQUE' = 'RECUPERATION_AR_PND_AUTOMATIQUE',
    'CONTROLE_BLOCAGE_ARGOS' = 'CONTROLE_BLOCAGE_ARGOS',
    'CONTROLE_BLOCAGE_ANTAI' = 'CONTROLE_BLOCAGE_ANTAI',
    'CONTROLE_BLOCAGE_DNID' = 'CONTROLE_BLOCAGE_DNID',
    'RECUPERATION_COURRIER_AUTOMATIQUE' = 'RECUPERATION_COURRIER_AUTOMATIQUE',
    'RENSEIGNER_INFORMATIONS_SIV' = 'RENSEIGNER_INFORMATIONS_SIV',
    'CONFIRMER_VOL_VVNR' = 'CONFIRMER_VOL_VVNR',
    'VALIDATION_ACTIONS_VVNR_HORS_SIF' = 'VALIDATION_ACTIONS_VVNR_HORS_SIF'
}
/**
    * @export
    * @enum {string}
    */
export enum DossierDtoAdminFonctionnelActionsRequisesEnum {
    'PROLONGER_MEF' = 'PROLONGER_MEF',
    'CLASSER_MANUELLEMENT' = 'CLASSER_MANUELLEMENT',
    'MODIFIER_COORDONNEES_PROPRIETAIRE' = 'MODIFIER_COORDONNEES_PROPRIETAIRE',
    'CORRIGER_DONNEES_NOTIFICATION_MANUELLE' = 'CORRIGER_DONNEES_NOTIFICATION_MANUELLE',
    'RENSEIGNER_SORTIE_VEHICULE' = 'RENSEIGNER_SORTIE_VEHICULE',
    'SAISIE_VHU' = 'SAISIE_VHU',
    'SAISIE_NOTIFICATION_MANUELLE' = 'SAISIE_NOTIFICATION_MANUELLE',
    'MAIN_LEVEE' = 'MAIN_LEVEE',
    'TRAITE_EN_FACTURATION' = 'TRAITE_EN_FACTURATION',
    'NON_TRAITE_EN_FACTURATION' = 'NON_TRAITE_EN_FACTURATION',
    'ANNULATION_SORTIE_VEHICULE' = 'ANNULATION_SORTIE_VEHICULE',
    'EXPORT_DONNEES_DOSSIER' = 'EXPORT_DONNEES_DOSSIER',
    'SUPPRESSION_DOSSIER' = 'SUPPRESSION_DOSSIER',
    'UPDATE_ENTITES_RATTACHEMENT_ADMIN' = 'UPDATE_ENTITES_RATTACHEMENT_ADMIN',
    'UPDATE_ENTITES_RATTACHEMENT_FO_AF' = 'UPDATE_ENTITES_RATTACHEMENT_FO_AF',
    'MODIFICATION_SORTIE_VEHICULE' = 'MODIFICATION_SORTIE_VEHICULE',
    'UPDATE_DONNEES_VEHICULE' = 'UPDATE_DONNEES_VEHICULE',
    'UPDATE_CLASSEMENT' = 'UPDATE_CLASSEMENT',
    'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE' = 'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE',
    'ENREGISTRER_ENTREE' = 'ENREGISTRER_ENTREE',
    'CONTROLER_DONNEES_VEHICULE' = 'CONTROLER_DONNEES_VEHICULE',
    'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE' = 'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE',
    'RELANCE_VENTE_VOL' = 'RELANCE_VENTE_VOL',
    'RELANCE_VENTE_IMMO_PJ' = 'RELANCE_VENTE_IMMO_PJ',
    'RELANCE_VENTE_OPPOSITION' = 'RELANCE_VENTE_OPPOSITION',
    'RELANCE_VENTE_DOUBLON' = 'RELANCE_VENTE_DOUBLON',
    'RELANCE_VENTE_MANQUANT' = 'RELANCE_VENTE_MANQUANT',
    'RECUPERATION_AR_PND_AUTOMATIQUE' = 'RECUPERATION_AR_PND_AUTOMATIQUE',
    'CONTROLE_BLOCAGE_ARGOS' = 'CONTROLE_BLOCAGE_ARGOS',
    'CONTROLE_BLOCAGE_ANTAI' = 'CONTROLE_BLOCAGE_ANTAI',
    'CONTROLE_BLOCAGE_DNID' = 'CONTROLE_BLOCAGE_DNID',
    'RECUPERATION_COURRIER_AUTOMATIQUE' = 'RECUPERATION_COURRIER_AUTOMATIQUE',
    'RENSEIGNER_INFORMATIONS_SIV' = 'RENSEIGNER_INFORMATIONS_SIV',
    'CONFIRMER_VOL_VVNR' = 'CONFIRMER_VOL_VVNR',
    'VALIDATION_ACTIONS_VVNR_HORS_SIF' = 'VALIDATION_ACTIONS_VVNR_HORS_SIF'
}
/**
    * @export
    * @enum {string}
    */
export enum DossierDtoAdminFonctionnelListAvailableFilesEnum {
    'DECISION_CLASSEMENT' = 'DECISION_CLASSEMENT',
    'DECISION_DESTRUCTION' = 'DECISION_DESTRUCTION',
    'CONSTAT_ABANDON' = 'CONSTAT_ABANDON',
    'DECISION_REMISE_DOMAINE' = 'DECISION_REMISE_DOMAINE',
    'BON_ENLEVEMENT_DESTRUCTION' = 'BON_ENLEVEMENT_DESTRUCTION',
    'FICHE_DESCRIPTIVE' = 'FICHE_DESCRIPTIVE',
    'PRESCRIPTION_MEF' = 'PRESCRIPTION_MEF',
    'MAIN_LEVEE_FO' = 'MAIN_LEVEE_FO',
    'MAIN_LEVEE_USAGER' = 'MAIN_LEVEE_USAGER',
    'COURRIER_NOTIFICATION' = 'COURRIER_NOTIFICATION',
    'AR_PND_NOTIFICATION' = 'AR_PND_NOTIFICATION'
}
/**
    * @export
    * @enum {string}
    */
export enum DossierDtoAdminFonctionnelStatusEnum {
    'A_REMPLIR' = 'A_REMPLIR',
    'ENREGISTRE' = 'ENREGISTRE',
    'NON_GERE' = 'NON_GERE',
    'NOTIFICATION_ENVOYEE' = 'NOTIFICATION_ENVOYEE',
    'NOTIFIE' = 'NOTIFIE',
    'A_NOTIFIER' = 'A_NOTIFIER',
    'EN_COURS_DE_NOTIFICATION' = 'EN_COURS_DE_NOTIFICATION',
    'A_REMETTRE_AUX_DOMAINES' = 'A_REMETTRE_AUX_DOMAINES',
    'REMIS_AU_DOMAINE' = 'REMIS_AU_DOMAINE',
    'MIS_EN_VENTE' = 'MIS_EN_VENTE',
    'VENDU' = 'VENDU',
    'A_RESTITUER' = 'A_RESTITUER',
    'VENTE_BLOQUEE' = 'VENTE_BLOQUEE',
    'A_DETRUIRE' = 'A_DETRUIRE',
    'SORTI' = 'SORTI',
    'DETRUIT' = 'DETRUIT'
}

/**
 * 
 * @export
 * @interface DossierEntreeViolationsDto
 */
export interface DossierEntreeViolationsDto {
    /**
     * 
     * @type {Array<EntreeVehiculeViolationDto>}
     * @memberof DossierEntreeViolationsDto
     */
    entreeVehiculeViolationDtoList: Array<EntreeVehiculeViolationDto> | null;
}
/**
 * 
 * @export
 * @interface DossierHeaderResponseDto
 */
export interface DossierHeaderResponseDto {
    /**
     * 
     * @type {string}
     * @memberof DossierHeaderResponseDto
     */
    id: string | null;
}
/**
 * 
 * @export
 * @interface DossierModificationViolationsDto
 */
export interface DossierModificationViolationsDto {
    /**
     * 
     * @type {Array<ControleDonneesVehiculeViolationDto>}
     * @memberof DossierModificationViolationsDto
     */
    controleDonneesVehiculeViolationDtoList: Array<ControleDonneesVehiculeViolationDto> | null;
    /**
     * 
     * @type {Array<FixDataNotificationManuelleViolationDto>}
     * @memberof DossierModificationViolationsDto
     */
    fixDataNotificationManuelleViolationDtoList: Array<FixDataNotificationManuelleViolationDto> | null;
    /**
     * 
     * @type {Array<UpdateClassementViolationDto>}
     * @memberof DossierModificationViolationsDto
     */
    updateClassementViolationDtoList: Array<UpdateClassementViolationDto> | null;
    /**
     * 
     * @type {Array<UpdateDonneesVehiculeViolationDto>}
     * @memberof DossierModificationViolationsDto
     */
    updateDonneesVehiculeViolationDtoList: Array<UpdateDonneesVehiculeViolationDto> | null;
    /**
     * 
     * @type {Array<UpdateEntitesRattachementViolationDto>}
     * @memberof DossierModificationViolationsDto
     */
    updateEntitesRattachementViolationDtoList: Array<UpdateEntitesRattachementViolationDto> | null;
}
/**
 * 
 * @export
 * @interface DossierResponseDto
 */
export interface DossierResponseDto {
    /**
     * 
     * @type {DossierBodyResponseDto}
     * @memberof DossierResponseDto
     */
    body: DossierBodyResponseDto | null;
    /**
     * 
     * @type {ComputedResponseDto}
     * @memberof DossierResponseDto
     */
    computed: ComputedResponseDto | null;
    /**
     * 
     * @type {DossierHeaderResponseDto}
     * @memberof DossierResponseDto
     */
    header: DossierHeaderResponseDto | null;
}
/**
 * 
 * @export
 * @interface DossierSummaryResponseDto
 */
export interface DossierSummaryResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof DossierSummaryResponseDto
     */
    anonymise: boolean;
    /**
     * 
     * @type {ComputedSummaryResponseDto}
     * @memberof DossierSummaryResponseDto
     */
    computed: ComputedSummaryResponseDto | null;
    /**
     * 
     * @type {FicheFourriereSummaryDto}
     * @memberof DossierSummaryResponseDto
     */
    ficheFourriere: FicheFourriereSummaryDto | null;
    /**
     * 
     * @type {FicheInfractionSummaryDto}
     * @memberof DossierSummaryResponseDto
     */
    ficheInfraction: FicheInfractionSummaryDto | null;
    /**
     * 
     * @type {FicheVehiculeSummaryDto}
     * @memberof DossierSummaryResponseDto
     */
    ficheVehicule: FicheVehiculeSummaryDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierSummaryResponseDto
     */
    hasAlerts: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DossierSummaryResponseDto
     */
    hasNotifications: boolean;
    /**
     * 
     * @type {string}
     * @memberof DossierSummaryResponseDto
     */
    id: string;
    /**
     * 
     * @type {SortieSummaryDto}
     * @memberof DossierSummaryResponseDto
     */
    sortie: SortieSummaryDto | null;
    /**
     * 
     * @type {TraitementSummaryDto}
     * @memberof DossierSummaryResponseDto
     */
    traitement: TraitementSummaryDto | null;
}
/**
 * 
 * @export
 * @interface DossierViolationsDto
 */
export interface DossierViolationsDto {
    /**
     * 
     * @type {Array<BonEnlevementGardienViolationDto>}
     * @memberof DossierViolationsDto
     */
    bonEnlevementGardienViolationDtoList: Array<BonEnlevementGardienViolationDto> | null;
    /**
     * 
     * @type {Array<ClassementManuelViolationDto>}
     * @memberof DossierViolationsDto
     */
    classementManuelViolationDtoList: Array<ClassementManuelViolationDto> | null;
    /**
     * 
     * @type {Array<FicheFourriereViolationDto>}
     * @memberof DossierViolationsDto
     */
    ficheFourriereViolationDtoList: Array<FicheFourriereViolationDto> | null;
    /**
     * 
     * @type {Array<FicheInfractionViolationDto>}
     * @memberof DossierViolationsDto
     */
    ficheInfractionViolationDtoList: Array<FicheInfractionViolationDto> | null;
    /**
     * 
     * @type {Array<FicheVehiculeViolationDto>}
     * @memberof DossierViolationsDto
     */
    ficheVehiculeViolationDtoList: Array<FicheVehiculeViolationDto> | null;
    /**
     * 
     * @type {Array<MainLeveeViolationDto>}
     * @memberof DossierViolationsDto
     */
    mainLeveeViolationDtoList: Array<MainLeveeViolationDto> | null;
    /**
     * 
     * @type {Array<NotificationInfosViolationDto>}
     * @memberof DossierViolationsDto
     */
    notificationInfosViolationDtos: Array<NotificationInfosViolationDto> | null;
    /**
     * 
     * @type {Array<ProlongationViolationDto>}
     * @memberof DossierViolationsDto
     */
    prolongationViolationDtoList: Array<ProlongationViolationDto> | null;
    /**
     * 
     * @type {Array<SortieVehiculeViolationDto>}
     * @memberof DossierViolationsDto
     */
    sortieVehiculeViolationDtoList: Array<SortieVehiculeViolationDto> | null;
    /**
     * 
     * @type {Array<CoordonneesProprietaireViolationDto>}
     * @memberof DossierViolationsDto
     */
    updateCoordonneesProprietaireViolationDtoList: Array<CoordonneesProprietaireViolationDto> | null;
    /**
     * 
     * @type {Array<ExpediteurNotificationViolationDto>}
     * @memberof DossierViolationsDto
     */
    updateExpediteurNotificationViolationDtoList: Array<ExpediteurNotificationViolationDto> | null;
}
/**
 * 
 * @export
 * @interface EnergieDto
 */
export interface EnergieDto {
    /**
     * 
     * @type {string}
     * @memberof EnergieDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof EnergieDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EnergieDto
     */
    libelle: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnergieDto
     */
    libelleCourt: string | null;
}
/**
 * 
 * @export
 * @interface EntreeVehiculeRequestDto
 */
export interface EntreeVehiculeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof EntreeVehiculeRequestDto
     */
    dateEnlevement: string | null;
    /**
     * 
     * @type {string}
     * @memberof EntreeVehiculeRequestDto
     */
    dateEntree: string | null;
    /**
     * 
     * @type {string}
     * @memberof EntreeVehiculeRequestDto
     */
    idCorrelationAutoriteFourriere: string | null;
}
/**
 * 
 * @export
 * @interface EntreeVehiculeViolationDto
 */
export interface EntreeVehiculeViolationDto {
    /**
     * 
     * @type {string}
     * @memberof EntreeVehiculeViolationDto
     */
    formField: EntreeVehiculeViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof EntreeVehiculeViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof EntreeVehiculeViolationDto
     */
    violationCode: EntreeVehiculeViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EntreeVehiculeViolationDtoFormFieldEnum {
    'ID_CORRELATION_AUTORITE_FOURRIERE' = 'ID_CORRELATION_AUTORITE_FOURRIERE',
    'DATE_ENLEVEMENT' = 'DATE_ENLEVEMENT',
    'DATE_ENTREE' = 'DATE_ENTREE'
}
/**
    * @export
    * @enum {string}
    */
export enum EntreeVehiculeViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'DATE_ENTREE_PRIOR_DATE_ENLEVEMENT' = 'DATE_ENTREE_PRIOR_DATE_ENLEVEMENT',
    'DATE_ENTREE_PRIOR_DATE_CONSTATATION' = 'DATE_ENTREE_PRIOR_DATE_CONSTATATION',
    'DATE_ENLEVEMENT_PRIOR_DATE_CONSTATATION' = 'DATE_ENLEVEMENT_PRIOR_DATE_CONSTATATION',
    'DATE_ENLEVEMENT_PRIOR_DATE_REDACTION' = 'DATE_ENLEVEMENT_PRIOR_DATE_REDACTION',
    'DATE_IN_THE_FUTURE' = 'DATE_IN_THE_FUTURE',
    'INVALID_AUTORITE_FOURRIERE' = 'INVALID_AUTORITE_FOURRIERE',
    'INCONSISTENT_AUTORITE_FOURRIERE' = 'INCONSISTENT_AUTORITE_FOURRIERE',
    'AUTORITE_FOURRIERE_NOT_FOUND' = 'AUTORITE_FOURRIERE_NOT_FOUND'
}

/**
 * 
 * @export
 * @interface ErrorDto
 */
export interface ErrorDto {
    /**
     * 
     * @type {string}
     * @memberof ErrorDto
     */
    code: ErrorDtoCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof ErrorDto
     */
    localized: string | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorDto
     */
    message: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ErrorDtoCodeEnum {
    'UNKNOWN_ERROR' = 'UNKNOWN_ERROR',
    'PROPERTY_ERROR' = 'PROPERTY_ERROR',
    'CONNECTION_FAILED_ERROR' = 'CONNECTION_FAILED_ERROR',
    'HTTP_PARSE_ERROR' = 'HTTP_PARSE_ERROR',
    'SPRING_BAD_REQUEST' = 'SPRING_BAD_REQUEST',
    'FILE_ENCODING_ERROR' = 'FILE_ENCODING_ERROR',
    'API_AUTHENTICATION_ERROR' = 'API_AUTHENTICATION_ERROR',
    'API_AUTHORIZATION_ERROR' = 'API_AUTHORIZATION_ERROR',
    'API_EXPIRED_TOKEN' = 'API_EXPIRED_TOKEN',
    'API_DOCUMENT_NOT_FOUND_ERROR' = 'API_DOCUMENT_NOT_FOUND_ERROR',
    'API_HEALTH_CHECK_ERROR' = 'API_HEALTH_CHECK_ERROR',
    'API_UNPROCESSABLE_FILE' = 'API_UNPROCESSABLE_FILE',
    'API_WRITE_HTTP_RESPONSE' = 'API_WRITE_HTTP_RESPONSE',
    'API_WRONG_HEADER_IDENTIFIER' = 'API_WRONG_HEADER_IDENTIFIER',
    'CAMUNDA_PROCESS_INSTANCE_ALREADY_EXISTS_ERROR' = 'CAMUNDA_PROCESS_INSTANCE_ALREADY_EXISTS_ERROR',
    'CAMUNDA_PROCESS_INSTANCE_NOT_FOUND' = 'CAMUNDA_PROCESS_INSTANCE_NOT_FOUND',
    'CAMUNDA_INCIDENT_NOT_FOUND' = 'CAMUNDA_INCIDENT_NOT_FOUND',
    'CAMUNDA_MESSAGE_EMISSION_ERROR' = 'CAMUNDA_MESSAGE_EMISSION_ERROR',
    'CAMUNDA_UNKNOWN_ERROR' = 'CAMUNDA_UNKNOWN_ERROR',
    'NULL_VALUE_ERROR' = 'NULL_VALUE_ERROR',
    'IDP_RESPONSE_ERROR' = 'IDP_RESPONSE_ERROR',
    'IDP_INVALID_REQUEST' = 'IDP_INVALID_REQUEST',
    'IDP_INVALID_CLIENT' = 'IDP_INVALID_CLIENT',
    'IDP_INVALID_GRANT' = 'IDP_INVALID_GRANT',
    'IDP_UNAUTHORIZED_CLIENT' = 'IDP_UNAUTHORIZED_CLIENT',
    'IDP_UNSUPPORTED_GRANT_TYPE' = 'IDP_UNSUPPORTED_GRANT_TYPE',
    'IDP_INVALID_SCOPE' = 'IDP_INVALID_SCOPE',
    'KEYCLOAK_MISSING_PROPERTY' = 'KEYCLOAK_MISSING_PROPERTY',
    'KEYCLOAK_INVALID_ROLE' = 'KEYCLOAK_INVALID_ROLE',
    'KEYCLOAK_AUTORITE_FOURRIERE_INVALID' = 'KEYCLOAK_AUTORITE_FOURRIERE_INVALID',
    'KEYCLOAK_AUTORITE_FOURRIERE_NOT_FOUND' = 'KEYCLOAK_AUTORITE_FOURRIERE_NOT_FOUND',
    'KEYCLOAK_AUTORITE_FOURRIERE_NO_ACTIVE_FOUND' = 'KEYCLOAK_AUTORITE_FOURRIERE_NO_ACTIVE_FOUND',
    'KEYCLOAK_AUTORITE_FOURRIERE_MULTIPLE_ACTIVE_FOUND' = 'KEYCLOAK_AUTORITE_FOURRIERE_MULTIPLE_ACTIVE_FOUND',
    'KEYCLOAK_FOURRIERE_INVALID' = 'KEYCLOAK_FOURRIERE_INVALID',
    'KEYCLOAK_FOURRIERE_NOT_FOUND' = 'KEYCLOAK_FOURRIERE_NOT_FOUND',
    'KEYCLOAK_FOURRIERE_NO_ACTIVE_FOUND' = 'KEYCLOAK_FOURRIERE_NO_ACTIVE_FOUND',
    'KEYCLOAK_FOURRIERE_MULTIPLE_ACTIVE_FOUND' = 'KEYCLOAK_FOURRIERE_MULTIPLE_ACTIVE_FOUND',
    'KEYCLOAK_UNITE_FO_INVALID' = 'KEYCLOAK_UNITE_FO_INVALID',
    'KEYCLOAK_UNITE_FO_NOT_FOUND' = 'KEYCLOAK_UNITE_FO_NOT_FOUND',
    'KEYCLOAK_UNITE_FO_NO_ACTIVE_FOUND' = 'KEYCLOAK_UNITE_FO_NO_ACTIVE_FOUND',
    'KEYCLOAK_UNITE_FO_MULTIPLE_ACTIVE_FOUND' = 'KEYCLOAK_UNITE_FO_MULTIPLE_ACTIVE_FOUND',
    'PASSAGE2_MISSING_PROPERTY' = 'PASSAGE2_MISSING_PROPERTY',
    'PASSAGE2_INVALID_ROLE' = 'PASSAGE2_INVALID_ROLE',
    'PASSAGE2_CODE_INSEE_NOT_FOUND' = 'PASSAGE2_CODE_INSEE_NOT_FOUND',
    'PASSAGE2_CODE_INSEE_NO_ACTIVE_FOUND' = 'PASSAGE2_CODE_INSEE_NO_ACTIVE_FOUND',
    'PASSAGE2_CODE_INSEE_MULTIPLE_ACTIVE_FOUND' = 'PASSAGE2_CODE_INSEE_MULTIPLE_ACTIVE_FOUND',
    'PASSAGE2_LIBELLE_LONG_INVALID' = 'PASSAGE2_LIBELLE_LONG_INVALID',
    'PASSAGE2_UNITE_FO_ABREVIATION_NOT_FOUND' = 'PASSAGE2_UNITE_FO_ABREVIATION_NOT_FOUND',
    'PASSAGE2_UNITE_FO_ABREVIATION_NO_ACTIVE_FOUND' = 'PASSAGE2_UNITE_FO_ABREVIATION_NO_ACTIVE_FOUND',
    'PASSAGE2_UNITE_FO_ABREVIATION_MULTIPLE_ACTIVE_FOUND' = 'PASSAGE2_UNITE_FO_ABREVIATION_MULTIPLE_ACTIVE_FOUND',
    'PASSAGE2_UNITE_FO_CODE_NOT_FOUND' = 'PASSAGE2_UNITE_FO_CODE_NOT_FOUND',
    'PASSAGE2_UNITE_FO_CODE_NO_ACTIVE_FOUND' = 'PASSAGE2_UNITE_FO_CODE_NO_ACTIVE_FOUND',
    'PASSAGE2_UNITE_FO_CODE_MULTIPLE_ACTIVE_FOUND' = 'PASSAGE2_UNITE_FO_CODE_MULTIPLE_ACTIVE_FOUND',
    'PASSAGE2_UNITE_FO_NOT_FOUND' = 'PASSAGE2_UNITE_FO_NOT_FOUND',
    'PASSAGE2_UNITE_FO_NO_ACTIVE_FOUND' = 'PASSAGE2_UNITE_FO_NO_ACTIVE_FOUND',
    'PASSAGE2_UNITE_FO_MULTIPLE_ACTIVE_FOUND' = 'PASSAGE2_UNITE_FO_MULTIPLE_ACTIVE_FOUND',
    'PASSAGE2_UNITE_FO_ALL_STRATEGIES_FAILED' = 'PASSAGE2_UNITE_FO_ALL_STRATEGIES_FAILED',
    'DOSSIER_UNKNOWN_ERROR' = 'DOSSIER_UNKNOWN_ERROR',
    'DOSSIER_NOT_FOUND' = 'DOSSIER_NOT_FOUND',
    'DOSSIER_RGPD_DATA_NOT_FOUND' = 'DOSSIER_RGPD_DATA_NOT_FOUND',
    'DOSSIER_CREATE_ERROR' = 'DOSSIER_CREATE_ERROR',
    'DOSSIER_UPDATE_ERROR' = 'DOSSIER_UPDATE_ERROR',
    'DOSSIER_SORTIE_VEHICULE_ERROR' = 'DOSSIER_SORTIE_VEHICULE_ERROR',
    'DOSSIER_ENTREE_VEHICULE_ERROR' = 'DOSSIER_ENTREE_VEHICULE_ERROR',
    'DOSSIER_MISSING_INFO_IN_BODY' = 'DOSSIER_MISSING_INFO_IN_BODY',
    'DOSSIER_MISSING_INFO_IN_HEADER' = 'DOSSIER_MISSING_INFO_IN_HEADER',
    'DOSSIER_PROLONGATION_MEF_ERROR' = 'DOSSIER_PROLONGATION_MEF_ERROR',
    'DOSSIER_CLASSEMENT_UNKNOWN_ERROR' = 'DOSSIER_CLASSEMENT_UNKNOWN_ERROR',
    'DOSSIER_EXISTING_CLASSEMENT_ERROR' = 'DOSSIER_EXISTING_CLASSEMENT_ERROR',
    'DOSSIER_UPDATE_ENTITES_RATTACHEMENT_ERROR' = 'DOSSIER_UPDATE_ENTITES_RATTACHEMENT_ERROR',
    'DOSSIER_UPDATE_FOURRIERE_RATTACHEMENT_DOUBLON_DOSSIER_ERROR' = 'DOSSIER_UPDATE_FOURRIERE_RATTACHEMENT_DOUBLON_DOSSIER_ERROR',
    'DOSSIER_UPDATE_DONNEES_VEHICULE_ERROR' = 'DOSSIER_UPDATE_DONNEES_VEHICULE_ERROR',
    'DOSSIER_CONTROLE_DONNEES_VEHICULE_ERROR' = 'DOSSIER_CONTROLE_DONNEES_VEHICULE_ERROR',
    'DOSSIER_BON_ENLEVEMENT_GARDIEN_UNKNOWN_ERROR' = 'DOSSIER_BON_ENLEVEMENT_GARDIEN_UNKNOWN_ERROR',
    'DOSSIER_EXISTING_BON_ENLEVEMENT_GARDIEN_ERROR' = 'DOSSIER_EXISTING_BON_ENLEVEMENT_GARDIEN_ERROR',
    'DOSSIER_INVALID_BON_ENLEVEMENT_GARDIEN_ERROR' = 'DOSSIER_INVALID_BON_ENLEVEMENT_GARDIEN_ERROR',
    'DOSSIER_WORKFLOW_SYNC_ERROR' = 'DOSSIER_WORKFLOW_SYNC_ERROR',
    'DOSSIER_WORKFLOW_RESET_ERROR' = 'DOSSIER_WORKFLOW_RESET_ERROR',
    'DOSSIER_UPDATE_SIV_INFORMATION_ERROR' = 'DOSSIER_UPDATE_SIV_INFORMATION_ERROR',
    'DOSSIER_UPDATE_DICEM_INFORMATION_ERROR' = 'DOSSIER_UPDATE_DICEM_INFORMATION_ERROR',
    'DOSSIER_UPDATE_MAIN_LEVEE_ERROR' = 'DOSSIER_UPDATE_MAIN_LEVEE_ERROR',
    'DOSSIER_ABANDON_ERROR' = 'DOSSIER_ABANDON_ERROR',
    'DOSSIER_NOTIFICATION_INFOS_ERROR' = 'DOSSIER_NOTIFICATION_INFOS_ERROR',
    'DOSSIER_EVENT_NOT_FOUND' = 'DOSSIER_EVENT_NOT_FOUND',
    'DOSSIER_UPDATE_HERMES_STATUS_ERROR' = 'DOSSIER_UPDATE_HERMES_STATUS_ERROR',
    'DOSSIER_COMPARAISON_SIV_FD_ERROR' = 'DOSSIER_COMPARAISON_SIV_FD_ERROR',
    'CLASSEMENT_BAD_TABLE_FORMAT_ERROR' = 'CLASSEMENT_BAD_TABLE_FORMAT_ERROR',
    'CLASSEMENT_BAD_REQUEST' = 'CLASSEMENT_BAD_REQUEST',
    'CLASSEMENT_EXPORT_TABLE_ERROR' = 'CLASSEMENT_EXPORT_TABLE_ERROR',
    'CLASSEMENT_FORBIDDEN_REQUEST' = 'CLASSEMENT_FORBIDDEN_REQUEST',
    'CLASSEMENT_CSV_ERROR' = 'CLASSEMENT_CSV_ERROR',
    'REFERENTIEL_UNKNOWN_ERROR' = 'REFERENTIEL_UNKNOWN_ERROR',
    'REFERENTIEL_NOT_FOUND' = 'REFERENTIEL_NOT_FOUND',
    'REFERENTIEL_NO_ACTIVE_FOUND' = 'REFERENTIEL_NO_ACTIVE_FOUND',
    'REFERENTIEL_MULTIPLE_ACTIVE_FOUND' = 'REFERENTIEL_MULTIPLE_ACTIVE_FOUND',
    'REFERENTIEL_PROFILE_NOT_FOUND' = 'REFERENTIEL_PROFILE_NOT_FOUND',
    'REFERENTIEL_INVALID_FILE' = 'REFERENTIEL_INVALID_FILE',
    'REFERENTIEL_INVALID_DATA' = 'REFERENTIEL_INVALID_DATA',
    'REFERENTIEL_FORBIDDEN_IMPORT' = 'REFERENTIEL_FORBIDDEN_IMPORT',
    'REFERENTIEL_UNABLE_CAST_SIV_DATA' = 'REFERENTIEL_UNABLE_CAST_SIV_DATA',
    'REFERENTIEL_UPDATE_PROFIL_UNITE_FO_ERROR' = 'REFERENTIEL_UPDATE_PROFIL_UNITE_FO_ERROR',
    'REFERENTIEL_CREATE_CENTRE_VHU_ERROR' = 'REFERENTIEL_CREATE_CENTRE_VHU_ERROR',
    'REFERENTIEL_UPDATE_CENTRE_VHU_ERROR' = 'REFERENTIEL_UPDATE_CENTRE_VHU_ERROR',
    'REFERENTIEL_CREATE_UNITE_FO_ERROR' = 'REFERENTIEL_CREATE_UNITE_FO_ERROR',
    'REFERENTIEL_UPDATE_UNITE_FO_ERROR' = 'REFERENTIEL_UPDATE_UNITE_FO_ERROR',
    'REFERENTIEL_CREATE_AUTORITE_FOURRIERE_ERROR' = 'REFERENTIEL_CREATE_AUTORITE_FOURRIERE_ERROR',
    'REFERENTIEL_UPDATE_AF_ERROR' = 'REFERENTIEL_UPDATE_AF_ERROR',
    'REFERENTIEL_UPDATE_GF_ERROR' = 'REFERENTIEL_UPDATE_GF_ERROR',
    'REFERENTIEL_CREATE_INFORMATION_MESSAGE_ERROR' = 'REFERENTIEL_CREATE_INFORMATION_MESSAGE_ERROR',
    'REFERENTIEL_UPDATE_INFORMATION_MESSAGE_ERROR' = 'REFERENTIEL_UPDATE_INFORMATION_MESSAGE_ERROR',
    'DEPRECATE_REFERENTIELS_UNKNOWN_ERROR' = 'DEPRECATE_REFERENTIELS_UNKNOWN_ERROR',
    'DEPRECATE_REFERENTIELS_UNABLE_TO_DEPRECATE_USED_BY_DOSSIER' = 'DEPRECATE_REFERENTIELS_UNABLE_TO_DEPRECATE_USED_BY_DOSSIER',
    'DEPRECATE_REFERENTIELS_UNABLE_TO_DEPRECATE_UNITE_PARENT_AND_USED_BY_DOSSIER' = 'DEPRECATE_REFERENTIELS_UNABLE_TO_DEPRECATE_UNITE_PARENT_AND_USED_BY_DOSSIER',
    'DEPRECATE_REFERENTIELS_UNABLE_TO_DEPRECATE_UNITE_PARENT' = 'DEPRECATE_REFERENTIELS_UNABLE_TO_DEPRECATE_UNITE_PARENT',
    'WORKFLOW_CONNECTION_FAILED' = 'WORKFLOW_CONNECTION_FAILED',
    'WORKFLOW_SYNC_PROCESS_FAILED' = 'WORKFLOW_SYNC_PROCESS_FAILED',
    'WORKFLOW_PARSE_JSON_FAILED' = 'WORKFLOW_PARSE_JSON_FAILED',
    'WORKFLOW_UNKNOWN_ERROR' = 'WORKFLOW_UNKNOWN_ERROR',
    'WORKFLOW_WORKER_ERROR' = 'WORKFLOW_WORKER_ERROR',
    'EXPORT_FILES_UNKNOWN_ERROR' = 'EXPORT_FILES_UNKNOWN_ERROR',
    'EXPORT_FILES_WRITE_CSV_ERROR' = 'EXPORT_FILES_WRITE_CSV_ERROR',
    'EXPORT_FILES_WRITE_ZIP_ERROR' = 'EXPORT_FILES_WRITE_ZIP_ERROR',
    'EXPORT_FILES_BAD_REQUEST' = 'EXPORT_FILES_BAD_REQUEST',
    'EXPORT_FILE_NOT_FOUND_EXPORT_ERROR' = 'EXPORT_FILE_NOT_FOUND_EXPORT_ERROR',
    'EXPORT_FILE_NOT_GENERATED_EXPORT_ERROR' = 'EXPORT_FILE_NOT_GENERATED_EXPORT_ERROR',
    'EXPORT_FILE_EXPIRED_EXPORT_ERROR' = 'EXPORT_FILE_EXPIRED_EXPORT_ERROR',
    'EXPORT_FILE_FAILED_EXPORT_ERROR' = 'EXPORT_FILE_FAILED_EXPORT_ERROR',
    'EXPORT_FILE_CANNOT_ACCESS_FILE_ERROR' = 'EXPORT_FILE_CANNOT_ACCESS_FILE_ERROR',
    'EXPORT_FILE_CANNOT_ACCESS_MANUEL' = 'EXPORT_FILE_CANNOT_ACCESS_MANUEL',
    'EXPORT_FILE_CANNOT_ACCESS_FICHE_DESCRIPTIVE' = 'EXPORT_FILE_CANNOT_ACCESS_FICHE_DESCRIPTIVE',
    'RIE_SIV_RESPONSE_PARSE_XML_TO_DTO_FAILED' = 'RIE_SIV_RESPONSE_PARSE_XML_TO_DTO_FAILED',
    'RIE_SIV_RESPONSE_PARSE_TO_XML_READER_FAILED' = 'RIE_SIV_RESPONSE_PARSE_TO_XML_READER_FAILED',
    'RIE_SIV_RESPONSE_WITH_ERRORS' = 'RIE_SIV_RESPONSE_WITH_ERRORS',
    'RIE_SIV_BUILD_SOAP_HEADER_FAILED' = 'RIE_SIV_BUILD_SOAP_HEADER_FAILED',
    'RIE_SIV_BAD_REQUEST' = 'RIE_SIV_BAD_REQUEST',
    'RIE_SIV_SEND_REQUEST_FAILED' = 'RIE_SIV_SEND_REQUEST_FAILED',
    'RIE_SIV_UNKNOWN_ERROR' = 'RIE_SIV_UNKNOWN_ERROR',
    'SWAPART_FAILED_TO_REACH_CONVENTIONNEMENT' = 'SWAPART_FAILED_TO_REACH_CONVENTIONNEMENT',
    'SWAPART_FAILED_TO_REACH_LOGIN' = 'SWAPART_FAILED_TO_REACH_LOGIN',
    'SWAPART_LOGIN_ERROR' = 'SWAPART_LOGIN_ERROR',
    'SWAPART_UNKNOWN_ERROR' = 'SWAPART_UNKNOWN_ERROR',
    'FVA_EMPTY_RESPONSE_ERROR' = 'FVA_EMPTY_RESPONSE_ERROR',
    'FVA_SSL_ERROR' = 'FVA_SSL_ERROR',
    'FVA_KEYSTORE_ERROR' = 'FVA_KEYSTORE_ERROR',
    'FVA_BAD_REQUEST_ERROR' = 'FVA_BAD_REQUEST_ERROR',
    'FVA_UNKNOWN_ERROR' = 'FVA_UNKNOWN_ERROR',
    'NOTIFICATION_UNKNOWN_ERROR' = 'NOTIFICATION_UNKNOWN_ERROR',
    'NOTIFICATION_DECOMPRESSION_ARCHIVE_ERROR' = 'NOTIFICATION_DECOMPRESSION_ARCHIVE_ERROR',
    'NOTIFICATION_DIRECTORY_ERROR' = 'NOTIFICATION_DIRECTORY_ERROR',
    'NOTIFICATION_UNABLE_TO_PARSE_XML' = 'NOTIFICATION_UNABLE_TO_PARSE_XML',
    'NOTIFICATION_AUTO_DEMANDE_EDITION_EVENT_ERROR' = 'NOTIFICATION_AUTO_DEMANDE_EDITION_EVENT_ERROR',
    'NOTIFICATION_AUTO_POSTED_EVENT_ERROR' = 'NOTIFICATION_AUTO_POSTED_EVENT_ERROR',
    'NOTIFICATION_AUTO_FIND_BY_ANTAI_ID_ERROR' = 'NOTIFICATION_AUTO_FIND_BY_ANTAI_ID_ERROR',
    'NOTIFICATION_AUTO_INFOS_RETOUR_EVENT_ERROR' = 'NOTIFICATION_AUTO_INFOS_RETOUR_EVENT_ERROR',
    'NOTIFICATION_AUTO_FETCH_COURRIER_ERROR' = 'NOTIFICATION_AUTO_FETCH_COURRIER_ERROR',
    'INES_SAS_INVALID_USER' = 'INES_SAS_INVALID_USER',
    'INES_SAS_INVALID_MESSAGE' = 'INES_SAS_INVALID_MESSAGE',
    'INES_SAS_UNKNOWN_ERROR' = 'INES_SAS_UNKNOWN_ERROR',
    'COMPTES_UTILISATEURS_RESPONSE_CSV_EXCEPTION' = 'COMPTES_UTILISATEURS_RESPONSE_CSV_EXCEPTION',
    'COMPTES_UTILISATEURS_UNKNOWN_ERROR' = 'COMPTES_UTILISATEURS_UNKNOWN_ERROR',
    'COMPTES_UTILISATEURS_USER_NOT_FOUND' = 'COMPTES_UTILISATEURS_USER_NOT_FOUND',
    'COMPTES_UTILISATEURS_CREATE_USERNAME_MAX_INCREMENTS_EXCEEDED' = 'COMPTES_UTILISATEURS_CREATE_USERNAME_MAX_INCREMENTS_EXCEEDED',
    'COMPTES_UTILISATEURS_ENTITY_NOT_FOUND' = 'COMPTES_UTILISATEURS_ENTITY_NOT_FOUND',
    'COMPTES_UTILISATEURS_CLIENT_UNKNOWN_ERROR' = 'COMPTES_UTILISATEURS_CLIENT_UNKNOWN_ERROR',
    'CLIENT_VEGA_UNKNOWN_ERROR' = 'CLIENT_VEGA_UNKNOWN_ERROR',
    'COMPTES_UTILISATEURS_CLIENT_FORBIDDEN_ERROR' = 'COMPTES_UTILISATEURS_CLIENT_FORBIDDEN_ERROR',
    'COMPTES_UTILISATEURS_KEYCLOAK_CONNECT_ERROR' = 'COMPTES_UTILISATEURS_KEYCLOAK_CONNECT_ERROR',
    'COMPTES_UTILISATEURS_BAD_CSV_LINES' = 'COMPTES_UTILISATEURS_BAD_CSV_LINES',
    'COMPTES_UTILISATEURS_UNABLE_READ_FILE' = 'COMPTES_UTILISATEURS_UNABLE_READ_FILE',
    'COMPTES_UTILISATEURS_UNABLE_PARSE_JSON_RESPONSE' = 'COMPTES_UTILISATEURS_UNABLE_PARSE_JSON_RESPONSE',
    'COMPTES_UTILISATEURS_ERROR_KEYCLOAK_GROUP_CONFIGURATION' = 'COMPTES_UTILISATEURS_ERROR_KEYCLOAK_GROUP_CONFIGURATION',
    'COMPTES_UTILISATEURS_UPDATE_ERROR' = 'COMPTES_UTILISATEURS_UPDATE_ERROR',
    'COMPTES_UTILISATEURS_CREATE_ERROR' = 'COMPTES_UTILISATEURS_CREATE_ERROR',
    'PDF_UNKNOWN_ERROR' = 'PDF_UNKNOWN_ERROR',
    'PDF_NOTIFICATION_MAPPER_ERROR' = 'PDF_NOTIFICATION_MAPPER_ERROR',
    'PDF_BON_ENLEVEMENT_MAPPER_ERROR' = 'PDF_BON_ENLEVEMENT_MAPPER_ERROR',
    'PDF_GENERATE_DOCUMENT_ERROR' = 'PDF_GENERATE_DOCUMENT_ERROR',
    'PDF_WRITE_DOCUMENT_IN_RESPONSE_ERROR' = 'PDF_WRITE_DOCUMENT_IN_RESPONSE_ERROR',
    'HERMES_UNKNOWN_ERROR' = 'HERMES_UNKNOWN_ERROR',
    'HERMES_BAD_CSV_LINES' = 'HERMES_BAD_CSV_LINES',
    'VEGA_MALFORMED_VEGA_ID_CORRELATION' = 'VEGA_MALFORMED_VEGA_ID_CORRELATION',
    'HERMES_UNREADABLE_FILE' = 'HERMES_UNREADABLE_FILE',
    'VEGA_UNKNOWN_RESPONSE_ERROR' = 'VEGA_UNKNOWN_RESPONSE_ERROR',
    'VEGA_UNHANDLED_RESPONSE_ERROR' = 'VEGA_UNHANDLED_RESPONSE_ERROR',
    'HERMES_UNPROCESSED_RESPONSE_ERROR' = 'HERMES_UNPROCESSED_RESPONSE_ERROR',
    'HERMES_INVALID_DOSSIER_ALREADY_SENT' = 'HERMES_INVALID_DOSSIER_ALREADY_SENT',
    'HERMES_INVALID_DOSSIER_NOT_ALIENATION' = 'HERMES_INVALID_DOSSIER_NOT_ALIENATION',
    'HERMES_INVALID_DOSSIER_NOT_ABANDONED' = 'HERMES_INVALID_DOSSIER_NOT_ABANDONED',
    'HERMES_CONTOURNEMENT_IMPORT_DISABLED' = 'HERMES_CONTOURNEMENT_IMPORT_DISABLED',
    'NULL_IDENTIFICATION' = 'NULL_IDENTIFICATION',
    'SIV_ERROR' = 'SIV_ERROR',
    'CLASSEMENT_INTERNAL_ERROR' = 'CLASSEMENT_INTERNAL_ERROR',
    'STORE_SI_TIERS_UNKONW_ERROR' = 'STORE_SI_TIERS_UNKONW_ERROR',
    'STORE_SI_TIERS_FAIL_TO_SAVE_LOCK' = 'STORE_SI_TIERS_FAIL_TO_SAVE_LOCK',
    'STORE_SI_TIERS_PROCESS_LOCK' = 'STORE_SI_TIERS_PROCESS_LOCK',
    'ANTAI_UNKNOWN_ERROR' = 'ANTAI_UNKNOWN_ERROR',
    'ANTAI_DEMANDE_EDITION_BAD_REQUEST' = 'ANTAI_DEMANDE_EDITION_BAD_REQUEST',
    'NULL_PARAMETER_ERROR' = 'NULL_PARAMETER_ERROR',
    'RANGE_TOO_LARGE' = 'RANGE_TOO_LARGE',
    'BEGINNING_AFTER_END' = 'BEGINNING_AFTER_END',
    'DILA_UNKNOWN_ERROR' = 'DILA_UNKNOWN_ERROR',
    'DILA_CONNECTION_FAILED_ERROR' = 'DILA_CONNECTION_FAILED_ERROR',
    'DILA_API_AUTHENTICATION_ERROR' = 'DILA_API_AUTHENTICATION_ERROR',
    'DILA_MISSING_FOURRIERE_DTO' = 'DILA_MISSING_FOURRIERE_DTO',
    'DILA_DOSSIER_NOT_FOUND' = 'DILA_DOSSIER_NOT_FOUND',
    'DILA_BAD_REQUEST' = 'DILA_BAD_REQUEST',
    'DILA_MISSING_IMMATRICULATION' = 'DILA_MISSING_IMMATRICULATION',
    'DILA_MISSING_NUMERO_OR_DATE' = 'DILA_MISSING_NUMERO_OR_DATE',
    'DILA_FICHE_DESCRIPTIVE_NOT_FOUND' = 'DILA_FICHE_DESCRIPTIVE_NOT_FOUND',
    'DILA_FICHE_DESCRIPTIVE_FORBIDDEN' = 'DILA_FICHE_DESCRIPTIVE_FORBIDDEN',
    'DILA_INTERDICTION_CIRCULER' = 'DILA_INTERDICTION_CIRCULER',
    'DILA_VEHICULE_VOLE' = 'DILA_VEHICULE_VOLE',
    'DILA_ASSURANCE_INVALIDE' = 'DILA_ASSURANCE_INVALIDE',
    'DILA_PERMIS_INVALIDE' = 'DILA_PERMIS_INVALIDE',
    'DILA_PERMIS_NON_VERIFIE' = 'DILA_PERMIS_NON_VERIFIE',
    'DILA_VEHICULE_DEJA_SORTI' = 'DILA_VEHICULE_DEJA_SORTI',
    'PHOTO_UNKNOWN_ERROR' = 'PHOTO_UNKNOWN_ERROR',
    'DILA_MAIN_LEVEE_INDISPONIBLE' = 'DILA_MAIN_LEVEE_INDISPONIBLE',
    'DICEM_FAILED_TO_REACH_CONSULTATION' = 'DICEM_FAILED_TO_REACH_CONSULTATION',
    'DICEM_BAD_REQUEST_ERROR' = 'DICEM_BAD_REQUEST_ERROR',
    'DICEM_UNKNOWN_ERROR' = 'DICEM_UNKNOWN_ERROR',
    'RPSI_BATCH_IS_RUNNING_EXCEPTION' = 'RPSI_BATCH_IS_RUNNING_EXCEPTION',
    'RPSI_REQUEST_LOCKED_EXCEPTION' = 'RPSI_REQUEST_LOCKED_EXCEPTION',
    'RPSI_BATCH_FAILED' = 'RPSI_BATCH_FAILED',
    'ERPC_UNKNOWN_ERROR' = 'ERPC_UNKNOWN_ERROR',
    'ERPC_AUTHORIZATION_ERROR' = 'ERPC_AUTHORIZATION_ERROR',
    'ERPC_AUTHENTIFICATION_ERROR' = 'ERPC_AUTHENTIFICATION_ERROR',
    'ERPC_EMPTY_RESPONSE_ERROR' = 'ERPC_EMPTY_RESPONSE_ERROR'
}

/**
 * 
 * @export
 * @interface EtatConventionnementResponseDto
 */
export interface EtatConventionnementResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof EtatConventionnementResponseDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof EtatConventionnementResponseDto
     */
    codeInsee: string;
    /**
     * 
     * @type {ConventionnementSwaPartDto}
     * @memberof EtatConventionnementResponseDto
     */
    conventionnement: ConventionnementSwaPartDto | null;
}
/**
 * 
 * @export
 * @interface EtatDto
 */
export interface EtatDto {
    /**
     * 
     * @type {string}
     * @memberof EtatDto
     */
    detail: string | null;
    /**
     * 
     * @type {string}
     * @memberof EtatDto
     */
    etat: EtatDtoEtatEnum;
    /**
     * 
     * @type {string}
     * @memberof EtatDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EtatDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof EtatDto
     */
    libelleCourt: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum EtatDtoEtatEnum {
    'BON_ETAT' = 'BON_ETAT',
    'DEGRADE' = 'DEGRADE',
    'DOMMAGES_GRAVES' = 'DOMMAGES_GRAVES'
}

/**
 * 
 * @export
 * @interface ExpediteurNotificationDto
 */
export interface ExpediteurNotificationDto {
    /**
     * 
     * @type {string}
     * @memberof ExpediteurNotificationDto
     */
    adresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpediteurNotificationDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpediteurNotificationDto
     */
    commune: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpediteurNotificationDto
     */
    idCorrelationUniteFo: string | null;
}
/**
 * 
 * @export
 * @interface ExpediteurNotificationRequestDto
 */
export interface ExpediteurNotificationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ExpediteurNotificationRequestDto
     */
    adresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpediteurNotificationRequestDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpediteurNotificationRequestDto
     */
    commune: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpediteurNotificationRequestDto
     */
    idCorrelationUniteFo: string | null;
}
/**
 * 
 * @export
 * @interface ExpediteurNotificationViolationDto
 */
export interface ExpediteurNotificationViolationDto {
    /**
     * 
     * @type {string}
     * @memberof ExpediteurNotificationViolationDto
     */
    formField: ExpediteurNotificationViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof ExpediteurNotificationViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ExpediteurNotificationViolationDto
     */
    violationCode: ExpediteurNotificationViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ExpediteurNotificationViolationDtoFormFieldEnum {
    'UNITE' = 'UNITE',
    'ADRESSE' = 'ADRESSE',
    'CODE_POSTAL' = 'CODE_POSTAL',
    'COMMUNE' = 'COMMUNE'
}
/**
    * @export
    * @enum {string}
    */
export enum ExpediteurNotificationViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'CODE_POSTAL_BAD_FORMAT' = 'CODE_POSTAL_BAD_FORMAT',
    'NOT_FOUND_IN_REFERENTIAL' = 'NOT_FOUND_IN_REFERENTIAL'
}

/**
 * 
 * @export
 * @interface ExportDonneesDossierAdminFonctionnelDto
 */
export interface ExportDonneesDossierAdminFonctionnelDto {
    /**
     * 
     * @type {DossierResponseDto}
     * @memberof ExportDonneesDossierAdminFonctionnelDto
     */
    dossier: DossierResponseDto;
    /**
     * 
     * @type {Array<DetailedEventDto>}
     * @memberof ExportDonneesDossierAdminFonctionnelDto
     */
    events: Array<DetailedEventDto>;
}
/**
 * 
 * @export
 * @interface ExportFilesDto
 */
export interface ExportFilesDto {
    /**
     * 
     * @type {string}
     * @memberof ExportFilesDto
     */
    errorDescription: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExportFilesDto
     */
    errorOccured: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExportFilesDto
     */
    expirationDate: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExportFilesDto
     */
    exportType: string;
    /**
     * 
     * @type {string}
     * @memberof ExportFilesDto
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof ExportFilesDto
     */
    fileType: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExportFilesDto
     */
    generated: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExportFilesDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ExportFilesDto
     */
    idUser: string;
}
/**
 * 
 * @export
 * @interface FaqAdminDto
 */
export interface FaqAdminDto {
    /**
     * 
     * @type {string}
     * @memberof FaqAdminDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof FaqAdminDto
     */
    domainId: string | null;
    /**
     * 
     * @type {string}
     * @memberof FaqAdminDto
     */
    domainName: string | null;
    /**
     * 
     * @type {string}
     * @memberof FaqAdminDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FaqAdminDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof FaqAdminDto
     */
    profiles: Array<FaqAdminDtoProfilesEnum>;
    /**
     * 
     * @type {string}
     * @memberof FaqAdminDto
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof FaqAdminDto
     */
    valid: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum FaqAdminDtoProfilesEnum {
    'AUTORITE_FOURRIERE' = 'AUTORITE_FOURRIERE',
    'DSR_BLR' = 'DSR_BLR',
    'FORCE_DE_L_ORDRE' = 'FORCE_DE_L_ORDRE',
    'GARDIEN_FOURRIERE' = 'GARDIEN_FOURRIERE',
    'ADMIN_FONCTIONNEL' = 'ADMIN_FONCTIONNEL',
    'CSU' = 'CSU'
}

/**
 * 
 * @export
 * @interface FaqDomainAdminDto
 */
export interface FaqDomainAdminDto {
    /**
     * 
     * @type {string}
     * @memberof FaqDomainAdminDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FaqDomainAdminDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof FaqDomainAdminDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof FaqDomainAdminDto
     */
    valid: boolean;
}
/**
 * 
 * @export
 * @interface FaqDomainDto
 */
export interface FaqDomainDto {
    /**
     * 
     * @type {Array<FaqDto>}
     * @memberof FaqDomainDto
     */
    faqs: Array<FaqDto>;
    /**
     * 
     * @type {string}
     * @memberof FaqDomainDto
     */
    name: string | null;
}
/**
 * 
 * @export
 * @interface FaqDomainRequestDto
 */
export interface FaqDomainRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FaqDomainRequestDto
     */
    name: string | null;
}
/**
 * 
 * @export
 * @interface FaqDomainViolationDto
 */
export interface FaqDomainViolationDto {
    /**
     * 
     * @type {string}
     * @memberof FaqDomainViolationDto
     */
    formField: FaqDomainViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof FaqDomainViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof FaqDomainViolationDto
     */
    violationCode: FaqDomainViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FaqDomainViolationDtoFormFieldEnum {
    'NAME' = 'NAME'
}
/**
    * @export
    * @enum {string}
    */
export enum FaqDomainViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'NAME_ALREADY_USED' = 'NAME_ALREADY_USED'
}

/**
 * 
 * @export
 * @interface FaqDto
 */
export interface FaqDto {
    /**
     * 
     * @type {string}
     * @memberof FaqDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof FaqDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FaqDto
     */
    title: string;
}
/**
 * 
 * @export
 * @interface FaqRequestDto
 */
export interface FaqRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FaqRequestDto
     */
    content: string | null;
    /**
     * 
     * @type {string}
     * @memberof FaqRequestDto
     */
    domainId: string | null;
    /**
     * 
     * @type {Set<string>}
     * @memberof FaqRequestDto
     */
    profiles: Array<FaqRequestDtoProfilesEnum> | null;
    /**
     * 
     * @type {string}
     * @memberof FaqRequestDto
     */
    title: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum FaqRequestDtoProfilesEnum {
    'AUTORITE_FOURRIERE' = 'AUTORITE_FOURRIERE',
    'DSR_BLR' = 'DSR_BLR',
    'FORCE_DE_L_ORDRE' = 'FORCE_DE_L_ORDRE',
    'GARDIEN_FOURRIERE' = 'GARDIEN_FOURRIERE',
    'ADMIN_FONCTIONNEL' = 'ADMIN_FONCTIONNEL',
    'CSU' = 'CSU'
}

/**
 * 
 * @export
 * @interface FaqViolationDto
 */
export interface FaqViolationDto {
    /**
     * 
     * @type {string}
     * @memberof FaqViolationDto
     */
    formField: FaqViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof FaqViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof FaqViolationDto
     */
    violationCode: FaqViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FaqViolationDtoFormFieldEnum {
    'DOMAIN_ID' = 'DOMAIN_ID',
    'TITLE' = 'TITLE',
    'PROFILES' = 'PROFILES',
    'CONTENT' = 'CONTENT'
}
/**
    * @export
    * @enum {string}
    */
export enum FaqViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'TITLE_ALREADY_USED' = 'TITLE_ALREADY_USED'
}

/**
 * 
 * @export
 * @interface FicheAgentFoRequestDto
 */
export interface FicheAgentFoRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FicheAgentFoRequestDto
     */
    dateEtablissement: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheAgentFoRequestDto
     */
    identifiantNigend: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheAgentFoRequestDto
     */
    presenceResponsableEnlevement: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FicheAgentFoRequestDto
     */
    qualiteAutorite: FicheAgentFoRequestDtoQualiteAutoriteEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum FicheAgentFoRequestDtoQualiteAutoriteEnum {
    'OPJ' = 'OPJ',
    'APJ' = 'APJ',
    'APJA' = 'APJA'
}

/**
 * 
 * @export
 * @interface FicheConditionsRequestDto
 */
export interface FicheConditionsRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FicheConditionsRequestDto
     */
    adresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheConditionsRequestDto
     */
    commune: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheConditionsRequestDto
     */
    dateConstatationInfraction: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheConditionsRequestDto
     */
    isNeige: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheConditionsRequestDto
     */
    isNuit: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheConditionsRequestDto
     */
    isPluie: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheConditionsRequestDto
     */
    lieuPrive: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FicheConditionsRequestDto
     */
    motifMefId: string | null;
}
/**
 * 
 * @export
 * @interface FicheConditionsViolationDto
 */
export interface FicheConditionsViolationDto {
    /**
     * 
     * @type {string}
     * @memberof FicheConditionsViolationDto
     */
    formField: FicheConditionsViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof FicheConditionsViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof FicheConditionsViolationDto
     */
    violationCode: FicheConditionsViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FicheConditionsViolationDtoFormFieldEnum {
    'DATE_CONSTATATION' = 'DATE_CONSTATATION',
    'MOTIVATION_MESURE' = 'MOTIVATION_MESURE',
    'ADRESSE' = 'ADRESSE',
    'COMMUNE' = 'COMMUNE'
}
/**
    * @export
    * @enum {string}
    */
export enum FicheConditionsViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'DATE_IN_THE_FUTURE' = 'DATE_IN_THE_FUTURE',
    'MOTIF_MEF_NOT_FOUND' = 'MOTIF_MEF_NOT_FOUND',
    'BRANCHE_SHOULD_BE_MAITRE_LIEU' = 'BRANCHE_SHOULD_BE_MAITRE_LIEU'
}

/**
 * 
 * @export
 * @interface FicheDetailsFoViolationDto
 */
export interface FicheDetailsFoViolationDto {
    /**
     * 
     * @type {string}
     * @memberof FicheDetailsFoViolationDto
     */
    formField: FicheDetailsFoViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof FicheDetailsFoViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof FicheDetailsFoViolationDto
     */
    violationCode: string;
}

/**
    * @export
    * @enum {string}
    */
export enum FicheDetailsFoViolationDtoFormFieldEnum {
    'VERROUILLAGE_PORTES' = 'VERROUILLAGE_PORTES',
    'VERROUILLAGE_COFFRES' = 'VERROUILLAGE_COFFRES',
    'ACCESSOIRES_OBJETS_VISIBLES' = 'ACCESSOIRES_OBJETS_VISIBLES',
    'ACCESSOIRES_OBSERVATIONS' = 'ACCESSOIRES_OBSERVATIONS'
}

/**
 * 
 * @export
 * @interface FicheDetailsVehiculeFoRequestDto
 */
export interface FicheDetailsVehiculeFoRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof FicheDetailsVehiculeFoRequestDto
     */
    coffres: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FicheDetailsVehiculeFoRequestDto
     */
    objetsVisibles: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheDetailsVehiculeFoRequestDto
     */
    observations: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheDetailsVehiculeFoRequestDto
     */
    portes: boolean | null;
}
/**
 * 
 * @export
 * @interface FicheEtatGeneralFoRequestDto
 */
export interface FicheEtatGeneralFoRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FicheEtatGeneralFoRequestDto
     */
    etatLibelle: FicheEtatGeneralFoRequestDtoEtatLibelleEnum | null;
    /**
     * 
     * @type {string}
     * @memberof FicheEtatGeneralFoRequestDto
     */
    etatPrecisionId: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum FicheEtatGeneralFoRequestDtoEtatLibelleEnum {
    'BON_ETAT' = 'BON_ETAT',
    'DEGRADE' = 'DEGRADE',
    'DOMMAGES_GRAVES' = 'DOMMAGES_GRAVES'
}

/**
 * 
 * @export
 * @interface FicheEtatGeneralFoViolationDto
 */
export interface FicheEtatGeneralFoViolationDto {
    /**
     * 
     * @type {string}
     * @memberof FicheEtatGeneralFoViolationDto
     */
    formField: FicheEtatGeneralFoViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof FicheEtatGeneralFoViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof FicheEtatGeneralFoViolationDto
     */
    violationCode: FicheEtatGeneralFoViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FicheEtatGeneralFoViolationDtoFormFieldEnum {
    'ETAT_LIBELLE' = 'ETAT_LIBELLE',
    'ETAT_PRECISION' = 'ETAT_PRECISION'
}
/**
    * @export
    * @enum {string}
    */
export enum FicheEtatGeneralFoViolationDtoViolationCodeEnum {
    'MISSING_VALUE' = 'MISSING_VALUE',
    'ETAT_PRECISION_INVALID' = 'ETAT_PRECISION_INVALID',
    'ETAT_WITHOUT_PRECISION_NOT_FOUND' = 'ETAT_WITHOUT_PRECISION_NOT_FOUND',
    'ETAT_PRECISION_MUST_BE_NULL_IN_THIS_CASE' = 'ETAT_PRECISION_MUST_BE_NULL_IN_THIS_CASE'
}

/**
 * 
 * @export
 * @interface FicheFourriereDto
 */
export interface FicheFourriereDto {
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereDto
     */
    dateEnlevement: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereDto
     */
    dateEntree: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereDto
     */
    idCorrelationFourriere: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereDto
     */
    numeroFacture: string | null;
}
/**
 * 
 * @export
 * @interface FicheFourriereFoRequestDto
 */
export interface FicheFourriereFoRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereFoRequestDto
     */
    autoriteQualifiee: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereFoRequestDto
     */
    idCorrelationAutoriteFourriere: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereFoRequestDto
     */
    idCorrelationFourriere: string | null;
}
/**
 * 
 * @export
 * @interface FicheFourriereFoViolationDto
 */
export interface FicheFourriereFoViolationDto {
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereFoViolationDto
     */
    formField: FicheFourriereFoViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereFoViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereFoViolationDto
     */
    violationCode: FicheFourriereFoViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FicheFourriereFoViolationDtoFormFieldEnum {
    'FOURRIERE' = 'FOURRIERE',
    'AUTORITE_FOURRIERE' = 'AUTORITE_FOURRIERE',
    'AUTORITE_QUALIFIEE' = 'AUTORITE_QUALIFIEE'
}
/**
    * @export
    * @enum {string}
    */
export enum FicheFourriereFoViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'INVALID_FOURRIERE' = 'INVALID_FOURRIERE',
    'FOURRIERE_NOT_FOUND' = 'FOURRIERE_NOT_FOUND',
    'INVALID_AUTORITE_FOURRIERE' = 'INVALID_AUTORITE_FOURRIERE',
    'AUTORITE_FOURRIERE_NOT_FOUND' = 'AUTORITE_FOURRIERE_NOT_FOUND'
}

/**
 * 
 * @export
 * @interface FicheFourriereRequestDto
 */
export interface FicheFourriereRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereRequestDto
     */
    dateEnlevement: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereRequestDto
     */
    dateEntree: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereRequestDto
     */
    idCorrelationFourriere: string | null;
}
/**
 * 
 * @export
 * @interface FicheFourriereSummaryDto
 */
export interface FicheFourriereSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereSummaryDto
     */
    dateMiseEnFourriere: string | null;
}
/**
 * 
 * @export
 * @interface FicheFourriereViolationDto
 */
export interface FicheFourriereViolationDto {
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereViolationDto
     */
    formField: FicheFourriereViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof FicheFourriereViolationDto
     */
    violationCode: FicheFourriereViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FicheFourriereViolationDtoFormFieldEnum {
    'ENTREE' = 'ENTREE',
    'ENLEVEMENT' = 'ENLEVEMENT',
    'FOURRIERE' = 'FOURRIERE'
}
/**
    * @export
    * @enum {string}
    */
export enum FicheFourriereViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'DATE_ENLEVEMENT_PRIOR_DATE_CONSTATATION' = 'DATE_ENLEVEMENT_PRIOR_DATE_CONSTATATION',
    'DATE_ENLEVEMENT_PRIOR_DATE_REDACTION' = 'DATE_ENLEVEMENT_PRIOR_DATE_REDACTION',
    'DATE_ENTREE_PRIOR_DATE_CONSTATATION' = 'DATE_ENTREE_PRIOR_DATE_CONSTATATION',
    'DATE_ENTREE_PRIOR_DATE_ENLEVEMENT' = 'DATE_ENTREE_PRIOR_DATE_ENLEVEMENT',
    'DATE_IN_THE_FUTURE' = 'DATE_IN_THE_FUTURE',
    'INVALID_FOURRIERE' = 'INVALID_FOURRIERE',
    'FOURRIERE_NOT_FOUND' = 'FOURRIERE_NOT_FOUND'
}

/**
 * 
 * @export
 * @interface FicheInfractionDto
 */
export interface FicheInfractionDto {
    /**
     * 
     * @type {AgentFoDto}
     * @memberof FicheInfractionDto
     */
    agent: AgentFoDto | null;
    /**
     * 
     * @type {AutoriteMainleveeDto}
     * @memberof FicheInfractionDto
     */
    autoriteMainlevee: AutoriteMainleveeDto | null;
    /**
     * 
     * @type {AutoritePrescriptriceDto}
     * @memberof FicheInfractionDto
     */
    autoritePrescriptrice: AutoritePrescriptriceDto | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionDto
     */
    communeEnlevement: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionDto
     */
    dateConstatation: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionDto
     */
    dateRedaction: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionDto
     */
    emailUnite: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionDto
     */
    idCorrelationAutoriteFourriere: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionDto
     */
    idCorrelationAutoriteFourriereFicheFo: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionDto
     */
    idCorrelationUniteFO: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionDto
     */
    lieuEnlevement: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheInfractionDto
     */
    lieuPrive: boolean | null;
    /**
     * 
     * @type {MotifMefDto}
     * @memberof FicheInfractionDto
     */
    motifMef: MotifMefDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheInfractionDto
     */
    nuit: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheInfractionDto
     */
    pluieOuNeige: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheInfractionDto
     */
    presenceResponsableEnlevement: boolean | null;
}
/**
 * 
 * @export
 * @interface FicheInfractionRequestDto
 */
export interface FicheInfractionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionRequestDto
     */
    autoriteQualifieePourLeverMesure: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionRequestDto
     */
    communeEnlevement: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionRequestDto
     */
    dateConstatation: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionRequestDto
     */
    dateRedaction: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionRequestDto
     */
    emailUnite: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionRequestDto
     */
    idCorrelationAutoriteFourriere: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionRequestDto
     */
    idCorrelationFourriere: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionRequestDto
     */
    idCorrelationUniteFO: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionRequestDto
     */
    lieuEnlevement: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheInfractionRequestDto
     */
    lieuPrive: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionRequestDto
     */
    motifMefId: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionRequestDto
     */
    nigend: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionRequestDto
     */
    nomAgent: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionRequestDto
     */
    nomAutorite: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheInfractionRequestDto
     */
    nuit: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheInfractionRequestDto
     */
    pluieOuNeige: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionRequestDto
     */
    qualiteAutorite: FicheInfractionRequestDtoQualiteAutoriteEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum FicheInfractionRequestDtoQualiteAutoriteEnum {
    'OPJ' = 'OPJ',
    'APJ' = 'APJ',
    'APJA' = 'APJA'
}

/**
 * 
 * @export
 * @interface FicheInfractionSummaryDto
 */
export interface FicheInfractionSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionSummaryDto
     */
    autoritePrescriptrice: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionSummaryDto
     */
    communeEnlevement: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheInfractionSummaryDto
     */
    lieuPrive: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionSummaryDto
     */
    motivationMesure: string | null;
}
/**
 * 
 * @export
 * @interface FicheInfractionViolationDto
 */
export interface FicheInfractionViolationDto {
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionViolationDto
     */
    formField: FicheInfractionViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof FicheInfractionViolationDto
     */
    violationCode: FicheInfractionViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FicheInfractionViolationDtoFormFieldEnum {
    'AUTORITE_FOURRIERE' = 'AUTORITE_FOURRIERE',
    'FOURRIERE' = 'FOURRIERE',
    'DATE_CONSTATATION' = 'DATE_CONSTATATION',
    'NOM_AUTORITE_PRESCRIPTRICE' = 'NOM_AUTORITE_PRESCRIPTRICE',
    'QUALITE_AUTORITE_PRESCRIPTRICE' = 'QUALITE_AUTORITE_PRESCRIPTRICE',
    'NOM_AGENT' = 'NOM_AGENT',
    'NIGEND' = 'NIGEND',
    'UNITE' = 'UNITE',
    'MAIL_UNITE' = 'MAIL_UNITE',
    'MOTIVATION_MESURE' = 'MOTIVATION_MESURE',
    'LIEU_PRIVE' = 'LIEU_PRIVE',
    'LIEU_ENLEVEMENT' = 'LIEU_ENLEVEMENT',
    'COMMUNE' = 'COMMUNE',
    'NUIT' = 'NUIT',
    'PLUIE_NEIGE' = 'PLUIE_NEIGE',
    'AUTORITE_POUR_LEVER_MESURE' = 'AUTORITE_POUR_LEVER_MESURE',
    'DATE_REDACTION' = 'DATE_REDACTION'
}
/**
    * @export
    * @enum {string}
    */
export enum FicheInfractionViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'MISSING_UNITE_FO' = 'MISSING_UNITE_FO',
    'AUTORITE_FOURRIERE_NOT_FOUND' = 'AUTORITE_FOURRIERE_NOT_FOUND',
    'INVALID_AUTORITE_FOURRIERE' = 'INVALID_AUTORITE_FOURRIERE',
    'UNITE_FO_NOT_FOUND' = 'UNITE_FO_NOT_FOUND',
    'INVALID_UNITE_FO' = 'INVALID_UNITE_FO',
    'FOURRIERE_NOT_FOUND' = 'FOURRIERE_NOT_FOUND',
    'INVALID_FOURRIERE' = 'INVALID_FOURRIERE',
    'MOTIF_MEF_NOT_FOUND' = 'MOTIF_MEF_NOT_FOUND',
    'DATE_IN_THE_FUTURE' = 'DATE_IN_THE_FUTURE',
    'NIGEND_BAD_FORMAT' = 'NIGEND_BAD_FORMAT',
    'MAIL_BAD_FORMAT' = 'MAIL_BAD_FORMAT',
    'DATE_REDACTION_PRIOR_DATE_CONSTATATION' = 'DATE_REDACTION_PRIOR_DATE_CONSTATATION',
    'BRANCHE_SHOULD_BE_MAITRE_LIEU' = 'BRANCHE_SHOULD_BE_MAITRE_LIEU',
    'INCONSISTENT_AUTORITE_FOURRIERE' = 'INCONSISTENT_AUTORITE_FOURRIERE'
}

/**
 * 
 * @export
 * @interface FicheVehiculeDto
 */
export interface FicheVehiculeDto {
    /**
     * 
     * @type {AccessoiresDto}
     * @memberof FicheVehiculeDto
     */
    accessoires: AccessoiresDto | null;
    /**
     * 
     * @type {DescriptionDto}
     * @memberof FicheVehiculeDto
     */
    description: DescriptionDto | null;
    /**
     * 
     * @type {EtatDto}
     * @memberof FicheVehiculeDto
     */
    etat: EtatDto | null;
    /**
     * 
     * @type {ImmatriculationDto}
     * @memberof FicheVehiculeDto
     */
    immatriculation: ImmatriculationDto | null;
    /**
     * 
     * @type {VerrouillageDto}
     * @memberof FicheVehiculeDto
     */
    verrouillage: VerrouillageDto | null;
}
/**
 * 
 * @export
 * @interface FicheVehiculeFoRequestDto
 */
export interface FicheVehiculeFoRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeFoRequestDto
     */
    couleur: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeFoRequestDto
     */
    genreSimplifie: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeFoRequestDto
     */
    marque: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheVehiculeFoRequestDto
     */
    marqueNonReferencee: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeFoRequestDto
     */
    modele: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheVehiculeFoRequestDto
     */
    modeleNonReference: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeFoRequestDto
     */
    numero: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeFoRequestDto
     */
    numeroImmatriculationVisible: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheVehiculeFoRequestDto
     */
    paysEtranger: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheVehiculeFoRequestDto
     */
    plaqueFrancaise: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheVehiculeFoRequestDto
     */
    sansPlaque: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeFoRequestDto
     */
    vin: string | null;
}
/**
 * 
 * @export
 * @interface FicheVehiculeFoValidationDto
 */
export interface FicheVehiculeFoValidationDto {
    /**
     * 
     * @type {FicheVehiculeFoRequestDto}
     * @memberof FicheVehiculeFoValidationDto
     */
    ficheVehicule: FicheVehiculeFoRequestDto | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeFoValidationDto
     */
    idCorrelationFourriere: string | null;
}
/**
 * 
 * @export
 * @interface FicheVehiculeFoViolationDto
 */
export interface FicheVehiculeFoViolationDto {
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeFoViolationDto
     */
    formField: FicheVehiculeFoViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeFoViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeFoViolationDto
     */
    violationCode: FicheVehiculeFoViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FicheVehiculeFoViolationDtoFormFieldEnum {
    'DESCRIPTION_GENRE' = 'DESCRIPTION_GENRE',
    'DESCRIPTION_MARQUE' = 'DESCRIPTION_MARQUE',
    'DESCRIPTION_MODELE' = 'DESCRIPTION_MODELE',
    'DESCRIPTION_COULEUR' = 'DESCRIPTION_COULEUR',
    'ETAT_LIBELLE' = 'ETAT_LIBELLE',
    'ETAT_PRECISION' = 'ETAT_PRECISION',
    'IMMATRICULATION_SANS_PLAQUE' = 'IMMATRICULATION_SANS_PLAQUE',
    'IMMATRICULATION_PAYS_ETRANGER' = 'IMMATRICULATION_PAYS_ETRANGER',
    'TYPE_PLAQUE' = 'TYPE_PLAQUE',
    'IMMATRICULATION_NUMERO' = 'IMMATRICULATION_NUMERO',
    'IMMATRICULATION_NUMERO_VISIBLE' = 'IMMATRICULATION_NUMERO_VISIBLE',
    'IMMATRICULATION_VIN' = 'IMMATRICULATION_VIN',
    'VERROUILLAGE_PORTES' = 'VERROUILLAGE_PORTES',
    'VERROUILLAGE_COFFRES' = 'VERROUILLAGE_COFFRES',
    'ACCESSOIRES_AUTO_RADIO_VISIBLE' = 'ACCESSOIRES_AUTO_RADIO_VISIBLE',
    'ACCESSOIRES_POSTE_CB_VISIBLE' = 'ACCESSOIRES_POSTE_CB_VISIBLE',
    'ACCESSOIRES_TELEPHONE_PORTABLE' = 'ACCESSOIRES_TELEPHONE_PORTABLE',
    'ACCESSOIRES_NOMBRE_ANTENNES' = 'ACCESSOIRES_NOMBRE_ANTENNES',
    'ACCESSOIRES_OBJETS_VISIBLES' = 'ACCESSOIRES_OBJETS_VISIBLES',
    'ACCESSOIRES_OBSERVATIONS' = 'ACCESSOIRES_OBSERVATIONS'
}
/**
    * @export
    * @enum {string}
    */
export enum FicheVehiculeFoViolationDtoViolationCodeEnum {
    'MISSING_VALUE' = 'MISSING_VALUE',
    'BAD_FORMAT' = 'BAD_FORMAT',
    'BAD_VIN' = 'BAD_VIN',
    'BAD_NUMERO_SERIE_DICEM' = 'BAD_NUMERO_SERIE_DICEM',
    'FORBIDDEN_PLAQUE' = 'FORBIDDEN_PLAQUE',
    'FORBIDDEN_MARQUE' = 'FORBIDDEN_MARQUE',
    'FORBIDDEN_MODELE' = 'FORBIDDEN_MODELE',
    'WRONG_MODELE' = 'WRONG_MODELE',
    'BAD_FORMAT_IMMATRICULATION' = 'BAD_FORMAT_IMMATRICULATION',
    'BAD_FORMAT_IDENTIFICATION' = 'BAD_FORMAT_IDENTIFICATION',
    'IMMATRICULATION_ALREADY_EXISTS' = 'IMMATRICULATION_ALREADY_EXISTS',
    'VIN_ALREADY_EXISTS' = 'VIN_ALREADY_EXISTS',
    'TYPE_PLAQUE_NOT_SET' = 'TYPE_PLAQUE_NOT_SET',
    'COULEUR_NOT_FOUND' = 'COULEUR_NOT_FOUND',
    'MARQUE_NOT_FOUND' = 'MARQUE_NOT_FOUND',
    'GENRE_NOT_FOUND' = 'GENRE_NOT_FOUND',
    'MODELE_NOT_FOUND' = 'MODELE_NOT_FOUND'
}

/**
 * 
 * @export
 * @interface FicheVehiculeRequestDto
 */
export interface FicheVehiculeRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof FicheVehiculeRequestDto
     */
    autoRadioVisible: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheVehiculeRequestDto
     */
    coffres: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeRequestDto
     */
    couleur: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeRequestDto
     */
    etatLibelle: FicheVehiculeRequestDtoEtatLibelleEnum | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeRequestDto
     */
    etatPrecisionId: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeRequestDto
     */
    genreSimplifie: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeRequestDto
     */
    marque: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheVehiculeRequestDto
     */
    marqueNonReferencee: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeRequestDto
     */
    modele: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheVehiculeRequestDto
     */
    modeleNonReference: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FicheVehiculeRequestDto
     */
    nombreAntennes: number | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeRequestDto
     */
    numero: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeRequestDto
     */
    numeroImmatriculationVisible: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeRequestDto
     */
    objetsVisibles: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeRequestDto
     */
    observations: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheVehiculeRequestDto
     */
    paysEtranger: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheVehiculeRequestDto
     */
    portes: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheVehiculeRequestDto
     */
    posteCbVisible: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheVehiculeRequestDto
     */
    sansPlaque: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheVehiculeRequestDto
     */
    telephonePortable: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeRequestDto
     */
    vin: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum FicheVehiculeRequestDtoEtatLibelleEnum {
    'BON_ETAT' = 'BON_ETAT',
    'DEGRADE' = 'DEGRADE',
    'DOMMAGES_GRAVES' = 'DOMMAGES_GRAVES'
}

/**
 * 
 * @export
 * @interface FicheVehiculeSummaryDto
 */
export interface FicheVehiculeSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeSummaryDto
     */
    couleur: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeSummaryDto
     */
    etat: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheVehiculeSummaryDto
     */
    etranger: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeSummaryDto
     */
    genre: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeSummaryDto
     */
    marque: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeSummaryDto
     */
    modele: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeSummaryDto
     */
    numeroIdentification: string | null;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeSummaryDto
     */
    numeroIdentificationVisible: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FicheVehiculeSummaryDto
     */
    sansPlaques: boolean | null;
}
/**
 * 
 * @export
 * @interface FicheVehiculeViolationDto
 */
export interface FicheVehiculeViolationDto {
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeViolationDto
     */
    formField: FicheVehiculeViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof FicheVehiculeViolationDto
     */
    violationCode: FicheVehiculeViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FicheVehiculeViolationDtoFormFieldEnum {
    'DESCRIPTION_GENRE' = 'DESCRIPTION_GENRE',
    'DESCRIPTION_MARQUE' = 'DESCRIPTION_MARQUE',
    'DESCRIPTION_MODELE' = 'DESCRIPTION_MODELE',
    'DESCRIPTION_COULEUR' = 'DESCRIPTION_COULEUR',
    'ETAT_LIBELLE' = 'ETAT_LIBELLE',
    'ETAT_PRECISION' = 'ETAT_PRECISION',
    'IMMATRICULATION_SANS_PLAQUE' = 'IMMATRICULATION_SANS_PLAQUE',
    'IMMATRICULATION_PAYS_ETRANGER' = 'IMMATRICULATION_PAYS_ETRANGER',
    'IMMATRICULATION_NUMERO' = 'IMMATRICULATION_NUMERO',
    'IMMATRICULATION_NUMERO_VISIBLE' = 'IMMATRICULATION_NUMERO_VISIBLE',
    'IMMATRICULATION_VIN' = 'IMMATRICULATION_VIN',
    'VERROUILLAGE_PORTES' = 'VERROUILLAGE_PORTES',
    'VERROUILLAGE_COFFRES' = 'VERROUILLAGE_COFFRES',
    'ACCESSOIRES_AUTO_RADIO_VISIBLE' = 'ACCESSOIRES_AUTO_RADIO_VISIBLE',
    'ACCESSOIRES_POSTE_CB_VISIBLE' = 'ACCESSOIRES_POSTE_CB_VISIBLE',
    'ACCESSOIRES_TELEPHONE_PORTABLE' = 'ACCESSOIRES_TELEPHONE_PORTABLE',
    'ACCESSOIRES_NOMBRE_ANTENNES' = 'ACCESSOIRES_NOMBRE_ANTENNES',
    'ACCESSOIRES_OBJETS_VISIBLES' = 'ACCESSOIRES_OBJETS_VISIBLES',
    'ACCESSOIRES_OBSERVATIONS' = 'ACCESSOIRES_OBSERVATIONS'
}
/**
    * @export
    * @enum {string}
    */
export enum FicheVehiculeViolationDtoViolationCodeEnum {
    'MISSING_VALUE' = 'MISSING_VALUE',
    'BAD_FORMAT' = 'BAD_FORMAT',
    'BAD_VIN' = 'BAD_VIN',
    'BAD_NUMERO_SERIE_DICEM' = 'BAD_NUMERO_SERIE_DICEM',
    'FORBIDDEN_PLAQUE' = 'FORBIDDEN_PLAQUE',
    'FORBIDDEN_MARQUE' = 'FORBIDDEN_MARQUE',
    'FORBIDDEN_MODELE' = 'FORBIDDEN_MODELE',
    'WRONG_MODELE' = 'WRONG_MODELE',
    'BAD_FORMAT_IMMATRICULATION' = 'BAD_FORMAT_IMMATRICULATION',
    'BAD_FORMAT_IDENTIFICATION' = 'BAD_FORMAT_IDENTIFICATION',
    'COULEUR_NOT_FOUND' = 'COULEUR_NOT_FOUND',
    'MARQUE_NOT_FOUND' = 'MARQUE_NOT_FOUND',
    'GENRE_NOT_FOUND' = 'GENRE_NOT_FOUND',
    'MODELE_NOT_FOUND' = 'MODELE_NOT_FOUND',
    'ETAT_PRECISION_INVALID' = 'ETAT_PRECISION_INVALID',
    'ETAT_WITHOUT_PRECISION_NOT_FOUND' = 'ETAT_WITHOUT_PRECISION_NOT_FOUND',
    'IMMATRICULATION_ALREADY_EXISTS' = 'IMMATRICULATION_ALREADY_EXISTS',
    'VIN_ALREADY_EXISTS' = 'VIN_ALREADY_EXISTS',
    'ETAT_PRECISION_MUST_BE_NULL_IN_THIS_CASE' = 'ETAT_PRECISION_MUST_BE_NULL_IN_THIS_CASE'
}

/**
 * 
 * @export
 * @interface FichesInfractionAndFourriereRequestsWrapperDto
 */
export interface FichesInfractionAndFourriereRequestsWrapperDto {
    /**
     * 
     * @type {string}
     * @memberof FichesInfractionAndFourriereRequestsWrapperDto
     */
    dateConstatation: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichesInfractionAndFourriereRequestsWrapperDto
     */
    dateRedaction: string | null;
    /**
     * 
     * @type {FicheFourriereRequestDto}
     * @memberof FichesInfractionAndFourriereRequestsWrapperDto
     */
    ficheFourriereRequest: FicheFourriereRequestDto | null;
}
/**
 * 
 * @export
 * @interface FixDataNotificationManuelleRequestDto
 */
export interface FixDataNotificationManuelleRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FixDataNotificationManuelleRequestDto
     */
    dateEnvoi: string | null;
    /**
     * 
     * @type {string}
     * @memberof FixDataNotificationManuelleRequestDto
     */
    dateSignature: string | null;
    /**
     * 
     * @type {string}
     * @memberof FixDataNotificationManuelleRequestDto
     */
    etatDistribution: FixDataNotificationManuelleRequestDtoEtatDistributionEnum | null;
    /**
     * 
     * @type {string}
     * @memberof FixDataNotificationManuelleRequestDto
     */
    justification: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum FixDataNotificationManuelleRequestDtoEtatDistributionEnum {
    'DESTINATAIRE_INCONNU' = 'DESTINATAIRE_INCONNU',
    'REFUS_PLI' = 'REFUS_PLI',
    'RECEPTION_LRAR' = 'RECEPTION_LRAR',
    'ABSENCE_AVEC_RETRAIT' = 'ABSENCE_AVEC_RETRAIT',
    'ABSENCE_SANS_RETRAIT' = 'ABSENCE_SANS_RETRAIT'
}

/**
 * 
 * @export
 * @interface FixDataNotificationManuelleViolationDto
 */
export interface FixDataNotificationManuelleViolationDto {
    /**
     * 
     * @type {string}
     * @memberof FixDataNotificationManuelleViolationDto
     */
    formField: FixDataNotificationManuelleViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof FixDataNotificationManuelleViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof FixDataNotificationManuelleViolationDto
     */
    violationCode: FixDataNotificationManuelleViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FixDataNotificationManuelleViolationDtoFormFieldEnum {
    'NEW_DATE_ENVOI' = 'NEW_DATE_ENVOI',
    'NEW_DATE_SIGNATURE' = 'NEW_DATE_SIGNATURE',
    'NEW_ETAT_DISTRIBUTION' = 'NEW_ETAT_DISTRIBUTION',
    'JUSTIFICATION' = 'JUSTIFICATION'
}
/**
    * @export
    * @enum {string}
    */
export enum FixDataNotificationManuelleViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'DATE_IN_FUTURE' = 'DATE_IN_FUTURE',
    'DATE_ENVOI_BEFORE_DATE_ENTREE' = 'DATE_ENVOI_BEFORE_DATE_ENTREE',
    'MISSING_DATE_ENVOI' = 'MISSING_DATE_ENVOI',
    'DATE_SIGNATURE_BEFORE_DATE_ENVOI' = 'DATE_SIGNATURE_BEFORE_DATE_ENVOI',
    'DATA_SHOULD_ALREADY_EXISTS' = 'DATA_SHOULD_ALREADY_EXISTS',
    'DATE_SIGNATURE_INCOMPLETE_FIX_RETOUR_NOTIFICATION_MANUELLE' = 'DATE_SIGNATURE_INCOMPLETE_FIX_RETOUR_NOTIFICATION_MANUELLE',
    'ETAT_DISTRIBUTION_INCOMPLETE_FIX_RETOUR_NOTIFICATION_MANUELLE' = 'ETAT_DISTRIBUTION_INCOMPLETE_FIX_RETOUR_NOTIFICATION_MANUELLE',
    'NO_MODIFICATION' = 'NO_MODIFICATION'
}

/**
 * 
 * @export
 * @interface FluxDto
 */
export interface FluxDto {
    /**
     * 
     * @type {string}
     * @memberof FluxDto
     */
    dateAppel: string;
    /**
     * 
     * @type {string}
     * @memberof FluxDto
     */
    dateDebut: string;
    /**
     * 
     * @type {string}
     * @memberof FluxDto
     */
    dateFin: string;
    /**
     * 
     * @type {string}
     * @memberof FluxDto
     */
    error: string | null;
    /**
     * 
     * @type {string}
     * @memberof FluxDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof FluxDto
     */
    nbWarnings: number;
}
/**
 * 
 * @export
 * @interface FoFullUserDto
 */
export interface FoFullUserDto {
    /**
     * 
     * @type {string}
     * @memberof FoFullUserDto
     */
    email: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FoFullUserDto
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof FoFullUserDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof FoFullUserDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FoFullUserDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof FoFullUserDto
     */
    libelleLongUniteFo: string | null;
    /**
     * 
     * @type {UniteFOResponseDto}
     * @memberof FoFullUserDto
     */
    uniteFO: UniteFOResponseDto | null;
    /**
     * 
     * @type {UniteFOResponseDto}
     * @memberof FoFullUserDto
     */
    uniteFineFO: UniteFOResponseDto | null;
    /**
     * 
     * @type {string}
     * @memberof FoFullUserDto
     */
    username: string;
}
/**
 * 
 * @export
 * @interface FooterLinkDto
 */
export interface FooterLinkDto {
    /**
     * 
     * @type {string}
     * @memberof FooterLinkDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FooterLinkDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof FooterLinkDto
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof FooterLinkDto
     */
    text: string | null;
}
/**
 * 
 * @export
 * @interface FourriereDto
 */
export interface FourriereDto {
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    adresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    adresseDnid: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    adresseMail: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    adresseMailResponsable: string | null;
    /**
     * 
     * @type {Array<AutoriteFourriereDto>}
     * @memberof FourriereDto
     */
    autoritesFourrieres: Array<AutoriteFourriereDto>;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    codePostal: string;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    commentaires: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    commune: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    complementAdresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    coordonneesGPS: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    dateAgrement: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    dateConfirmationUtilisateur: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FourriereDto
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    finAgrement: string | null;
    /**
     * 
     * @type {HorairesDto}
     * @memberof FourriereDto
     */
    horaires: HorairesDto | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    idHermes: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    noVoie: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    nomResponsable: string | null;
    /**
     * 
     * @type {number}
     * @memberof FourriereDto
     */
    nombreEmplacements: number | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    numeroAgrement: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    raisonSociale: string;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    raisonSocialeDnid: string;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    repetition: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    telephoneFixe: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereDto
     */
    telephoneMobile: string | null;
}
/**
 * 
 * @export
 * @interface FourriereFluxDto
 */
export interface FourriereFluxDto {
    /**
     * 
     * @type {string}
     * @memberof FourriereFluxDto
     */
    dateAppel: string;
    /**
     * 
     * @type {string}
     * @memberof FourriereFluxDto
     */
    error: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereFluxDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof FourriereFluxDto
     */
    nbWarnings: number;
}
/**
 * 
 * @export
 * @interface FourriereRequestDto
 */
export interface FourriereRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    adresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    adresseDnid: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    commentaires: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    commune: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    complementAdresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    dateAgrement: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    emailResponsable: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    finAgrement: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    gps: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    idHermes: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FourriereRequestDto
     */
    listIdCorrelationAf: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof FourriereRequestDto
     */
    nbPlaces: number | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    nomResponsable: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    numeroAgrement: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    numeroVoie: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    raisonSociale: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    raisonSocialeDnid: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    repetition: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    telephoneFixe: string | null;
    /**
     * 
     * @type {string}
     * @memberof FourriereRequestDto
     */
    telephonePortable: string | null;
}
/**
 * 
 * @export
 * @interface FourriereViolationDto
 */
export interface FourriereViolationDto {
    /**
     * 
     * @type {string}
     * @memberof FourriereViolationDto
     */
    formField: FourriereViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof FourriereViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof FourriereViolationDto
     */
    violationCode: FourriereViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FourriereViolationDtoFormFieldEnum {
    'RAISON_SOCIALE' = 'RAISON_SOCIALE',
    'RAISON_SOCIALE_DNID' = 'RAISON_SOCIALE_DNID',
    'AF_RATTACHEMENT' = 'AF_RATTACHEMENT',
    'CODE_POSTAL' = 'CODE_POSTAL',
    'ADRESSE' = 'ADRESSE',
    'NUMERO_VOIE' = 'NUMERO_VOIE',
    'REPETITION' = 'REPETITION',
    'COMMUNE' = 'COMMUNE',
    'TELEPHONE_PORTABLE' = 'TELEPHONE_PORTABLE',
    'TELEPHONE_FIXE' = 'TELEPHONE_FIXE',
    'EMAIL' = 'EMAIL',
    'EMAIL_RESPONSABLE' = 'EMAIL_RESPONSABLE',
    'ID_HERMES' = 'ID_HERMES'
}
/**
    * @export
    * @enum {string}
    */
export enum FourriereViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'CODE_POSTAL_BAD_FORMAT' = 'CODE_POSTAL_BAD_FORMAT',
    'ADRESSE_AND_RAISON_SOCIALE_UNICITY' = 'ADRESSE_AND_RAISON_SOCIALE_UNICITY',
    'ID_HERMES_BAD_FORMAT' = 'ID_HERMES_BAD_FORMAT',
    'ID_HERMES_UNICITY_VIOLATION' = 'ID_HERMES_UNICITY_VIOLATION',
    'ID_HERMES_SHOULD_BE_NULL' = 'ID_HERMES_SHOULD_BE_NULL',
    'PHONE_BAD_FORMAT' = 'PHONE_BAD_FORMAT',
    'EMAIL_BAD_FORMAT' = 'EMAIL_BAD_FORMAT',
    'AF_NOT_FOUND' = 'AF_NOT_FOUND',
    'AF_NOT_ACTIVE' = 'AF_NOT_ACTIVE'
}

/**
 * 
 * @export
 * @interface FourriereWarningDto
 */
export interface FourriereWarningDto {
    /**
     * 
     * @type {string}
     * @memberof FourriereWarningDto
     */
    dateAppel: string;
    /**
     * 
     * @type {string}
     * @memberof FourriereWarningDto
     */
    hermesId: string;
    /**
     * 
     * @type {string}
     * @memberof FourriereWarningDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FourriereWarningDto
     */
    message: string;
}
/**
 * 
 * @export
 * @interface GenreDto
 */
export interface GenreDto {
    /**
     * 
     * @type {string}
     * @memberof GenreDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof GenreDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GenreDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof GenreDto
     */
    libelle: string;
    /**
     * 
     * @type {string}
     * @memberof GenreDto
     */
    sousCategorieId: string;
}
/**
 * 
 * @export
 * @interface GenreSimplifieDto
 */
export interface GenreSimplifieDto {
    /**
     * 
     * @type {boolean}
     * @memberof GenreSimplifieDto
     */
    dicem: boolean;
    /**
     * 
     * @type {Set<string>}
     * @memberof GenreSimplifieDto
     */
    formatsPlaque: Array<GenreSimplifieDtoFormatsPlaqueEnum>;
    /**
     * 
     * @type {string}
     * @memberof GenreSimplifieDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GenreSimplifieDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof GenreSimplifieDto
     */
    libelle: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GenreSimplifieDtoFormatsPlaqueEnum {
    'ANCIEN' = 'ANCIEN',
    'FNI' = 'FNI',
    'W_GARAGE' = 'W_GARAGE',
    'SIV' = 'SIV',
    'DEUX_ROUES' = 'DEUX_ROUES'
}

/**
 * 
 * @export
 * @interface Horaire
 */
export interface Horaire {
    /**
     * 
     * @type {string}
     * @memberof Horaire
     */
    debut: string;
    /**
     * 
     * @type {string}
     * @memberof Horaire
     */
    fin: string;
}
/**
 * 
 * @export
 * @interface HorairesDto
 */
export interface HorairesDto {
    /**
     * 
     * @type {Jour}
     * @memberof HorairesDto
     */
    dimanche: Jour | null;
    /**
     * 
     * @type {Jour}
     * @memberof HorairesDto
     */
    jeudi: Jour | null;
    /**
     * 
     * @type {Jour}
     * @memberof HorairesDto
     */
    lundi: Jour | null;
    /**
     * 
     * @type {Jour}
     * @memberof HorairesDto
     */
    mardi: Jour | null;
    /**
     * 
     * @type {Jour}
     * @memberof HorairesDto
     */
    mercredi: Jour | null;
    /**
     * 
     * @type {Jour}
     * @memberof HorairesDto
     */
    samedi: Jour | null;
    /**
     * 
     * @type {Jour}
     * @memberof HorairesDto
     */
    vendredi: Jour | null;
}
/**
 * 
 * @export
 * @interface ImmatriculationDto
 */
export interface ImmatriculationDto {
    /**
     * 
     * @type {string}
     * @memberof ImmatriculationDto
     */
    numero: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImmatriculationDto
     */
    numeroImmatriculationVisible: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ImmatriculationDto
     */
    paysEtranger: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ImmatriculationDto
     */
    sansPlaque: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ImmatriculationDto
     */
    vin: string | null;
}
/**
 * 
 * @export
 * @interface ImportTableViolationDto
 */
export interface ImportTableViolationDto {
    /**
     * 
     * @type {string}
     * @memberof ImportTableViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ImportTableViolationDto
     */
    violationCode: ImportTableViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ImportTableViolationDtoViolationCodeEnum {
    'UNKNOWN_COLUMN' = 'UNKNOWN_COLUMN',
    'TYPE_REF_UNKNOWN' = 'TYPE_REF_UNKNOWN',
    'BAD_VALUE_IN_COLUMN' = 'BAD_VALUE_IN_COLUMN',
    'UNKNOWN_VALUE_WARNING' = 'UNKNOWN_VALUE_WARNING',
    'UNKNOWN_BOOLEAN_WARNING' = 'UNKNOWN_BOOLEAN_WARNING',
    'NOT_UNIQUE_DECISION' = 'NOT_UNIQUE_DECISION',
    'NOT_UNIQUE_OUTPUT' = 'NOT_UNIQUE_OUTPUT',
    'BAD_DEFAULT_RULE' = 'BAD_DEFAULT_RULE'
}

/**
 * 
 * @export
 * @interface ImportViolationListDto
 */
export interface ImportViolationListDto {
    /**
     * 
     * @type {Array<ImportTableViolationDto>}
     * @memberof ImportViolationListDto
     */
    violations: Array<ImportTableViolationDto>;
}
/**
 * 
 * @export
 * @interface ImpossibiliteNotifierDto
 */
export interface ImpossibiliteNotifierDto {
    /**
     * 
     * @type {string}
     * @memberof ImpossibiliteNotifierDto
     */
    dateImpossibiliteNotifier: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ImpossibiliteNotifierDto
     */
    isPresent: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ImpossibiliteNotifierDto
     */
    willImpossibiliteNotifierBeSet: boolean | null;
}
/**
 * 
 * @export
 * @interface InformationMessageDto
 */
export interface InformationMessageDto {
    /**
     * 
     * @type {string}
     * @memberof InformationMessageDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof InformationMessageDto
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof InformationMessageDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InformationMessageDto
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof InformationMessageDto
     */
    title: string;
}
/**
 * 
 * @export
 * @interface InformationMessageRequestDto
 */
export interface InformationMessageRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof InformationMessageRequestDto
     */
    active: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InformationMessageRequestDto
     */
    content: string | null;
    /**
     * 
     * @type {string}
     * @memberof InformationMessageRequestDto
     */
    endDate: string | null;
    /**
     * 
     * @type {string}
     * @memberof InformationMessageRequestDto
     */
    startDate: string | null;
    /**
     * 
     * @type {string}
     * @memberof InformationMessageRequestDto
     */
    title: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InformationMessageRequestDto
     */
    userProfileList: Array<InformationMessageRequestDtoUserProfileListEnum> | null;
}

/**
    * @export
    * @enum {string}
    */
export enum InformationMessageRequestDtoUserProfileListEnum {
    'AUTORITE_FOURRIERE' = 'AUTORITE_FOURRIERE',
    'FORCE_DE_L_ORDRE' = 'FORCE_DE_L_ORDRE',
    'GARDIEN_FOURRIERE' = 'GARDIEN_FOURRIERE',
    'BORD_DE_ROUTE' = 'BORD_DE_ROUTE'
}

/**
 * 
 * @export
 * @interface InformationMessageViolationDto
 */
export interface InformationMessageViolationDto {
    /**
     * 
     * @type {string}
     * @memberof InformationMessageViolationDto
     */
    formField: InformationMessageViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof InformationMessageViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof InformationMessageViolationDto
     */
    violationCode: InformationMessageViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InformationMessageViolationDtoFormFieldEnum {
    'TITRE' = 'TITRE',
    'CONTENT' = 'CONTENT',
    'START_DATE' = 'START_DATE',
    'END_DATE' = 'END_DATE',
    'VALID' = 'VALID',
    'PROFILES' = 'PROFILES'
}
/**
    * @export
    * @enum {string}
    */
export enum InformationMessageViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'BEFORE_NOW' = 'BEFORE_NOW',
    'DATE_BEFORE' = 'DATE_BEFORE',
    'EDIT_DATE' = 'EDIT_DATE',
    'EDIT' = 'EDIT'
}

/**
 * 
 * @export
 * @interface InformationMessageViolationsDto
 */
export interface InformationMessageViolationsDto {
    /**
     * 
     * @type {Array<InformationMessageViolationDto>}
     * @memberof InformationMessageViolationsDto
     */
    informationMessageViolationDtos: Array<InformationMessageViolationDto> | null;
}
/**
 * 
 * @export
 * @interface InformationsTechniquesResponseDto
 */
export interface InformationsTechniquesResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InformationsTechniquesResponseDto
     */
    carrosserieId: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InformationsTechniquesResponseDto
     */
    dangereux: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InformationsTechniquesResponseDto
     */
    datePremiereImmatriculation: string | null;
    /**
     * 
     * @type {string}
     * @memberof InformationsTechniquesResponseDto
     */
    energieId: string | null;
    /**
     * 
     * @type {string}
     * @memberof InformationsTechniquesResponseDto
     */
    genreId: string | null;
    /**
     * 
     * @type {string}
     * @memberof InformationsTechniquesResponseDto
     */
    labelMotifMef: string | null;
    /**
     * 
     * @type {string}
     * @memberof InformationsTechniquesResponseDto
     */
    libelleMarque: string | null;
    /**
     * 
     * @type {number}
     * @memberof InformationsTechniquesResponseDto
     */
    puissanceCv: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof InformationsTechniquesResponseDto
     */
    reparable: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InformationsTechniquesResponseDto
     */
    vehiculeEtranger: boolean | null;
}
/**
 * 
 * @export
 * @interface InfosAssureurDto
 */
export interface InfosAssureurDto {
    /**
     * 
     * @type {boolean}
     * @memberof InfosAssureurDto
     */
    assureIndeminse: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InfosAssureurDto
     */
    dateContact: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosAssureurDto
     */
    dateInfosAssureur: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosAssureurDto
     */
    immatriculation: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosAssureurDto
     */
    priseEnChargeVol: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosAssureurDto
     */
    sirenAssureurAvise: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosAssureurDto
     */
    vin: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InfosAssureurDto
     */
    volAdherent: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InfosAssureurDto
     */
    volFoves: boolean | null;
}
/**
 * 
 * @export
 * @interface InfosSivDocumentRequestDto
 */
export interface InfosSivDocumentRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InfosSivDocumentRequestDto
     */
    dateCi: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivDocumentRequestDto
     */
    numeroFormule: string | null;
}
/**
 * 
 * @export
 * @interface InfosSivPersonneRattacheeRequestDto
 */
export interface InfosSivPersonneRattacheeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    boitePostale: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    codeCedex: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    codeTypePersonne: InfosSivPersonneRattacheeRequestDtoCodeTypePersonneEnum | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    complementAdresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    extension: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    libelleCedex: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    libelleCommune: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    libelleVoie: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    lieuDit: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    nomNaissance: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    nomUsage: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    numeroVoie: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    pays: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    pointRemise: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    prenom: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    raisonSociale: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivPersonneRattacheeRequestDto
     */
    typeVoie: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum InfosSivPersonneRattacheeRequestDtoCodeTypePersonneEnum {
    'TITULAIRE' = 'TITULAIRE',
    'ACQUEREUR' = 'ACQUEREUR',
    'LOCATAIRE' = 'LOCATAIRE',
    'LOUEUR' = 'LOUEUR'
}

/**
 * 
 * @export
 * @interface InfosSivRequestDto
 */
export interface InfosSivRequestDto {
    /**
     * 
     * @type {InfosSivDocumentRequestDto}
     * @memberof InfosSivRequestDto
     */
    infosSivDocumentRequestDto: InfosSivDocumentRequestDto | null;
    /**
     * 
     * @type {InfosSivPersonneRattacheeRequestDto}
     * @memberof InfosSivRequestDto
     */
    infosSivPersonneRattacheeRequestDto: InfosSivPersonneRattacheeRequestDto | null;
    /**
     * 
     * @type {InfosSivSituationAdministrativeRequestDto}
     * @memberof InfosSivRequestDto
     */
    infosSivSituationAdministrativeRequestDto: InfosSivSituationAdministrativeRequestDto | null;
    /**
     * 
     * @type {InfosSivVehiculeRequestDto}
     * @memberof InfosSivRequestDto
     */
    infosSivVehiculeRequestDto: InfosSivVehiculeRequestDto | null;
}
/**
 * 
 * @export
 * @interface InfosSivSituationAdministrativeRequestDto
 */
export interface InfosSivSituationAdministrativeRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof InfosSivSituationAdministrativeRequestDto
     */
    flagPve: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InfosSivSituationAdministrativeRequestDto
     */
    flagVehiculeVole: boolean | null;
}
/**
 * 
 * @export
 * @interface InfosSivVehiculeRequestDto
 */
export interface InfosSivVehiculeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InfosSivVehiculeRequestDto
     */
    cnit: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivVehiculeRequestDto
     */
    datePremiereImmatriculation: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivVehiculeRequestDto
     */
    idCorrelationGenre: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivVehiculeRequestDto
     */
    idCorrelationMarque: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivVehiculeRequestDto
     */
    idCorrelationModele: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivVehiculeRequestDto
     */
    numeroImmatriculation: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfosSivVehiculeRequestDto
     */
    vin: string | null;
}
/**
 * 
 * @export
 * @interface InterdictionCirculer
 */
export interface InterdictionCirculer {
    /**
     * 
     * @type {string}
     * @memberof InterdictionCirculer
     */
    dateDebutPermissionProvisoire: string | null;
    /**
     * 
     * @type {string}
     * @memberof InterdictionCirculer
     */
    dateFinPermissionProvisoire: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InterdictionCirculer
     */
    hasInterdictionCirculer: boolean;
    /**
     * 
     * @type {string}
     * @memberof InterdictionCirculer
     */
    motifInterdictionCirculer: string | null;
}
/**
 * 
 * @export
 * @interface InternalHorairesRequestDto
 */
export interface InternalHorairesRequestDto {
    /**
     * 
     * @type {Jour}
     * @memberof InternalHorairesRequestDto
     */
    dimanche: Jour | null;
    /**
     * 
     * @type {Jour}
     * @memberof InternalHorairesRequestDto
     */
    jeudi: Jour | null;
    /**
     * 
     * @type {Jour}
     * @memberof InternalHorairesRequestDto
     */
    lundi: Jour | null;
    /**
     * 
     * @type {Jour}
     * @memberof InternalHorairesRequestDto
     */
    mardi: Jour | null;
    /**
     * 
     * @type {Jour}
     * @memberof InternalHorairesRequestDto
     */
    mercredi: Jour | null;
    /**
     * 
     * @type {Jour}
     * @memberof InternalHorairesRequestDto
     */
    samedi: Jour | null;
    /**
     * 
     * @type {Jour}
     * @memberof InternalHorairesRequestDto
     */
    vendredi: Jour | null;
}
/**
 * 
 * @export
 * @interface Jour
 */
export interface Jour {
    /**
     * 
     * @type {Array<Horaire>}
     * @memberof Jour
     */
    horairesAppel: Array<Horaire>;
    /**
     * 
     * @type {Array<Horaire>}
     * @memberof Jour
     */
    horairesOuverture: Array<Horaire>;
    /**
     * 
     * @type {boolean}
     * @memberof Jour
     */
    surRdvUniquement: boolean;
}
/**
 * 
 * @export
 * @interface JwtDto
 */
export interface JwtDto {
    /**
     * 
     * @type {string}
     * @memberof JwtDto
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof JwtDto
     */
    refreshToken: string | null;
}
/**
 * 
 * @export
 * @interface MainLeveeDto
 */
export interface MainLeveeDto {
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    codePostalProprietaire: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    communeAdresseProprietaire: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    communeFonctionAgent: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    communeRedaction: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    dateDebutPermissionProvisoire: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    dateFinPermissionProvisoire: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    dateMiseAJour: string;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    dateProcesVerbal: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    gradeAgent: MainLeveeDtoGradeAgentEnum | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    idCorrelationFourriere: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    idCorrelationGenreSimplifie: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    idMarque: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    immatriculation: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MainLeveeDto
     */
    interdictionCirculer: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    justificationSortie: MainLeveeDtoJustificationSortieEnum | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    lieuAdresseProprietaire: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    matriculeAgent: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    motifInterdictionCirculer: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    nomAgent: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    nomProprietaire: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    numProcesVerbal: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    prenomProprietaire: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    rsProprietaire: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeDto
     */
    typeRestitution: MainLeveeDtoTypeRestitutionEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum MainLeveeDtoGradeAgentEnum {
    'APJA' = 'APJA',
    'APJ' = 'APJ',
    'OPJ' = 'OPJ'
}
/**
    * @export
    * @enum {string}
    */
export enum MainLeveeDtoJustificationSortieEnum {
    'VERIFICATIONS_NON_USAGE_STUPEFIANTS' = 'VERIFICATIONS_NON_USAGE_STUPEFIANTS',
    'VOL' = 'VOL',
    'LOCATION_TITRE_ONEREUX_A_UN_TIERS' = 'LOCATION_TITRE_ONEREUX_A_UN_TIERS',
    'DECISION_APRES_CONTESTATION_PROCUREUR_OU_PREFET' = 'DECISION_APRES_CONTESTATION_PROCUREUR_OU_PREFET'
}
/**
    * @export
    * @enum {string}
    */
export enum MainLeveeDtoTypeRestitutionEnum {
    'ASSURANCE_PERMIS' = 'ASSURANCE_PERMIS',
    'ASSURANCE_REMORQUE' = 'ASSURANCE_REMORQUE'
}

/**
 * 
 * @export
 * @interface MainLeveeViolationDto
 */
export interface MainLeveeViolationDto {
    /**
     * 
     * @type {string}
     * @memberof MainLeveeViolationDto
     */
    formField: MainLeveeViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof MainLeveeViolationDto
     */
    violationCode: MainLeveeViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MainLeveeViolationDtoFormFieldEnum {
    'NUM_PROCES_VERBAL' = 'NUM_PROCES_VERBAL',
    'DATE_PROCES_VERBAL' = 'DATE_PROCES_VERBAL',
    'GRADE_AGENT' = 'GRADE_AGENT',
    'MATRICULE_AGENT' = 'MATRICULE_AGENT',
    'COMMUNE_FONCTION' = 'COMMUNE_FONCTION',
    'TYPE_RESTITUTION' = 'TYPE_RESTITUTION',
    'GENRE_SIMPLIFIE_ID' = 'GENRE_SIMPLIFIE_ID',
    'IMMATRICULATION' = 'IMMATRICULATION',
    'MARQUE_ID' = 'MARQUE_ID',
    'NOM_PROPRIETAIRE' = 'NOM_PROPRIETAIRE',
    'LIEU_ADRESSE_PROPRIETAIRE' = 'LIEU_ADRESSE_PROPRIETAIRE',
    'CODE_POSTAL_PROPRIETAIRE' = 'CODE_POSTAL_PROPRIETAIRE',
    'COMMUNE_ADRESSE_PROPRIETAIRE' = 'COMMUNE_ADRESSE_PROPRIETAIRE',
    'FOURRIERE_ID' = 'FOURRIERE_ID',
    'DEBUT_AUTORISATION' = 'DEBUT_AUTORISATION',
    'FIN_AUTORISATION' = 'FIN_AUTORISATION',
    'COMMUNE_REDACTION' = 'COMMUNE_REDACTION',
    'JUSTIFICATION_SORTIE' = 'JUSTIFICATION_SORTIE'
}
/**
    * @export
    * @enum {string}
    */
export enum MainLeveeViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'FIELD_EMPTY_IF_NOT_IC' = 'FIELD_EMPTY_IF_NOT_IC',
    'DATE_FIN_BEFORE_DATE_DEBUT' = 'DATE_FIN_BEFORE_DATE_DEBUT',
    'MANDATORY_IF_IC' = 'MANDATORY_IF_IC',
    'BAD_NUMERIC_FORMAT' = 'BAD_NUMERIC_FORMAT',
    'INVALID_GENRE_SIMPLIFIE' = 'INVALID_GENRE_SIMPLIFIE',
    'INVALID_MARQUE' = 'INVALID_MARQUE',
    'INVALID_FOURRIERE' = 'INVALID_FOURRIERE',
    'INVALID_IMMATRICULATION' = 'INVALID_IMMATRICULATION',
    'DATE_IN_FUTURE' = 'DATE_IN_FUTURE'
}

/**
 * 
 * @export
 * @interface MainleveeResponseDto
 */
export interface MainleveeResponseDto {
    /**
     * 
     * @type {Acheteur}
     * @memberof MainleveeResponseDto
     */
    acheteur: Acheteur | null;
    /**
     * 
     * @type {AgentDto}
     * @memberof MainleveeResponseDto
     */
    agent: AgentDto;
    /**
     * 
     * @type {string}
     * @memberof MainleveeResponseDto
     */
    communeRedaction: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainleveeResponseDto
     */
    idCorrelationFourriere: string | null;
    /**
     * 
     * @type {InterdictionCirculer}
     * @memberof MainleveeResponseDto
     */
    interdictionCirculer: InterdictionCirculer;
    /**
     * 
     * @type {string}
     * @memberof MainleveeResponseDto
     */
    justificationSortie: MainleveeResponseDtoJustificationSortieEnum | null;
    /**
     * 
     * @type {ProcesVerbalDto}
     * @memberof MainleveeResponseDto
     */
    procesVerbal: ProcesVerbalDto;
    /**
     * 
     * @type {Proprietaire}
     * @memberof MainleveeResponseDto
     */
    proprietaire: Proprietaire;
    /**
     * 
     * @type {string}
     * @memberof MainleveeResponseDto
     */
    typeRestitution: MainleveeResponseDtoTypeRestitutionEnum | null;
    /**
     * 
     * @type {Vehicule}
     * @memberof MainleveeResponseDto
     */
    vehicule: Vehicule;
}

/**
    * @export
    * @enum {string}
    */
export enum MainleveeResponseDtoJustificationSortieEnum {
    'VERIFICATIONS_NON_USAGE_STUPEFIANTS' = 'VERIFICATIONS_NON_USAGE_STUPEFIANTS',
    'VOL' = 'VOL',
    'LOCATION_TITRE_ONEREUX_A_UN_TIERS' = 'LOCATION_TITRE_ONEREUX_A_UN_TIERS',
    'DECISION_APRES_CONTESTATION_PROCUREUR_OU_PREFET' = 'DECISION_APRES_CONTESTATION_PROCUREUR_OU_PREFET'
}
/**
    * @export
    * @enum {string}
    */
export enum MainleveeResponseDtoTypeRestitutionEnum {
    'ASSURANCE_PERMIS' = 'ASSURANCE_PERMIS',
    'ASSURANCE_REMORQUE' = 'ASSURANCE_REMORQUE'
}

/**
 * 
 * @export
 * @interface MarqueDto
 */
export interface MarqueDto {
    /**
     * 
     * @type {string}
     * @memberof MarqueDto
     */
    categorie: MarqueDtoCategorieEnum | null;
    /**
     * 
     * @type {string}
     * @memberof MarqueDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MarqueDto
     */
    idCorrelation: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarqueDto
     */
    libelle: string;
    /**
     * 
     * @type {string}
     * @memberof MarqueDto
     */
    marqueExacteId: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MarqueDto
     */
    prioritaire: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MarqueDto
     */
    type: MarqueDtoTypeEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum MarqueDtoCategorieEnum {
    'A_CATEGORISER' = 'A_CATEGORISER',
    'PREMIUM' = 'PREMIUM',
    'NON_VALORISABLE' = 'NON_VALORISABLE'
}
/**
    * @export
    * @enum {string}
    */
export enum MarqueDtoTypeEnum {
    'EXACTE' = 'EXACTE',
    'APPROXIMATIVE' = 'APPROXIMATIVE',
    'INCONNUE' = 'INCONNUE'
}

/**
 * 
 * @export
 * @interface MarqueRequestDto
 */
export interface MarqueRequestDto {
    /**
     * 
     * @type {string}
     * @memberof MarqueRequestDto
     */
    categorie: MarqueRequestDtoCategorieEnum | null;
    /**
     * 
     * @type {string}
     * @memberof MarqueRequestDto
     */
    marqueExacteId: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarqueRequestDto
     */
    type: MarqueRequestDtoTypeEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum MarqueRequestDtoCategorieEnum {
    'A_CATEGORISER' = 'A_CATEGORISER',
    'PREMIUM' = 'PREMIUM',
    'NON_VALORISABLE' = 'NON_VALORISABLE'
}
/**
    * @export
    * @enum {string}
    */
export enum MarqueRequestDtoTypeEnum {
    'EXACTE' = 'EXACTE',
    'APPROXIMATIVE' = 'APPROXIMATIVE',
    'INCONNUE' = 'INCONNUE'
}

/**
 * 
 * @export
 * @interface MarqueViolationDto
 */
export interface MarqueViolationDto {
    /**
     * 
     * @type {string}
     * @memberof MarqueViolationDto
     */
    formField: MarqueViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof MarqueViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof MarqueViolationDto
     */
    violationCode: MarqueViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MarqueViolationDtoFormFieldEnum {
    'CATEGORIE' = 'CATEGORIE',
    'TYPE' = 'TYPE',
    'MARQUE_EXACTE' = 'MARQUE_EXACTE'
}
/**
    * @export
    * @enum {string}
    */
export enum MarqueViolationDtoViolationCodeEnum {
    'MARQUE_HAS_APPROXIMATIVE_LINKED' = 'MARQUE_HAS_APPROXIMATIVE_LINKED',
    'MARQUE_HAS_EXACTE_MARQUE_LINKED' = 'MARQUE_HAS_EXACTE_MARQUE_LINKED',
    'MARQUE_EXACTE_NOT_FOUND' = 'MARQUE_EXACTE_NOT_FOUND',
    'MARQUE_EXACTE_NOT_EXACTE' = 'MARQUE_EXACTE_NOT_EXACTE'
}

/**
 * 
 * @export
 * @interface MigrateUniteFoRequestDto
 */
export interface MigrateUniteFoRequestDto {
    /**
     * 
     * @type {string}
     * @memberof MigrateUniteFoRequestDto
     */
    comment: string | null;
    /**
     * 
     * @type {string}
     * @memberof MigrateUniteFoRequestDto
     */
    newUniteFoIdCorrelation: string | null;
    /**
     * 
     * @type {string}
     * @memberof MigrateUniteFoRequestDto
     */
    oldUniteFoIdCorrelation: string | null;
}
/**
 * 
 * @export
 * @interface MigrateUniteFoViolationDto
 */
export interface MigrateUniteFoViolationDto {
    /**
     * 
     * @type {string}
     * @memberof MigrateUniteFoViolationDto
     */
    formField: MigrateUniteFoViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof MigrateUniteFoViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof MigrateUniteFoViolationDto
     */
    violationCode: MigrateUniteFoViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MigrateUniteFoViolationDtoFormFieldEnum {
    'NEW_UNITE_FO_CORRELATION_ID' = 'NEW_UNITE_FO_CORRELATION_ID',
    'OLD_UNITE_FO_CORRELATION_ID' = 'OLD_UNITE_FO_CORRELATION_ID',
    'COMMENT' = 'COMMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum MigrateUniteFoViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'INVALID_UNITE_FO' = 'INVALID_UNITE_FO',
    'UNITE_FO_NOT_FOUND' = 'UNITE_FO_NOT_FOUND',
    'NO_MODIFICATION' = 'NO_MODIFICATION'
}

/**
 * 
 * @export
 * @interface ModeleDto
 */
export interface ModeleDto {
    /**
     * 
     * @type {string}
     * @memberof ModeleDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModeleDto
     */
    idCorrelation: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModeleDto
     */
    libelle: string;
    /**
     * 
     * @type {string}
     * @memberof ModeleDto
     */
    marque: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModeleDto
     */
    prioritaire: boolean | null;
}
/**
 * 
 * @export
 * @interface MotifMefDto
 */
export interface MotifMefDto {
    /**
     * 
     * @type {BrancheDto}
     * @memberof MotifMefDto
     */
    branche: BrancheDto;
    /**
     * 
     * @type {boolean}
     * @memberof MotifMefDto
     */
    dicem: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MotifMefDto
     */
    enabled: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MotifMefDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MotifMefDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof MotifMefDto
     */
    motifMef: string;
    /**
     * 
     * @type {string}
     * @memberof MotifMefDto
     */
    nature: string;
    /**
     * 
     * @type {string}
     * @memberof MotifMefDto
     */
    natureLibelleCourt: string;
    /**
     * 
     * @type {boolean}
     * @memberof MotifMefDto
     */
    rodeo: boolean | null;
}
/**
 * 
 * @export
 * @interface MotifMefRequestDto
 */
export interface MotifMefRequestDto {
    /**
     * 
     * @type {string}
     * @memberof MotifMefRequestDto
     */
    brancheIdCorrelation: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MotifMefRequestDto
     */
    dicem: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MotifMefRequestDto
     */
    motifMef: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotifMefRequestDto
     */
    nature: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MotifMefRequestDto
     */
    rodeo: boolean | null;
}
/**
 * 
 * @export
 * @interface MotifMefViolationDto
 */
export interface MotifMefViolationDto {
    /**
     * 
     * @type {string}
     * @memberof MotifMefViolationDto
     */
    formField: MotifMefViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof MotifMefViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof MotifMefViolationDto
     */
    violationCode: MotifMefViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MotifMefViolationDtoFormFieldEnum {
    'MOTIF_MEF' = 'MOTIF_MEF',
    'BRANCHE' = 'BRANCHE',
    'NATURE' = 'NATURE',
    'DICEM' = 'DICEM',
    'RODEO' = 'RODEO'
}
/**
    * @export
    * @enum {string}
    */
export enum MotifMefViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'BRANCHE_NOT_FOUND' = 'BRANCHE_NOT_FOUND',
    'BRANCHE_NOT_ACTIVE' = 'BRANCHE_NOT_ACTIVE',
    'MOTIF_MEF_UNICITY_VIOLATION' = 'MOTIF_MEF_UNICITY_VIOLATION'
}

/**
 * 
 * @export
 * @interface MotifSortieDto
 */
export interface MotifSortieDto {
    /**
     * 
     * @type {string}
     * @memberof MotifSortieDto
     */
    commentaire: string;
    /**
     * 
     * @type {number}
     * @memberof MotifSortieDto
     */
    delaiEffacementEnMois: number;
    /**
     * 
     * @type {string}
     * @memberof MotifSortieDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MotifSortieDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof MotifSortieDto
     */
    motif: string;
    /**
     * 
     * @type {boolean}
     * @memberof MotifSortieDto
     */
    valid: boolean | null;
}
/**
 * 
 * @export
 * @interface MotifSortieRequestDto
 */
export interface MotifSortieRequestDto {
    /**
     * 
     * @type {string}
     * @memberof MotifSortieRequestDto
     */
    commentaire: string | null;
    /**
     * 
     * @type {number}
     * @memberof MotifSortieRequestDto
     */
    delaiEffacement: number | null;
    /**
     * 
     * @type {string}
     * @memberof MotifSortieRequestDto
     */
    motif: string | null;
}
/**
 * 
 * @export
 * @interface MotifSortieViolationDto
 */
export interface MotifSortieViolationDto {
    /**
     * 
     * @type {string}
     * @memberof MotifSortieViolationDto
     */
    formField: MotifSortieViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof MotifSortieViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof MotifSortieViolationDto
     */
    violationCode: MotifSortieViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MotifSortieViolationDtoFormFieldEnum {
    'MOTIF' = 'MOTIF',
    'COMMENTAIRE' = 'COMMENTAIRE',
    'DELAI_EFFACEMENT' = 'DELAI_EFFACEMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum MotifSortieViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'DELAI_EFFACEMENT_NEGATIVE_VALUE' = 'DELAI_EFFACEMENT_NEGATIVE_VALUE',
    'MOTIF_UNICITY_VIOLATION' = 'MOTIF_UNICITY_VIOLATION'
}

/**
 * 
 * @export
 * @interface NotificationAutomatiqueInfosDto
 */
export interface NotificationAutomatiqueInfosDto {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationAutomatiqueInfosDto
     */
    absenceRetour: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationAutomatiqueInfosDto
     */
    antaiId: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationAutomatiqueInfosDto
     */
    arPndFilename: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationAutomatiqueInfosDto
     */
    compteurEnvoi: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationAutomatiqueInfosDto
     */
    courrierFilename: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationAutomatiqueInfosDto
     */
    dateDeclenchementAbsenceRetour: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationAutomatiqueInfosDto
     */
    dateDemandeEdition: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationAutomatiqueInfosDto
     */
    dateEstimeeRetour: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationAutomatiqueInfosDto
     */
    dateMiseAJourEtat: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationAutomatiqueInfosDto
     */
    dateRemisEnPoste: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationAutomatiqueInfosDto
     */
    etatDistribution: NotificationAutomatiqueInfosDtoEtatDistributionEnum | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationAutomatiqueInfosDto
     */
    fileIds: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationAutomatiqueInfosDto
     */
    remisEnPoste: boolean | null;
}

/**
    * @export
    * @enum {string}
    */
export enum NotificationAutomatiqueInfosDtoEtatDistributionEnum {
    'AR' = 'AR',
    'REFUSE' = 'REFUSE',
    'NON_RECLAME' = 'NON_RECLAME',
    'DEFAUT_ACCESS_OU_ADRESSAGE' = 'DEFAUT_ACCESS_OU_ADRESSAGE',
    'DESTINATAIRE_INCONNU' = 'DESTINATAIRE_INCONNU',
    'INCONNU' = 'INCONNU'
}

/**
 * 
 * @export
 * @interface NotificationInfosRequestDto
 */
export interface NotificationInfosRequestDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationInfosRequestDto
     */
    dateEnvoi: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfosRequestDto
     */
    dateRetour: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfosRequestDto
     */
    etatDistribution: NotificationInfosRequestDtoEtatDistributionEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum NotificationInfosRequestDtoEtatDistributionEnum {
    'DESTINATAIRE_INCONNU' = 'DESTINATAIRE_INCONNU',
    'REFUS_PLI' = 'REFUS_PLI',
    'RECEPTION_LRAR' = 'RECEPTION_LRAR',
    'ABSENCE_AVEC_RETRAIT' = 'ABSENCE_AVEC_RETRAIT',
    'ABSENCE_SANS_RETRAIT' = 'ABSENCE_SANS_RETRAIT'
}

/**
 * 
 * @export
 * @interface NotificationInfosViolationDto
 */
export interface NotificationInfosViolationDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationInfosViolationDto
     */
    formField: NotificationInfosViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfosViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfosViolationDto
     */
    violationCode: NotificationInfosViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum NotificationInfosViolationDtoFormFieldEnum {
    'DATE_ENVOI' = 'DATE_ENVOI',
    'DATE_RETOUR' = 'DATE_RETOUR',
    'ETAT_DISTRIBUTION' = 'ETAT_DISTRIBUTION'
}
/**
    * @export
    * @enum {string}
    */
export enum NotificationInfosViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'DATE_ENVOI_DIFFERENT_FROM_DOSSIER' = 'DATE_ENVOI_DIFFERENT_FROM_DOSSIER',
    'DATE_IN_THE_FUTURE' = 'DATE_IN_THE_FUTURE',
    'DATE_ENVOI_BEFORE_ENTREE' = 'DATE_ENVOI_BEFORE_ENTREE',
    'DATE_RETOUR_BEFORE_ENVOI' = 'DATE_RETOUR_BEFORE_ENVOI'
}

/**
 * 
 * @export
 * @interface NotificationManuelleInfosDto
 */
export interface NotificationManuelleInfosDto {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationManuelleInfosDto
     */
    absenceRetour: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationManuelleInfosDto
     */
    ajoutActionRequiseRetour: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationManuelleInfosDto
     */
    dateDeclenchementAbsenceRetour: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationManuelleInfosDto
     */
    dateDeclenchementActionRequiseRetour: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationManuelleInfosDto
     */
    dateEnvoi: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationManuelleInfosDto
     */
    dateRetour: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationManuelleInfosDto
     */
    dateRetourEstimee: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationManuelleInfosDto
     */
    etatDistribution: NotificationManuelleInfosDtoEtatDistributionEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum NotificationManuelleInfosDtoEtatDistributionEnum {
    'DESTINATAIRE_INCONNU' = 'DESTINATAIRE_INCONNU',
    'REFUS_PLI' = 'REFUS_PLI',
    'RECEPTION_LRAR' = 'RECEPTION_LRAR',
    'ABSENCE_AVEC_RETRAIT' = 'ABSENCE_AVEC_RETRAIT',
    'ABSENCE_SANS_RETRAIT' = 'ABSENCE_SANS_RETRAIT'
}

/**
 * 
 * @export
 * @interface ProcesVerbalDto
 */
export interface ProcesVerbalDto {
    /**
     * 
     * @type {string}
     * @memberof ProcesVerbalDto
     */
    date: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProcesVerbalDto
     */
    numero: string | null;
}
/**
 * 
 * @export
 * @interface ProfilAutoriteFourriereRequestDto
 */
export interface ProfilAutoriteFourriereRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ProfilAutoriteFourriereRequestDto
     */
    cedex: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilAutoriteFourriereRequestDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilAutoriteFourriereRequestDto
     */
    commune: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilAutoriteFourriereRequestDto
     */
    complementAdresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilAutoriteFourriereRequestDto
     */
    numeroVoie: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilAutoriteFourriereRequestDto
     */
    voie: string | null;
}
/**
 * 
 * @export
 * @interface ProfilAutoriteFourriereViolationDto
 */
export interface ProfilAutoriteFourriereViolationDto {
    /**
     * 
     * @type {string}
     * @memberof ProfilAutoriteFourriereViolationDto
     */
    formField: ProfilAutoriteFourriereViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof ProfilAutoriteFourriereViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilAutoriteFourriereViolationDto
     */
    violationCode: ProfilAutoriteFourriereViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProfilAutoriteFourriereViolationDtoFormFieldEnum {
    'TYPE' = 'TYPE',
    'NOM_VOIE' = 'NOM_VOIE',
    'NUMERO_VOIE' = 'NUMERO_VOIE',
    'ADRESSE' = 'ADRESSE',
    'COMPLEMENT_ADRESSE' = 'COMPLEMENT_ADRESSE',
    'CODE_POSTAL' = 'CODE_POSTAL',
    'COMMUNE' = 'COMMUNE',
    'CEDEX' = 'CEDEX'
}
/**
    * @export
    * @enum {string}
    */
export enum ProfilAutoriteFourriereViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'NOT_IN_REFERENTIEL' = 'NOT_IN_REFERENTIEL',
    'CODE_POSTAL_WRONG_FORMAT' = 'CODE_POSTAL_WRONG_FORMAT',
    'STRING_LENGTH_LIMIT' = 'STRING_LENGTH_LIMIT'
}

/**
 * 
 * @export
 * @interface ProfilAutoriteFourriereViolationsDto
 */
export interface ProfilAutoriteFourriereViolationsDto {
    /**
     * 
     * @type {Array<ProfilAutoriteFourriereViolationDto>}
     * @memberof ProfilAutoriteFourriereViolationsDto
     */
    violationDtos: Array<ProfilAutoriteFourriereViolationDto> | null;
}
/**
 * 
 * @export
 * @interface ProfilFourriereRequestDto
 */
export interface ProfilFourriereRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereRequestDto
     */
    adresseMail: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfilFourriereRequestDto
     */
    autoriteFourriereRattachement: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereRequestDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereRequestDto
     */
    commune: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereRequestDto
     */
    complementAdresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereRequestDto
     */
    coordonneesGps: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereRequestDto
     */
    dateAgrementDebut: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereRequestDto
     */
    dateAgrementFin: string | null;
    /**
     * 
     * @type {InternalHorairesRequestDto}
     * @memberof ProfilFourriereRequestDto
     */
    horaires: InternalHorairesRequestDto | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereRequestDto
     */
    mailResponsable: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereRequestDto
     */
    nomResponsable: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereRequestDto
     */
    nomVoie: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfilFourriereRequestDto
     */
    nombrePlacesParc: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereRequestDto
     */
    numeroAgrement: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereRequestDto
     */
    numeroVoie: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereRequestDto
     */
    raisonSociale: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereRequestDto
     */
    repetition: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereRequestDto
     */
    telephoneFixe: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereRequestDto
     */
    telephoneMobile: string | null;
}
/**
 * 
 * @export
 * @interface ProfilFourriereViolationDto
 */
export interface ProfilFourriereViolationDto {
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereViolationDto
     */
    formField: ProfilFourriereViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilFourriereViolationDto
     */
    violationCode: ProfilFourriereViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProfilFourriereViolationDtoFormFieldEnum {
    'ID' = 'ID',
    'ID_CORRELATION' = 'ID_CORRELATION',
    'AUTORITES_FOURRIERE_IDS' = 'AUTORITES_FOURRIERE_IDS',
    'RAISON_SOCIALE' = 'RAISON_SOCIALE',
    'NUMERO_AGREMENT' = 'NUMERO_AGREMENT',
    'DATE_AGREMENT_DEBUT' = 'DATE_AGREMENT_DEBUT',
    'DATE_AGREMENT_FIN' = 'DATE_AGREMENT_FIN',
    'NOMBRE_EMPLACEMENTS' = 'NOMBRE_EMPLACEMENTS',
    'NOM_VOIE' = 'NOM_VOIE',
    'NUMERO_VOIE' = 'NUMERO_VOIE',
    'REPETITION' = 'REPETITION',
    'ADRESSE' = 'ADRESSE',
    'COMPLEMENT_ADRESSE' = 'COMPLEMENT_ADRESSE',
    'CODE_POSTAL' = 'CODE_POSTAL',
    'COMMUNE' = 'COMMUNE',
    'TELEPHONE_FIXE' = 'TELEPHONE_FIXE',
    'TELEPHONE_MOBILE' = 'TELEPHONE_MOBILE',
    'ADRESSE_MAIL' = 'ADRESSE_MAIL',
    'COORDONNEES_GPS' = 'COORDONNEES_GPS',
    'NOM_RESPONSABLE' = 'NOM_RESPONSABLE',
    'ADRESSE_MAIL_RESPONSABLE' = 'ADRESSE_MAIL_RESPONSABLE',
    'HORAIRES' = 'HORAIRES'
}
/**
    * @export
    * @enum {string}
    */
export enum ProfilFourriereViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'PHONE_WRONG_FORMAT' = 'PHONE_WRONG_FORMAT',
    'MAIL_WRONG_FORMAT' = 'MAIL_WRONG_FORMAT',
    'CODE_POSTAL_WRONG_FORMAT' = 'CODE_POSTAL_WRONG_FORMAT',
    'NOMBRE_PLACES_NOT_SUPERIOR_TO_ONE' = 'NOMBRE_PLACES_NOT_SUPERIOR_TO_ONE',
    'DATE_DEBUT_AGREMENT_AFTER_DATE_FIN_AGREMENT' = 'DATE_DEBUT_AGREMENT_AFTER_DATE_FIN_AGREMENT',
    'INCONSISTENT_HORAIRES_DEBUT_AFTER_FIN' = 'INCONSISTENT_HORAIRES_DEBUT_AFTER_FIN',
    'JOUR_RDV_AVEC_HORAIRES_OUVERTURE' = 'JOUR_RDV_AVEC_HORAIRES_OUVERTURE',
    'JOUR_OUVERT_SANS_HORAIRES_OUVERTURE' = 'JOUR_OUVERT_SANS_HORAIRES_OUVERTURE',
    'JOUR_OUVERT_SANS_HORAIRES_APPEL' = 'JOUR_OUVERT_SANS_HORAIRES_APPEL',
    'STRING_LENGTH_LIMIT' = 'STRING_LENGTH_LIMIT'
}

/**
 * 
 * @export
 * @interface ProfilFourriereViolationsDto
 */
export interface ProfilFourriereViolationsDto {
    /**
     * 
     * @type {Array<ProfilFourriereViolationDto>}
     * @memberof ProfilFourriereViolationsDto
     */
    violationDtos: Array<ProfilFourriereViolationDto> | null;
}
/**
 * 
 * @export
 * @interface ProfilUniteFoRequestDto
 */
export interface ProfilUniteFoRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ProfilUniteFoRequestDto
     */
    adresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilUniteFoRequestDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilUniteFoRequestDto
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilUniteFoRequestDto
     */
    telephone: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilUniteFoRequestDto
     */
    ville: string | null;
}
/**
 * 
 * @export
 * @interface ProfilUniteFoViolationDto
 */
export interface ProfilUniteFoViolationDto {
    /**
     * 
     * @type {string}
     * @memberof ProfilUniteFoViolationDto
     */
    formField: ProfilUniteFoViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof ProfilUniteFoViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilUniteFoViolationDto
     */
    violationCode: ProfilUniteFoViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProfilUniteFoViolationDtoFormFieldEnum {
    'ADRESSE' = 'ADRESSE',
    'CODE_POSTAL' = 'CODE_POSTAL',
    'VILLE' = 'VILLE',
    'EMAIL' = 'EMAIL',
    'TELEPHONE' = 'TELEPHONE'
}
/**
    * @export
    * @enum {string}
    */
export enum ProfilUniteFoViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'EMAIL_WRONG_FORMAT' = 'EMAIL_WRONG_FORMAT',
    'PHONE_WRONG_FORMAT' = 'PHONE_WRONG_FORMAT',
    'EMPTY_ABREVIATION' = 'EMPTY_ABREVIATION',
    'CODE_POSTAL_WRONG_FORMAT' = 'CODE_POSTAL_WRONG_FORMAT'
}

/**
 * 
 * @export
 * @interface ProfilUniteFoViolationsDto
 */
export interface ProfilUniteFoViolationsDto {
    /**
     * 
     * @type {Array<ProfilUniteFoViolationDto>}
     * @memberof ProfilUniteFoViolationsDto
     */
    violationDtos: Array<ProfilUniteFoViolationDto> | null;
}
/**
 * 
 * @export
 * @interface ProlongationRequestDto
 */
export interface ProlongationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ProlongationRequestDto
     */
    dateDecisionProcureur: string | null;
}
/**
 * 
 * @export
 * @interface ProlongationViolationDto
 */
export interface ProlongationViolationDto {
    /**
     * 
     * @type {string}
     * @memberof ProlongationViolationDto
     */
    formField: ProlongationViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof ProlongationViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ProlongationViolationDto
     */
    violationCode: ProlongationViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProlongationViolationDtoFormFieldEnum {
    'DATE_DECISION_PROCUREUR' = 'DATE_DECISION_PROCUREUR'
}
/**
    * @export
    * @enum {string}
    */
export enum ProlongationViolationDtoViolationCodeEnum {
    'MISSING_DATE_DECISION_PROCUREUR' = 'MISSING_DATE_DECISION_PROCUREUR',
    'DATE_DECISION_AFTER_NOW' = 'DATE_DECISION_AFTER_NOW',
    'DATE_DECISION_BEFORE_DATE_ENTREE' = 'DATE_DECISION_BEFORE_DATE_ENTREE'
}

/**
 * 
 * @export
 * @interface Proprietaire
 */
export interface Proprietaire {
    /**
     * 
     * @type {string}
     * @memberof Proprietaire
     */
    adresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof Proprietaire
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof Proprietaire
     */
    commune: string | null;
    /**
     * 
     * @type {string}
     * @memberof Proprietaire
     */
    nom: string | null;
    /**
     * 
     * @type {string}
     * @memberof Proprietaire
     */
    prenom: string | null;
    /**
     * 
     * @type {string}
     * @memberof Proprietaire
     */
    raisonSociale: string | null;
}
/**
 * 
 * @export
 * @interface ProprietaireDto
 */
export interface ProprietaireDto {
    /**
     * 
     * @type {string}
     * @memberof ProprietaireDto
     */
    nom: string | null;
}
/**
 * 
 * @export
 * @interface ReferenceDtoAutoriteFourriereDto
 */
export interface ReferenceDtoAutoriteFourriereDto {
    /**
     * 
     * @type {Array<AutoriteFourriereDto>}
     * @memberof ReferenceDtoAutoriteFourriereDto
     */
    referenceDtoList: Array<AutoriteFourriereDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoBrancheDto
 */
export interface ReferenceDtoBrancheDto {
    /**
     * 
     * @type {Array<BrancheDto>}
     * @memberof ReferenceDtoBrancheDto
     */
    referenceDtoList: Array<BrancheDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoCarrosserieClassementManuelDto
 */
export interface ReferenceDtoCarrosserieClassementManuelDto {
    /**
     * 
     * @type {Array<CarrosserieClassementManuelDto>}
     * @memberof ReferenceDtoCarrosserieClassementManuelDto
     */
    referenceDtoList: Array<CarrosserieClassementManuelDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoCarrosserieDto
 */
export interface ReferenceDtoCarrosserieDto {
    /**
     * 
     * @type {Array<CarrosserieDto>}
     * @memberof ReferenceDtoCarrosserieDto
     */
    referenceDtoList: Array<CarrosserieDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoCentreVHUDto
 */
export interface ReferenceDtoCentreVHUDto {
    /**
     * 
     * @type {Array<CentreVHUDto>}
     * @memberof ReferenceDtoCentreVHUDto
     */
    referenceDtoList: Array<CentreVHUDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoCouleurDto
 */
export interface ReferenceDtoCouleurDto {
    /**
     * 
     * @type {Array<CouleurDto>}
     * @memberof ReferenceDtoCouleurDto
     */
    referenceDtoList: Array<CouleurDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoEnergieDto
 */
export interface ReferenceDtoEnergieDto {
    /**
     * 
     * @type {Array<EnergieDto>}
     * @memberof ReferenceDtoEnergieDto
     */
    referenceDtoList: Array<EnergieDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoEtatDto
 */
export interface ReferenceDtoEtatDto {
    /**
     * 
     * @type {Array<EtatDto>}
     * @memberof ReferenceDtoEtatDto
     */
    referenceDtoList: Array<EtatDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoFaqAdminDto
 */
export interface ReferenceDtoFaqAdminDto {
    /**
     * 
     * @type {Array<FaqAdminDto>}
     * @memberof ReferenceDtoFaqAdminDto
     */
    referenceDtoList: Array<FaqAdminDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoFaqDomainAdminDto
 */
export interface ReferenceDtoFaqDomainAdminDto {
    /**
     * 
     * @type {Array<FaqDomainAdminDto>}
     * @memberof ReferenceDtoFaqDomainAdminDto
     */
    referenceDtoList: Array<FaqDomainAdminDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoFaqDomainDto
 */
export interface ReferenceDtoFaqDomainDto {
    /**
     * 
     * @type {Array<FaqDomainDto>}
     * @memberof ReferenceDtoFaqDomainDto
     */
    referenceDtoList: Array<FaqDomainDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoFaqDto
 */
export interface ReferenceDtoFaqDto {
    /**
     * 
     * @type {Array<FaqDto>}
     * @memberof ReferenceDtoFaqDto
     */
    referenceDtoList: Array<FaqDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoFooterLinkDto
 */
export interface ReferenceDtoFooterLinkDto {
    /**
     * 
     * @type {Array<FooterLinkDto>}
     * @memberof ReferenceDtoFooterLinkDto
     */
    referenceDtoList: Array<FooterLinkDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoFourriereDto
 */
export interface ReferenceDtoFourriereDto {
    /**
     * 
     * @type {Array<FourriereDto>}
     * @memberof ReferenceDtoFourriereDto
     */
    referenceDtoList: Array<FourriereDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoGenreDto
 */
export interface ReferenceDtoGenreDto {
    /**
     * 
     * @type {Array<GenreDto>}
     * @memberof ReferenceDtoGenreDto
     */
    referenceDtoList: Array<GenreDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoGenreSimplifieDto
 */
export interface ReferenceDtoGenreSimplifieDto {
    /**
     * 
     * @type {Array<GenreSimplifieDto>}
     * @memberof ReferenceDtoGenreSimplifieDto
     */
    referenceDtoList: Array<GenreSimplifieDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoInformationMessageDto
 */
export interface ReferenceDtoInformationMessageDto {
    /**
     * 
     * @type {Array<InformationMessageDto>}
     * @memberof ReferenceDtoInformationMessageDto
     */
    referenceDtoList: Array<InformationMessageDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoMarqueDto
 */
export interface ReferenceDtoMarqueDto {
    /**
     * 
     * @type {Array<MarqueDto>}
     * @memberof ReferenceDtoMarqueDto
     */
    referenceDtoList: Array<MarqueDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoModeleDto
 */
export interface ReferenceDtoModeleDto {
    /**
     * 
     * @type {Array<ModeleDto>}
     * @memberof ReferenceDtoModeleDto
     */
    referenceDtoList: Array<ModeleDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoMotifMefDto
 */
export interface ReferenceDtoMotifMefDto {
    /**
     * 
     * @type {Array<MotifMefDto>}
     * @memberof ReferenceDtoMotifMefDto
     */
    referenceDtoList: Array<MotifMefDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoMotifSortieDto
 */
export interface ReferenceDtoMotifSortieDto {
    /**
     * 
     * @type {Array<MotifSortieDto>}
     * @memberof ReferenceDtoMotifSortieDto
     */
    referenceDtoList: Array<MotifSortieDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoStatutDto
 */
export interface ReferenceDtoStatutDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReferenceDtoStatutDto
     */
    statuts: Array<ReferenceDtoStatutDtoStatutsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum ReferenceDtoStatutDtoStatutsEnum {
    'A_REMPLIR' = 'A_REMPLIR',
    'ENREGISTRE' = 'ENREGISTRE',
    'NON_GERE' = 'NON_GERE',
    'NOTIFICATION_ENVOYEE' = 'NOTIFICATION_ENVOYEE',
    'NOTIFIE' = 'NOTIFIE',
    'A_NOTIFIER' = 'A_NOTIFIER',
    'EN_COURS_DE_NOTIFICATION' = 'EN_COURS_DE_NOTIFICATION',
    'A_REMETTRE_AUX_DOMAINES' = 'A_REMETTRE_AUX_DOMAINES',
    'REMIS_AU_DOMAINE' = 'REMIS_AU_DOMAINE',
    'MIS_EN_VENTE' = 'MIS_EN_VENTE',
    'VENDU' = 'VENDU',
    'A_RESTITUER' = 'A_RESTITUER',
    'VENTE_BLOQUEE' = 'VENTE_BLOQUEE',
    'A_DETRUIRE' = 'A_DETRUIRE',
    'SORTI' = 'SORTI',
    'DETRUIT' = 'DETRUIT'
}

/**
 * 
 * @export
 * @interface ReferenceDtoTypeAutoriteFourriereDto
 */
export interface ReferenceDtoTypeAutoriteFourriereDto {
    /**
     * 
     * @type {Array<TypeAutoriteFourriereDto>}
     * @memberof ReferenceDtoTypeAutoriteFourriereDto
     */
    referenceDtoList: Array<TypeAutoriteFourriereDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoUniteFOAdminResponseDto
 */
export interface ReferenceDtoUniteFOAdminResponseDto {
    /**
     * 
     * @type {Array<UniteFOAdminResponseDto>}
     * @memberof ReferenceDtoUniteFOAdminResponseDto
     */
    referenceDtoList: Array<UniteFOAdminResponseDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoUniteFOResponseDto
 */
export interface ReferenceDtoUniteFOResponseDto {
    /**
     * 
     * @type {Array<UniteFOResponseDto>}
     * @memberof ReferenceDtoUniteFOResponseDto
     */
    referenceDtoList: Array<UniteFOResponseDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDtoVideoTutorielDto
 */
export interface ReferenceDtoVideoTutorielDto {
    /**
     * 
     * @type {Array<VideoTutorielDto>}
     * @memberof ReferenceDtoVideoTutorielDto
     */
    referenceDtoList: Array<VideoTutorielDto>;
}
/**
 * 
 * @export
 * @interface ReferentielCommentairesRequestDto
 */
export interface ReferentielCommentairesRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ReferentielCommentairesRequestDto
     */
    commentaires: string | null;
}
/**
 * 
 * @export
 * @interface ReferentielEnumDto
 */
export interface ReferentielEnumDto {
    /**
     * 
     * @type {string}
     * @memberof ReferentielEnumDto
     */
    importReferentielEnumCSV: ReferentielEnumDtoImportReferentielEnumCSVEnum;
    /**
     * 
     * @type {string}
     * @memberof ReferentielEnumDto
     */
    referentialEnumCSV: ReferentielEnumDtoReferentialEnumCSVEnum;
    /**
     * 
     * @type {string}
     * @memberof ReferentielEnumDto
     */
    referentialEnumJSON: ReferentielEnumDtoReferentialEnumJSONEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReferentielEnumDtoImportReferentielEnumCSVEnum {
    'BRANCHE' = 'BRANCHE',
    'ETAT' = 'ETAT',
    'FOOTER_LINK' = 'FOOTER_LINK',
    'GENRE_SIMPLIFIE' = 'GENRE_SIMPLIFIE',
    'VIDEO_TUTORIEL' = 'VIDEO_TUTORIEL'
}
/**
    * @export
    * @enum {string}
    */
export enum ReferentielEnumDtoReferentialEnumCSVEnum {
    'AUTORITE_FOURRIERE' = 'AUTORITE_FOURRIERE',
    'BRANCHE' = 'BRANCHE',
    'CENTRE_VHU' = 'CENTRE_VHU',
    'ETAT' = 'ETAT',
    'FOURRIERE' = 'FOURRIERE',
    'FOOTER_LINK' = 'FOOTER_LINK',
    'GENRE_SIMPLIFIE' = 'GENRE_SIMPLIFIE',
    'MOTIF_MEF' = 'MOTIF_MEF',
    'MOTIF_SORTIE' = 'MOTIF_SORTIE',
    'TYPE_AF' = 'TYPE_AF',
    'UNITE_FO' = 'UNITE_FO',
    'FAQ' = 'FAQ',
    'VIDEO_TUTORIEL' = 'VIDEO_TUTORIEL'
}
/**
    * @export
    * @enum {string}
    */
export enum ReferentielEnumDtoReferentialEnumJSONEnum {
    'CATEGORIE' = 'CATEGORIE'
}

/**
 * 
 * @export
 * @interface ReferentielSearchResultDtoAutoriteFourriereDto
 */
export interface ReferentielSearchResultDtoAutoriteFourriereDto {
    /**
     * 
     * @type {ReferenceDtoAutoriteFourriereDto}
     * @memberof ReferentielSearchResultDtoAutoriteFourriereDto
     */
    results: ReferenceDtoAutoriteFourriereDto;
    /**
     * 
     * @type {number}
     * @memberof ReferentielSearchResultDtoAutoriteFourriereDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ReferentielSearchResultDtoCentreVHUDto
 */
export interface ReferentielSearchResultDtoCentreVHUDto {
    /**
     * 
     * @type {ReferenceDtoCentreVHUDto}
     * @memberof ReferentielSearchResultDtoCentreVHUDto
     */
    results: ReferenceDtoCentreVHUDto;
    /**
     * 
     * @type {number}
     * @memberof ReferentielSearchResultDtoCentreVHUDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ReferentielSearchResultDtoFaqAdminDto
 */
export interface ReferentielSearchResultDtoFaqAdminDto {
    /**
     * 
     * @type {ReferenceDtoFaqAdminDto}
     * @memberof ReferentielSearchResultDtoFaqAdminDto
     */
    results: ReferenceDtoFaqAdminDto;
    /**
     * 
     * @type {number}
     * @memberof ReferentielSearchResultDtoFaqAdminDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ReferentielSearchResultDtoFaqDomainAdminDto
 */
export interface ReferentielSearchResultDtoFaqDomainAdminDto {
    /**
     * 
     * @type {ReferenceDtoFaqDomainAdminDto}
     * @memberof ReferentielSearchResultDtoFaqDomainAdminDto
     */
    results: ReferenceDtoFaqDomainAdminDto;
    /**
     * 
     * @type {number}
     * @memberof ReferentielSearchResultDtoFaqDomainAdminDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ReferentielSearchResultDtoFourriereDto
 */
export interface ReferentielSearchResultDtoFourriereDto {
    /**
     * 
     * @type {ReferenceDtoFourriereDto}
     * @memberof ReferentielSearchResultDtoFourriereDto
     */
    results: ReferenceDtoFourriereDto;
    /**
     * 
     * @type {number}
     * @memberof ReferentielSearchResultDtoFourriereDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ReferentielSearchResultDtoMarqueDto
 */
export interface ReferentielSearchResultDtoMarqueDto {
    /**
     * 
     * @type {ReferenceDtoMarqueDto}
     * @memberof ReferentielSearchResultDtoMarqueDto
     */
    results: ReferenceDtoMarqueDto;
    /**
     * 
     * @type {number}
     * @memberof ReferentielSearchResultDtoMarqueDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ReferentielSearchResultDtoModeleDto
 */
export interface ReferentielSearchResultDtoModeleDto {
    /**
     * 
     * @type {ReferenceDtoModeleDto}
     * @memberof ReferentielSearchResultDtoModeleDto
     */
    results: ReferenceDtoModeleDto;
    /**
     * 
     * @type {number}
     * @memberof ReferentielSearchResultDtoModeleDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ReferentielSearchResultDtoMotifMefDto
 */
export interface ReferentielSearchResultDtoMotifMefDto {
    /**
     * 
     * @type {ReferenceDtoMotifMefDto}
     * @memberof ReferentielSearchResultDtoMotifMefDto
     */
    results: ReferenceDtoMotifMefDto;
    /**
     * 
     * @type {number}
     * @memberof ReferentielSearchResultDtoMotifMefDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ReferentielSearchResultDtoMotifSortieDto
 */
export interface ReferentielSearchResultDtoMotifSortieDto {
    /**
     * 
     * @type {ReferenceDtoMotifSortieDto}
     * @memberof ReferentielSearchResultDtoMotifSortieDto
     */
    results: ReferenceDtoMotifSortieDto;
    /**
     * 
     * @type {number}
     * @memberof ReferentielSearchResultDtoMotifSortieDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ReferentielSearchResultDtoTypeAutoriteFourriereDto
 */
export interface ReferentielSearchResultDtoTypeAutoriteFourriereDto {
    /**
     * 
     * @type {ReferenceDtoTypeAutoriteFourriereDto}
     * @memberof ReferentielSearchResultDtoTypeAutoriteFourriereDto
     */
    results: ReferenceDtoTypeAutoriteFourriereDto;
    /**
     * 
     * @type {number}
     * @memberof ReferentielSearchResultDtoTypeAutoriteFourriereDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ReferentielSearchResultDtoUniteFOAdminResponseDto
 */
export interface ReferentielSearchResultDtoUniteFOAdminResponseDto {
    /**
     * 
     * @type {ReferenceDtoUniteFOAdminResponseDto}
     * @memberof ReferentielSearchResultDtoUniteFOAdminResponseDto
     */
    results: ReferenceDtoUniteFOAdminResponseDto;
    /**
     * 
     * @type {number}
     * @memberof ReferentielSearchResultDtoUniteFOAdminResponseDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ReferentielUsesDto
 */
export interface ReferentielUsesDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReferentielUsesDto
     */
    listChildrenUniteIdCorrelation: Array<string> | null;
    /**
     * 
     * @type {SearchDossierIdResultDto}
     * @memberof ReferentielUsesDto
     */
    searchDossierResult: SearchDossierIdResultDto | null;
}
/**
 * 
 * @export
 * @interface ReferentielViolationsDto
 */
export interface ReferentielViolationsDto {
    /**
     * 
     * @type {Array<AutoriteFourriereViolationDto>}
     * @memberof ReferentielViolationsDto
     */
    autoriteFourriereViolationsDto: Array<AutoriteFourriereViolationDto> | null;
    /**
     * 
     * @type {Array<CentreVHUViolationDto>}
     * @memberof ReferentielViolationsDto
     */
    centreVHUViolationsDto: Array<CentreVHUViolationDto> | null;
    /**
     * 
     * @type {Array<FaqDomainViolationDto>}
     * @memberof ReferentielViolationsDto
     */
    faqDomainViolationsDto: Array<FaqDomainViolationDto> | null;
    /**
     * 
     * @type {Array<FaqViolationDto>}
     * @memberof ReferentielViolationsDto
     */
    faqViolationsDto: Array<FaqViolationDto> | null;
    /**
     * 
     * @type {Array<FourriereViolationDto>}
     * @memberof ReferentielViolationsDto
     */
    fourriereViolationsDto: Array<FourriereViolationDto> | null;
    /**
     * 
     * @type {Array<MarqueViolationDto>}
     * @memberof ReferentielViolationsDto
     */
    marqueViolationsDto: Array<MarqueViolationDto> | null;
    /**
     * 
     * @type {Array<MotifMefViolationDto>}
     * @memberof ReferentielViolationsDto
     */
    motifMefViolationsDto: Array<MotifMefViolationDto> | null;
    /**
     * 
     * @type {Array<MotifSortieViolationDto>}
     * @memberof ReferentielViolationsDto
     */
    motifSortieViolationsDto: Array<MotifSortieViolationDto> | null;
    /**
     * 
     * @type {Array<TypeAutoriteFourriereViolationDto>}
     * @memberof ReferentielViolationsDto
     */
    typeAutoriteFourriereViolationDto: Array<TypeAutoriteFourriereViolationDto> | null;
    /**
     * 
     * @type {Array<UniteFoViolationDto>}
     * @memberof ReferentielViolationsDto
     */
    uniteFoViolationsDto: Array<UniteFoViolationDto> | null;
}
/**
 * 
 * @export
 * @interface RelanceVenteDto
 */
export interface RelanceVenteDto {
    /**
     * 
     * @type {string}
     * @memberof RelanceVenteDto
     */
    dossierId: string;
    /**
     * 
     * @type {string}
     * @memberof RelanceVenteDto
     */
    relanceVenteType: RelanceVenteDtoRelanceVenteTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RelanceVenteDto
     */
    statut: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum RelanceVenteDtoRelanceVenteTypeEnum {
    'MANQUANT' = 'MANQUANT',
    'VOL' = 'VOL',
    'IMMO_PJ' = 'IMMO_PJ',
    'OPPOSITION' = 'OPPOSITION',
    'DOUBLON' = 'DOUBLON',
    'ERREUR_IDENTIFICATION_SANS_MODIFICATION' = 'ERREUR_IDENTIFICATION_SANS_MODIFICATION'
}

/**
 * 
 * @export
 * @interface RequestHeaders
 */
export interface RequestHeaders {
    /**
     * 
     * @type {string}
     * @memberof RequestHeaders
     */
    source: string | null;
}
/**
 * 
 * @export
 * @interface RequiredUserActionsDto
 */
export interface RequiredUserActionsDto {
    /**
     * 
     * @type {string}
     * @memberof RequiredUserActionsDto
     */
    idAutoriteFourriereToConfirm: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequiredUserActionsDto
     */
    idFourriereToConfirm: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredUserActionsDto
     */
    mustConfirmAccount: boolean;
}
/**
 * 
 * @export
 * @interface SearchCommuneEnlevementResultDto
 */
export interface SearchCommuneEnlevementResultDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchCommuneEnlevementResultDto
     */
    communeList: Array<string>;
}
/**
 * 
 * @export
 * @interface SearchDossierIdResultDto
 */
export interface SearchDossierIdResultDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchDossierIdResultDto
     */
    listDossierId: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SearchDossierIdResultDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface SearchDossiersResponseDto
 */
export interface SearchDossiersResponseDto {
    /**
     * 
     * @type {Array<DossierSummaryResponseDto>}
     * @memberof SearchDossiersResponseDto
     */
    dossiers: Array<DossierSummaryResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof SearchDossiersResponseDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface SearchHermesFourriereResponseDto
 */
export interface SearchHermesFourriereResponseDto {
    /**
     * 
     * @type {Array<FourriereFluxDto>}
     * @memberof SearchHermesFourriereResponseDto
     */
    flux: Array<FourriereFluxDto>;
    /**
     * 
     * @type {number}
     * @memberof SearchHermesFourriereResponseDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface SearchHermesFourriereWarningResponseDto
 */
export interface SearchHermesFourriereWarningResponseDto {
    /**
     * 
     * @type {number}
     * @memberof SearchHermesFourriereWarningResponseDto
     */
    total: number;
    /**
     * 
     * @type {Array<FourriereWarningDto>}
     * @memberof SearchHermesFourriereWarningResponseDto
     */
    warnings: Array<FourriereWarningDto>;
}
/**
 * 
 * @export
 * @interface SearchHermesRecuperationEtatBiensResponseDto
 */
export interface SearchHermesRecuperationEtatBiensResponseDto {
    /**
     * 
     * @type {Array<FluxDto>}
     * @memberof SearchHermesRecuperationEtatBiensResponseDto
     */
    flux: Array<FluxDto>;
    /**
     * 
     * @type {number}
     * @memberof SearchHermesRecuperationEtatBiensResponseDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface SearchHermesRecuperationEtatBiensWarningResponseDto
 */
export interface SearchHermesRecuperationEtatBiensWarningResponseDto {
    /**
     * 
     * @type {number}
     * @memberof SearchHermesRecuperationEtatBiensWarningResponseDto
     */
    total: number;
    /**
     * 
     * @type {Array<WarningDto>}
     * @memberof SearchHermesRecuperationEtatBiensWarningResponseDto
     */
    warnings: Array<WarningDto>;
}
/**
 * 
 * @export
 * @interface SearchInformationMessageResultDto
 */
export interface SearchInformationMessageResultDto {
    /**
     * 
     * @type {Array<DetailedInformationMessageDto>}
     * @memberof SearchInformationMessageResultDto
     */
    messages: Array<DetailedInformationMessageDto>;
    /**
     * 
     * @type {number}
     * @memberof SearchInformationMessageResultDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface SearchUserResultDto
 */
export interface SearchUserResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof SearchUserResultDto
     */
    hasNextPage: boolean | null;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof SearchUserResultDto
     */
    users: Array<UserDto> | null;
}
/**
 * 
 * @export
 * @interface SortieSummaryDto
 */
export interface SortieSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof SortieSummaryDto
     */
    dateSortie: string | null;
    /**
     * 
     * @type {string}
     * @memberof SortieSummaryDto
     */
    motifSortie: string | null;
    /**
     * 
     * @type {string}
     * @memberof SortieSummaryDto
     */
    statutFacturation: SortieSummaryDtoStatutFacturationEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum SortieSummaryDtoStatutFacturationEnum {
    'TRAITE' = 'TRAITE',
    'NON_TRAITE' = 'NON_TRAITE',
    'NON_CONCERNE' = 'NON_CONCERNE'
}

/**
 * 
 * @export
 * @interface SortieVehiculeRequestDto
 */
export interface SortieVehiculeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof SortieVehiculeRequestDto
     */
    dateSortie: string | null;
    /**
     *
     * @type {string}
     * @memberof SortieVehiculeRequestDto
     */
    motifId: string | null;
    /**
     * 
     * @type {string}
     * @memberof SortieVehiculeRequestDto
     */
    motifSortieIdCorrelation: string | null;
}
/**
 * 
 * @export
 * @interface SortieVehiculeResponseDto
 */
export interface SortieVehiculeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof SortieVehiculeResponseDto
     */
    dateSaisieSortie: string | null;
    /**
     * 
     * @type {string}
     * @memberof SortieVehiculeResponseDto
     */
    dateSortie: string | null;
    /**
     * 
     * @type {MotifSortieDto}
     * @memberof SortieVehiculeResponseDto
     */
    motifSortie: MotifSortieDto | null;
    /**
     * 
     * @type {string}
     * @memberof SortieVehiculeResponseDto
     */
    statutFacturation: SortieVehiculeResponseDtoStatutFacturationEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum SortieVehiculeResponseDtoStatutFacturationEnum {
    'TRAITE' = 'TRAITE',
    'NON_TRAITE' = 'NON_TRAITE',
    'NON_CONCERNE' = 'NON_CONCERNE'
}

/**
 * 
 * @export
 * @interface SortieVehiculeViolationDto
 */
export interface SortieVehiculeViolationDto {
    /**
     * 
     * @type {string}
     * @memberof SortieVehiculeViolationDto
     */
    formField: SortieVehiculeViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof SortieVehiculeViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof SortieVehiculeViolationDto
     */
    violationCode: SortieVehiculeViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SortieVehiculeViolationDtoFormFieldEnum {
    'DATE_SORTIE' = 'DATE_SORTIE',
    'MOTIF_SORTIE' = 'MOTIF_SORTIE',
    'COMMENT' = 'COMMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum SortieVehiculeViolationDtoViolationCodeEnum {
    'MISSING_DATE_SORTIE' = 'MISSING_DATE_SORTIE',
    'MISSING_COMMENT' = 'MISSING_COMMENT',
    'DATE_SORTIE_BEFORE_DATE_ENTREE' = 'DATE_SORTIE_BEFORE_DATE_ENTREE',
    'DATE_SORTIE_IN_FUTURE' = 'DATE_SORTIE_IN_FUTURE',
    'NO_MODIFICATION' = 'NO_MODIFICATION',
    'INVALID_MOTIF_SORTIE' = 'INVALID_MOTIF_SORTIE',
    'MISSING_MOTIF_SORTIE' = 'MISSING_MOTIF_SORTIE'
}

/**
 * 
 * @export
 * @interface SourceImpossibiliteNotifierDto
 */
export interface SourceImpossibiliteNotifierDto {
    /**
     * 
     * @type {string}
     * @memberof SourceImpossibiliteNotifierDto
     */
    code: SourceImpossibiliteNotifierDtoCodeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof SourceImpossibiliteNotifierDto
     */
    message: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum SourceImpossibiliteNotifierDtoCodeEnum {
    'NOT_FRANCE' = 'NOT_FRANCE',
    'MISSING_RAISON_SOCIALE_NOM' = 'MISSING_RAISON_SOCIALE_NOM',
    'MISSING_COMMUNE' = 'MISSING_COMMUNE',
    'MISSING_CODE_POSTAL' = 'MISSING_CODE_POSTAL'
}

/**
 * 
 * @export
 * @interface SourcesImpossibiliteNotifierDto
 */
export interface SourcesImpossibiliteNotifierDto {
    /**
     * 
     * @type {Array<SourceImpossibiliteNotifierDto>}
     * @memberof SourcesImpossibiliteNotifierDto
     */
    sources: Array<SourceImpossibiliteNotifierDto>;
}
/**
 * 
 * @export
 * @interface SummaryEventDto
 */
export interface SummaryEventDto {
    /**
     * 
     * @type {string}
     * @memberof SummaryEventDto
     */
    date: string | null;
    /**
     * 
     * @type {string}
     * @memberof SummaryEventDto
     */
    details: string | null;
    /**
     * 
     * @type {string}
     * @memberof SummaryEventDto
     */
    label: string;
}
/**
 * 
 * @export
 * @interface TraitementResponseDto
 */
export interface TraitementResponseDto {
    /**
     * 
     * @type {AbandonDto}
     * @memberof TraitementResponseDto
     */
    abandon: AbandonDto | null;
    /**
     * 
     * @type {BonEnlevementGardienDto}
     * @memberof TraitementResponseDto
     */
    bonEnlevementGardienData: BonEnlevementGardienDto | null;
    /**
     * 
     * @type {Array<BrancheDto>}
     * @memberof TraitementResponseDto
     */
    branches: Array<BrancheDto> | null;
    /**
     * 
     * @type {string}
     * @memberof TraitementResponseDto
     */
    classement: TraitementResponseDtoClassementEnum | null;
    /**
     * 
     * @type {string}
     * @memberof TraitementResponseDto
     */
    dateMiseAJour: string | null;
    /**
     * 
     * @type {string}
     * @memberof TraitementResponseDto
     */
    dateOrdreDestruction: string | null;
    /**
     * 
     * @type {string}
     * @memberof TraitementResponseDto
     */
    dateProlongationDecisionProcureur: string | null;
    /**
     * 
     * @type {ExpediteurNotificationDto}
     * @memberof TraitementResponseDto
     */
    expediteurNotification: ExpediteurNotificationDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof TraitementResponseDto
     */
    hasPassedByClassementManuel: boolean | null;
    /**
     * 
     * @type {ImpossibiliteNotifierDto}
     * @memberof TraitementResponseDto
     */
    impossibiliteNotifier: ImpossibiliteNotifierDto | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TraitementResponseDto
     */
    listStatut: Array<TraitementResponseDtoListStatutEnum> | null;
    /**
     * 
     * @type {MainLeveeDto}
     * @memberof TraitementResponseDto
     */
    mainLevee: MainLeveeDto | null;
    /**
     * 
     * @type {NotificationAutomatiqueInfosDto}
     * @memberof TraitementResponseDto
     */
    notificationAutomatique: NotificationAutomatiqueInfosDto | null;
    /**
     * 
     * @type {NotificationManuelleInfosDto}
     * @memberof TraitementResponseDto
     */
    notificationManuelle: NotificationManuelleInfosDto | null;
    /**
     * 
     * @type {string}
     * @memberof TraitementResponseDto
     */
    typeNotification: TraitementResponseDtoTypeNotificationEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum TraitementResponseDtoClassementEnum {
    'ALIENATION' = 'ALIENATION',
    'DESTRUCTION' = 'DESTRUCTION',
    'MANUEL' = 'MANUEL'
}
/**
    * @export
    * @enum {string}
    */
export enum TraitementResponseDtoListStatutEnum {
    'A_REMPLIR' = 'A_REMPLIR',
    'ENREGISTRE' = 'ENREGISTRE',
    'NON_GERE' = 'NON_GERE',
    'NOTIFICATION_ENVOYEE' = 'NOTIFICATION_ENVOYEE',
    'NOTIFIE' = 'NOTIFIE',
    'A_NOTIFIER' = 'A_NOTIFIER',
    'EN_COURS_DE_NOTIFICATION' = 'EN_COURS_DE_NOTIFICATION',
    'A_REMETTRE_AUX_DOMAINES' = 'A_REMETTRE_AUX_DOMAINES',
    'REMIS_AU_DOMAINE' = 'REMIS_AU_DOMAINE',
    'MIS_EN_VENTE' = 'MIS_EN_VENTE',
    'VENDU' = 'VENDU',
    'A_RESTITUER' = 'A_RESTITUER',
    'VENTE_BLOQUEE' = 'VENTE_BLOQUEE',
    'A_DETRUIRE' = 'A_DETRUIRE',
    'SORTI' = 'SORTI',
    'DETRUIT' = 'DETRUIT'
}
/**
    * @export
    * @enum {string}
    */
export enum TraitementResponseDtoTypeNotificationEnum {
    'IMPOSSIBILITE_NOTIFIER' = 'IMPOSSIBILITE_NOTIFIER',
    'NOTIFICATION_AUTOMATIQUE' = 'NOTIFICATION_AUTOMATIQUE',
    'NOTIFICATION_MANUELLE' = 'NOTIFICATION_MANUELLE'
}

/**
 * 
 * @export
 * @interface TraitementSummaryDto
 */
export interface TraitementSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof TraitementSummaryDto
     */
    classement: TraitementSummaryDtoClassementEnum | null;
    /**
     * 
     * @type {string}
     * @memberof TraitementSummaryDto
     */
    dateBonEnlevement: string | null;
    /**
     * 
     * @type {string}
     * @memberof TraitementSummaryDto
     */
    dateMiseAJour: string | null;
    /**
     * 
     * @type {string}
     * @memberof TraitementSummaryDto
     */
    dateOrdreDestruction: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum TraitementSummaryDtoClassementEnum {
    'ALIENATION' = 'ALIENATION',
    'DESTRUCTION' = 'DESTRUCTION',
    'MANUEL' = 'MANUEL'
}

/**
 * 
 * @export
 * @interface TypeAutoriteFourriereDto
 */
export interface TypeAutoriteFourriereDto {
    /**
     * 
     * @type {string}
     * @memberof TypeAutoriteFourriereDto
     */
    displayedText: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeAutoriteFourriereDto
     */
    hermesCodeTypologieNiveau1: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeAutoriteFourriereDto
     */
    hermesCodeTypologieNiveau2: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeAutoriteFourriereDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TypeAutoriteFourriereDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof TypeAutoriteFourriereDto
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof TypeAutoriteFourriereDto
     */
    valid: boolean | null;
}
/**
 * 
 * @export
 * @interface TypeAutoriteFourriereRequestDto
 */
export interface TypeAutoriteFourriereRequestDto {
    /**
     * 
     * @type {string}
     * @memberof TypeAutoriteFourriereRequestDto
     */
    codeTypologieNiveau1: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeAutoriteFourriereRequestDto
     */
    codeTypologieNiveau2: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeAutoriteFourriereRequestDto
     */
    displayedText: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeAutoriteFourriereRequestDto
     */
    type: string | null;
}
/**
 * 
 * @export
 * @interface TypeAutoriteFourriereViolationDto
 */
export interface TypeAutoriteFourriereViolationDto {
    /**
     * 
     * @type {string}
     * @memberof TypeAutoriteFourriereViolationDto
     */
    formField: TypeAutoriteFourriereViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof TypeAutoriteFourriereViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof TypeAutoriteFourriereViolationDto
     */
    violationCode: TypeAutoriteFourriereViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TypeAutoriteFourriereViolationDtoFormFieldEnum {
    'TYPE' = 'TYPE',
    'LIBELLE_NOTIFICATION' = 'LIBELLE_NOTIFICATION',
    'CODE_TYPOLOGIE_NIVEAU_1' = 'CODE_TYPOLOGIE_NIVEAU_1',
    'CODE_TYPOLOGIE_NIVEAU_2' = 'CODE_TYPOLOGIE_NIVEAU_2'
}
/**
    * @export
    * @enum {string}
    */
export enum TypeAutoriteFourriereViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'TYPE_UNICITY_VIOLATION' = 'TYPE_UNICITY_VIOLATION'
}

/**
 * 
 * @export
 * @interface UniteFOAdminResponseDto
 */
export interface UniteFOAdminResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UniteFOAdminResponseDto
     */
    abreviation: string;
    /**
     * 
     * @type {string}
     * @memberof UniteFOAdminResponseDto
     */
    adresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFOAdminResponseDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof UniteFOAdminResponseDto
     */
    codeInsee: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFOAdminResponseDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFOAdminResponseDto
     */
    ctRattachement: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFOAdminResponseDto
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFOAdminResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UniteFOAdminResponseDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof UniteFOAdminResponseDto
     */
    idCorrelationParent: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UniteFOAdminResponseDto
     */
    notificationManuelle: boolean;
    /**
     * 
     * @type {string}
     * @memberof UniteFOAdminResponseDto
     */
    rpsiStatus: UniteFOAdminResponseDtoRpsiStatusEnum | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFOAdminResponseDto
     */
    telephone: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFOAdminResponseDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof UniteFOAdminResponseDto
     */
    unite: string;
    /**
     * 
     * @type {boolean}
     * @memberof UniteFOAdminResponseDto
     */
    valid: boolean;
    /**
     * 
     * @type {string}
     * @memberof UniteFOAdminResponseDto
     */
    ville: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum UniteFOAdminResponseDtoRpsiStatusEnum {
    'NOUVELLE_UNITE' = 'NOUVELLE_UNITE',
    'A_SUPPRIMER_DOSSIER_ATTACHE' = 'A_SUPPRIMER_DOSSIER_ATTACHE',
    'A_SUPPRIMER_UNITE_PARENT' = 'A_SUPPRIMER_UNITE_PARENT',
    'A_SUPPRIMER_UNITE_PARENT_ET_DOSSIER_ATTACHE' = 'A_SUPPRIMER_UNITE_PARENT_ET_DOSSIER_ATTACHE',
    'MISE_A_JOUR_ERREUR_VALIDATION' = 'MISE_A_JOUR_ERREUR_VALIDATION'
}

/**
 * 
 * @export
 * @interface UniteFOResponseDto
 */
export interface UniteFOResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UniteFOResponseDto
     */
    abreviation: string;
    /**
     * 
     * @type {string}
     * @memberof UniteFOResponseDto
     */
    adresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFOResponseDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof UniteFOResponseDto
     */
    codeInsee: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFOResponseDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFOResponseDto
     */
    ctRattachement: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFOResponseDto
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFOResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UniteFOResponseDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof UniteFOResponseDto
     */
    idCorrelationParent: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UniteFOResponseDto
     */
    notificationManuelle: boolean;
    /**
     * 
     * @type {string}
     * @memberof UniteFOResponseDto
     */
    telephone: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFOResponseDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof UniteFOResponseDto
     */
    unite: string;
    /**
     * 
     * @type {boolean}
     * @memberof UniteFOResponseDto
     */
    valid: boolean;
    /**
     * 
     * @type {string}
     * @memberof UniteFOResponseDto
     */
    ville: string | null;
}
/**
 * 
 * @export
 * @interface UniteFoRequestDto
 */
export interface UniteFoRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UniteFoRequestDto
     */
    abreviation: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFoRequestDto
     */
    adresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFoRequestDto
     */
    code: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFoRequestDto
     */
    codeInsee: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFoRequestDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFoRequestDto
     */
    ctRattachement: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFoRequestDto
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFoRequestDto
     */
    idCorrelationUniteFoParent: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFoRequestDto
     */
    telephone: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFoRequestDto
     */
    type: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFoRequestDto
     */
    unite: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteFoRequestDto
     */
    ville: string | null;
}
/**
 * 
 * @export
 * @interface UniteFoViolationDto
 */
export interface UniteFoViolationDto {
    /**
     * 
     * @type {string}
     * @memberof UniteFoViolationDto
     */
    formField: UniteFoViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof UniteFoViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof UniteFoViolationDto
     */
    violationCode: UniteFoViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UniteFoViolationDtoFormFieldEnum {
    'TYPE' = 'TYPE',
    'CODE' = 'CODE',
    'UNITE' = 'UNITE',
    'ABREVIATION' = 'ABREVIATION',
    'CODE_POSTAL' = 'CODE_POSTAL',
    'EMAIL' = 'EMAIL',
    'TELEPHONE' = 'TELEPHONE',
    'CODE_INSEE' = 'CODE_INSEE',
    'PARENT' = 'PARENT'
}
/**
    * @export
    * @enum {string}
    */
export enum UniteFoViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'MISSING_CODE_INSEE' = 'MISSING_CODE_INSEE',
    'INVALID_UNITE_FO' = 'INVALID_UNITE_FO',
    'UNITE_PARENT_IS_UNITE_FILLE' = 'UNITE_PARENT_IS_UNITE_FILLE',
    'UNITE_IS_UNITE_PARENT' = 'UNITE_IS_UNITE_PARENT',
    'UNITE_PARENT_CANT_BE_ITSELF' = 'UNITE_PARENT_CANT_BE_ITSELF',
    'TYPE_BAD_FORMAT' = 'TYPE_BAD_FORMAT',
    'PHONE_BAD_FORMAT' = 'PHONE_BAD_FORMAT',
    'EMAIL_BAD_FORMAT' = 'EMAIL_BAD_FORMAT',
    'CODE_POSTAL_BAD_FORMAT' = 'CODE_POSTAL_BAD_FORMAT',
    'CODE_ALREADY_USED' = 'CODE_ALREADY_USED',
    'LIBELLE_ALREADY_USED' = 'LIBELLE_ALREADY_USED',
    'ABREVIATION_ALREADY_USED' = 'ABREVIATION_ALREADY_USED'
}

/**
 * 
 * @export
 * @interface UpdateClassementRequestDto
 */
export interface UpdateClassementRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateClassementRequestDto
     */
    justification: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateClassementRequestDto
     */
    newClassement: UpdateClassementRequestDtoNewClassementEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateClassementRequestDtoNewClassementEnum {
    'ALIENATION' = 'ALIENATION',
    'DESTRUCTION' = 'DESTRUCTION',
    'MANUEL' = 'MANUEL'
}

/**
 * 
 * @export
 * @interface UpdateClassementViolationDto
 */
export interface UpdateClassementViolationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateClassementViolationDto
     */
    formField: UpdateClassementViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateClassementViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClassementViolationDto
     */
    violationCode: UpdateClassementViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateClassementViolationDtoFormFieldEnum {
    'JUSTIFICATION' = 'JUSTIFICATION',
    'NEW_CLASSEMENT' = 'NEW_CLASSEMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateClassementViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'MISSING_COMMENT' = 'MISSING_COMMENT'
}

/**
 * 
 * @export
 * @interface UpdateDonneesVehiculeRequestDto
 */
export interface UpdateDonneesVehiculeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDonneesVehiculeRequestDto
     */
    comment: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDonneesVehiculeRequestDto
     */
    newEtatIdCorrelation: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDonneesVehiculeRequestDto
     */
    newMotifEntreeIdCorrelation: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDonneesVehiculeRequestDto
     */
    newPaysEtranger: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDonneesVehiculeRequestDto
     */
    newPlaqueImmatriculation: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDonneesVehiculeRequestDto
     */
    newSansPlaque: boolean | null;
}
/**
 * 
 * @export
 * @interface UpdateDonneesVehiculeViolationDto
 */
export interface UpdateDonneesVehiculeViolationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDonneesVehiculeViolationDto
     */
    formField: UpdateDonneesVehiculeViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateDonneesVehiculeViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDonneesVehiculeViolationDto
     */
    violationCode: UpdateDonneesVehiculeViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateDonneesVehiculeViolationDtoFormFieldEnum {
    'NEW_MOTIF_ENTREE_ID_CORRELATION' = 'NEW_MOTIF_ENTREE_ID_CORRELATION',
    'NEW_IS_PAYS_ETRANGER' = 'NEW_IS_PAYS_ETRANGER',
    'NEW_IS_SANS_PLAQUE' = 'NEW_IS_SANS_PLAQUE',
    'NEW_PLAQUE_IMMATRICULATION' = 'NEW_PLAQUE_IMMATRICULATION',
    'NEW_ETAT_ID_CORRELATION' = 'NEW_ETAT_ID_CORRELATION',
    'COMMENT' = 'COMMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateDonneesVehiculeViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'NO_MODIFICATION' = 'NO_MODIFICATION',
    'MOTIF_MEF_NOT_FOUND' = 'MOTIF_MEF_NOT_FOUND',
    'ETAT_NOT_FOUND' = 'ETAT_NOT_FOUND'
}

/**
 * 
 * @export
 * @interface UpdateEntitesRattachementRequestDto
 */
export interface UpdateEntitesRattachementRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateEntitesRattachementRequestDto
     */
    comment: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEntitesRattachementRequestDto
     */
    newAutoriteFourriereIdCorrelation: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEntitesRattachementRequestDto
     */
    newFourriereIdCorrelation: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEntitesRattachementRequestDto
     */
    newUniteFoIdCorrelation: string | null;
}
/**
 * 
 * @export
 * @interface UpdateEntitesRattachementViolationDto
 */
export interface UpdateEntitesRattachementViolationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateEntitesRattachementViolationDto
     */
    formField: UpdateEntitesRattachementViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateEntitesRattachementViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEntitesRattachementViolationDto
     */
    violationCode: UpdateEntitesRattachementViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateEntitesRattachementViolationDtoFormFieldEnum {
    'NEW_FOURRIERE_CORRELATION_ID' = 'NEW_FOURRIERE_CORRELATION_ID',
    'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' = 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID',
    'NEW_UNITE_FO_CORRELATION_ID' = 'NEW_UNITE_FO_CORRELATION_ID',
    'COMMENT' = 'COMMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateEntitesRattachementViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'INVALID_FOURRIERE' = 'INVALID_FOURRIERE',
    'FOURRIERE_NOT_FOUND' = 'FOURRIERE_NOT_FOUND',
    'INVALID_AF' = 'INVALID_AF',
    'AF_NOT_FOUND' = 'AF_NOT_FOUND',
    'INVALID_UNITE_FO' = 'INVALID_UNITE_FO',
    'UNITE_FO_NOT_FOUND' = 'UNITE_FO_NOT_FOUND',
    'NO_MODIFICATION' = 'NO_MODIFICATION'
}

/**
 * 
 * @export
 * @interface UpdateMainLeveeRequestDto
 */
export interface UpdateMainLeveeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    codePostalProprietaire: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    communeAdresseProprietaire: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    communeFonctionAgent: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    communeRedaction: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    dateDebutPermissionProvisoire: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    dateFinPermissionProvisoire: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    dateProcesVerbal: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    gradeAgent: UpdateMainLeveeRequestDtoGradeAgentEnum | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    idCorrelationFourriere: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    idCorrelationGenreSimplifie: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    idMarque: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    immatriculation: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMainLeveeRequestDto
     */
    interdictionCirculer: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    justificationSortie: UpdateMainLeveeRequestDtoJustificationSortieEnum | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    lieuAdresseProprietaire: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    matriculeAgent: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    motifInterdictionCirculer: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    nomAgent: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    nomProprietaire: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    numProcesVerbal: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    prenomProprietaire: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    rsProprietaire: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMainLeveeRequestDto
     */
    typeRestitution: UpdateMainLeveeRequestDtoTypeRestitutionEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateMainLeveeRequestDtoGradeAgentEnum {
    'APJA' = 'APJA',
    'APJ' = 'APJ',
    'OPJ' = 'OPJ'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateMainLeveeRequestDtoJustificationSortieEnum {
    'VERIFICATIONS_NON_USAGE_STUPEFIANTS' = 'VERIFICATIONS_NON_USAGE_STUPEFIANTS',
    'VOL' = 'VOL',
    'LOCATION_TITRE_ONEREUX_A_UN_TIERS' = 'LOCATION_TITRE_ONEREUX_A_UN_TIERS',
    'DECISION_APRES_CONTESTATION_PROCUREUR_OU_PREFET' = 'DECISION_APRES_CONTESTATION_PROCUREUR_OU_PREFET'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateMainLeveeRequestDtoTypeRestitutionEnum {
    'ASSURANCE_PERMIS' = 'ASSURANCE_PERMIS',
    'ASSURANCE_REMORQUE' = 'ASSURANCE_REMORQUE'
}

/**
 * 
 * @export
 * @interface UpdateSortieVehiculeRequestDto
 */
export interface UpdateSortieVehiculeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSortieVehiculeRequestDto
     */
    comment: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSortieVehiculeRequestDto
     */
    dateSortie: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSortieVehiculeRequestDto
     */
    motifId: string | null;
}
/**
 * 
 * @export
 * @interface UpdateUserRequestDto
 */
export interface UpdateUserRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequestDto
     */
    actif: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequestDto
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequestDto
     */
    entiteRattachement: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequestDto
     */
    firstName: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequestDto
     */
    lastName: string | null;
}
/**
 * 
 * @export
 * @interface UpdateViolationDto
 */
export interface UpdateViolationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateViolationDto
     */
    formField: UpdateViolationDtoFormFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateViolationDto
     */
    violationCode: UpdateViolationDtoViolationCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateViolationDtoFormFieldEnum {
    'FIRSTNAME' = 'FIRSTNAME',
    'LASTNAME' = 'LASTNAME',
    'EMAIL' = 'EMAIL',
    'UNITE_RATTACHEMENT' = 'UNITE_RATTACHEMENT',
    'ACTIF' = 'ACTIF'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateViolationDtoViolationCodeEnum {
    'MISSING_FIELD' = 'MISSING_FIELD',
    'BAD_FORMAT_EMAIL' = 'BAD_FORMAT_EMAIL',
    'INVALID_ENTITE_RATTACHEMENT' = 'INVALID_ENTITE_RATTACHEMENT'
}

/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    email: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    idCorrelationAutoriteFourriere: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    idCorrelationFourriere: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    idCorrelationUniteFO: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    idCorrelationUniteFineFO: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    libelleEntiteRattachement: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    passage2UniteFOLibelle: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    passage2UniteFoCodeUnite: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    passage2UniteFoTypeOrganisation: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    profile: UserDtoProfileEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    uniteFOCommuneFonction: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    username: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserDtoProfileEnum {
    'AUTORITE_FOURRIERE' = 'AUTORITE_FOURRIERE',
    'DSR_BLR' = 'DSR_BLR',
    'FORCE_DE_L_ORDRE' = 'FORCE_DE_L_ORDRE',
    'GARDIEN_FOURRIERE' = 'GARDIEN_FOURRIERE',
    'ADMIN_FONCTIONNEL' = 'ADMIN_FONCTIONNEL',
    'CSU' = 'CSU'
}

/**
 * 
 * @export
 * @interface UserViolationsDto
 */
export interface UserViolationsDto {
    /**
     * 
     * @type {Array<CreateViolationDto>}
     * @memberof UserViolationsDto
     */
    createUserViolations: Array<CreateViolationDto> | null;
    /**
     * 
     * @type {Array<UpdateViolationDto>}
     * @memberof UserViolationsDto
     */
    updateUserViolations: Array<UpdateViolationDto> | null;
}
/**
 * 
 * @export
 * @interface Vehicule
 */
export interface Vehicule {
    /**
     * 
     * @type {string}
     * @memberof Vehicule
     */
    idCorrelationGenreSimplifie: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicule
     */
    idMarque: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicule
     */
    immatriculation: string | null;
}
/**
 * 
 * @export
 * @interface VerrouillageDto
 */
export interface VerrouillageDto {
    /**
     * 
     * @type {boolean}
     * @memberof VerrouillageDto
     */
    coffres: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VerrouillageDto
     */
    portes: boolean | null;
}
/**
 * 
 * @export
 * @interface VersionDto
 */
export interface VersionDto {
    /**
     * 
     * @type {string}
     * @memberof VersionDto
     */
    version: string;
}
/**
 * 
 * @export
 * @interface VideoTutorielDto
 */
export interface VideoTutorielDto {
    /**
     * 
     * @type {string}
     * @memberof VideoTutorielDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof VideoTutorielDto
     */
    idCorrelation: string;
    /**
     * 
     * @type {string}
     * @memberof VideoTutorielDto
     */
    profile: VideoTutorielDtoProfileEnum;
    /**
     * 
     * @type {string}
     * @memberof VideoTutorielDto
     */
    videoId: string;
}

/**
    * @export
    * @enum {string}
    */
export enum VideoTutorielDtoProfileEnum {
    'AUTORITE_FOURRIERE' = 'AUTORITE_FOURRIERE',
    'DSR_BLR' = 'DSR_BLR',
    'FORCE_DE_L_ORDRE' = 'FORCE_DE_L_ORDRE',
    'GARDIEN_FOURRIERE' = 'GARDIEN_FOURRIERE',
    'ADMIN_FONCTIONNEL' = 'ADMIN_FONCTIONNEL',
    'CSU' = 'CSU'
}

/**
 * 
 * @export
 * @interface WarningDto
 */
export interface WarningDto {
    /**
     * 
     * @type {string}
     * @memberof WarningDto
     */
    dateAppel: string;
    /**
     * 
     * @type {string}
     * @memberof WarningDto
     */
    dossierId: string;
    /**
     * 
     * @type {string}
     * @memberof WarningDto
     */
    hermesId: string;
    /**
     * 
     * @type {string}
     * @memberof WarningDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WarningDto
     */
    message: string;
}

//////////////////////////////////// AdminFonctionnelControllerApi /////////////////////////////////////////////////////

/**
 * objet contenant les query params de la route etatUsingGET
 * @export
 * @interface EtatUsingGETQueryParams
 */
export interface EtatUsingGETQueryParams {
    /**
     * paramÃ¨tres de recherche
     * @type {string}
     * @memberof EtatUsingGETQueryParams
     */
    insee: string;
}

/**
 * objet contenant les query params de la route searchFaqDomainUsingSpecsUsingGET
 * @export
 * @interface SearchFaqDomainUsingSpecsUsingGETQueryParams
 */
export interface SearchFaqDomainUsingSpecsUsingGETQueryParams {
    /**
     * objet contenant les filters de SearchFaqDomainUsingSpecsUsingGETQueryParams
     * @type {SearchFaqDomainUsingSpecsUsingGETQueryParamsFilters}
     * @memberof SearchFaqDomainUsingSpecsUsingGETQueryParams
     */
    filters: SearchFaqDomainUsingSpecsUsingGETQueryParamsFilters;
}

export interface SearchFaqDomainUsingSpecsUsingGETQueryParamsFilters {
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchFaqDomainUsingSpecsUsingGETQueryParamsFilters
    */
    idCorrelation?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchFaqDomainUsingSpecsUsingGETQueryParamsFilters
    */
    name?: string;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchFaqDomainUsingSpecsUsingGETQueryParamsFilters
    */
    page?: number;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchFaqDomainUsingSpecsUsingGETQueryParamsFilters
    */
    pageSize?: number;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchFaqDomainUsingSpecsUsingGETQueryParamsFilters
    */
    valid?: boolean;
}

/**
 * objet contenant les query params de la route searchFaqUsingSpecsUsingGET
 * @export
 * @interface SearchFaqUsingSpecsUsingGETQueryParams
 */
export interface SearchFaqUsingSpecsUsingGETQueryParams {
    /**
     * objet contenant les filters de SearchFaqUsingSpecsUsingGETQueryParams
     * @type {SearchFaqUsingSpecsUsingGETQueryParamsFilters}
     * @memberof SearchFaqUsingSpecsUsingGETQueryParams
     */
    filters: SearchFaqUsingSpecsUsingGETQueryParamsFilters;
}

export interface SearchFaqUsingSpecsUsingGETQueryParamsFilters {
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchFaqUsingSpecsUsingGETQueryParamsFilters
    */
    domainId?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchFaqUsingSpecsUsingGETQueryParamsFilters
    */
    idCorrelation?: string;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchFaqUsingSpecsUsingGETQueryParamsFilters
    */
    page?: number;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchFaqUsingSpecsUsingGETQueryParamsFilters
    */
    pageSize?: number;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchFaqUsingSpecsUsingGETQueryParamsFilters
    */
    valid?: boolean;
}

/**
 * objet contenant les query params de la route searchFluxUsingGET
 * @export
 * @interface SearchFluxUsingGETQueryParams
 */
export interface SearchFluxUsingGETQueryParams {
    /**
     * 
     * @type {number}
     * @memberof SearchFluxUsingGETQueryParams
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchFluxUsingGETQueryParams
     */
    pageSize?: number;
    /**
     * objet contenant les sortDto de SearchFluxUsingGETQueryParams
     * @type {SearchFluxUsingGETQueryParamsSortDto}
     * @memberof SearchFluxUsingGETQueryParams
     */
    sortDto: SearchFluxUsingGETQueryParamsSortDto;
}

export interface SearchFluxUsingGETQueryParamsSortDto {
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchFluxUsingGETQueryParamsSortDto
    */
    dateAppel?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchFluxUsingGETQueryParamsSortDto
    */
    dateDebut?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchFluxUsingGETQueryParamsSortDto
    */
    dateFin?: boolean;
}

/**
 * objet contenant les query params de la route searchMotifSortieUsingSpecsUsingGET
 * @export
 * @interface SearchMotifSortieUsingSpecsUsingGETQueryParams
 */
export interface SearchMotifSortieUsingSpecsUsingGETQueryParams {
    /**
     * objet contenant les filters de SearchMotifSortieUsingSpecsUsingGETQueryParams
     * @type {SearchMotifSortieUsingSpecsUsingGETQueryParamsFilters}
     * @memberof SearchMotifSortieUsingSpecsUsingGETQueryParams
     */
    filters: SearchMotifSortieUsingSpecsUsingGETQueryParamsFilters;
}

export interface SearchMotifSortieUsingSpecsUsingGETQueryParamsFilters {
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchMotifSortieUsingSpecsUsingGETQueryParamsFilters
    */
    idCorrelation?: string;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchMotifSortieUsingSpecsUsingGETQueryParamsFilters
    */
    page?: number;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchMotifSortieUsingSpecsUsingGETQueryParamsFilters
    */
    pageSize?: number;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchMotifSortieUsingSpecsUsingGETQueryParamsFilters
    */
    valid?: boolean;
}

/**
 * objet contenant les query params de la route searchTypeAfUsingSpecsUsingGET
 * @export
 * @interface SearchTypeAfUsingSpecsUsingGETQueryParams
 */
export interface SearchTypeAfUsingSpecsUsingGETQueryParams {
    /**
     * objet contenant les filters de SearchTypeAfUsingSpecsUsingGETQueryParams
     * @type {SearchTypeAfUsingSpecsUsingGETQueryParamsFilters}
     * @memberof SearchTypeAfUsingSpecsUsingGETQueryParams
     */
    filters: SearchTypeAfUsingSpecsUsingGETQueryParamsFilters;
}

export interface SearchTypeAfUsingSpecsUsingGETQueryParamsFilters {
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchTypeAfUsingSpecsUsingGETQueryParamsFilters
    */
    idCorrelation?: string;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchTypeAfUsingSpecsUsingGETQueryParamsFilters
    */
    page?: number;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchTypeAfUsingSpecsUsingGETQueryParamsFilters
    */
    pageSize?: number;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchTypeAfUsingSpecsUsingGETQueryParamsFilters
    */
    valid?: boolean;
}

/**
 * objet contenant les query params de la route searchUsingGET
 * @export
 * @interface SearchUsingGETQueryParams
 */
export interface SearchUsingGETQueryParams {
    /**
     * 
     * @type {string}
     * @memberof SearchUsingGETQueryParams
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUsingGETQueryParams
     */
    entiteRattachement?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchUsingGETQueryParams
     */
    exact?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchUsingGETQueryParams
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUsingGETQueryParams
     */
    lastName?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchUsingGETQueryParams
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchUsingGETQueryParams
     */
    pageSize?: number;
    /**
     * 
     * @type {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'}
     * @memberof SearchUsingGETQueryParams
     */
    userType?: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU';
    /**
     * 
     * @type {string}
     * @memberof SearchUsingGETQueryParams
     */
    username?: string;
}

/**
 * objet contenant les query params de la route searchUsingGET1
 * @export
 * @interface SearchUsingGET1QueryParams
 */
export interface SearchUsingGET1QueryParams {
    /**
     * NumÃ©ro de page
     * @type {number}
     * @memberof SearchUsingGET1QueryParams
     */
    currentPage?: number;
    /**
     * Nombre d\'Ã©lÃ©ments par page
     * @type {number}
     * @memberof SearchUsingGET1QueryParams
     */
    pageSize?: number;
    /**
     * Indique si le tri sur la date d\'appel est croissant (true) ou dÃ©croissant (false)
     * @type {boolean}
     * @memberof SearchUsingGET1QueryParams
     */
    sortDateAppelAsc?: boolean;
}

/**
 * objet contenant les query params de la route searchWarningsUsingGET
 * @export
 * @interface SearchWarningsUsingGETQueryParams
 */
export interface SearchWarningsUsingGETQueryParams {
    /**
     * NumÃ©ro de page
     * @type {number}
     * @memberof SearchWarningsUsingGETQueryParams
     */
    currentPage?: number;
    /**
     * Nombre d\'Ã©lÃ©ments par page
     * @type {number}
     * @memberof SearchWarningsUsingGETQueryParams
     */
    pageSize?: number;
}

/**
 * objet contenant les query params de la route warningsOfFluxUsingGET
 * @export
 * @interface WarningsOfFluxUsingGETQueryParams
 */
export interface WarningsOfFluxUsingGETQueryParams {
    /**
     * 
     * @type {number}
     * @memberof WarningsOfFluxUsingGETQueryParams
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof WarningsOfFluxUsingGETQueryParams
     */
    pageSize?: number;
}

/**
 * AdminFonctionnelControllerApi - fetch parameter creator
 * @export
 */
export const AdminFonctionnelControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant Ã  l\'admin fonctionnel d\'annuler la sortie d\'un vÃ©hicule
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annulationSortieVehiculeUsingPUT(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling annulationSortieVehiculeUsingPUT.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/annulation-sortie`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de modifier les positions des domaines faq
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeFaqDomainPositionUsingPOST(requestBody: Array<string>, options: any = {}): FetchArgs {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling changeFaqDomainPositionUsingPOST.');
            }
            const localVarPath = `/api/referentiel/faq-domain/position`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(requestBody || {}) : (requestBody || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de modifier les positions des questions
         * @param {string} domainId id du domaine des questions
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeFaqPositionUsingPOST(domainId: string, requestBody: Array<string>, options: any = {}): FetchArgs {
            // verify required parameter 'domainId' is not null or undefined
            if (domainId === null || domainId === undefined) {
                throw new RequiredError('domainId','Required parameter domainId was null or undefined when calling changeFaqPositionUsingPOST.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling changeFaqPositionUsingPOST.');
            }
            const localVarPath = `/api/referentiel/foire-aux-questions/{domainId}/position`
                .replace(`{${"domainId"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(requestBody || {}) : (requestBody || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si une autoritÃ© de fourriÃ¨re peut Ãªtre rÃ©activÃ©e
         * @param {string} idCorrelation Identifiant de l\'autoritÃ© de fourriÃ¨re
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationAutoriteFourriereUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling conditionReactivationAutoriteFourriereUsingGET.');
            }
            const localVarPath = `/api/referentiel/autorite-fourriere/{idCorrelation}/condition-reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si un centre vhu peut Ãªtre rÃ©activÃ©e
         * @param {string} idCorrelation Identifiant du centre vhu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationCentreVhuUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling conditionReactivationCentreVhuUsingGET.');
            }
            const localVarPath = `/api/referentiel/centre-vhu/{idCorrelation}/condition-reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si un domaine faq peut Ãªtre rÃ©activÃ©e
         * @param {string} idCorrelation Identifiant du domaine faq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationFaqDomainUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling conditionReactivationFaqDomainUsingGET.');
            }
            const localVarPath = `/api/referentiel/faq-domain/{idCorrelation}/condition-reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si une question peut Ãªtre rÃ©activÃ©e
         * @param {string} idCorrelation Identifiant de la question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationFaqUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling conditionReactivationFaqUsingGET.');
            }
            const localVarPath = `/api/referentiel/foire-aux-questions/{idCorrelation}/condition-reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si une  fourriÃ¨re peut Ãªtre rÃ©activÃ©e
         * @param {string} idCorrelation Identifiant de la fourriÃ¨re
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationFourriereUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling conditionReactivationFourriereUsingGET.');
            }
            const localVarPath = `/api/referentiel/fourriere/{idCorrelation}/condition-reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si un motif de MEF peut Ãªtre rÃ©activÃ©
         * @param {string} idCorrelation Identifiant du motif de MEF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationMotifMefUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling conditionReactivationMotifMefUsingGET.');
            }
            const localVarPath = `/api/referentiel/motif-mef/{idCorrelation}/condition-reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si un motif de sortie peut Ãªtre rÃ©activÃ©
         * @param {string} idCorrelation Identifiant du motif de sortie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationMotifSortieUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling conditionReactivationMotifSortieUsingGET.');
            }
            const localVarPath = `/api/referentiel/motif-sortie/{idCorrelation}/condition-reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si un type d\'AF peut Ãªtre rÃ©activÃ©
         * @param {string} idCorrelation Identifiant du type d\'AF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationTypeAfUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling conditionReactivationTypeAfUsingGET.');
            }
            const localVarPath = `/api/referentiel/type-autorite-fourriere/{idCorrelation}/condition-reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si une unitÃ© FO peut Ãªtre rÃ©activÃ©e
         * @param {string} idCorrelation Identifiant de l\'unitÃ© FO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling conditionReactivationUsingGET.');
            }
            const localVarPath = `/api/referentiel/unite-fo/{idCorrelation}/condition-reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant d\'importer un fichier avec les retours de la DNID.
         * @param {any} file Fichier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contournementCsvImportUsingPOST(file: any, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling contournementCsvImportUsingPOST.');
            }
            const localVarPath = `/api/hermes/contournement/retour`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de crÃ©er une autorite de fourriÃ¨re.
         * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto DonnÃ©es utilisÃ©es pour crÃ©er l\'autoritÃ© de fourriÃ¨re
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAutoriteFourriereUsingPOST(autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'autoriteFourriereRequestDto' is not null or undefined
            if (autoriteFourriereRequestDto === null || autoriteFourriereRequestDto === undefined) {
                throw new RequiredError('autoriteFourriereRequestDto','Required parameter autoriteFourriereRequestDto was null or undefined when calling createAutoriteFourriereUsingPOST.');
            }
            const localVarPath = `/api/referentiel/autorite-fourriere`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AutoriteFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(autoriteFourriereRequestDto || {}) : (autoriteFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de crÃ©er un centre VHU
         * @param {CentreVHURequestDto} centreVHURequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCentreVHUUsingPOST(centreVHURequestDto: CentreVHURequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'centreVHURequestDto' is not null or undefined
            if (centreVHURequestDto === null || centreVHURequestDto === undefined) {
                throw new RequiredError('centreVHURequestDto','Required parameter centreVHURequestDto was null or undefined when calling createCentreVHUUsingPOST.');
            }
            const localVarPath = `/api/referentiel/centre-vhu`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CentreVHURequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(centreVHURequestDto || {}) : (centreVHURequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de crÃ©er un domaine faq
         * @param {FaqDomainRequestDto} faqDomainRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaqDomainUsingPOST(faqDomainRequestDto: FaqDomainRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'faqDomainRequestDto' is not null or undefined
            if (faqDomainRequestDto === null || faqDomainRequestDto === undefined) {
                throw new RequiredError('faqDomainRequestDto','Required parameter faqDomainRequestDto was null or undefined when calling createFaqDomainUsingPOST.');
            }
            const localVarPath = `/api/referentiel/faq-domain`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FaqDomainRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(faqDomainRequestDto || {}) : (faqDomainRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de crÃ©er une question
         * @param {FaqRequestDto} faqRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaqUsingPOST(faqRequestDto: FaqRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'faqRequestDto' is not null or undefined
            if (faqRequestDto === null || faqRequestDto === undefined) {
                throw new RequiredError('faqRequestDto','Required parameter faqRequestDto was null or undefined when calling createFaqUsingPOST.');
            }
            const localVarPath = `/api/referentiel/foire-aux-questions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FaqRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(faqRequestDto || {}) : (faqRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de crÃ©er une fourriÃ¨re.
         * @param {FourriereRequestDto} fourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFourriereUsingPOST(fourriereRequestDto: FourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'fourriereRequestDto' is not null or undefined
            if (fourriereRequestDto === null || fourriereRequestDto === undefined) {
                throw new RequiredError('fourriereRequestDto','Required parameter fourriereRequestDto was null or undefined when calling createFourriereUsingPOST.');
            }
            const localVarPath = `/api/referentiel/fourriere`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fourriereRequestDto || {}) : (fourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de crÃ©er un motif de MEF.
         * @param {MotifMefRequestDto} motifMefRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMotifMefUsingPOST(motifMefRequestDto: MotifMefRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'motifMefRequestDto' is not null or undefined
            if (motifMefRequestDto === null || motifMefRequestDto === undefined) {
                throw new RequiredError('motifMefRequestDto','Required parameter motifMefRequestDto was null or undefined when calling createMotifMefUsingPOST.');
            }
            const localVarPath = `/api/referentiel/motif-mef`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MotifMefRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(motifMefRequestDto || {}) : (motifMefRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de crÃ©er un motif de sortie.
         * @param {MotifSortieRequestDto} motifSortieRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMotifSortieUsingPOST(motifSortieRequestDto: MotifSortieRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'motifSortieRequestDto' is not null or undefined
            if (motifSortieRequestDto === null || motifSortieRequestDto === undefined) {
                throw new RequiredError('motifSortieRequestDto','Required parameter motifSortieRequestDto was null or undefined when calling createMotifSortieUsingPOST.');
            }
            const localVarPath = `/api/referentiel/motif-sortie`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MotifSortieRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(motifSortieRequestDto || {}) : (motifSortieRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de crÃ©er un type d\'AF.
         * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTypeAfUsingPOST(typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'typeAutoriteFourriereRequestDto' is not null or undefined
            if (typeAutoriteFourriereRequestDto === null || typeAutoriteFourriereRequestDto === undefined) {
                throw new RequiredError('typeAutoriteFourriereRequestDto','Required parameter typeAutoriteFourriereRequestDto was null or undefined when calling createTypeAfUsingPOST.');
            }
            const localVarPath = `/api/referentiel/type-autorite-fourriere`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TypeAutoriteFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(typeAutoriteFourriereRequestDto || {}) : (typeAutoriteFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de crÃ©er une unitÃ© de force de l\'ordre.
         * @param {UniteFoRequestDto} uniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUniteFoUsingPOST(uniteFoRequestDto: UniteFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'uniteFoRequestDto' is not null or undefined
            if (uniteFoRequestDto === null || uniteFoRequestDto === undefined) {
                throw new RequiredError('uniteFoRequestDto','Required parameter uniteFoRequestDto was null or undefined when calling createUniteFoUsingPOST.');
            }
            const localVarPath = `/api/referentiel/unite-fo`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UniteFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(uniteFoRequestDto || {}) : (uniteFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route crÃ©ant un utilisateur et retournant les valeurs gÃ©nÃ©rÃ©es
         * @param {CreateUserRequestDto} createUserRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST(createUserRequestDto: CreateUserRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'createUserRequestDto' is not null or undefined
            if (createUserRequestDto === null || createUserRequestDto === undefined) {
                throw new RequiredError('createUserRequestDto','Required parameter createUserRequestDto was null or undefined when calling createUsingPOST.');
            }
            const localVarPath = `/api/comptes-utilisateurs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateUserRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(createUserRequestDto || {}) : (createUserRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de supprimer un utilisateur
         * @param {string} userId Id du compte Ã  supprimer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsingDELETE(userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteUserUsingDELETE.');
            }
            const localVarPath = `/api/comptes-utilisateurs/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary DÃ©sactivation d\'une autoritÃ© de fourriÃ¨re.
         * @param {string} idCorrelation Identifiant de l\'autoritÃ© de fourriÃ¨re
         * @param {ReferentielCommentairesRequestDto} referentielCommentairesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateAutoriteFourriereUsingPUT(idCorrelation: string, referentielCommentairesRequestDto: ReferentielCommentairesRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling deprecateAutoriteFourriereUsingPUT.');
            }
            // verify required parameter 'referentielCommentairesRequestDto' is not null or undefined
            if (referentielCommentairesRequestDto === null || referentielCommentairesRequestDto === undefined) {
                throw new RequiredError('referentielCommentairesRequestDto','Required parameter referentielCommentairesRequestDto was null or undefined when calling deprecateAutoriteFourriereUsingPUT.');
            }
            const localVarPath = `/api/referentiel/autorite-fourriere/{idCorrelation}/desactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReferentielCommentairesRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(referentielCommentairesRequestDto || {}) : (referentielCommentairesRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary DÃ©sactivation d\'un centre vhu.
         * @param {string} idCorrelation Identifiant du centre vhu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateCentreVhuUsingPUT(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling deprecateCentreVhuUsingPUT.');
            }
            const localVarPath = `/api/referentiel/centre-vhu/{idCorrelation}/desactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary DÃ©sactivation d\'un domaine faq.
         * @param {string} idCorrelation Identifiant du domaine faq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateFaqDomainUsingPUT(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling deprecateFaqDomainUsingPUT.');
            }
            const localVarPath = `/api/referentiel/faq-domain/{idCorrelation}/desactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary DÃ©sactivation d\'une question
         * @param {string} idCorrelation Identifiant de la question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateFaqUsingPUT(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling deprecateFaqUsingPUT.');
            }
            const localVarPath = `/api/referentiel/foire-aux-questions/{idCorrelation}/desactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary DÃ©sactivation d\'une fourriÃ¨re.
         * @param {string} idCorrelation Identifiant de la fourriÃ¨re
         * @param {ReferentielCommentairesRequestDto} referentielCommentairesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateFourriereUsingPUT(idCorrelation: string, referentielCommentairesRequestDto: ReferentielCommentairesRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling deprecateFourriereUsingPUT.');
            }
            // verify required parameter 'referentielCommentairesRequestDto' is not null or undefined
            if (referentielCommentairesRequestDto === null || referentielCommentairesRequestDto === undefined) {
                throw new RequiredError('referentielCommentairesRequestDto','Required parameter referentielCommentairesRequestDto was null or undefined when calling deprecateFourriereUsingPUT.');
            }
            const localVarPath = `/api/referentiel/fourriere/{idCorrelation}/desactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReferentielCommentairesRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(referentielCommentairesRequestDto || {}) : (referentielCommentairesRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary DÃ©sactivation d\'un motif de MEF.
         * @param {string} idCorrelation Identifiant du motif de MEF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateMotifMefUsingPUT(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling deprecateMotifMefUsingPUT.');
            }
            const localVarPath = `/api/referentiel/motif-mef/{idCorrelation}/deprecation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary DÃ©sactivation d\'un motif de sortie.
         * @param {string} idCorrelation Identifiant du motif de sortie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateMotifSortieUsingPUT(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling deprecateMotifSortieUsingPUT.');
            }
            const localVarPath = `/api/referentiel/motif-sortie/{idCorrelation}/desactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary DÃ©sactivation d\'un type d\'AF.
         * @param {string} idCorrelation Identifiant du type d\'AF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateTypeAfUsingPUT(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling deprecateTypeAfUsingPUT.');
            }
            const localVarPath = `/api/referentiel/type-autorite-fourriere/{idCorrelation}/desactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary DÃ©sactivation d\'une unitÃ© FO.
         * @param {string} idCorrelation Identifiant de l\'unitÃ© FO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateUsingPUT(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling deprecateUsingPUT.');
            }
            const localVarPath = `/api/referentiel/unite-fo/{idCorrelation}/desactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant d\'exporter la table de dÃ©cision au format DMN.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCsvTableUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/classement/export/dmn`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @param {etatUsingGETQueryParams} queryParams objet contenant les query params de la route etatUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        etatUsingGET(queryParams: EtatUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'insee' is not null or undefined
            if (queryParams.insee === null || queryParams.insee === undefined) {
                throw new RequiredError('insee','Required parameter insee was null or undefined when calling etatUsingGET.');
            }
            const localVarPath = `/api/conventionnement`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.insee !== undefined) {
                localVarQueryParameter['insee'] = queryParams.insee;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer l\'AR/PND depuis l\'ANTAI
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchArPndUsingPOST(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling fetchArPndUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/notification/ar-pnd/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer le courrier de notification depuis l\'ANTAI
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCourrierUsingPOST(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling fetchCourrierUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/notification/courrier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de modifier les donnÃ©es de notification manuelle d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {FixDataNotificationManuelleRequestDto} fixDataNotificationManuelleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixDataNotificationManuelleUsingPOST(id: string, fixDataNotificationManuelleRequestDto: FixDataNotificationManuelleRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling fixDataNotificationManuelleUsingPOST.');
            }
            // verify required parameter 'fixDataNotificationManuelleRequestDto' is not null or undefined
            if (fixDataNotificationManuelleRequestDto === null || fixDataNotificationManuelleRequestDto === undefined) {
                throw new RequiredError('fixDataNotificationManuelleRequestDto','Required parameter fixDataNotificationManuelleRequestDto was null or undefined when calling fixDataNotificationManuelleUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/notification-manuelle`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FixDataNotificationManuelleRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fixDataNotificationManuelleRequestDto || {}) : (fixDataNotificationManuelleRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant d\'exporter l\'ensemble des donnÃ©es d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDonneesAndEventsDossierUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAllDonneesAndEventsDossierUsingGET.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/export`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant d\'obtenir la liste des dÃ©tails des Ã©vÃ¨nements
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedEventsUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDetailedEventsUsingGET.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/detailed-events`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche les fourrieres associÃ©es Ã  une autoritÃ© fourriere
         * @param {string} idCorrelation Identifiant de l\'autoritÃ© de fourriÃ¨re
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFourrieresFromValidAutoriteFourriereUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling getFourrieresFromValidAutoriteFourriereUsingGET.');
            }
            const localVarPath = `/api/referentiel/autorite-fourriere/{idCorrelation}/fourrieres`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de dÃ©terminer quelle solution est active pour Hermes.<br/> Celle-ci pouvant Ãªtre l\'API d\'Hermes ou la solution de contournement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHermesActiveSolutionUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/hermes/solution-active`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant d\'obtenir les informations du bandeau de l\'admin fonctionnel avec l\'objet DossierDtoAdminFonctionnel
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInformationsByDossierIdUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInformationsByDossierIdUsingGET.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les questions valides, regroupÃ©es par domaines pour l\'admin.
         * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} profile Profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlyValidFaqByDomainsForProfileUsingGET(profile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', options: any = {}): FetchArgs {
            // verify required parameter 'profile' is not null or undefined
            if (profile === null || profile === undefined) {
                throw new RequiredError('profile','Required parameter profile was null or undefined when calling getOnlyValidFaqByDomainsForProfileUsingGET.');
            }
            const localVarPath = `/api/referentiel/faq/domaine/{profile}`
                .replace(`{${"profile"}}`, encodeURIComponent(String(profile)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer les instances Camunda d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessInstancesUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProcessInstancesUsingGET.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/process-instances`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant d\'importer une table de dÃ©cision au format DMN.
         * @param {any} file Fichier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCsvTableUsingPOST(file: any, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling importCsvTableUsingPOST.');
            }
            const localVarPath = `/api/classement/import/dmn`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de migrer d\'une unitÃ© fo Ã  une autre les dossiers associÃ©s
         * @param {MigrateUniteFoRequestDto} migrateUniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateUniteFoUsingPOST(migrateUniteFoRequestDto: MigrateUniteFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'migrateUniteFoRequestDto' is not null or undefined
            if (migrateUniteFoRequestDto === null || migrateUniteFoRequestDto === undefined) {
                throw new RequiredError('migrateUniteFoRequestDto','Required parameter migrateUniteFoRequestDto was null or undefined when calling migrateUniteFoUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/migrate-unite-fo`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MigrateUniteFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(migrateUniteFoRequestDto || {}) : (migrateUniteFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary RÃ©activation d\'une autoritÃ© de fourriÃ¨re.
         * @param {string} idCorrelation Identifiant de l\'autoritÃ© de fourriÃ¨re
         * @param {ReferentielCommentairesRequestDto} referentielCommentairesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateAutoriteFourriereUsingPUT(idCorrelation: string, referentielCommentairesRequestDto: ReferentielCommentairesRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling reactivateAutoriteFourriereUsingPUT.');
            }
            // verify required parameter 'referentielCommentairesRequestDto' is not null or undefined
            if (referentielCommentairesRequestDto === null || referentielCommentairesRequestDto === undefined) {
                throw new RequiredError('referentielCommentairesRequestDto','Required parameter referentielCommentairesRequestDto was null or undefined when calling reactivateAutoriteFourriereUsingPUT.');
            }
            const localVarPath = `/api/referentiel/autorite-fourriere/{idCorrelation}/reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReferentielCommentairesRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(referentielCommentairesRequestDto || {}) : (referentielCommentairesRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary RÃ©activation d\'un centre vhu.
         * @param {string} idCorrelation Identifiant du centre vhu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateCentreVhuUsingPUT(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling reactivateCentreVhuUsingPUT.');
            }
            const localVarPath = `/api/referentiel/centre-vhu/{idCorrelation}/reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary RÃ©activation d\'un domaine faq.
         * @param {string} idCorrelation Identifiant du domaine faq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateFaqDomainUsingPUT(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling reactivateFaqDomainUsingPUT.');
            }
            const localVarPath = `/api/referentiel/faq-domain/{idCorrelation}/reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary RÃ©activation d\'une question
         * @param {string} idCorrelation Identifiant de la question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateFaqUsingPUT(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling reactivateFaqUsingPUT.');
            }
            const localVarPath = `/api/referentiel/foire-aux-questions/{idCorrelation}/reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary RÃ©activation d\'une fourriÃ¨re.
         * @param {string} idCorrelation Identifiant de la fourriÃ¨re
         * @param {ReferentielCommentairesRequestDto} referentielCommentairesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateFourriereUsingPUT(idCorrelation: string, referentielCommentairesRequestDto: ReferentielCommentairesRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling reactivateFourriereUsingPUT.');
            }
            // verify required parameter 'referentielCommentairesRequestDto' is not null or undefined
            if (referentielCommentairesRequestDto === null || referentielCommentairesRequestDto === undefined) {
                throw new RequiredError('referentielCommentairesRequestDto','Required parameter referentielCommentairesRequestDto was null or undefined when calling reactivateFourriereUsingPUT.');
            }
            const localVarPath = `/api/referentiel/fourriere/{idCorrelation}/reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReferentielCommentairesRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(referentielCommentairesRequestDto || {}) : (referentielCommentairesRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary RÃ©activation d\'un motif de MEF.
         * @param {string} idCorrelation Identifiant du motif de MEF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateMotifMefUsingPUT(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling reactivateMotifMefUsingPUT.');
            }
            const localVarPath = `/api/referentiel/motif-mef/{idCorrelation}/reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary RÃ©activation d\'un motif de sortie.
         * @param {string} idCorrelation Identifiant du motif de sortie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateMotifSortieUsingPUT(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling reactivateMotifSortieUsingPUT.');
            }
            const localVarPath = `/api/referentiel/motif-sortie/{idCorrelation}/reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary RÃ©activation d\'un type d\'AF.
         * @param {string} idCorrelation Identifiant du type d\'AF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateTypeAfUsingPUT(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling reactivateTypeAfUsingPUT.');
            }
            const localVarPath = `/api/referentiel/type-autorite-fourriere/{idCorrelation}/reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary RÃ©activation d\'une unitÃ© FO.
         * @param {string} idCorrelation Identifiant de l\'unitÃ© FO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateUsingPUT(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling reactivateUsingPUT.');
            }
            const localVarPath = `/api/referentiel/unite-fo/{idCorrelation}/reactivation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route dÃ©clenchant une relance du processus de vente chez le service des domaines, engendrant une nouvelle remise aux domaines.
         * @param {RelanceVenteDto} relanceVenteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relanceVenteAdminUsingPOST(relanceVenteDto: RelanceVenteDto, options: any = {}): FetchArgs {
            // verify required parameter 'relanceVenteDto' is not null or undefined
            if (relanceVenteDto === null || relanceVenteDto === undefined) {
                throw new RequiredError('relanceVenteDto','Required parameter relanceVenteDto was null or undefined when calling relanceVenteAdminUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/relance-vente`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RelanceVenteDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(relanceVenteDto || {}) : (relanceVenteDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de rÃ©initialiser le mot de passe d\'un utilisateur
         * @param {string} userId Id du compte dont on veut rÃ©initialiser le mot de passe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPUT(userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling resetPasswordUsingPUT.');
            }
            const localVarPath = `/api/comptes-utilisateurs/{userId}/reset-password`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de sauvegarder une fiche descriptive
         * @param {any} file La fiche descriptive, reprÃ©sentÃ©e par un InputStream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFicheDescriptiveUsingPOST(file: any, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling saveFicheDescriptiveUsingPOST.');
            }
            const localVarPath = `/api/fiche-descriptive/save`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de sauvegarder un manuel Bdr
         * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile Le type d\'utilisateur visÃ© par le manuel, reprÃ©sentÃ© par un UserProfile.
         * @param {any} file Le manuel, reprÃ©sentÃ© par un InputStream.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveManualBdrUsingPOST(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', file: any, options: any = {}): FetchArgs {
            // verify required parameter 'userProfile' is not null or undefined
            if (userProfile === null || userProfile === undefined) {
                throw new RequiredError('userProfile','Required parameter userProfile was null or undefined when calling saveManualBdrUsingPOST.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling saveManualBdrUsingPOST.');
            }
            const localVarPath = `/api/manual/bdr/{userProfile}`
                .replace(`{${"userProfile"}}`, encodeURIComponent(String(userProfile)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de sauvegarder un manuel utilisateur
         * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile Le type d\'utilisateur visÃ© par le manuel, reprÃ©sentÃ© par un UserProfile.
         * @param {any} file Le manuel, reprÃ©sentÃ© par un InputStream.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveManualUtilisateurUsingPOST(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', file: any, options: any = {}): FetchArgs {
            // verify required parameter 'userProfile' is not null or undefined
            if (userProfile === null || userProfile === undefined) {
                throw new RequiredError('userProfile','Required parameter userProfile was null or undefined when calling saveManualUtilisateurUsingPOST.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling saveManualUtilisateurUsingPOST.');
            }
            const localVarPath = `/api/manual/{userProfile}`
                .replace(`{${"userProfile"}}`, encodeURIComponent(String(userProfile)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche de la liste de domaine faq filtrÃ©s par le body de la requÃªte
         * @param {searchFaqDomainUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchFaqDomainUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFaqDomainUsingSpecsUsingGET(queryParams: SearchFaqDomainUsingSpecsUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'filters' is not null or undefined
            if (queryParams.filters === null || queryParams.filters === undefined) {
                throw new RequiredError('filters','Required parameter filters was null or undefined when calling searchFaqDomainUsingSpecsUsingGET.');
            }
            const localVarPath = `/api/referentiel/faq/domain/with-specs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.filters.idCorrelation !== undefined) {
                localVarQueryParameter['filters.idCorrelation'] = queryParams.filters.idCorrelation;
            }
            if (queryParams.filters.name !== undefined) {
                localVarQueryParameter['filters.name'] = queryParams.filters.name;
            }
            if (queryParams.filters.page !== undefined) {
                localVarQueryParameter['filters.page'] = queryParams.filters.page;
            }
            if (queryParams.filters.pageSize !== undefined) {
                localVarQueryParameter['filters.pageSize'] = queryParams.filters.pageSize;
            }
            if (queryParams.filters.valid !== undefined) {
                localVarQueryParameter['filters.valid'] = queryParams.filters.valid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche de la liste des faq filtrÃ©s par le body de la requÃªte
         * @param {searchFaqUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchFaqUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFaqUsingSpecsUsingGET(queryParams: SearchFaqUsingSpecsUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'filters' is not null or undefined
            if (queryParams.filters === null || queryParams.filters === undefined) {
                throw new RequiredError('filters','Required parameter filters was null or undefined when calling searchFaqUsingSpecsUsingGET.');
            }
            const localVarPath = `/api/referentiel/faq/with-specs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.filters.domainId !== undefined) {
                localVarQueryParameter['filters.domainId'] = queryParams.filters.domainId;
            }
            if (queryParams.filters.idCorrelation !== undefined) {
                localVarQueryParameter['filters.idCorrelation'] = queryParams.filters.idCorrelation;
            }
            if (queryParams.filters.page !== undefined) {
                localVarQueryParameter['filters.page'] = queryParams.filters.page;
            }
            if (queryParams.filters.pageSize !== undefined) {
                localVarQueryParameter['filters.pageSize'] = queryParams.filters.pageSize;
            }
            if (queryParams.filters.valid !== undefined) {
                localVarQueryParameter['filters.valid'] = queryParams.filters.valid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant Ã  l\'admin fonctionnel de rÃ©cupÃ©rer les flux Hermes de rÃ©cupÃ©ration de l\'Ã©tat des biens
         * @param {searchFluxUsingGETQueryParams} queryParams objet contenant les query params de la route searchFluxUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFluxUsingGET(queryParams: SearchFluxUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'sortDto' is not null or undefined
            if (queryParams.sortDto === null || queryParams.sortDto === undefined) {
                throw new RequiredError('sortDto','Required parameter sortDto was null or undefined when calling searchFluxUsingGET.');
            }
            const localVarPath = `/api/admin-fonctionnel/hermes/etat-bien`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.page !== undefined) {
                localVarQueryParameter['page'] = queryParams.page;
            }

            if (queryParams.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = queryParams.pageSize;
            }

            if (queryParams.sortDto.dateAppel !== undefined) {
                localVarQueryParameter['sortDto.dateAppel'] = queryParams.sortDto.dateAppel;
            }
            if (queryParams.sortDto.dateDebut !== undefined) {
                localVarQueryParameter['sortDto.dateDebut'] = queryParams.sortDto.dateDebut;
            }
            if (queryParams.sortDto.dateFin !== undefined) {
                localVarQueryParameter['sortDto.dateFin'] = queryParams.sortDto.dateFin;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche de la liste de motifs de sortie filtrÃ©e par le body de la requÃªte
         * @param {searchMotifSortieUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchMotifSortieUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMotifSortieUsingSpecsUsingGET(queryParams: SearchMotifSortieUsingSpecsUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'filters' is not null or undefined
            if (queryParams.filters === null || queryParams.filters === undefined) {
                throw new RequiredError('filters','Required parameter filters was null or undefined when calling searchMotifSortieUsingSpecsUsingGET.');
            }
            const localVarPath = `/api/referentiel/motif-sortie/with-specs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.filters.idCorrelation !== undefined) {
                localVarQueryParameter['filters.idCorrelation'] = queryParams.filters.idCorrelation;
            }
            if (queryParams.filters.page !== undefined) {
                localVarQueryParameter['filters.page'] = queryParams.filters.page;
            }
            if (queryParams.filters.pageSize !== undefined) {
                localVarQueryParameter['filters.pageSize'] = queryParams.filters.pageSize;
            }
            if (queryParams.filters.valid !== undefined) {
                localVarQueryParameter['filters.valid'] = queryParams.filters.valid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant Ã  l\'admin fonctionnel de rÃ©cupÃ©rer un flux Hermes de rÃ©cupÃ©ration de l\'Ã©tat des biens
         * @param {string} fluxId Id du flux, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSpecificFluxUsingGET(fluxId: string, options: any = {}): FetchArgs {
            // verify required parameter 'fluxId' is not null or undefined
            if (fluxId === null || fluxId === undefined) {
                throw new RequiredError('fluxId','Required parameter fluxId was null or undefined when calling searchSpecificFluxUsingGET.');
            }
            const localVarPath = `/api/admin-fonctionnel/hermes/etat-bien/flux/{fluxId}`
                .replace(`{${"fluxId"}}`, encodeURIComponent(String(fluxId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche de la liste des types d\'AF filtrÃ©e par le body de la requÃªte
         * @param {searchTypeAfUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchTypeAfUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTypeAfUsingSpecsUsingGET(queryParams: SearchTypeAfUsingSpecsUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'filters' is not null or undefined
            if (queryParams.filters === null || queryParams.filters === undefined) {
                throw new RequiredError('filters','Required parameter filters was null or undefined when calling searchTypeAfUsingSpecsUsingGET.');
            }
            const localVarPath = `/api/referentiel/type-autorite-fourriere/with-specs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.filters.idCorrelation !== undefined) {
                localVarQueryParameter['filters.idCorrelation'] = queryParams.filters.idCorrelation;
            }
            if (queryParams.filters.page !== undefined) {
                localVarQueryParameter['filters.page'] = queryParams.filters.page;
            }
            if (queryParams.filters.pageSize !== undefined) {
                localVarQueryParameter['filters.pageSize'] = queryParams.filters.pageSize;
            }
            if (queryParams.filters.valid !== undefined) {
                localVarQueryParameter['filters.valid'] = queryParams.filters.valid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche des utilisations d\'une autorite de fourriÃ¨re dans le SI
         * @param {string} idCorrelation Identifiant de l\'autoritÃ© de fourriÃ¨re
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsesAutoriteFourriereUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling searchUsesAutoriteFourriereUsingGET.');
            }
            const localVarPath = `/api/referentiel/autorite-fourriere/{idCorrelation}/uses`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche des utilisations d\'une fourriÃ¨re dans le SI
         * @param {string} idCorrelation Identifiant de la fourriÃ¨re
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsesFourriereUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling searchUsesFourriereUsingGET.');
            }
            const localVarPath = `/api/referentiel/fourriere/{idCorrelation}/uses`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche des utilisations d\'une UnitÃ© FO dans le SI
         * @param {string} idCorrelation Identifiant de l\'unitÃ© FO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsesUniteFoUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling searchUsesUniteFoUsingGET.');
            }
            const localVarPath = `/api/referentiel/unite-fo/{idCorrelation}/uses`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer une liste d\'utilisateur paginÃ©e
         * @param {searchUsingGETQueryParams} queryParams objet contenant les query params de la route searchUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsingGET(queryParams: SearchUsingGETQueryParams, options: any = {}): FetchArgs {
            const localVarPath = `/api/comptes-utilisateurs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.email !== undefined) {
                localVarQueryParameter['email'] = queryParams.email;
            }

            if (queryParams.entiteRattachement !== undefined) {
                localVarQueryParameter['entiteRattachement'] = queryParams.entiteRattachement;
            }

            if (queryParams.exact !== undefined) {
                localVarQueryParameter['exact'] = queryParams.exact;
            }

            if (queryParams.firstName !== undefined) {
                localVarQueryParameter['firstName'] = queryParams.firstName;
            }

            if (queryParams.lastName !== undefined) {
                localVarQueryParameter['lastName'] = queryParams.lastName;
            }

            if (queryParams.page !== undefined) {
                localVarQueryParameter['page'] = queryParams.page;
            }

            if (queryParams.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = queryParams.pageSize;
            }

            if (queryParams.userType !== undefined) {
                localVarQueryParameter['userType'] = queryParams.userType;
            }

            if (queryParams.username !== undefined) {
                localVarQueryParameter['username'] = queryParams.username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant Ã  l\'admin fonctionnel de rÃ©cupÃ©rer les flux de fourriÃ¨res Hermes
         * @param {searchUsingGET1QueryParams} queryParams objet contenant les query params de la route searchUsingGET1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsingGET1(queryParams: SearchUsingGET1QueryParams, options: any = {}): FetchArgs {
            const localVarPath = `/api/admin-fonctionnel/hermes/fourriere/flux`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = queryParams.currentPage;
            }

            if (queryParams.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = queryParams.pageSize;
            }

            if (queryParams.sortDateAppelAsc !== undefined) {
                localVarQueryParameter['sortDateAppelAsc'] = queryParams.sortDateAppelAsc;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant Ã  l\'admin fonctionnel de rÃ©cupÃ©rer les warnings d\'un flux de fourriÃ¨res Hermes
          * @param {string} fluxId Id du flux, reprÃ©sentÃ© par un UUID
         * @param {searchWarningsUsingGETQueryParams} queryParams objet contenant les query params de la route searchWarningsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchWarningsUsingGET(fluxId: string, queryParams: SearchWarningsUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'fluxId' is not null or undefined
            if (fluxId === null || fluxId === undefined) {
                throw new RequiredError('fluxId','Required parameter fluxId was null or undefined when calling searchWarningsUsingGET.');
            }
            const localVarPath = `/api/admin-fonctionnel/hermes/fourriere/flux/{fluxId}/warnings`
                .replace(`{${"fluxId"}}`, encodeURIComponent(String(fluxId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = queryParams.currentPage;
            }

            if (queryParams.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = queryParams.pageSize;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant Ã  l\'admin fonctionnel de supprimer un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suppressionDossierUsingDELETE(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling suppressionDossierUsingDELETE.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour une autoritÃ© de fourriÃ¨re.
         * @param {string} idCorrelation ID de l\'autorite de fourriÃ¨re, reprÃ©sentÃ© par un UUID
         * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAutoriteFourriereUsingPUT(idCorrelation: string, autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling updateAutoriteFourriereUsingPUT.');
            }
            // verify required parameter 'autoriteFourriereRequestDto' is not null or undefined
            if (autoriteFourriereRequestDto === null || autoriteFourriereRequestDto === undefined) {
                throw new RequiredError('autoriteFourriereRequestDto','Required parameter autoriteFourriereRequestDto was null or undefined when calling updateAutoriteFourriereUsingPUT.');
            }
            const localVarPath = `/api/referentiel/autorite-fourriere/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AutoriteFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(autoriteFourriereRequestDto || {}) : (autoriteFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour un centre VHU.
         * @param {string} idCorrelation ID du centre vhu, reprÃ©sentÃ© par un UUID
         * @param {CentreVHURequestDto} centreVHURequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCentreVHUUsingPUT(idCorrelation: string, centreVHURequestDto: CentreVHURequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling updateCentreVHUUsingPUT.');
            }
            // verify required parameter 'centreVHURequestDto' is not null or undefined
            if (centreVHURequestDto === null || centreVHURequestDto === undefined) {
                throw new RequiredError('centreVHURequestDto','Required parameter centreVHURequestDto was null or undefined when calling updateCentreVHUUsingPUT.');
            }
            const localVarPath = `/api/referentiel/centre-vhu/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CentreVHURequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(centreVHURequestDto || {}) : (centreVHURequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de modifier le classement d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateClassementRequestDto} updateClassementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClassementUsingPOST1(id: string, updateClassementRequestDto: UpdateClassementRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateClassementUsingPOST1.');
            }
            // verify required parameter 'updateClassementRequestDto' is not null or undefined
            if (updateClassementRequestDto === null || updateClassementRequestDto === undefined) {
                throw new RequiredError('updateClassementRequestDto','Required parameter updateClassementRequestDto was null or undefined when calling updateClassementUsingPOST1.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/classement`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateClassementRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateClassementRequestDto || {}) : (updateClassementRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de modifier les les donnÃ©es du vÃ©hicule d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateDonneesVehiculeRequestDto} updateDonneesVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDonneesVehiculeUsingPOST(id: string, updateDonneesVehiculeRequestDto: UpdateDonneesVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateDonneesVehiculeUsingPOST.');
            }
            // verify required parameter 'updateDonneesVehiculeRequestDto' is not null or undefined
            if (updateDonneesVehiculeRequestDto === null || updateDonneesVehiculeRequestDto === undefined) {
                throw new RequiredError('updateDonneesVehiculeRequestDto','Required parameter updateDonneesVehiculeRequestDto was null or undefined when calling updateDonneesVehiculeUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/vehicule`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateDonneesVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateDonneesVehiculeRequestDto || {}) : (updateDonneesVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de modifier les entitÃ©es de rattachement d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntitesRattachementUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateEntitesRattachementUsingPOST.');
            }
            // verify required parameter 'updateEntitesRattachementRequestDto' is not null or undefined
            if (updateEntitesRattachementRequestDto === null || updateEntitesRattachementRequestDto === undefined) {
                throw new RequiredError('updateEntitesRattachementRequestDto','Required parameter updateEntitesRattachementRequestDto was null or undefined when calling updateEntitesRattachementUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/entites-rattachement`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateEntitesRattachementRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateEntitesRattachementRequestDto || {}) : (updateEntitesRattachementRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour un domain faq.
         * @param {string} idCorrelation ID du domaine faq, reprÃ©sentÃ© par un UUID
         * @param {FaqDomainRequestDto} faqDomainRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaqDomainUsingPUT(idCorrelation: string, faqDomainRequestDto: FaqDomainRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling updateFaqDomainUsingPUT.');
            }
            // verify required parameter 'faqDomainRequestDto' is not null or undefined
            if (faqDomainRequestDto === null || faqDomainRequestDto === undefined) {
                throw new RequiredError('faqDomainRequestDto','Required parameter faqDomainRequestDto was null or undefined when calling updateFaqDomainUsingPUT.');
            }
            const localVarPath = `/api/referentiel/faq-domain/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FaqDomainRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(faqDomainRequestDto || {}) : (faqDomainRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour une question
         * @param {string} idCorrelation ID de la question, reprÃ©sentÃ© par un UUID
         * @param {FaqRequestDto} faqRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaqUsingPUT(idCorrelation: string, faqRequestDto: FaqRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling updateFaqUsingPUT.');
            }
            // verify required parameter 'faqRequestDto' is not null or undefined
            if (faqRequestDto === null || faqRequestDto === undefined) {
                throw new RequiredError('faqRequestDto','Required parameter faqRequestDto was null or undefined when calling updateFaqUsingPUT.');
            }
            const localVarPath = `/api/referentiel/foire-aux-questions/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FaqRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(faqRequestDto || {}) : (faqRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour une fourriÃ¨re.
         * @param {string} idCorrelation ID de la fourrÃ¨re, reprÃ©sentÃ©e par un UUID
         * @param {FourriereRequestDto} fourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFourriereUsingPUT(idCorrelation: string, fourriereRequestDto: FourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling updateFourriereUsingPUT.');
            }
            // verify required parameter 'fourriereRequestDto' is not null or undefined
            if (fourriereRequestDto === null || fourriereRequestDto === undefined) {
                throw new RequiredError('fourriereRequestDto','Required parameter fourriereRequestDto was null or undefined when calling updateFourriereUsingPUT.');
            }
            const localVarPath = `/api/referentiel/fourriere/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fourriereRequestDto || {}) : (fourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de renseigner manuellement les donnÃ©es siv d\'un vÃ©hicule
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {InfosSivRequestDto} infosSivRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInfosSivManuelUsingPOST(id: string, infosSivRequestDto: InfosSivRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateInfosSivManuelUsingPOST.');
            }
            // verify required parameter 'infosSivRequestDto' is not null or undefined
            if (infosSivRequestDto === null || infosSivRequestDto === undefined) {
                throw new RequiredError('infosSivRequestDto','Required parameter infosSivRequestDto was null or undefined when calling updateInfosSivManuelUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/infos-siv`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InfosSivRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(infosSivRequestDto || {}) : (infosSivRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de modifier la catÃ©gorie d\'une marque.
         * @param {string} idCorrelation 
         * @param {MarqueRequestDto} marqueRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarqueUsingPOST(idCorrelation: string, marqueRequestDto: MarqueRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling updateMarqueUsingPOST.');
            }
            // verify required parameter 'marqueRequestDto' is not null or undefined
            if (marqueRequestDto === null || marqueRequestDto === undefined) {
                throw new RequiredError('marqueRequestDto','Required parameter marqueRequestDto was null or undefined when calling updateMarqueUsingPOST.');
            }
            const localVarPath = `/api/referentiel/marque/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MarqueRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(marqueRequestDto || {}) : (marqueRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour un motif de MEF.
         * @param {string} idCorrelation ID du motif de MEF, reprÃ©sentÃ© par un UUID
         * @param {MotifMefRequestDto} motifMefRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMotifMefUsingPUT(idCorrelation: string, motifMefRequestDto: MotifMefRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling updateMotifMefUsingPUT.');
            }
            // verify required parameter 'motifMefRequestDto' is not null or undefined
            if (motifMefRequestDto === null || motifMefRequestDto === undefined) {
                throw new RequiredError('motifMefRequestDto','Required parameter motifMefRequestDto was null or undefined when calling updateMotifMefUsingPUT.');
            }
            const localVarPath = `/api/referentiel/motif-mef/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MotifMefRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(motifMefRequestDto || {}) : (motifMefRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de modifier un motif de sortie.
         * @param {string} idCorrelation Identifiant de correlation du motif
         * @param {MotifSortieRequestDto} motifSortieRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMotifSortieUsingPUT(idCorrelation: string, motifSortieRequestDto: MotifSortieRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling updateMotifSortieUsingPUT.');
            }
            // verify required parameter 'motifSortieRequestDto' is not null or undefined
            if (motifSortieRequestDto === null || motifSortieRequestDto === undefined) {
                throw new RequiredError('motifSortieRequestDto','Required parameter motifSortieRequestDto was null or undefined when calling updateMotifSortieUsingPUT.');
            }
            const localVarPath = `/api/referentiel/motif-sortie/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MotifSortieRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(motifSortieRequestDto || {}) : (motifSortieRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de modifier les donnÃ©es de sortie d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateSortieVehiculeRequestDto} updateSortieVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSortieVehiculeUsingPOST(id: string, updateSortieVehiculeRequestDto: UpdateSortieVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateSortieVehiculeUsingPOST.');
            }
            // verify required parameter 'updateSortieVehiculeRequestDto' is not null or undefined
            if (updateSortieVehiculeRequestDto === null || updateSortieVehiculeRequestDto === undefined) {
                throw new RequiredError('updateSortieVehiculeRequestDto','Required parameter updateSortieVehiculeRequestDto was null or undefined when calling updateSortieVehiculeUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/update-sortie`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateSortieVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateSortieVehiculeRequestDto || {}) : (updateSortieVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de modifier un type d\'AF.
         * @param {string} idCorrelation Identifiant de correlation du type
         * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTypeAfUsingPUT(idCorrelation: string, typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling updateTypeAfUsingPUT.');
            }
            // verify required parameter 'typeAutoriteFourriereRequestDto' is not null or undefined
            if (typeAutoriteFourriereRequestDto === null || typeAutoriteFourriereRequestDto === undefined) {
                throw new RequiredError('typeAutoriteFourriereRequestDto','Required parameter typeAutoriteFourriereRequestDto was null or undefined when calling updateTypeAfUsingPUT.');
            }
            const localVarPath = `/api/referentiel/type-autorite-fourriere/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TypeAutoriteFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(typeAutoriteFourriereRequestDto || {}) : (typeAutoriteFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour une unitÃ© de force de l\'ordre.
         * @param {string} idCorrelation ID de l\'unitÃ© force de l\'ordre, reprÃ©sentÃ© par un UUID
         * @param {UniteFoRequestDto} uniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUniteFoUsingPUT(idCorrelation: string, uniteFoRequestDto: UniteFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling updateUniteFoUsingPUT.');
            }
            // verify required parameter 'uniteFoRequestDto' is not null or undefined
            if (uniteFoRequestDto === null || uniteFoRequestDto === undefined) {
                throw new RequiredError('uniteFoRequestDto','Required parameter uniteFoRequestDto was null or undefined when calling updateUniteFoUsingPUT.');
            }
            const localVarPath = `/api/referentiel/unite-fo/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UniteFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(uniteFoRequestDto || {}) : (uniteFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour un utilisateur
         * @param {string} userId Id du compte Ã  mettre Ã  jour
         * @param {UpdateUserRequestDto} updateUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsingPUT(userId: string, updateUserRequestDto: UpdateUserRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateUsingPUT.');
            }
            // verify required parameter 'updateUserRequestDto' is not null or undefined
            if (updateUserRequestDto === null || updateUserRequestDto === undefined) {
                throw new RequiredError('updateUserRequestDto','Required parameter updateUserRequestDto was null or undefined when calling updateUsingPUT.');
            }
            const localVarPath = `/api/comptes-utilisateurs/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateUserRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateUserRequestDto || {}) : (updateUserRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'une autoritÃ© fourriÃ¨re.
         * @param {'NUMERO_DEPARTEMENT' | 'LIBELLE_DEPARTEMENT' | 'TYPE' | 'LIBELLE' | 'CODE_POSTAL' | 'VILLE' | 'VOIE' | 'ID_HERMES' | 'ID_HERMES_UTILISATEUR'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateAutoriteFourriereFieldUsingPOST(field: 'NUMERO_DEPARTEMENT' | 'LIBELLE_DEPARTEMENT' | 'TYPE' | 'LIBELLE' | 'CODE_POSTAL' | 'VILLE' | 'VOIE' | 'ID_HERMES' | 'ID_HERMES_UTILISATEUR', autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateCreateAutoriteFourriereFieldUsingPOST.');
            }
            // verify required parameter 'autoriteFourriereRequestDto' is not null or undefined
            if (autoriteFourriereRequestDto === null || autoriteFourriereRequestDto === undefined) {
                throw new RequiredError('autoriteFourriereRequestDto','Required parameter autoriteFourriereRequestDto was null or undefined when calling validateCreateAutoriteFourriereFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/autorite-fourriere/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AutoriteFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(autoriteFourriereRequestDto || {}) : (autoriteFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'une autoritÃ© de fourriÃ¨re.
         * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateAutoriteFourriereUsingPOST(autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'autoriteFourriereRequestDto' is not null or undefined
            if (autoriteFourriereRequestDto === null || autoriteFourriereRequestDto === undefined) {
                throw new RequiredError('autoriteFourriereRequestDto','Required parameter autoriteFourriereRequestDto was null or undefined when calling validateCreateAutoriteFourriereUsingPOST.');
            }
            const localVarPath = `/api/referentiel/autorite-fourriere/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AutoriteFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(autoriteFourriereRequestDto || {}) : (autoriteFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'un centre vhu.
         * @param {'RAISON_SOCIALE' | 'SIREN' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE' | 'EMAIL' | 'NUMERO_AGREMENT_VHU' | 'PREFECTURE_DELIVRANTE'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {CentreVHURequestDto} centreVHURequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateCentreVHUFieldUsingPOST(field: 'RAISON_SOCIALE' | 'SIREN' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE' | 'EMAIL' | 'NUMERO_AGREMENT_VHU' | 'PREFECTURE_DELIVRANTE', centreVHURequestDto: CentreVHURequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateCreateCentreVHUFieldUsingPOST.');
            }
            // verify required parameter 'centreVHURequestDto' is not null or undefined
            if (centreVHURequestDto === null || centreVHURequestDto === undefined) {
                throw new RequiredError('centreVHURequestDto','Required parameter centreVHURequestDto was null or undefined when calling validateCreateCentreVHUFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/centre-vhu/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CentreVHURequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(centreVHURequestDto || {}) : (centreVHURequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'un centre VHU
         * @param {CentreVHURequestDto} centreVHURequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateCentreVHUUsingPOST(centreVHURequestDto: CentreVHURequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'centreVHURequestDto' is not null or undefined
            if (centreVHURequestDto === null || centreVHURequestDto === undefined) {
                throw new RequiredError('centreVHURequestDto','Required parameter centreVHURequestDto was null or undefined when calling validateCreateCentreVHUUsingPOST.');
            }
            const localVarPath = `/api/referentiel/centre-vhu/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CentreVHURequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(centreVHURequestDto || {}) : (centreVHURequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route validant le champ spÃ©cifiÃ© dans le formulaire de crÃ©ation d\'un compte.
         * @param {'PRENOM' | 'NOM' | 'EMAIL' | 'ENTITE_RATTACHEMENT' | 'PROFILE' | 'ACTIF'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {CreateUserRequestDto} createUserRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateFieldUsingPOST(field: 'PRENOM' | 'NOM' | 'EMAIL' | 'ENTITE_RATTACHEMENT' | 'PROFILE' | 'ACTIF', createUserRequestDto: CreateUserRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateCreateFieldUsingPOST.');
            }
            // verify required parameter 'createUserRequestDto' is not null or undefined
            if (createUserRequestDto === null || createUserRequestDto === undefined) {
                throw new RequiredError('createUserRequestDto','Required parameter createUserRequestDto was null or undefined when calling validateCreateFieldUsingPOST.');
            }
            const localVarPath = `/api/comptes-utilisateurs/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateUserRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(createUserRequestDto || {}) : (createUserRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'une fourriÃ¨re.
         * @param {'RAISON_SOCIALE' | 'RAISON_SOCIALE_DNID' | 'AF_RATTACHEMENT' | 'CODE_POSTAL' | 'ADRESSE' | 'NUMERO_VOIE' | 'REPETITION' | 'COMMUNE' | 'TELEPHONE_PORTABLE' | 'TELEPHONE_FIXE' | 'EMAIL' | 'EMAIL_RESPONSABLE' | 'ID_HERMES'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {FourriereRequestDto} fourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateFourriereFieldUsingPOST(field: 'RAISON_SOCIALE' | 'RAISON_SOCIALE_DNID' | 'AF_RATTACHEMENT' | 'CODE_POSTAL' | 'ADRESSE' | 'NUMERO_VOIE' | 'REPETITION' | 'COMMUNE' | 'TELEPHONE_PORTABLE' | 'TELEPHONE_FIXE' | 'EMAIL' | 'EMAIL_RESPONSABLE' | 'ID_HERMES', fourriereRequestDto: FourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateCreateFourriereFieldUsingPOST.');
            }
            // verify required parameter 'fourriereRequestDto' is not null or undefined
            if (fourriereRequestDto === null || fourriereRequestDto === undefined) {
                throw new RequiredError('fourriereRequestDto','Required parameter fourriereRequestDto was null or undefined when calling validateCreateFourriereFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/fourriere/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fourriereRequestDto || {}) : (fourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'une fourriÃ¨re.
         * @param {FourriereRequestDto} fourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateFourriereUsingPOST(fourriereRequestDto: FourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'fourriereRequestDto' is not null or undefined
            if (fourriereRequestDto === null || fourriereRequestDto === undefined) {
                throw new RequiredError('fourriereRequestDto','Required parameter fourriereRequestDto was null or undefined when calling validateCreateFourriereUsingPOST.');
            }
            const localVarPath = `/api/referentiel/fourriere/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fourriereRequestDto || {}) : (fourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'un motif de MEF.
         * @param {'MOTIF_MEF' | 'BRANCHE' | 'NATURE' | 'DICEM' | 'RODEO'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {MotifMefRequestDto} motifMefRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateMotifMefFieldUsingPOST(field: 'MOTIF_MEF' | 'BRANCHE' | 'NATURE' | 'DICEM' | 'RODEO', motifMefRequestDto: MotifMefRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateCreateMotifMefFieldUsingPOST.');
            }
            // verify required parameter 'motifMefRequestDto' is not null or undefined
            if (motifMefRequestDto === null || motifMefRequestDto === undefined) {
                throw new RequiredError('motifMefRequestDto','Required parameter motifMefRequestDto was null or undefined when calling validateCreateMotifMefFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/motif-mef/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MotifMefRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(motifMefRequestDto || {}) : (motifMefRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'un motif de MEF.
         * @param {MotifMefRequestDto} motifMefRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateMotifMefUsingPOST(motifMefRequestDto: MotifMefRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'motifMefRequestDto' is not null or undefined
            if (motifMefRequestDto === null || motifMefRequestDto === undefined) {
                throw new RequiredError('motifMefRequestDto','Required parameter motifMefRequestDto was null or undefined when calling validateCreateMotifMefUsingPOST.');
            }
            const localVarPath = `/api/referentiel/motif-mef/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MotifMefRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(motifMefRequestDto || {}) : (motifMefRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'un motif de sortie.
         * @param {'MOTIF' | 'COMMENTAIRE' | 'DELAI_EFFACEMENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {MotifSortieRequestDto} motifSortieRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateMotifSortieFieldUsingPOST(field: 'MOTIF' | 'COMMENTAIRE' | 'DELAI_EFFACEMENT', motifSortieRequestDto: MotifSortieRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateCreateMotifSortieFieldUsingPOST.');
            }
            // verify required parameter 'motifSortieRequestDto' is not null or undefined
            if (motifSortieRequestDto === null || motifSortieRequestDto === undefined) {
                throw new RequiredError('motifSortieRequestDto','Required parameter motifSortieRequestDto was null or undefined when calling validateCreateMotifSortieFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/motif-sortie/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MotifSortieRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(motifSortieRequestDto || {}) : (motifSortieRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'un motif de sortie.
         * @param {MotifSortieRequestDto} motifSortieRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateMotifSortieUsingPOST(motifSortieRequestDto: MotifSortieRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'motifSortieRequestDto' is not null or undefined
            if (motifSortieRequestDto === null || motifSortieRequestDto === undefined) {
                throw new RequiredError('motifSortieRequestDto','Required parameter motifSortieRequestDto was null or undefined when calling validateCreateMotifSortieUsingPOST.');
            }
            const localVarPath = `/api/referentiel/motif-sortie/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MotifSortieRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(motifSortieRequestDto || {}) : (motifSortieRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'un type d\'AF.
         * @param {'TYPE' | 'LIBELLE_NOTIFICATION' | 'CODE_TYPOLOGIE_NIVEAU_1' | 'CODE_TYPOLOGIE_NIVEAU_2'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateTypeAFFieldUsingPOST(field: 'TYPE' | 'LIBELLE_NOTIFICATION' | 'CODE_TYPOLOGIE_NIVEAU_1' | 'CODE_TYPOLOGIE_NIVEAU_2', typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateCreateTypeAFFieldUsingPOST.');
            }
            // verify required parameter 'typeAutoriteFourriereRequestDto' is not null or undefined
            if (typeAutoriteFourriereRequestDto === null || typeAutoriteFourriereRequestDto === undefined) {
                throw new RequiredError('typeAutoriteFourriereRequestDto','Required parameter typeAutoriteFourriereRequestDto was null or undefined when calling validateCreateTypeAFFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/type-autorite-fourriere/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TypeAutoriteFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(typeAutoriteFourriereRequestDto || {}) : (typeAutoriteFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'un type d\'AF.
         * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateTypeAfUsingPOST(typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'typeAutoriteFourriereRequestDto' is not null or undefined
            if (typeAutoriteFourriereRequestDto === null || typeAutoriteFourriereRequestDto === undefined) {
                throw new RequiredError('typeAutoriteFourriereRequestDto','Required parameter typeAutoriteFourriereRequestDto was null or undefined when calling validateCreateTypeAfUsingPOST.');
            }
            const localVarPath = `/api/referentiel/type-autorite-fourriere/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TypeAutoriteFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(typeAutoriteFourriereRequestDto || {}) : (typeAutoriteFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'une unitÃ© FO.
         * @param {'TYPE' | 'CODE' | 'UNITE' | 'ABREVIATION' | 'CODE_POSTAL' | 'EMAIL' | 'TELEPHONE' | 'CODE_INSEE' | 'PARENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {UniteFoRequestDto} uniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateUniteFoFieldUsingPOST(field: 'TYPE' | 'CODE' | 'UNITE' | 'ABREVIATION' | 'CODE_POSTAL' | 'EMAIL' | 'TELEPHONE' | 'CODE_INSEE' | 'PARENT', uniteFoRequestDto: UniteFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateCreateUniteFoFieldUsingPOST.');
            }
            // verify required parameter 'uniteFoRequestDto' is not null or undefined
            if (uniteFoRequestDto === null || uniteFoRequestDto === undefined) {
                throw new RequiredError('uniteFoRequestDto','Required parameter uniteFoRequestDto was null or undefined when calling validateCreateUniteFoFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/unite-fo/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UniteFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(uniteFoRequestDto || {}) : (uniteFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'une unite FO.
         * @param {UniteFoRequestDto} uniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateUniteFoUsingPOST(uniteFoRequestDto: UniteFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'uniteFoRequestDto' is not null or undefined
            if (uniteFoRequestDto === null || uniteFoRequestDto === undefined) {
                throw new RequiredError('uniteFoRequestDto','Required parameter uniteFoRequestDto was null or undefined when calling validateCreateUniteFoUsingPOST.');
            }
            const localVarPath = `/api/referentiel/unite-fo/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UniteFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(uniteFoRequestDto || {}) : (uniteFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route validant tous les champs du formulaire de crÃ©ation d\'un utilisateur
         * @param {CreateUserRequestDto} createUserRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateUsingPOST(createUserRequestDto: CreateUserRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'createUserRequestDto' is not null or undefined
            if (createUserRequestDto === null || createUserRequestDto === undefined) {
                throw new RequiredError('createUserRequestDto','Required parameter createUserRequestDto was null or undefined when calling validateCreateUsingPOST.');
            }
            const localVarPath = `/api/comptes-utilisateurs/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateUserRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(createUserRequestDto || {}) : (createUserRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'un domaine faq.
         * @param {'NAME'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {FaqDomainRequestDto} faqDomainRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFaqDomainFieldUsingPOST(field: 'NAME', faqDomainRequestDto: FaqDomainRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateFaqDomainFieldUsingPOST.');
            }
            // verify required parameter 'faqDomainRequestDto' is not null or undefined
            if (faqDomainRequestDto === null || faqDomainRequestDto === undefined) {
                throw new RequiredError('faqDomainRequestDto','Required parameter faqDomainRequestDto was null or undefined when calling validateFaqDomainFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/faq-domain/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FaqDomainRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(faqDomainRequestDto || {}) : (faqDomainRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'un domaine faq
         * @param {FaqDomainRequestDto} faqDomainRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFaqDomainUsingPOST(faqDomainRequestDto: FaqDomainRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'faqDomainRequestDto' is not null or undefined
            if (faqDomainRequestDto === null || faqDomainRequestDto === undefined) {
                throw new RequiredError('faqDomainRequestDto','Required parameter faqDomainRequestDto was null or undefined when calling validateFaqDomainUsingPOST.');
            }
            const localVarPath = `/api/referentiel/faq-domain/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FaqDomainRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(faqDomainRequestDto || {}) : (faqDomainRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'une question
         * @param {'DOMAIN_ID' | 'TITLE' | 'PROFILES' | 'CONTENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {FaqRequestDto} faqRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFaqFieldUsingPOST(field: 'DOMAIN_ID' | 'TITLE' | 'PROFILES' | 'CONTENT', faqRequestDto: FaqRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateFaqFieldUsingPOST.');
            }
            // verify required parameter 'faqRequestDto' is not null or undefined
            if (faqRequestDto === null || faqRequestDto === undefined) {
                throw new RequiredError('faqRequestDto','Required parameter faqRequestDto was null or undefined when calling validateFaqFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/foire-aux-questions/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FaqRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(faqRequestDto || {}) : (faqRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'une question
         * @param {FaqRequestDto} faqRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFaqUsingPOST(faqRequestDto: FaqRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'faqRequestDto' is not null or undefined
            if (faqRequestDto === null || faqRequestDto === undefined) {
                throw new RequiredError('faqRequestDto','Required parameter faqRequestDto was null or undefined when calling validateFaqUsingPOST.');
            }
            const localVarPath = `/api/referentiel/foire-aux-questions/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FaqRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(faqRequestDto || {}) : (faqRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les modifications du classement d\'un dossier
         * @param {'JUSTIFICATION' | 'NEW_CLASSEMENT'} field 
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateClassementRequestDto} updateClassementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFieldUpdateClassementUsingPOST(field: 'JUSTIFICATION' | 'NEW_CLASSEMENT', id: string, updateClassementRequestDto: UpdateClassementRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateFieldUpdateClassementUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateFieldUpdateClassementUsingPOST.');
            }
            // verify required parameter 'updateClassementRequestDto' is not null or undefined
            if (updateClassementRequestDto === null || updateClassementRequestDto === undefined) {
                throw new RequiredError('updateClassementRequestDto','Required parameter updateClassementRequestDto was null or undefined when calling validateFieldUpdateClassementUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/classement/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateClassementRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateClassementRequestDto || {}) : (updateClassementRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les modifications de la notification manuelle d\'un dossier
         * @param {'NEW_DATE_ENVOI' | 'NEW_DATE_SIGNATURE' | 'NEW_ETAT_DISTRIBUTION' | 'JUSTIFICATION'} field 
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {FixDataNotificationManuelleRequestDto} fixDataNotificationManuelleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFixDataNotificationManuelleFieldUsingPOST(field: 'NEW_DATE_ENVOI' | 'NEW_DATE_SIGNATURE' | 'NEW_ETAT_DISTRIBUTION' | 'JUSTIFICATION', id: string, fixDataNotificationManuelleRequestDto: FixDataNotificationManuelleRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateFixDataNotificationManuelleFieldUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateFixDataNotificationManuelleFieldUsingPOST.');
            }
            // verify required parameter 'fixDataNotificationManuelleRequestDto' is not null or undefined
            if (fixDataNotificationManuelleRequestDto === null || fixDataNotificationManuelleRequestDto === undefined) {
                throw new RequiredError('fixDataNotificationManuelleRequestDto','Required parameter fixDataNotificationManuelleRequestDto was null or undefined when calling validateFixDataNotificationManuelleFieldUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/notification-manuelle/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FixDataNotificationManuelleRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fixDataNotificationManuelleRequestDto || {}) : (fixDataNotificationManuelleRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les modifications de la notification manuelle d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {FixDataNotificationManuelleRequestDto} fixDataNotificationManuelleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFixDataNotificationManuelleUsingPOST(id: string, fixDataNotificationManuelleRequestDto: FixDataNotificationManuelleRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateFixDataNotificationManuelleUsingPOST.');
            }
            // verify required parameter 'fixDataNotificationManuelleRequestDto' is not null or undefined
            if (fixDataNotificationManuelleRequestDto === null || fixDataNotificationManuelleRequestDto === undefined) {
                throw new RequiredError('fixDataNotificationManuelleRequestDto','Required parameter fixDataNotificationManuelleRequestDto was null or undefined when calling validateFixDataNotificationManuelleUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/notification-manuelle/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FixDataNotificationManuelleRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fixDataNotificationManuelleRequestDto || {}) : (fixDataNotificationManuelleRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les modifications d\'un champ de la migration d\'unitÃ© FO
         * @param {'NEW_UNITE_FO_CORRELATION_ID' | 'OLD_UNITE_FO_CORRELATION_ID' | 'COMMENT'} field 
         * @param {MigrateUniteFoRequestDto} migrateUniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateMigrateUniteFoFieldUsingPOST(field: 'NEW_UNITE_FO_CORRELATION_ID' | 'OLD_UNITE_FO_CORRELATION_ID' | 'COMMENT', migrateUniteFoRequestDto: MigrateUniteFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateMigrateUniteFoFieldUsingPOST.');
            }
            // verify required parameter 'migrateUniteFoRequestDto' is not null or undefined
            if (migrateUniteFoRequestDto === null || migrateUniteFoRequestDto === undefined) {
                throw new RequiredError('migrateUniteFoRequestDto','Required parameter migrateUniteFoRequestDto was null or undefined when calling validateMigrateUniteFoFieldUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/migrate-unite-fo/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MigrateUniteFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(migrateUniteFoRequestDto || {}) : (migrateUniteFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les modifications de la migration d\'unitÃ© FO
         * @param {MigrateUniteFoRequestDto} migrateUniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateMigrateUniteFoUsingPOST(migrateUniteFoRequestDto: MigrateUniteFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'migrateUniteFoRequestDto' is not null or undefined
            if (migrateUniteFoRequestDto === null || migrateUniteFoRequestDto === undefined) {
                throw new RequiredError('migrateUniteFoRequestDto','Required parameter migrateUniteFoRequestDto was null or undefined when calling validateMigrateUniteFoUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/migrate-unite-fo/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MigrateUniteFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(migrateUniteFoRequestDto || {}) : (migrateUniteFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'une autoritÃ© fourriÃ¨re.
         * @param {'NUMERO_DEPARTEMENT' | 'LIBELLE_DEPARTEMENT' | 'TYPE' | 'LIBELLE' | 'CODE_POSTAL' | 'VILLE' | 'VOIE' | 'ID_HERMES' | 'ID_HERMES_UTILISATEUR'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation ID de l\'autorite de fourriÃ¨re, reprÃ©sentÃ© par un UUID
         * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateAutoriteFourriereFieldUsingPOST(field: 'NUMERO_DEPARTEMENT' | 'LIBELLE_DEPARTEMENT' | 'TYPE' | 'LIBELLE' | 'CODE_POSTAL' | 'VILLE' | 'VOIE' | 'ID_HERMES' | 'ID_HERMES_UTILISATEUR', idCorrelation: string, autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUpdateAutoriteFourriereFieldUsingPOST.');
            }
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateAutoriteFourriereFieldUsingPOST.');
            }
            // verify required parameter 'autoriteFourriereRequestDto' is not null or undefined
            if (autoriteFourriereRequestDto === null || autoriteFourriereRequestDto === undefined) {
                throw new RequiredError('autoriteFourriereRequestDto','Required parameter autoriteFourriereRequestDto was null or undefined when calling validateUpdateAutoriteFourriereFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/autorite-fourriere/update/{idCorrelation}/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AutoriteFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(autoriteFourriereRequestDto || {}) : (autoriteFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'une autoritÃ© de fourriÃ¨re.
         * @param {string} idCorrelation ID de l\'autorite de fourriÃ¨re, reprÃ©sentÃ© par un UUID
         * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateAutoriteFourriereUsingPOST(idCorrelation: string, autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateAutoriteFourriereUsingPOST.');
            }
            // verify required parameter 'autoriteFourriereRequestDto' is not null or undefined
            if (autoriteFourriereRequestDto === null || autoriteFourriereRequestDto === undefined) {
                throw new RequiredError('autoriteFourriereRequestDto','Required parameter autoriteFourriereRequestDto was null or undefined when calling validateUpdateAutoriteFourriereUsingPOST.');
            }
            const localVarPath = `/api/referentiel/autorite-fourriere/update/{idCorrelation}/validation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AutoriteFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(autoriteFourriereRequestDto || {}) : (autoriteFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'un centre vhu.
         * @param {'RAISON_SOCIALE' | 'SIREN' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE' | 'EMAIL' | 'NUMERO_AGREMENT_VHU' | 'PREFECTURE_DELIVRANTE'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation Id de corrÃ©lation du centre vhu Ã  mettre Ã  jour
         * @param {CentreVHURequestDto} centreVHURequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateCentreVHUFieldUsingPOST(field: 'RAISON_SOCIALE' | 'SIREN' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE' | 'EMAIL' | 'NUMERO_AGREMENT_VHU' | 'PREFECTURE_DELIVRANTE', idCorrelation: string, centreVHURequestDto: CentreVHURequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUpdateCentreVHUFieldUsingPOST.');
            }
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateCentreVHUFieldUsingPOST.');
            }
            // verify required parameter 'centreVHURequestDto' is not null or undefined
            if (centreVHURequestDto === null || centreVHURequestDto === undefined) {
                throw new RequiredError('centreVHURequestDto','Required parameter centreVHURequestDto was null or undefined when calling validateUpdateCentreVHUFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/centre-vhu/{idCorrelation}/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CentreVHURequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(centreVHURequestDto || {}) : (centreVHURequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'un centre vhu.
         * @param {string} idCorrelation Id de corrÃ©lation du centre vhu Ã  mettre Ã  jour
         * @param {CentreVHURequestDto} centreVHURequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateCentreVHUUsingPOST(idCorrelation: string, centreVHURequestDto: CentreVHURequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateCentreVHUUsingPOST.');
            }
            // verify required parameter 'centreVHURequestDto' is not null or undefined
            if (centreVHURequestDto === null || centreVHURequestDto === undefined) {
                throw new RequiredError('centreVHURequestDto','Required parameter centreVHURequestDto was null or undefined when calling validateUpdateCentreVHUUsingPOST.');
            }
            const localVarPath = `/api/referentiel/centre-vhu/{idCorrelation}/validation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CentreVHURequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(centreVHURequestDto || {}) : (centreVHURequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les modifications du classement d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateClassementRequestDto} updateClassementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateClassementUsingPOST(id: string, updateClassementRequestDto: UpdateClassementRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateUpdateClassementUsingPOST.');
            }
            // verify required parameter 'updateClassementRequestDto' is not null or undefined
            if (updateClassementRequestDto === null || updateClassementRequestDto === undefined) {
                throw new RequiredError('updateClassementRequestDto','Required parameter updateClassementRequestDto was null or undefined when calling validateUpdateClassementUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/classement/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateClassementRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateClassementRequestDto || {}) : (updateClassementRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les modifications des donnÃ©es du vÃ©hicule d\'un dossier
         * @param {'NEW_MOTIF_ENTREE_ID_CORRELATION' | 'NEW_IS_PAYS_ETRANGER' | 'NEW_IS_SANS_PLAQUE' | 'NEW_PLAQUE_IMMATRICULATION' | 'NEW_ETAT_ID_CORRELATION' | 'COMMENT'} field 
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateDonneesVehiculeRequestDto} updateDonneesVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateDonneesVehiculeFieldUsingPOST(field: 'NEW_MOTIF_ENTREE_ID_CORRELATION' | 'NEW_IS_PAYS_ETRANGER' | 'NEW_IS_SANS_PLAQUE' | 'NEW_PLAQUE_IMMATRICULATION' | 'NEW_ETAT_ID_CORRELATION' | 'COMMENT', id: string, updateDonneesVehiculeRequestDto: UpdateDonneesVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUpdateDonneesVehiculeFieldUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateUpdateDonneesVehiculeFieldUsingPOST.');
            }
            // verify required parameter 'updateDonneesVehiculeRequestDto' is not null or undefined
            if (updateDonneesVehiculeRequestDto === null || updateDonneesVehiculeRequestDto === undefined) {
                throw new RequiredError('updateDonneesVehiculeRequestDto','Required parameter updateDonneesVehiculeRequestDto was null or undefined when calling validateUpdateDonneesVehiculeFieldUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/vehicule/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateDonneesVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateDonneesVehiculeRequestDto || {}) : (updateDonneesVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les modifications des donnÃ©es d\'un vÃ©hicule et de l\'infraction d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateDonneesVehiculeRequestDto} updateDonneesVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateDonneesVehiculeUsingPOST(id: string, updateDonneesVehiculeRequestDto: UpdateDonneesVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateUpdateDonneesVehiculeUsingPOST.');
            }
            // verify required parameter 'updateDonneesVehiculeRequestDto' is not null or undefined
            if (updateDonneesVehiculeRequestDto === null || updateDonneesVehiculeRequestDto === undefined) {
                throw new RequiredError('updateDonneesVehiculeRequestDto','Required parameter updateDonneesVehiculeRequestDto was null or undefined when calling validateUpdateDonneesVehiculeUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/vehicule/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateDonneesVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateDonneesVehiculeRequestDto || {}) : (updateDonneesVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les modifications des entitÃ©es de rattachement d\'un dossier
         * @param {'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT'} field 
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateEntitesRattachementFieldUsingPOST(field: 'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT', id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUpdateEntitesRattachementFieldUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateUpdateEntitesRattachementFieldUsingPOST.');
            }
            // verify required parameter 'updateEntitesRattachementRequestDto' is not null or undefined
            if (updateEntitesRattachementRequestDto === null || updateEntitesRattachementRequestDto === undefined) {
                throw new RequiredError('updateEntitesRattachementRequestDto','Required parameter updateEntitesRattachementRequestDto was null or undefined when calling validateUpdateEntitesRattachementFieldUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/entites-rattachement/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateEntitesRattachementRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateEntitesRattachementRequestDto || {}) : (updateEntitesRattachementRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les modifications des entitÃ©es de rattachement d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateEntitesRattachementUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateUpdateEntitesRattachementUsingPOST.');
            }
            // verify required parameter 'updateEntitesRattachementRequestDto' is not null or undefined
            if (updateEntitesRattachementRequestDto === null || updateEntitesRattachementRequestDto === undefined) {
                throw new RequiredError('updateEntitesRattachementRequestDto','Required parameter updateEntitesRattachementRequestDto was null or undefined when calling validateUpdateEntitesRattachementUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/entites-rattachement/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateEntitesRattachementRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateEntitesRattachementRequestDto || {}) : (updateEntitesRattachementRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de modification d\'une question
         * @param {'DOMAIN_ID' | 'TITLE' | 'PROFILES' | 'CONTENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation Id de corrÃ©lation de la question Ã  mettre Ã  jour
         * @param {FaqRequestDto} faqRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateFaqFieldUsingPOST(field: 'DOMAIN_ID' | 'TITLE' | 'PROFILES' | 'CONTENT', idCorrelation: string, faqRequestDto: FaqRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUpdateFaqFieldUsingPOST.');
            }
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateFaqFieldUsingPOST.');
            }
            // verify required parameter 'faqRequestDto' is not null or undefined
            if (faqRequestDto === null || faqRequestDto === undefined) {
                throw new RequiredError('faqRequestDto','Required parameter faqRequestDto was null or undefined when calling validateUpdateFaqFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/foire-aux-questions/{idCorrelation}/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FaqRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(faqRequestDto || {}) : (faqRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de la modification d\'une question
         * @param {string} idCorrelation Id de corrÃ©lation de la question Ã  mettre Ã  jour
         * @param {FaqRequestDto} faqRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateFaqUsingPOST(idCorrelation: string, faqRequestDto: FaqRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateFaqUsingPOST.');
            }
            // verify required parameter 'faqRequestDto' is not null or undefined
            if (faqRequestDto === null || faqRequestDto === undefined) {
                throw new RequiredError('faqRequestDto','Required parameter faqRequestDto was null or undefined when calling validateUpdateFaqUsingPOST.');
            }
            const localVarPath = `/api/referentiel/foire-aux-questions/{idCorrelation}/validation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FaqRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(faqRequestDto || {}) : (faqRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route validant le champ spÃ©cifiÃ© dans le formulaire de mise Ã  jour d\'un compte.
         * @param {'FIRSTNAME' | 'LASTNAME' | 'EMAIL' | 'UNITE_RATTACHEMENT' | 'ACTIF'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} userId ID du compte, reprÃ©sentÃ© par une chaine de caractÃ¨res.
         * @param {UpdateUserRequestDto} updateUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateFieldUsingPOST(field: 'FIRSTNAME' | 'LASTNAME' | 'EMAIL' | 'UNITE_RATTACHEMENT' | 'ACTIF', userId: string, updateUserRequestDto: UpdateUserRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUpdateFieldUsingPOST.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling validateUpdateFieldUsingPOST.');
            }
            // verify required parameter 'updateUserRequestDto' is not null or undefined
            if (updateUserRequestDto === null || updateUserRequestDto === undefined) {
                throw new RequiredError('updateUserRequestDto','Required parameter updateUserRequestDto was null or undefined when calling validateUpdateFieldUsingPOST.');
            }
            const localVarPath = `/api/comptes-utilisateurs/{userId}/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateUserRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateUserRequestDto || {}) : (updateUserRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'une fourriÃ¨re.
         * @param {'RAISON_SOCIALE' | 'RAISON_SOCIALE_DNID' | 'AF_RATTACHEMENT' | 'CODE_POSTAL' | 'ADRESSE' | 'NUMERO_VOIE' | 'REPETITION' | 'COMMUNE' | 'TELEPHONE_PORTABLE' | 'TELEPHONE_FIXE' | 'EMAIL' | 'EMAIL_RESPONSABLE' | 'ID_HERMES'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation Id de corrÃ©lation de la fourriÃ¨re Ã  mettre Ã  jour
         * @param {FourriereRequestDto} fourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateFourriereFieldUsingPOST(field: 'RAISON_SOCIALE' | 'RAISON_SOCIALE_DNID' | 'AF_RATTACHEMENT' | 'CODE_POSTAL' | 'ADRESSE' | 'NUMERO_VOIE' | 'REPETITION' | 'COMMUNE' | 'TELEPHONE_PORTABLE' | 'TELEPHONE_FIXE' | 'EMAIL' | 'EMAIL_RESPONSABLE' | 'ID_HERMES', idCorrelation: string, fourriereRequestDto: FourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUpdateFourriereFieldUsingPOST.');
            }
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateFourriereFieldUsingPOST.');
            }
            // verify required parameter 'fourriereRequestDto' is not null or undefined
            if (fourriereRequestDto === null || fourriereRequestDto === undefined) {
                throw new RequiredError('fourriereRequestDto','Required parameter fourriereRequestDto was null or undefined when calling validateUpdateFourriereFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/fourriere/{idCorrelation}/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fourriereRequestDto || {}) : (fourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'une fourriÃ¨re.
         * @param {string} idCorrelation Id de corrÃ©lation de la fourriÃ¨re Ã  mettre Ã  jour
         * @param {FourriereRequestDto} fourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateFourriereUsingPOST(idCorrelation: string, fourriereRequestDto: FourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateFourriereUsingPOST.');
            }
            // verify required parameter 'fourriereRequestDto' is not null or undefined
            if (fourriereRequestDto === null || fourriereRequestDto === undefined) {
                throw new RequiredError('fourriereRequestDto','Required parameter fourriereRequestDto was null or undefined when calling validateUpdateFourriereUsingPOST.');
            }
            const localVarPath = `/api/referentiel/fourriere/{idCorrelation}/validation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fourriereRequestDto || {}) : (fourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'une marque.
         * @param {'CATEGORIE' | 'TYPE' | 'MARQUE_EXACTE'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation Id de corrÃ©lation de la marque Ã  mettre Ã  jour
         * @param {MarqueRequestDto} marqueRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateMarqueFieldUsingPOST(field: 'CATEGORIE' | 'TYPE' | 'MARQUE_EXACTE', idCorrelation: string, marqueRequestDto: MarqueRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUpdateMarqueFieldUsingPOST.');
            }
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateMarqueFieldUsingPOST.');
            }
            // verify required parameter 'marqueRequestDto' is not null or undefined
            if (marqueRequestDto === null || marqueRequestDto === undefined) {
                throw new RequiredError('marqueRequestDto','Required parameter marqueRequestDto was null or undefined when calling validateUpdateMarqueFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/marque/{idCorrelation}/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MarqueRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(marqueRequestDto || {}) : (marqueRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'une marque.
         * @param {string} idCorrelation Id de corrÃ©lation de la marque Ã  mettre Ã  jour
         * @param {MarqueRequestDto} marqueRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateMarqueUsingPOST(idCorrelation: string, marqueRequestDto: MarqueRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateMarqueUsingPOST.');
            }
            // verify required parameter 'marqueRequestDto' is not null or undefined
            if (marqueRequestDto === null || marqueRequestDto === undefined) {
                throw new RequiredError('marqueRequestDto','Required parameter marqueRequestDto was null or undefined when calling validateUpdateMarqueUsingPOST.');
            }
            const localVarPath = `/api/referentiel/marque/{idCorrelation}/validation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MarqueRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(marqueRequestDto || {}) : (marqueRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'un motif de mef.
         * @param {'MOTIF_MEF' | 'BRANCHE' | 'NATURE' | 'DICEM' | 'RODEO'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation Id de corrÃ©lation du motif de MEF Ã  mettre Ã  jour
         * @param {MotifMefRequestDto} motifMefRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateMotifMefFieldUsingPOST(field: 'MOTIF_MEF' | 'BRANCHE' | 'NATURE' | 'DICEM' | 'RODEO', idCorrelation: string, motifMefRequestDto: MotifMefRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUpdateMotifMefFieldUsingPOST.');
            }
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateMotifMefFieldUsingPOST.');
            }
            // verify required parameter 'motifMefRequestDto' is not null or undefined
            if (motifMefRequestDto === null || motifMefRequestDto === undefined) {
                throw new RequiredError('motifMefRequestDto','Required parameter motifMefRequestDto was null or undefined when calling validateUpdateMotifMefFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/motif-mef/{idCorrelation}/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MotifMefRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(motifMefRequestDto || {}) : (motifMefRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'un motif de MEF.
         * @param {string} idCorrelation Id de corrÃ©lation du motif Ã  mettre Ã  jour
         * @param {MotifMefRequestDto} motifMefRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateMotifMefUsingPOST(idCorrelation: string, motifMefRequestDto: MotifMefRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateMotifMefUsingPOST.');
            }
            // verify required parameter 'motifMefRequestDto' is not null or undefined
            if (motifMefRequestDto === null || motifMefRequestDto === undefined) {
                throw new RequiredError('motifMefRequestDto','Required parameter motifMefRequestDto was null or undefined when calling validateUpdateMotifMefUsingPOST.');
            }
            const localVarPath = `/api/referentiel/motif-mef/{idCorrelation}/validation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MotifMefRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(motifMefRequestDto || {}) : (motifMefRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'un motif de sortie.
         * @param {'MOTIF' | 'COMMENTAIRE' | 'DELAI_EFFACEMENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation Identifiant de correlation du motif
         * @param {MotifSortieRequestDto} motifSortieRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateMotifSortieFieldUsingPUT(field: 'MOTIF' | 'COMMENTAIRE' | 'DELAI_EFFACEMENT', idCorrelation: string, motifSortieRequestDto: MotifSortieRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUpdateMotifSortieFieldUsingPUT.');
            }
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateMotifSortieFieldUsingPUT.');
            }
            // verify required parameter 'motifSortieRequestDto' is not null or undefined
            if (motifSortieRequestDto === null || motifSortieRequestDto === undefined) {
                throw new RequiredError('motifSortieRequestDto','Required parameter motifSortieRequestDto was null or undefined when calling validateUpdateMotifSortieFieldUsingPUT.');
            }
            const localVarPath = `/api/referentiel/motif-sortie/{idCorrelation}/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MotifSortieRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(motifSortieRequestDto || {}) : (motifSortieRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'un motif de sortie.
         * @param {string} idCorrelation Identifiant de correlation du motif
         * @param {MotifSortieRequestDto} motifSortieRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateMotifSortieUsingPOST(idCorrelation: string, motifSortieRequestDto: MotifSortieRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateMotifSortieUsingPOST.');
            }
            // verify required parameter 'motifSortieRequestDto' is not null or undefined
            if (motifSortieRequestDto === null || motifSortieRequestDto === undefined) {
                throw new RequiredError('motifSortieRequestDto','Required parameter motifSortieRequestDto was null or undefined when calling validateUpdateMotifSortieUsingPOST.');
            }
            const localVarPath = `/api/referentiel/motif-sortie/{idCorrelation}/validation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MotifSortieRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(motifSortieRequestDto || {}) : (motifSortieRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les modifications des entitÃ©es de rattachement d\'un dossier
         * @param {'DATE_SORTIE' | 'MOTIF_SORTIE' | 'COMMENT'} field 
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateSortieVehiculeRequestDto} updateSortieVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateSortieVehiculeFieldUsingPOST(field: 'DATE_SORTIE' | 'MOTIF_SORTIE' | 'COMMENT', id: string, updateSortieVehiculeRequestDto: UpdateSortieVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUpdateSortieVehiculeFieldUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateUpdateSortieVehiculeFieldUsingPOST.');
            }
            // verify required parameter 'updateSortieVehiculeRequestDto' is not null or undefined
            if (updateSortieVehiculeRequestDto === null || updateSortieVehiculeRequestDto === undefined) {
                throw new RequiredError('updateSortieVehiculeRequestDto','Required parameter updateSortieVehiculeRequestDto was null or undefined when calling validateUpdateSortieVehiculeFieldUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/update-sortie/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateSortieVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateSortieVehiculeRequestDto || {}) : (updateSortieVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les modifications de la sortie d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateSortieVehiculeRequestDto} updateSortieVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateSortieVehiculeUsingPOST(id: string, updateSortieVehiculeRequestDto: UpdateSortieVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateUpdateSortieVehiculeUsingPOST.');
            }
            // verify required parameter 'updateSortieVehiculeRequestDto' is not null or undefined
            if (updateSortieVehiculeRequestDto === null || updateSortieVehiculeRequestDto === undefined) {
                throw new RequiredError('updateSortieVehiculeRequestDto','Required parameter updateSortieVehiculeRequestDto was null or undefined when calling validateUpdateSortieVehiculeUsingPOST.');
            }
            const localVarPath = `/api/admin-fonctionnel/dossier/{id}/update-sortie/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateSortieVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateSortieVehiculeRequestDto || {}) : (updateSortieVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'un type d\'AF.
         * @param {'TYPE' | 'LIBELLE_NOTIFICATION' | 'CODE_TYPOLOGIE_NIVEAU_1' | 'CODE_TYPOLOGIE_NIVEAU_2'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation Identifiant de correlation du type
         * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateTypeAfFieldUsingPOST(field: 'TYPE' | 'LIBELLE_NOTIFICATION' | 'CODE_TYPOLOGIE_NIVEAU_1' | 'CODE_TYPOLOGIE_NIVEAU_2', idCorrelation: string, typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUpdateTypeAfFieldUsingPOST.');
            }
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateTypeAfFieldUsingPOST.');
            }
            // verify required parameter 'typeAutoriteFourriereRequestDto' is not null or undefined
            if (typeAutoriteFourriereRequestDto === null || typeAutoriteFourriereRequestDto === undefined) {
                throw new RequiredError('typeAutoriteFourriereRequestDto','Required parameter typeAutoriteFourriereRequestDto was null or undefined when calling validateUpdateTypeAfFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/type-autorite-fourriere/{idCorrelation}/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TypeAutoriteFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(typeAutoriteFourriereRequestDto || {}) : (typeAutoriteFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'un type d\'AF.
         * @param {string} idCorrelation Identifiant de correlation du type
         * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateTypeAfUsingPOST(idCorrelation: string, typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateTypeAfUsingPOST.');
            }
            // verify required parameter 'typeAutoriteFourriereRequestDto' is not null or undefined
            if (typeAutoriteFourriereRequestDto === null || typeAutoriteFourriereRequestDto === undefined) {
                throw new RequiredError('typeAutoriteFourriereRequestDto','Required parameter typeAutoriteFourriereRequestDto was null or undefined when calling validateUpdateTypeAfUsingPOST.');
            }
            const localVarPath = `/api/referentiel/type-autorite-fourriere/{idCorrelation}/validation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TypeAutoriteFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(typeAutoriteFourriereRequestDto || {}) : (typeAutoriteFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'une unitÃ© FO.
         * @param {'TYPE' | 'CODE' | 'UNITE' | 'ABREVIATION' | 'CODE_POSTAL' | 'EMAIL' | 'TELEPHONE' | 'CODE_INSEE' | 'PARENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation Id de corrÃ©lation de l\'unitÃ© FO Ã  mettre Ã  jour
         * @param {UniteFoRequestDto} uniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateUniteFoFieldUsingPOST(field: 'TYPE' | 'CODE' | 'UNITE' | 'ABREVIATION' | 'CODE_POSTAL' | 'EMAIL' | 'TELEPHONE' | 'CODE_INSEE' | 'PARENT', idCorrelation: string, uniteFoRequestDto: UniteFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUpdateUniteFoFieldUsingPOST.');
            }
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateUniteFoFieldUsingPOST.');
            }
            // verify required parameter 'uniteFoRequestDto' is not null or undefined
            if (uniteFoRequestDto === null || uniteFoRequestDto === undefined) {
                throw new RequiredError('uniteFoRequestDto','Required parameter uniteFoRequestDto was null or undefined when calling validateUpdateUniteFoFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/unite-fo/{idCorrelation}/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UniteFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(uniteFoRequestDto || {}) : (uniteFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'une unite FO.
         * @param {string} idCorrelation Id de corrÃ©lation de l\'unitÃ© FO Ã  mettre Ã  jour
         * @param {UniteFoRequestDto} uniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateUniteFoUsingPOST(idCorrelation: string, uniteFoRequestDto: UniteFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUpdateUniteFoUsingPOST.');
            }
            // verify required parameter 'uniteFoRequestDto' is not null or undefined
            if (uniteFoRequestDto === null || uniteFoRequestDto === undefined) {
                throw new RequiredError('uniteFoRequestDto','Required parameter uniteFoRequestDto was null or undefined when calling validateUpdateUniteFoUsingPOST.');
            }
            const localVarPath = `/api/referentiel/unite-fo/{idCorrelation}/validation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UniteFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(uniteFoRequestDto || {}) : (uniteFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route validant tous les champs du formulaire de mise Ã  jour d\'un utilisateur
         * @param {string} userId ID du compte, reprÃ©sentÃ© par une chaine de caractÃ¨res.
         * @param {UpdateUserRequestDto} updateUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateUsingPOST(userId: string, updateUserRequestDto: UpdateUserRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling validateUpdateUsingPOST.');
            }
            // verify required parameter 'updateUserRequestDto' is not null or undefined
            if (updateUserRequestDto === null || updateUserRequestDto === undefined) {
                throw new RequiredError('updateUserRequestDto','Required parameter updateUserRequestDto was null or undefined when calling validateUpdateUsingPOST.');
            }
            const localVarPath = `/api/comptes-utilisateurs/{userId}/validation`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateUserRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateUserRequestDto || {}) : (updateUserRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant Ã  l\'admin fonctionnel de rÃ©cupÃ©rer les warnings d\'un flux
          * @param {string} fluxId Id du flux, reprÃ©sentÃ© par un UUID.
         * @param {warningsOfFluxUsingGETQueryParams} queryParams objet contenant les query params de la route warningsOfFluxUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warningsOfFluxUsingGET(fluxId: string, queryParams: WarningsOfFluxUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'fluxId' is not null or undefined
            if (fluxId === null || fluxId === undefined) {
                throw new RequiredError('fluxId','Required parameter fluxId was null or undefined when calling warningsOfFluxUsingGET.');
            }
            const localVarPath = `/api/admin-fonctionnel/hermes/etat-bien/flux/{fluxId}/warnings`
                .replace(`{${"fluxId"}}`, encodeURIComponent(String(fluxId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.page !== undefined) {
                localVarQueryParameter['page'] = queryParams.page;
            }

            if (queryParams.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = queryParams.pageSize;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminFonctionnelControllerApi - functional programming interface
 * @export
 */
export const AdminFonctionnelControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant Ã  l\'admin fonctionnel d\'annuler la sortie d\'un vÃ©hicule
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annulationSortieVehiculeUsingPUT(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierDtoAdminFonctionnel> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).annulationSortieVehiculeUsingPUT(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de modifier les positions des domaines faq
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeFaqDomainPositionUsingPOST(requestBody: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FaqDomainAdminDto>> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).changeFaqDomainPositionUsingPOST(requestBody, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de modifier les positions des questions
         * @param {string} domainId id du domaine des questions
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeFaqPositionUsingPOST(domainId: string, requestBody: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FaqAdminDto>> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).changeFaqPositionUsingPOST(domainId, requestBody, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si une autoritÃ© de fourriÃ¨re peut Ãªtre rÃ©activÃ©e
         * @param {string} idCorrelation Identifiant de l\'autoritÃ© de fourriÃ¨re
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationAutoriteFourriereUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConditionsReactivateAutoriteFourriereDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).conditionReactivationAutoriteFourriereUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si un centre vhu peut Ãªtre rÃ©activÃ©e
         * @param {string} idCorrelation Identifiant du centre vhu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationCentreVhuUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConditionsReactivateCentreVHUDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).conditionReactivationCentreVhuUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si un domaine faq peut Ãªtre rÃ©activÃ©e
         * @param {string} idCorrelation Identifiant du domaine faq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationFaqDomainUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConditionsReactivateFaqDomainDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).conditionReactivationFaqDomainUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si une question peut Ãªtre rÃ©activÃ©e
         * @param {string} idCorrelation Identifiant de la question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationFaqUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConditionsReactivateFaqDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).conditionReactivationFaqUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si une  fourriÃ¨re peut Ãªtre rÃ©activÃ©e
         * @param {string} idCorrelation Identifiant de la fourriÃ¨re
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationFourriereUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConditionsReactivateFourriereDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).conditionReactivationFourriereUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si un motif de MEF peut Ãªtre rÃ©activÃ©
         * @param {string} idCorrelation Identifiant du motif de MEF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationMotifMefUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConditionsReactivateMotifMefDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).conditionReactivationMotifMefUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si un motif de sortie peut Ãªtre rÃ©activÃ©
         * @param {string} idCorrelation Identifiant du motif de sortie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationMotifSortieUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConditionsReactivateMotifSortieDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).conditionReactivationMotifSortieUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si un type d\'AF peut Ãªtre rÃ©activÃ©
         * @param {string} idCorrelation Identifiant du type d\'AF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationTypeAfUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConditionsReactivateTypeAutoriteFourriereDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).conditionReactivationTypeAfUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche des donnÃ©es permettant de savoir si une unitÃ© FO peut Ãªtre rÃ©activÃ©e
         * @param {string} idCorrelation Identifiant de l\'unitÃ© FO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionReactivationUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConditionsReactivateUniteFoDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).conditionReactivationUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant d\'importer un fichier avec les retours de la DNID.
         * @param {any} file Fichier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contournementCsvImportUsingPOST(file: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).contournementCsvImportUsingPOST(file, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de crÃ©er une autorite de fourriÃ¨re.
         * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto DonnÃ©es utilisÃ©es pour crÃ©er l\'autoritÃ© de fourriÃ¨re
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAutoriteFourriereUsingPOST(autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AutoriteFourriereDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).createAutoriteFourriereUsingPOST(autoriteFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de crÃ©er un centre VHU
         * @param {CentreVHURequestDto} centreVHURequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCentreVHUUsingPOST(centreVHURequestDto: CentreVHURequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CentreVHUDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).createCentreVHUUsingPOST(centreVHURequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de crÃ©er un domaine faq
         * @param {FaqDomainRequestDto} faqDomainRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaqDomainUsingPOST(faqDomainRequestDto: FaqDomainRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FaqDomainAdminDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).createFaqDomainUsingPOST(faqDomainRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de crÃ©er une question
         * @param {FaqRequestDto} faqRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaqUsingPOST(faqRequestDto: FaqRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FaqAdminDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).createFaqUsingPOST(faqRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de crÃ©er une fourriÃ¨re.
         * @param {FourriereRequestDto} fourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFourriereUsingPOST(fourriereRequestDto: FourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FourriereDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).createFourriereUsingPOST(fourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de crÃ©er un motif de MEF.
         * @param {MotifMefRequestDto} motifMefRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMotifMefUsingPOST(motifMefRequestDto: MotifMefRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MotifMefDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).createMotifMefUsingPOST(motifMefRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de crÃ©er un motif de sortie.
         * @param {MotifSortieRequestDto} motifSortieRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMotifSortieUsingPOST(motifSortieRequestDto: MotifSortieRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MotifSortieDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).createMotifSortieUsingPOST(motifSortieRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de crÃ©er un type d\'AF.
         * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTypeAfUsingPOST(typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TypeAutoriteFourriereDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).createTypeAfUsingPOST(typeAutoriteFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de crÃ©er une unitÃ© de force de l\'ordre.
         * @param {UniteFoRequestDto} uniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUniteFoUsingPOST(uniteFoRequestDto: UniteFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UniteFOAdminResponseDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).createUniteFoUsingPOST(uniteFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route crÃ©ant un utilisateur et retournant les valeurs gÃ©nÃ©rÃ©es
         * @param {CreateUserRequestDto} createUserRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST(createUserRequestDto: CreateUserRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreateUserResultDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).createUsingPOST(createUserRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de supprimer un utilisateur
         * @param {string} userId Id du compte Ã  supprimer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsingDELETE(userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).deleteUserUsingDELETE(userId, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary DÃ©sactivation d\'une autoritÃ© de fourriÃ¨re.
         * @param {string} idCorrelation Identifiant de l\'autoritÃ© de fourriÃ¨re
         * @param {ReferentielCommentairesRequestDto} referentielCommentairesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateAutoriteFourriereUsingPUT(idCorrelation: string, referentielCommentairesRequestDto: ReferentielCommentairesRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).deprecateAutoriteFourriereUsingPUT(idCorrelation, referentielCommentairesRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary DÃ©sactivation d\'un centre vhu.
         * @param {string} idCorrelation Identifiant du centre vhu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateCentreVhuUsingPUT(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).deprecateCentreVhuUsingPUT(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary DÃ©sactivation d\'un domaine faq.
         * @param {string} idCorrelation Identifiant du domaine faq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateFaqDomainUsingPUT(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).deprecateFaqDomainUsingPUT(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary DÃ©sactivation d\'une question
         * @param {string} idCorrelation Identifiant de la question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateFaqUsingPUT(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).deprecateFaqUsingPUT(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary DÃ©sactivation d\'une fourriÃ¨re.
         * @param {string} idCorrelation Identifiant de la fourriÃ¨re
         * @param {ReferentielCommentairesRequestDto} referentielCommentairesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateFourriereUsingPUT(idCorrelation: string, referentielCommentairesRequestDto: ReferentielCommentairesRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).deprecateFourriereUsingPUT(idCorrelation, referentielCommentairesRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary DÃ©sactivation d\'un motif de MEF.
         * @param {string} idCorrelation Identifiant du motif de MEF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateMotifMefUsingPUT(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).deprecateMotifMefUsingPUT(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary DÃ©sactivation d\'un motif de sortie.
         * @param {string} idCorrelation Identifiant du motif de sortie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateMotifSortieUsingPUT(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).deprecateMotifSortieUsingPUT(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary DÃ©sactivation d\'un type d\'AF.
         * @param {string} idCorrelation Identifiant du type d\'AF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateTypeAfUsingPUT(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).deprecateTypeAfUsingPUT(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary DÃ©sactivation d\'une unitÃ© FO.
         * @param {string} idCorrelation Identifiant de l\'unitÃ© FO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateUsingPUT(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).deprecateUsingPUT(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant d\'exporter la table de dÃ©cision au format DMN.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCsvTableUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).downloadCsvTableUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @param {etatUsingGETQueryParams} queryParams objet contenant les query params de la route etatUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        etatUsingGET(queryParams: EtatUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EtatConventionnementResponseDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).etatUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer l\'AR/PND depuis l\'ANTAI
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchArPndUsingPOST(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).fetchArPndUsingPOST(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer le courrier de notification depuis l\'ANTAI
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCourrierUsingPOST(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).fetchCourrierUsingPOST(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de modifier les donnÃ©es de notification manuelle d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {FixDataNotificationManuelleRequestDto} fixDataNotificationManuelleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixDataNotificationManuelleUsingPOST(id: string, fixDataNotificationManuelleRequestDto: FixDataNotificationManuelleRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierDtoAdminFonctionnel> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).fixDataNotificationManuelleUsingPOST(id, fixDataNotificationManuelleRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant d\'exporter l\'ensemble des donnÃ©es d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDonneesAndEventsDossierUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ExportDonneesDossierAdminFonctionnelDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).getAllDonneesAndEventsDossierUsingGET(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant d\'obtenir la liste des dÃ©tails des Ã©vÃ¨nements
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedEventsUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DetailedEventDto>> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).getDetailedEventsUsingGET(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche les fourrieres associÃ©es Ã  une autoritÃ© fourriere
         * @param {string} idCorrelation Identifiant de l\'autoritÃ© de fourriÃ¨re
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFourrieresFromValidAutoriteFourriereUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoFourriereDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).getFourrieresFromValidAutoriteFourriereUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de dÃ©terminer quelle solution est active pour Hermes.<br/> Celle-ci pouvant Ãªtre l\'API d\'Hermes ou la solution de contournement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHermesActiveSolutionUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AlienationActiveSolutionDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).getHermesActiveSolutionUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant d\'obtenir les informations du bandeau de l\'admin fonctionnel avec l\'objet DossierDtoAdminFonctionnel
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInformationsByDossierIdUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierDtoAdminFonctionnel> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).getInformationsByDossierIdUsingGET(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les questions valides, regroupÃ©es par domaines pour l\'admin.
         * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} profile Profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlyValidFaqByDomainsForProfileUsingGET(profile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoFaqDomainDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).getOnlyValidFaqByDomainsForProfileUsingGET(profile, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer les instances Camunda d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessInstancesUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CamundaProcessInstanceDto>> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).getProcessInstancesUsingGET(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant d\'importer une table de dÃ©cision au format DMN.
         * @param {any} file Fichier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCsvTableUsingPOST(file: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ImportViolationListDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).importCsvTableUsingPOST(file, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de migrer d\'une unitÃ© fo Ã  une autre les dossiers associÃ©s
         * @param {MigrateUniteFoRequestDto} migrateUniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateUniteFoUsingPOST(migrateUniteFoRequestDto: MigrateUniteFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).migrateUniteFoUsingPOST(migrateUniteFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary RÃ©activation d\'une autoritÃ© de fourriÃ¨re.
         * @param {string} idCorrelation Identifiant de l\'autoritÃ© de fourriÃ¨re
         * @param {ReferentielCommentairesRequestDto} referentielCommentairesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateAutoriteFourriereUsingPUT(idCorrelation: string, referentielCommentairesRequestDto: ReferentielCommentairesRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).reactivateAutoriteFourriereUsingPUT(idCorrelation, referentielCommentairesRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary RÃ©activation d\'un centre vhu.
         * @param {string} idCorrelation Identifiant du centre vhu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateCentreVhuUsingPUT(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).reactivateCentreVhuUsingPUT(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary RÃ©activation d\'un domaine faq.
         * @param {string} idCorrelation Identifiant du domaine faq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateFaqDomainUsingPUT(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).reactivateFaqDomainUsingPUT(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary RÃ©activation d\'une question
         * @param {string} idCorrelation Identifiant de la question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateFaqUsingPUT(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).reactivateFaqUsingPUT(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary RÃ©activation d\'une fourriÃ¨re.
         * @param {string} idCorrelation Identifiant de la fourriÃ¨re
         * @param {ReferentielCommentairesRequestDto} referentielCommentairesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateFourriereUsingPUT(idCorrelation: string, referentielCommentairesRequestDto: ReferentielCommentairesRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).reactivateFourriereUsingPUT(idCorrelation, referentielCommentairesRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary RÃ©activation d\'un motif de MEF.
         * @param {string} idCorrelation Identifiant du motif de MEF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateMotifMefUsingPUT(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).reactivateMotifMefUsingPUT(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary RÃ©activation d\'un motif de sortie.
         * @param {string} idCorrelation Identifiant du motif de sortie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateMotifSortieUsingPUT(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).reactivateMotifSortieUsingPUT(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary RÃ©activation d\'un type d\'AF.
         * @param {string} idCorrelation Identifiant du type d\'AF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateTypeAfUsingPUT(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).reactivateTypeAfUsingPUT(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary RÃ©activation d\'une unitÃ© FO.
         * @param {string} idCorrelation Identifiant de l\'unitÃ© FO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateUsingPUT(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).reactivateUsingPUT(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route dÃ©clenchant une relance du processus de vente chez le service des domaines, engendrant une nouvelle remise aux domaines.
         * @param {RelanceVenteDto} relanceVenteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relanceVenteAdminUsingPOST(relanceVenteDto: RelanceVenteDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierDtoAdminFonctionnel> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).relanceVenteAdminUsingPOST(relanceVenteDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de rÃ©initialiser le mot de passe d\'un utilisateur
         * @param {string} userId Id du compte dont on veut rÃ©initialiser le mot de passe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPUT(userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).resetPasswordUsingPUT(userId, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de sauvegarder une fiche descriptive
         * @param {any} file La fiche descriptive, reprÃ©sentÃ©e par un InputStream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFicheDescriptiveUsingPOST(file: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).saveFicheDescriptiveUsingPOST(file, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de sauvegarder un manuel Bdr
         * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile Le type d\'utilisateur visÃ© par le manuel, reprÃ©sentÃ© par un UserProfile.
         * @param {any} file Le manuel, reprÃ©sentÃ© par un InputStream.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveManualBdrUsingPOST(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', file: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).saveManualBdrUsingPOST(userProfile, file, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de sauvegarder un manuel utilisateur
         * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile Le type d\'utilisateur visÃ© par le manuel, reprÃ©sentÃ© par un UserProfile.
         * @param {any} file Le manuel, reprÃ©sentÃ© par un InputStream.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveManualUtilisateurUsingPOST(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', file: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).saveManualUtilisateurUsingPOST(userProfile, file, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche de la liste de domaine faq filtrÃ©s par le body de la requÃªte
         * @param {searchFaqDomainUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchFaqDomainUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFaqDomainUsingSpecsUsingGET(queryParams: SearchFaqDomainUsingSpecsUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielSearchResultDtoFaqDomainAdminDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).searchFaqDomainUsingSpecsUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche de la liste des faq filtrÃ©s par le body de la requÃªte
         * @param {searchFaqUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchFaqUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFaqUsingSpecsUsingGET(queryParams: SearchFaqUsingSpecsUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielSearchResultDtoFaqAdminDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).searchFaqUsingSpecsUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant Ã  l\'admin fonctionnel de rÃ©cupÃ©rer les flux Hermes de rÃ©cupÃ©ration de l\'Ã©tat des biens
         * @param {searchFluxUsingGETQueryParams} queryParams objet contenant les query params de la route searchFluxUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFluxUsingGET(queryParams: SearchFluxUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SearchHermesRecuperationEtatBiensResponseDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).searchFluxUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche de la liste de motifs de sortie filtrÃ©e par le body de la requÃªte
         * @param {searchMotifSortieUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchMotifSortieUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMotifSortieUsingSpecsUsingGET(queryParams: SearchMotifSortieUsingSpecsUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielSearchResultDtoMotifSortieDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).searchMotifSortieUsingSpecsUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant Ã  l\'admin fonctionnel de rÃ©cupÃ©rer un flux Hermes de rÃ©cupÃ©ration de l\'Ã©tat des biens
         * @param {string} fluxId Id du flux, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSpecificFluxUsingGET(fluxId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FluxDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).searchSpecificFluxUsingGET(fluxId, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche de la liste des types d\'AF filtrÃ©e par le body de la requÃªte
         * @param {searchTypeAfUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchTypeAfUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTypeAfUsingSpecsUsingGET(queryParams: SearchTypeAfUsingSpecsUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielSearchResultDtoTypeAutoriteFourriereDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).searchTypeAfUsingSpecsUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche des utilisations d\'une autorite de fourriÃ¨re dans le SI
         * @param {string} idCorrelation Identifiant de l\'autoritÃ© de fourriÃ¨re
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsesAutoriteFourriereUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielUsesDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).searchUsesAutoriteFourriereUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche des utilisations d\'une fourriÃ¨re dans le SI
         * @param {string} idCorrelation Identifiant de la fourriÃ¨re
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsesFourriereUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielUsesDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).searchUsesFourriereUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche des utilisations d\'une UnitÃ© FO dans le SI
         * @param {string} idCorrelation Identifiant de l\'unitÃ© FO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsesUniteFoUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielUsesDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).searchUsesUniteFoUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer une liste d\'utilisateur paginÃ©e
         * @param {searchUsingGETQueryParams} queryParams objet contenant les query params de la route searchUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsingGET(queryParams: SearchUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SearchUserResultDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).searchUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant Ã  l\'admin fonctionnel de rÃ©cupÃ©rer les flux de fourriÃ¨res Hermes
         * @param {searchUsingGET1QueryParams} queryParams objet contenant les query params de la route searchUsingGET1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsingGET1(queryParams: SearchUsingGET1QueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SearchHermesFourriereResponseDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).searchUsingGET1(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant Ã  l\'admin fonctionnel de rÃ©cupÃ©rer les warnings d\'un flux de fourriÃ¨res Hermes
         * @param {string} fluxId Id du flux, reprÃ©sentÃ© par un UUID
         * @param {searchWarningsUsingGETQueryParams} queryParams objet contenant les query params de la route searchWarningsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchWarningsUsingGET(fluxId: string, queryParams: SearchWarningsUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SearchHermesFourriereWarningResponseDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).searchWarningsUsingGET(fluxId, queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant Ã  l\'admin fonctionnel de supprimer un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suppressionDossierUsingDELETE(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).suppressionDossierUsingDELETE(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour une autoritÃ© de fourriÃ¨re.
         * @param {string} idCorrelation ID de l\'autorite de fourriÃ¨re, reprÃ©sentÃ© par un UUID
         * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAutoriteFourriereUsingPUT(idCorrelation: string, autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AutoriteFourriereDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).updateAutoriteFourriereUsingPUT(idCorrelation, autoriteFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour un centre VHU.
         * @param {string} idCorrelation ID du centre vhu, reprÃ©sentÃ© par un UUID
         * @param {CentreVHURequestDto} centreVHURequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCentreVHUUsingPUT(idCorrelation: string, centreVHURequestDto: CentreVHURequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CentreVHUDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).updateCentreVHUUsingPUT(idCorrelation, centreVHURequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de modifier le classement d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateClassementRequestDto} updateClassementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClassementUsingPOST1(id: string, updateClassementRequestDto: UpdateClassementRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierDtoAdminFonctionnel> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).updateClassementUsingPOST1(id, updateClassementRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de modifier les les donnÃ©es du vÃ©hicule d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateDonneesVehiculeRequestDto} updateDonneesVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDonneesVehiculeUsingPOST(id: string, updateDonneesVehiculeRequestDto: UpdateDonneesVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierDtoAdminFonctionnel> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).updateDonneesVehiculeUsingPOST(id, updateDonneesVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de modifier les entitÃ©es de rattachement d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntitesRattachementUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierDtoAdminFonctionnel> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).updateEntitesRattachementUsingPOST(id, updateEntitesRattachementRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour un domain faq.
         * @param {string} idCorrelation ID du domaine faq, reprÃ©sentÃ© par un UUID
         * @param {FaqDomainRequestDto} faqDomainRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaqDomainUsingPUT(idCorrelation: string, faqDomainRequestDto: FaqDomainRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FaqDomainAdminDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).updateFaqDomainUsingPUT(idCorrelation, faqDomainRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour une question
         * @param {string} idCorrelation ID de la question, reprÃ©sentÃ© par un UUID
         * @param {FaqRequestDto} faqRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaqUsingPUT(idCorrelation: string, faqRequestDto: FaqRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FaqAdminDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).updateFaqUsingPUT(idCorrelation, faqRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour une fourriÃ¨re.
         * @param {string} idCorrelation ID de la fourrÃ¨re, reprÃ©sentÃ©e par un UUID
         * @param {FourriereRequestDto} fourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFourriereUsingPUT(idCorrelation: string, fourriereRequestDto: FourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FourriereDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).updateFourriereUsingPUT(idCorrelation, fourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de renseigner manuellement les donnÃ©es siv d\'un vÃ©hicule
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {InfosSivRequestDto} infosSivRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInfosSivManuelUsingPOST(id: string, infosSivRequestDto: InfosSivRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierDtoAdminFonctionnel> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).updateInfosSivManuelUsingPOST(id, infosSivRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de modifier la catÃ©gorie d\'une marque.
         * @param {string} idCorrelation 
         * @param {MarqueRequestDto} marqueRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarqueUsingPOST(idCorrelation: string, marqueRequestDto: MarqueRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MarqueDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).updateMarqueUsingPOST(idCorrelation, marqueRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour un motif de MEF.
         * @param {string} idCorrelation ID du motif de MEF, reprÃ©sentÃ© par un UUID
         * @param {MotifMefRequestDto} motifMefRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMotifMefUsingPUT(idCorrelation: string, motifMefRequestDto: MotifMefRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MotifMefDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).updateMotifMefUsingPUT(idCorrelation, motifMefRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de modifier un motif de sortie.
         * @param {string} idCorrelation Identifiant de correlation du motif
         * @param {MotifSortieRequestDto} motifSortieRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMotifSortieUsingPUT(idCorrelation: string, motifSortieRequestDto: MotifSortieRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MotifSortieDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).updateMotifSortieUsingPUT(idCorrelation, motifSortieRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de modifier les donnÃ©es de sortie d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateSortieVehiculeRequestDto} updateSortieVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSortieVehiculeUsingPOST(id: string, updateSortieVehiculeRequestDto: UpdateSortieVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierDtoAdminFonctionnel> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).updateSortieVehiculeUsingPOST(id, updateSortieVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de modifier un type d\'AF.
         * @param {string} idCorrelation Identifiant de correlation du type
         * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTypeAfUsingPUT(idCorrelation: string, typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TypeAutoriteFourriereDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).updateTypeAfUsingPUT(idCorrelation, typeAutoriteFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour une unitÃ© de force de l\'ordre.
         * @param {string} idCorrelation ID de l\'unitÃ© force de l\'ordre, reprÃ©sentÃ© par un UUID
         * @param {UniteFoRequestDto} uniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUniteFoUsingPUT(idCorrelation: string, uniteFoRequestDto: UniteFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UniteFOAdminResponseDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).updateUniteFoUsingPUT(idCorrelation, uniteFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour un utilisateur
         * @param {string} userId Id du compte Ã  mettre Ã  jour
         * @param {UpdateUserRequestDto} updateUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsingPUT(userId: string, updateUserRequestDto: UpdateUserRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).updateUsingPUT(userId, updateUserRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'une autoritÃ© fourriÃ¨re.
         * @param {'NUMERO_DEPARTEMENT' | 'LIBELLE_DEPARTEMENT' | 'TYPE' | 'LIBELLE' | 'CODE_POSTAL' | 'VILLE' | 'VOIE' | 'ID_HERMES' | 'ID_HERMES_UTILISATEUR'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateAutoriteFourriereFieldUsingPOST(field: 'NUMERO_DEPARTEMENT' | 'LIBELLE_DEPARTEMENT' | 'TYPE' | 'LIBELLE' | 'CODE_POSTAL' | 'VILLE' | 'VOIE' | 'ID_HERMES' | 'ID_HERMES_UTILISATEUR', autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateCreateAutoriteFourriereFieldUsingPOST(field, autoriteFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'une autoritÃ© de fourriÃ¨re.
         * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateAutoriteFourriereUsingPOST(autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateCreateAutoriteFourriereUsingPOST(autoriteFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'un centre vhu.
         * @param {'RAISON_SOCIALE' | 'SIREN' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE' | 'EMAIL' | 'NUMERO_AGREMENT_VHU' | 'PREFECTURE_DELIVRANTE'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {CentreVHURequestDto} centreVHURequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateCentreVHUFieldUsingPOST(field: 'RAISON_SOCIALE' | 'SIREN' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE' | 'EMAIL' | 'NUMERO_AGREMENT_VHU' | 'PREFECTURE_DELIVRANTE', centreVHURequestDto: CentreVHURequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateCreateCentreVHUFieldUsingPOST(field, centreVHURequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'un centre VHU
         * @param {CentreVHURequestDto} centreVHURequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateCentreVHUUsingPOST(centreVHURequestDto: CentreVHURequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateCreateCentreVHUUsingPOST(centreVHURequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route validant le champ spÃ©cifiÃ© dans le formulaire de crÃ©ation d\'un compte.
         * @param {'PRENOM' | 'NOM' | 'EMAIL' | 'ENTITE_RATTACHEMENT' | 'PROFILE' | 'ACTIF'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {CreateUserRequestDto} createUserRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateFieldUsingPOST(field: 'PRENOM' | 'NOM' | 'EMAIL' | 'ENTITE_RATTACHEMENT' | 'PROFILE' | 'ACTIF', createUserRequestDto: CreateUserRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateCreateFieldUsingPOST(field, createUserRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'une fourriÃ¨re.
         * @param {'RAISON_SOCIALE' | 'RAISON_SOCIALE_DNID' | 'AF_RATTACHEMENT' | 'CODE_POSTAL' | 'ADRESSE' | 'NUMERO_VOIE' | 'REPETITION' | 'COMMUNE' | 'TELEPHONE_PORTABLE' | 'TELEPHONE_FIXE' | 'EMAIL' | 'EMAIL_RESPONSABLE' | 'ID_HERMES'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {FourriereRequestDto} fourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateFourriereFieldUsingPOST(field: 'RAISON_SOCIALE' | 'RAISON_SOCIALE_DNID' | 'AF_RATTACHEMENT' | 'CODE_POSTAL' | 'ADRESSE' | 'NUMERO_VOIE' | 'REPETITION' | 'COMMUNE' | 'TELEPHONE_PORTABLE' | 'TELEPHONE_FIXE' | 'EMAIL' | 'EMAIL_RESPONSABLE' | 'ID_HERMES', fourriereRequestDto: FourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateCreateFourriereFieldUsingPOST(field, fourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'une fourriÃ¨re.
         * @param {FourriereRequestDto} fourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateFourriereUsingPOST(fourriereRequestDto: FourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateCreateFourriereUsingPOST(fourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'un motif de MEF.
         * @param {'MOTIF_MEF' | 'BRANCHE' | 'NATURE' | 'DICEM' | 'RODEO'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {MotifMefRequestDto} motifMefRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateMotifMefFieldUsingPOST(field: 'MOTIF_MEF' | 'BRANCHE' | 'NATURE' | 'DICEM' | 'RODEO', motifMefRequestDto: MotifMefRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateCreateMotifMefFieldUsingPOST(field, motifMefRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'un motif de MEF.
         * @param {MotifMefRequestDto} motifMefRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateMotifMefUsingPOST(motifMefRequestDto: MotifMefRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateCreateMotifMefUsingPOST(motifMefRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'un motif de sortie.
         * @param {'MOTIF' | 'COMMENTAIRE' | 'DELAI_EFFACEMENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {MotifSortieRequestDto} motifSortieRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateMotifSortieFieldUsingPOST(field: 'MOTIF' | 'COMMENTAIRE' | 'DELAI_EFFACEMENT', motifSortieRequestDto: MotifSortieRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateCreateMotifSortieFieldUsingPOST(field, motifSortieRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'un motif de sortie.
         * @param {MotifSortieRequestDto} motifSortieRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateMotifSortieUsingPOST(motifSortieRequestDto: MotifSortieRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateCreateMotifSortieUsingPOST(motifSortieRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'un type d\'AF.
         * @param {'TYPE' | 'LIBELLE_NOTIFICATION' | 'CODE_TYPOLOGIE_NIVEAU_1' | 'CODE_TYPOLOGIE_NIVEAU_2'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateTypeAFFieldUsingPOST(field: 'TYPE' | 'LIBELLE_NOTIFICATION' | 'CODE_TYPOLOGIE_NIVEAU_1' | 'CODE_TYPOLOGIE_NIVEAU_2', typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateCreateTypeAFFieldUsingPOST(field, typeAutoriteFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'un type d\'AF.
         * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateTypeAfUsingPOST(typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateCreateTypeAfUsingPOST(typeAutoriteFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'une unitÃ© FO.
         * @param {'TYPE' | 'CODE' | 'UNITE' | 'ABREVIATION' | 'CODE_POSTAL' | 'EMAIL' | 'TELEPHONE' | 'CODE_INSEE' | 'PARENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {UniteFoRequestDto} uniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateUniteFoFieldUsingPOST(field: 'TYPE' | 'CODE' | 'UNITE' | 'ABREVIATION' | 'CODE_POSTAL' | 'EMAIL' | 'TELEPHONE' | 'CODE_INSEE' | 'PARENT', uniteFoRequestDto: UniteFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateCreateUniteFoFieldUsingPOST(field, uniteFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'une unite FO.
         * @param {UniteFoRequestDto} uniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateUniteFoUsingPOST(uniteFoRequestDto: UniteFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateCreateUniteFoUsingPOST(uniteFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route validant tous les champs du formulaire de crÃ©ation d\'un utilisateur
         * @param {CreateUserRequestDto} createUserRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreateUsingPOST(createUserRequestDto: CreateUserRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateCreateUsingPOST(createUserRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'un domaine faq.
         * @param {'NAME'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {FaqDomainRequestDto} faqDomainRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFaqDomainFieldUsingPOST(field: 'NAME', faqDomainRequestDto: FaqDomainRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateFaqDomainFieldUsingPOST(field, faqDomainRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'un domaine faq
         * @param {FaqDomainRequestDto} faqDomainRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFaqDomainUsingPOST(faqDomainRequestDto: FaqDomainRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateFaqDomainUsingPOST(faqDomainRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'une question
         * @param {'DOMAIN_ID' | 'TITLE' | 'PROFILES' | 'CONTENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {FaqRequestDto} faqRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFaqFieldUsingPOST(field: 'DOMAIN_ID' | 'TITLE' | 'PROFILES' | 'CONTENT', faqRequestDto: FaqRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateFaqFieldUsingPOST(field, faqRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'une question
         * @param {FaqRequestDto} faqRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFaqUsingPOST(faqRequestDto: FaqRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateFaqUsingPOST(faqRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les modifications du classement d\'un dossier
         * @param {'JUSTIFICATION' | 'NEW_CLASSEMENT'} field 
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateClassementRequestDto} updateClassementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFieldUpdateClassementUsingPOST(field: 'JUSTIFICATION' | 'NEW_CLASSEMENT', id: string, updateClassementRequestDto: UpdateClassementRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierModificationViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateFieldUpdateClassementUsingPOST(field, id, updateClassementRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les modifications de la notification manuelle d\'un dossier
         * @param {'NEW_DATE_ENVOI' | 'NEW_DATE_SIGNATURE' | 'NEW_ETAT_DISTRIBUTION' | 'JUSTIFICATION'} field 
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {FixDataNotificationManuelleRequestDto} fixDataNotificationManuelleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFixDataNotificationManuelleFieldUsingPOST(field: 'NEW_DATE_ENVOI' | 'NEW_DATE_SIGNATURE' | 'NEW_ETAT_DISTRIBUTION' | 'JUSTIFICATION', id: string, fixDataNotificationManuelleRequestDto: FixDataNotificationManuelleRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierModificationViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateFixDataNotificationManuelleFieldUsingPOST(field, id, fixDataNotificationManuelleRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les modifications de la notification manuelle d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {FixDataNotificationManuelleRequestDto} fixDataNotificationManuelleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFixDataNotificationManuelleUsingPOST(id: string, fixDataNotificationManuelleRequestDto: FixDataNotificationManuelleRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierModificationViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateFixDataNotificationManuelleUsingPOST(id, fixDataNotificationManuelleRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les modifications d\'un champ de la migration d\'unitÃ© FO
         * @param {'NEW_UNITE_FO_CORRELATION_ID' | 'OLD_UNITE_FO_CORRELATION_ID' | 'COMMENT'} field 
         * @param {MigrateUniteFoRequestDto} migrateUniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateMigrateUniteFoFieldUsingPOST(field: 'NEW_UNITE_FO_CORRELATION_ID' | 'OLD_UNITE_FO_CORRELATION_ID' | 'COMMENT', migrateUniteFoRequestDto: MigrateUniteFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MigrateUniteFoViolationDto>> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateMigrateUniteFoFieldUsingPOST(field, migrateUniteFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les modifications de la migration d\'unitÃ© FO
         * @param {MigrateUniteFoRequestDto} migrateUniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateMigrateUniteFoUsingPOST(migrateUniteFoRequestDto: MigrateUniteFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MigrateUniteFoViolationDto>> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateMigrateUniteFoUsingPOST(migrateUniteFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'une autoritÃ© fourriÃ¨re.
         * @param {'NUMERO_DEPARTEMENT' | 'LIBELLE_DEPARTEMENT' | 'TYPE' | 'LIBELLE' | 'CODE_POSTAL' | 'VILLE' | 'VOIE' | 'ID_HERMES' | 'ID_HERMES_UTILISATEUR'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation ID de l\'autorite de fourriÃ¨re, reprÃ©sentÃ© par un UUID
         * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateAutoriteFourriereFieldUsingPOST(field: 'NUMERO_DEPARTEMENT' | 'LIBELLE_DEPARTEMENT' | 'TYPE' | 'LIBELLE' | 'CODE_POSTAL' | 'VILLE' | 'VOIE' | 'ID_HERMES' | 'ID_HERMES_UTILISATEUR', idCorrelation: string, autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateAutoriteFourriereFieldUsingPOST(field, idCorrelation, autoriteFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'une autoritÃ© de fourriÃ¨re.
         * @param {string} idCorrelation ID de l\'autorite de fourriÃ¨re, reprÃ©sentÃ© par un UUID
         * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateAutoriteFourriereUsingPOST(idCorrelation: string, autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateAutoriteFourriereUsingPOST(idCorrelation, autoriteFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'un centre vhu.
         * @param {'RAISON_SOCIALE' | 'SIREN' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE' | 'EMAIL' | 'NUMERO_AGREMENT_VHU' | 'PREFECTURE_DELIVRANTE'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation Id de corrÃ©lation du centre vhu Ã  mettre Ã  jour
         * @param {CentreVHURequestDto} centreVHURequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateCentreVHUFieldUsingPOST(field: 'RAISON_SOCIALE' | 'SIREN' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE' | 'EMAIL' | 'NUMERO_AGREMENT_VHU' | 'PREFECTURE_DELIVRANTE', idCorrelation: string, centreVHURequestDto: CentreVHURequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateCentreVHUFieldUsingPOST(field, idCorrelation, centreVHURequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'un centre vhu.
         * @param {string} idCorrelation Id de corrÃ©lation du centre vhu Ã  mettre Ã  jour
         * @param {CentreVHURequestDto} centreVHURequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateCentreVHUUsingPOST(idCorrelation: string, centreVHURequestDto: CentreVHURequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateCentreVHUUsingPOST(idCorrelation, centreVHURequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les modifications du classement d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateClassementRequestDto} updateClassementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateClassementUsingPOST(id: string, updateClassementRequestDto: UpdateClassementRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierModificationViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateClassementUsingPOST(id, updateClassementRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les modifications des donnÃ©es du vÃ©hicule d\'un dossier
         * @param {'NEW_MOTIF_ENTREE_ID_CORRELATION' | 'NEW_IS_PAYS_ETRANGER' | 'NEW_IS_SANS_PLAQUE' | 'NEW_PLAQUE_IMMATRICULATION' | 'NEW_ETAT_ID_CORRELATION' | 'COMMENT'} field 
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateDonneesVehiculeRequestDto} updateDonneesVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateDonneesVehiculeFieldUsingPOST(field: 'NEW_MOTIF_ENTREE_ID_CORRELATION' | 'NEW_IS_PAYS_ETRANGER' | 'NEW_IS_SANS_PLAQUE' | 'NEW_PLAQUE_IMMATRICULATION' | 'NEW_ETAT_ID_CORRELATION' | 'COMMENT', id: string, updateDonneesVehiculeRequestDto: UpdateDonneesVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierModificationViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateDonneesVehiculeFieldUsingPOST(field, id, updateDonneesVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les modifications des donnÃ©es d\'un vÃ©hicule et de l\'infraction d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateDonneesVehiculeRequestDto} updateDonneesVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateDonneesVehiculeUsingPOST(id: string, updateDonneesVehiculeRequestDto: UpdateDonneesVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierModificationViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateDonneesVehiculeUsingPOST(id, updateDonneesVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les modifications des entitÃ©es de rattachement d\'un dossier
         * @param {'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT'} field 
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateEntitesRattachementFieldUsingPOST(field: 'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT', id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierModificationViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateEntitesRattachementFieldUsingPOST(field, id, updateEntitesRattachementRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les modifications des entitÃ©es de rattachement d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateEntitesRattachementUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierModificationViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateEntitesRattachementUsingPOST(id, updateEntitesRattachementRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de modification d\'une question
         * @param {'DOMAIN_ID' | 'TITLE' | 'PROFILES' | 'CONTENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation Id de corrÃ©lation de la question Ã  mettre Ã  jour
         * @param {FaqRequestDto} faqRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateFaqFieldUsingPOST(field: 'DOMAIN_ID' | 'TITLE' | 'PROFILES' | 'CONTENT', idCorrelation: string, faqRequestDto: FaqRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateFaqFieldUsingPOST(field, idCorrelation, faqRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de la modification d\'une question
         * @param {string} idCorrelation Id de corrÃ©lation de la question Ã  mettre Ã  jour
         * @param {FaqRequestDto} faqRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateFaqUsingPOST(idCorrelation: string, faqRequestDto: FaqRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateFaqUsingPOST(idCorrelation, faqRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route validant le champ spÃ©cifiÃ© dans le formulaire de mise Ã  jour d\'un compte.
         * @param {'FIRSTNAME' | 'LASTNAME' | 'EMAIL' | 'UNITE_RATTACHEMENT' | 'ACTIF'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} userId ID du compte, reprÃ©sentÃ© par une chaine de caractÃ¨res.
         * @param {UpdateUserRequestDto} updateUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateFieldUsingPOST(field: 'FIRSTNAME' | 'LASTNAME' | 'EMAIL' | 'UNITE_RATTACHEMENT' | 'ACTIF', userId: string, updateUserRequestDto: UpdateUserRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateFieldUsingPOST(field, userId, updateUserRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'une fourriÃ¨re.
         * @param {'RAISON_SOCIALE' | 'RAISON_SOCIALE_DNID' | 'AF_RATTACHEMENT' | 'CODE_POSTAL' | 'ADRESSE' | 'NUMERO_VOIE' | 'REPETITION' | 'COMMUNE' | 'TELEPHONE_PORTABLE' | 'TELEPHONE_FIXE' | 'EMAIL' | 'EMAIL_RESPONSABLE' | 'ID_HERMES'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation Id de corrÃ©lation de la fourriÃ¨re Ã  mettre Ã  jour
         * @param {FourriereRequestDto} fourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateFourriereFieldUsingPOST(field: 'RAISON_SOCIALE' | 'RAISON_SOCIALE_DNID' | 'AF_RATTACHEMENT' | 'CODE_POSTAL' | 'ADRESSE' | 'NUMERO_VOIE' | 'REPETITION' | 'COMMUNE' | 'TELEPHONE_PORTABLE' | 'TELEPHONE_FIXE' | 'EMAIL' | 'EMAIL_RESPONSABLE' | 'ID_HERMES', idCorrelation: string, fourriereRequestDto: FourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateFourriereFieldUsingPOST(field, idCorrelation, fourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'une fourriÃ¨re.
         * @param {string} idCorrelation Id de corrÃ©lation de la fourriÃ¨re Ã  mettre Ã  jour
         * @param {FourriereRequestDto} fourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateFourriereUsingPOST(idCorrelation: string, fourriereRequestDto: FourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateFourriereUsingPOST(idCorrelation, fourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'une marque.
         * @param {'CATEGORIE' | 'TYPE' | 'MARQUE_EXACTE'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation Id de corrÃ©lation de la marque Ã  mettre Ã  jour
         * @param {MarqueRequestDto} marqueRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateMarqueFieldUsingPOST(field: 'CATEGORIE' | 'TYPE' | 'MARQUE_EXACTE', idCorrelation: string, marqueRequestDto: MarqueRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateMarqueFieldUsingPOST(field, idCorrelation, marqueRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'une marque.
         * @param {string} idCorrelation Id de corrÃ©lation de la marque Ã  mettre Ã  jour
         * @param {MarqueRequestDto} marqueRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateMarqueUsingPOST(idCorrelation: string, marqueRequestDto: MarqueRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateMarqueUsingPOST(idCorrelation, marqueRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'un motif de mef.
         * @param {'MOTIF_MEF' | 'BRANCHE' | 'NATURE' | 'DICEM' | 'RODEO'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation Id de corrÃ©lation du motif de MEF Ã  mettre Ã  jour
         * @param {MotifMefRequestDto} motifMefRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateMotifMefFieldUsingPOST(field: 'MOTIF_MEF' | 'BRANCHE' | 'NATURE' | 'DICEM' | 'RODEO', idCorrelation: string, motifMefRequestDto: MotifMefRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateMotifMefFieldUsingPOST(field, idCorrelation, motifMefRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'un motif de MEF.
         * @param {string} idCorrelation Id de corrÃ©lation du motif Ã  mettre Ã  jour
         * @param {MotifMefRequestDto} motifMefRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateMotifMefUsingPOST(idCorrelation: string, motifMefRequestDto: MotifMefRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateMotifMefUsingPOST(idCorrelation, motifMefRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'un motif de sortie.
         * @param {'MOTIF' | 'COMMENTAIRE' | 'DELAI_EFFACEMENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation Identifiant de correlation du motif
         * @param {MotifSortieRequestDto} motifSortieRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateMotifSortieFieldUsingPUT(field: 'MOTIF' | 'COMMENTAIRE' | 'DELAI_EFFACEMENT', idCorrelation: string, motifSortieRequestDto: MotifSortieRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateMotifSortieFieldUsingPUT(field, idCorrelation, motifSortieRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'un motif de sortie.
         * @param {string} idCorrelation Identifiant de correlation du motif
         * @param {MotifSortieRequestDto} motifSortieRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateMotifSortieUsingPOST(idCorrelation: string, motifSortieRequestDto: MotifSortieRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateMotifSortieUsingPOST(idCorrelation, motifSortieRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les modifications des entitÃ©es de rattachement d\'un dossier
         * @param {'DATE_SORTIE' | 'MOTIF_SORTIE' | 'COMMENT'} field 
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateSortieVehiculeRequestDto} updateSortieVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateSortieVehiculeFieldUsingPOST(field: 'DATE_SORTIE' | 'MOTIF_SORTIE' | 'COMMENT', id: string, updateSortieVehiculeRequestDto: UpdateSortieVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateSortieVehiculeFieldUsingPOST(field, id, updateSortieVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les modifications de la sortie d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateSortieVehiculeRequestDto} updateSortieVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateSortieVehiculeUsingPOST(id: string, updateSortieVehiculeRequestDto: UpdateSortieVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateSortieVehiculeUsingPOST(id, updateSortieVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'un type d\'AF.
         * @param {'TYPE' | 'LIBELLE_NOTIFICATION' | 'CODE_TYPOLOGIE_NIVEAU_1' | 'CODE_TYPOLOGIE_NIVEAU_2'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation Identifiant de correlation du type
         * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateTypeAfFieldUsingPOST(field: 'TYPE' | 'LIBELLE_NOTIFICATION' | 'CODE_TYPOLOGIE_NIVEAU_1' | 'CODE_TYPOLOGIE_NIVEAU_2', idCorrelation: string, typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateTypeAfFieldUsingPOST(field, idCorrelation, typeAutoriteFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'un type d\'AF.
         * @param {string} idCorrelation Identifiant de correlation du type
         * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateTypeAfUsingPOST(idCorrelation: string, typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateTypeAfUsingPOST(idCorrelation, typeAutoriteFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'une unitÃ© FO.
         * @param {'TYPE' | 'CODE' | 'UNITE' | 'ABREVIATION' | 'CODE_POSTAL' | 'EMAIL' | 'TELEPHONE' | 'CODE_INSEE' | 'PARENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
         * @param {string} idCorrelation Id de corrÃ©lation de l\'unitÃ© FO Ã  mettre Ã  jour
         * @param {UniteFoRequestDto} uniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateUniteFoFieldUsingPOST(field: 'TYPE' | 'CODE' | 'UNITE' | 'ABREVIATION' | 'CODE_POSTAL' | 'EMAIL' | 'TELEPHONE' | 'CODE_INSEE' | 'PARENT', idCorrelation: string, uniteFoRequestDto: UniteFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateUniteFoFieldUsingPOST(field, idCorrelation, uniteFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'une unite FO.
         * @param {string} idCorrelation Id de corrÃ©lation de l\'unitÃ© FO Ã  mettre Ã  jour
         * @param {UniteFoRequestDto} uniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateUniteFoUsingPOST(idCorrelation: string, uniteFoRequestDto: UniteFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateUniteFoUsingPOST(idCorrelation, uniteFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route validant tous les champs du formulaire de mise Ã  jour d\'un utilisateur
         * @param {string} userId ID du compte, reprÃ©sentÃ© par une chaine de caractÃ¨res.
         * @param {UpdateUserRequestDto} updateUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateUsingPOST(userId: string, updateUserRequestDto: UpdateUserRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserViolationsDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).validateUpdateUsingPOST(userId, updateUserRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant Ã  l\'admin fonctionnel de rÃ©cupÃ©rer les warnings d\'un flux
         * @param {string} fluxId Id du flux, reprÃ©sentÃ© par un UUID.
         * @param {warningsOfFluxUsingGETQueryParams} queryParams objet contenant les query params de la route warningsOfFluxUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warningsOfFluxUsingGET(fluxId: string, queryParams: WarningsOfFluxUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SearchHermesRecuperationEtatBiensWarningResponseDto> {
            const localVarFetchArgs = AdminFonctionnelControllerApiFetchParamCreator(configuration).warningsOfFluxUsingGET(fluxId, queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdminFonctionnelControllerApi - object-oriented interface
 * @export
 * @class AdminFonctionnelControllerApi
 * @extends {BaseAPI}
 */
export class AdminFonctionnelControllerApi extends BaseAPI {
    /**
     * @summary Route permettant Ã  l\'admin fonctionnel d\'annuler la sortie d\'un vÃ©hicule
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public annulationSortieVehiculeUsingPUT(id: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).annulationSortieVehiculeUsingPUT(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de modifier les positions des domaines faq
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public changeFaqDomainPositionUsingPOST(requestBody: Array<string>, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).changeFaqDomainPositionUsingPOST(requestBody, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de modifier les positions des questions
     * @param {string} domainId id du domaine des questions
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public changeFaqPositionUsingPOST(domainId: string, requestBody: Array<string>, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).changeFaqPositionUsingPOST(domainId, requestBody, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche des donnÃ©es permettant de savoir si une autoritÃ© de fourriÃ¨re peut Ãªtre rÃ©activÃ©e
     * @param {string} idCorrelation Identifiant de l\'autoritÃ© de fourriÃ¨re
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public conditionReactivationAutoriteFourriereUsingGET(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).conditionReactivationAutoriteFourriereUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche des donnÃ©es permettant de savoir si un centre vhu peut Ãªtre rÃ©activÃ©e
     * @param {string} idCorrelation Identifiant du centre vhu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public conditionReactivationCentreVhuUsingGET(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).conditionReactivationCentreVhuUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche des donnÃ©es permettant de savoir si un domaine faq peut Ãªtre rÃ©activÃ©e
     * @param {string} idCorrelation Identifiant du domaine faq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public conditionReactivationFaqDomainUsingGET(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).conditionReactivationFaqDomainUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche des donnÃ©es permettant de savoir si une question peut Ãªtre rÃ©activÃ©e
     * @param {string} idCorrelation Identifiant de la question
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public conditionReactivationFaqUsingGET(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).conditionReactivationFaqUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche des donnÃ©es permettant de savoir si une  fourriÃ¨re peut Ãªtre rÃ©activÃ©e
     * @param {string} idCorrelation Identifiant de la fourriÃ¨re
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public conditionReactivationFourriereUsingGET(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).conditionReactivationFourriereUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche des donnÃ©es permettant de savoir si un motif de MEF peut Ãªtre rÃ©activÃ©
     * @param {string} idCorrelation Identifiant du motif de MEF
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public conditionReactivationMotifMefUsingGET(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).conditionReactivationMotifMefUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche des donnÃ©es permettant de savoir si un motif de sortie peut Ãªtre rÃ©activÃ©
     * @param {string} idCorrelation Identifiant du motif de sortie
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public conditionReactivationMotifSortieUsingGET(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).conditionReactivationMotifSortieUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche des donnÃ©es permettant de savoir si un type d\'AF peut Ãªtre rÃ©activÃ©
     * @param {string} idCorrelation Identifiant du type d\'AF
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public conditionReactivationTypeAfUsingGET(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).conditionReactivationTypeAfUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche des donnÃ©es permettant de savoir si une unitÃ© FO peut Ãªtre rÃ©activÃ©e
     * @param {string} idCorrelation Identifiant de l\'unitÃ© FO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public conditionReactivationUsingGET(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).conditionReactivationUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant d\'importer un fichier avec les retours de la DNID.
     * @param {any} file Fichier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public contournementCsvImportUsingPOST(file: any, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).contournementCsvImportUsingPOST(file, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de crÃ©er une autorite de fourriÃ¨re.
     * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto DonnÃ©es utilisÃ©es pour crÃ©er l\'autoritÃ© de fourriÃ¨re
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public createAutoriteFourriereUsingPOST(autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).createAutoriteFourriereUsingPOST(autoriteFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de crÃ©er un centre VHU
     * @param {CentreVHURequestDto} centreVHURequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public createCentreVHUUsingPOST(centreVHURequestDto: CentreVHURequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).createCentreVHUUsingPOST(centreVHURequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de crÃ©er un domaine faq
     * @param {FaqDomainRequestDto} faqDomainRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public createFaqDomainUsingPOST(faqDomainRequestDto: FaqDomainRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).createFaqDomainUsingPOST(faqDomainRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de crÃ©er une question
     * @param {FaqRequestDto} faqRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public createFaqUsingPOST(faqRequestDto: FaqRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).createFaqUsingPOST(faqRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de crÃ©er une fourriÃ¨re.
     * @param {FourriereRequestDto} fourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public createFourriereUsingPOST(fourriereRequestDto: FourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).createFourriereUsingPOST(fourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de crÃ©er un motif de MEF.
     * @param {MotifMefRequestDto} motifMefRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public createMotifMefUsingPOST(motifMefRequestDto: MotifMefRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).createMotifMefUsingPOST(motifMefRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de crÃ©er un motif de sortie.
     * @param {MotifSortieRequestDto} motifSortieRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public createMotifSortieUsingPOST(motifSortieRequestDto: MotifSortieRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).createMotifSortieUsingPOST(motifSortieRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de crÃ©er un type d\'AF.
     * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public createTypeAfUsingPOST(typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).createTypeAfUsingPOST(typeAutoriteFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de crÃ©er une unitÃ© de force de l\'ordre.
     * @param {UniteFoRequestDto} uniteFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public createUniteFoUsingPOST(uniteFoRequestDto: UniteFoRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).createUniteFoUsingPOST(uniteFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route crÃ©ant un utilisateur et retournant les valeurs gÃ©nÃ©rÃ©es
     * @param {CreateUserRequestDto} createUserRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public createUsingPOST(createUserRequestDto: CreateUserRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).createUsingPOST(createUserRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de supprimer un utilisateur
     * @param {string} userId Id du compte Ã  supprimer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public deleteUserUsingDELETE(userId: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).deleteUserUsingDELETE(userId, options)(this.fetch, this.basePath);
    }

    /**
     * @summary DÃ©sactivation d\'une autoritÃ© de fourriÃ¨re.
     * @param {string} idCorrelation Identifiant de l\'autoritÃ© de fourriÃ¨re
     * @param {ReferentielCommentairesRequestDto} referentielCommentairesRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public deprecateAutoriteFourriereUsingPUT(idCorrelation: string, referentielCommentairesRequestDto: ReferentielCommentairesRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).deprecateAutoriteFourriereUsingPUT(idCorrelation, referentielCommentairesRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary DÃ©sactivation d\'un centre vhu.
     * @param {string} idCorrelation Identifiant du centre vhu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public deprecateCentreVhuUsingPUT(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).deprecateCentreVhuUsingPUT(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary DÃ©sactivation d\'un domaine faq.
     * @param {string} idCorrelation Identifiant du domaine faq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public deprecateFaqDomainUsingPUT(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).deprecateFaqDomainUsingPUT(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary DÃ©sactivation d\'une question
     * @param {string} idCorrelation Identifiant de la question
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public deprecateFaqUsingPUT(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).deprecateFaqUsingPUT(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary DÃ©sactivation d\'une fourriÃ¨re.
     * @param {string} idCorrelation Identifiant de la fourriÃ¨re
     * @param {ReferentielCommentairesRequestDto} referentielCommentairesRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public deprecateFourriereUsingPUT(idCorrelation: string, referentielCommentairesRequestDto: ReferentielCommentairesRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).deprecateFourriereUsingPUT(idCorrelation, referentielCommentairesRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary DÃ©sactivation d\'un motif de MEF.
     * @param {string} idCorrelation Identifiant du motif de MEF
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public deprecateMotifMefUsingPUT(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).deprecateMotifMefUsingPUT(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary DÃ©sactivation d\'un motif de sortie.
     * @param {string} idCorrelation Identifiant du motif de sortie
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public deprecateMotifSortieUsingPUT(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).deprecateMotifSortieUsingPUT(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary DÃ©sactivation d\'un type d\'AF.
     * @param {string} idCorrelation Identifiant du type d\'AF
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public deprecateTypeAfUsingPUT(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).deprecateTypeAfUsingPUT(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary DÃ©sactivation d\'une unitÃ© FO.
     * @param {string} idCorrelation Identifiant de l\'unitÃ© FO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public deprecateUsingPUT(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).deprecateUsingPUT(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant d\'exporter la table de dÃ©cision au format DMN.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public downloadCsvTableUsingGET(options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).downloadCsvTableUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @param {etatUsingGETQueryParams} queryParams objet contenant les query params de la route etatUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public etatUsingGET(queryParams: EtatUsingGETQueryParams, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).etatUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de rÃ©cupÃ©rer l\'AR/PND depuis l\'ANTAI
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public fetchArPndUsingPOST(id: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).fetchArPndUsingPOST(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de rÃ©cupÃ©rer le courrier de notification depuis l\'ANTAI
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public fetchCourrierUsingPOST(id: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).fetchCourrierUsingPOST(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de modifier les donnÃ©es de notification manuelle d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {FixDataNotificationManuelleRequestDto} fixDataNotificationManuelleRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public fixDataNotificationManuelleUsingPOST(id: string, fixDataNotificationManuelleRequestDto: FixDataNotificationManuelleRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).fixDataNotificationManuelleUsingPOST(id, fixDataNotificationManuelleRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant d\'exporter l\'ensemble des donnÃ©es d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getAllDonneesAndEventsDossierUsingGET(id: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).getAllDonneesAndEventsDossierUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant d\'obtenir la liste des dÃ©tails des Ã©vÃ¨nements
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getDetailedEventsUsingGET(id: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).getDetailedEventsUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche les fourrieres associÃ©es Ã  une autoritÃ© fourriere
     * @param {string} idCorrelation Identifiant de l\'autoritÃ© de fourriÃ¨re
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getFourrieresFromValidAutoriteFourriereUsingGET(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).getFourrieresFromValidAutoriteFourriereUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de dÃ©terminer quelle solution est active pour Hermes.<br/> Celle-ci pouvant Ãªtre l\'API d\'Hermes ou la solution de contournement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getHermesActiveSolutionUsingGET(options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).getHermesActiveSolutionUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant d\'obtenir les informations du bandeau de l\'admin fonctionnel avec l\'objet DossierDtoAdminFonctionnel
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getInformationsByDossierIdUsingGET(id: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).getInformationsByDossierIdUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant les questions valides, regroupÃ©es par domaines pour l\'admin.
     * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} profile Profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getOnlyValidFaqByDomainsForProfileUsingGET(profile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).getOnlyValidFaqByDomainsForProfileUsingGET(profile, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de rÃ©cupÃ©rer les instances Camunda d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getProcessInstancesUsingGET(id: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).getProcessInstancesUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant d\'importer une table de dÃ©cision au format DMN.
     * @param {any} file Fichier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public importCsvTableUsingPOST(file: any, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).importCsvTableUsingPOST(file, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de migrer d\'une unitÃ© fo Ã  une autre les dossiers associÃ©s
     * @param {MigrateUniteFoRequestDto} migrateUniteFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public migrateUniteFoUsingPOST(migrateUniteFoRequestDto: MigrateUniteFoRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).migrateUniteFoUsingPOST(migrateUniteFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary RÃ©activation d\'une autoritÃ© de fourriÃ¨re.
     * @param {string} idCorrelation Identifiant de l\'autoritÃ© de fourriÃ¨re
     * @param {ReferentielCommentairesRequestDto} referentielCommentairesRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public reactivateAutoriteFourriereUsingPUT(idCorrelation: string, referentielCommentairesRequestDto: ReferentielCommentairesRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).reactivateAutoriteFourriereUsingPUT(idCorrelation, referentielCommentairesRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary RÃ©activation d\'un centre vhu.
     * @param {string} idCorrelation Identifiant du centre vhu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public reactivateCentreVhuUsingPUT(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).reactivateCentreVhuUsingPUT(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary RÃ©activation d\'un domaine faq.
     * @param {string} idCorrelation Identifiant du domaine faq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public reactivateFaqDomainUsingPUT(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).reactivateFaqDomainUsingPUT(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary RÃ©activation d\'une question
     * @param {string} idCorrelation Identifiant de la question
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public reactivateFaqUsingPUT(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).reactivateFaqUsingPUT(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary RÃ©activation d\'une fourriÃ¨re.
     * @param {string} idCorrelation Identifiant de la fourriÃ¨re
     * @param {ReferentielCommentairesRequestDto} referentielCommentairesRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public reactivateFourriereUsingPUT(idCorrelation: string, referentielCommentairesRequestDto: ReferentielCommentairesRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).reactivateFourriereUsingPUT(idCorrelation, referentielCommentairesRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary RÃ©activation d\'un motif de MEF.
     * @param {string} idCorrelation Identifiant du motif de MEF
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public reactivateMotifMefUsingPUT(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).reactivateMotifMefUsingPUT(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary RÃ©activation d\'un motif de sortie.
     * @param {string} idCorrelation Identifiant du motif de sortie
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public reactivateMotifSortieUsingPUT(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).reactivateMotifSortieUsingPUT(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary RÃ©activation d\'un type d\'AF.
     * @param {string} idCorrelation Identifiant du type d\'AF
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public reactivateTypeAfUsingPUT(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).reactivateTypeAfUsingPUT(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary RÃ©activation d\'une unitÃ© FO.
     * @param {string} idCorrelation Identifiant de l\'unitÃ© FO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public reactivateUsingPUT(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).reactivateUsingPUT(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route dÃ©clenchant une relance du processus de vente chez le service des domaines, engendrant une nouvelle remise aux domaines.
     * @param {RelanceVenteDto} relanceVenteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public relanceVenteAdminUsingPOST(relanceVenteDto: RelanceVenteDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).relanceVenteAdminUsingPOST(relanceVenteDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de rÃ©initialiser le mot de passe d\'un utilisateur
     * @param {string} userId Id du compte dont on veut rÃ©initialiser le mot de passe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public resetPasswordUsingPUT(userId: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).resetPasswordUsingPUT(userId, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de sauvegarder une fiche descriptive
     * @param {any} file La fiche descriptive, reprÃ©sentÃ©e par un InputStream
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public saveFicheDescriptiveUsingPOST(file: any, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).saveFicheDescriptiveUsingPOST(file, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de sauvegarder un manuel Bdr
     * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile Le type d\'utilisateur visÃ© par le manuel, reprÃ©sentÃ© par un UserProfile.
     * @param {any} file Le manuel, reprÃ©sentÃ© par un InputStream.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public saveManualBdrUsingPOST(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', file: any, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).saveManualBdrUsingPOST(userProfile, file, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de sauvegarder un manuel utilisateur
     * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile Le type d\'utilisateur visÃ© par le manuel, reprÃ©sentÃ© par un UserProfile.
     * @param {any} file Le manuel, reprÃ©sentÃ© par un InputStream.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public saveManualUtilisateurUsingPOST(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', file: any, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).saveManualUtilisateurUsingPOST(userProfile, file, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche de la liste de domaine faq filtrÃ©s par le body de la requÃªte
     * @param {searchFaqDomainUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchFaqDomainUsingSpecsUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchFaqDomainUsingSpecsUsingGET(queryParams: SearchFaqDomainUsingSpecsUsingGETQueryParams, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).searchFaqDomainUsingSpecsUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche de la liste des faq filtrÃ©s par le body de la requÃªte
     * @param {searchFaqUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchFaqUsingSpecsUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchFaqUsingSpecsUsingGET(queryParams: SearchFaqUsingSpecsUsingGETQueryParams, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).searchFaqUsingSpecsUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant Ã  l\'admin fonctionnel de rÃ©cupÃ©rer les flux Hermes de rÃ©cupÃ©ration de l\'Ã©tat des biens
     * @param {searchFluxUsingGETQueryParams} queryParams objet contenant les query params de la route searchFluxUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchFluxUsingGET(queryParams: SearchFluxUsingGETQueryParams, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).searchFluxUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche de la liste de motifs de sortie filtrÃ©e par le body de la requÃªte
     * @param {searchMotifSortieUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchMotifSortieUsingSpecsUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchMotifSortieUsingSpecsUsingGET(queryParams: SearchMotifSortieUsingSpecsUsingGETQueryParams, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).searchMotifSortieUsingSpecsUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant Ã  l\'admin fonctionnel de rÃ©cupÃ©rer un flux Hermes de rÃ©cupÃ©ration de l\'Ã©tat des biens
     * @param {string} fluxId Id du flux, reprÃ©sentÃ© par un UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchSpecificFluxUsingGET(fluxId: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).searchSpecificFluxUsingGET(fluxId, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche de la liste des types d\'AF filtrÃ©e par le body de la requÃªte
     * @param {searchTypeAfUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchTypeAfUsingSpecsUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchTypeAfUsingSpecsUsingGET(queryParams: SearchTypeAfUsingSpecsUsingGETQueryParams, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).searchTypeAfUsingSpecsUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche des utilisations d\'une autorite de fourriÃ¨re dans le SI
     * @param {string} idCorrelation Identifiant de l\'autoritÃ© de fourriÃ¨re
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchUsesAutoriteFourriereUsingGET(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).searchUsesAutoriteFourriereUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche des utilisations d\'une fourriÃ¨re dans le SI
     * @param {string} idCorrelation Identifiant de la fourriÃ¨re
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchUsesFourriereUsingGET(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).searchUsesFourriereUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche des utilisations d\'une UnitÃ© FO dans le SI
     * @param {string} idCorrelation Identifiant de l\'unitÃ© FO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchUsesUniteFoUsingGET(idCorrelation: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).searchUsesUniteFoUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de rÃ©cupÃ©rer une liste d\'utilisateur paginÃ©e
     * @param {searchUsingGETQueryParams} queryParams objet contenant les query params de la route searchUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchUsingGET(queryParams: SearchUsingGETQueryParams, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).searchUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant Ã  l\'admin fonctionnel de rÃ©cupÃ©rer les flux de fourriÃ¨res Hermes
     * @param {searchUsingGET1QueryParams} queryParams objet contenant les query params de la route searchUsingGET1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchUsingGET1(queryParams: SearchUsingGET1QueryParams, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).searchUsingGET1(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant Ã  l\'admin fonctionnel de rÃ©cupÃ©rer les warnings d\'un flux de fourriÃ¨res Hermes
     * @param {string} fluxId Id du flux, reprÃ©sentÃ© par un UUID
     * @param {searchWarningsUsingGETQueryParams} queryParams objet contenant les query params de la route searchWarningsUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchWarningsUsingGET(fluxId: string, queryParams: SearchWarningsUsingGETQueryParams, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).searchWarningsUsingGET(fluxId, queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant Ã  l\'admin fonctionnel de supprimer un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public suppressionDossierUsingDELETE(id: string, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).suppressionDossierUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de mettre Ã  jour une autoritÃ© de fourriÃ¨re.
     * @param {string} idCorrelation ID de l\'autorite de fourriÃ¨re, reprÃ©sentÃ© par un UUID
     * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateAutoriteFourriereUsingPUT(idCorrelation: string, autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).updateAutoriteFourriereUsingPUT(idCorrelation, autoriteFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de mettre Ã  jour un centre VHU.
     * @param {string} idCorrelation ID du centre vhu, reprÃ©sentÃ© par un UUID
     * @param {CentreVHURequestDto} centreVHURequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateCentreVHUUsingPUT(idCorrelation: string, centreVHURequestDto: CentreVHURequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).updateCentreVHUUsingPUT(idCorrelation, centreVHURequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de modifier le classement d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateClassementRequestDto} updateClassementRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateClassementUsingPOST1(id: string, updateClassementRequestDto: UpdateClassementRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).updateClassementUsingPOST1(id, updateClassementRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de modifier les les donnÃ©es du vÃ©hicule d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateDonneesVehiculeRequestDto} updateDonneesVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateDonneesVehiculeUsingPOST(id: string, updateDonneesVehiculeRequestDto: UpdateDonneesVehiculeRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).updateDonneesVehiculeUsingPOST(id, updateDonneesVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de modifier les entitÃ©es de rattachement d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateEntitesRattachementUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).updateEntitesRattachementUsingPOST(id, updateEntitesRattachementRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de mettre Ã  jour un domain faq.
     * @param {string} idCorrelation ID du domaine faq, reprÃ©sentÃ© par un UUID
     * @param {FaqDomainRequestDto} faqDomainRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateFaqDomainUsingPUT(idCorrelation: string, faqDomainRequestDto: FaqDomainRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).updateFaqDomainUsingPUT(idCorrelation, faqDomainRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de mettre Ã  jour une question
     * @param {string} idCorrelation ID de la question, reprÃ©sentÃ© par un UUID
     * @param {FaqRequestDto} faqRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateFaqUsingPUT(idCorrelation: string, faqRequestDto: FaqRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).updateFaqUsingPUT(idCorrelation, faqRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de mettre Ã  jour une fourriÃ¨re.
     * @param {string} idCorrelation ID de la fourrÃ¨re, reprÃ©sentÃ©e par un UUID
     * @param {FourriereRequestDto} fourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateFourriereUsingPUT(idCorrelation: string, fourriereRequestDto: FourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).updateFourriereUsingPUT(idCorrelation, fourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de renseigner manuellement les donnÃ©es siv d\'un vÃ©hicule
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {InfosSivRequestDto} infosSivRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateInfosSivManuelUsingPOST(id: string, infosSivRequestDto: InfosSivRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).updateInfosSivManuelUsingPOST(id, infosSivRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de modifier la catÃ©gorie d\'une marque.
     * @param {string} idCorrelation 
     * @param {MarqueRequestDto} marqueRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateMarqueUsingPOST(idCorrelation: string, marqueRequestDto: MarqueRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).updateMarqueUsingPOST(idCorrelation, marqueRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de mettre Ã  jour un motif de MEF.
     * @param {string} idCorrelation ID du motif de MEF, reprÃ©sentÃ© par un UUID
     * @param {MotifMefRequestDto} motifMefRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateMotifMefUsingPUT(idCorrelation: string, motifMefRequestDto: MotifMefRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).updateMotifMefUsingPUT(idCorrelation, motifMefRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de modifier un motif de sortie.
     * @param {string} idCorrelation Identifiant de correlation du motif
     * @param {MotifSortieRequestDto} motifSortieRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateMotifSortieUsingPUT(idCorrelation: string, motifSortieRequestDto: MotifSortieRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).updateMotifSortieUsingPUT(idCorrelation, motifSortieRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de modifier les donnÃ©es de sortie d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateSortieVehiculeRequestDto} updateSortieVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateSortieVehiculeUsingPOST(id: string, updateSortieVehiculeRequestDto: UpdateSortieVehiculeRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).updateSortieVehiculeUsingPOST(id, updateSortieVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de modifier un type d\'AF.
     * @param {string} idCorrelation Identifiant de correlation du type
     * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateTypeAfUsingPUT(idCorrelation: string, typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).updateTypeAfUsingPUT(idCorrelation, typeAutoriteFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de mettre Ã  jour une unitÃ© de force de l\'ordre.
     * @param {string} idCorrelation ID de l\'unitÃ© force de l\'ordre, reprÃ©sentÃ© par un UUID
     * @param {UniteFoRequestDto} uniteFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateUniteFoUsingPUT(idCorrelation: string, uniteFoRequestDto: UniteFoRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).updateUniteFoUsingPUT(idCorrelation, uniteFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de mettre Ã  jour un utilisateur
     * @param {string} userId Id du compte Ã  mettre Ã  jour
     * @param {UpdateUserRequestDto} updateUserRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateUsingPUT(userId: string, updateUserRequestDto: UpdateUserRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).updateUsingPUT(userId, updateUserRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'une autoritÃ© fourriÃ¨re.
     * @param {'NUMERO_DEPARTEMENT' | 'LIBELLE_DEPARTEMENT' | 'TYPE' | 'LIBELLE' | 'CODE_POSTAL' | 'VILLE' | 'VOIE' | 'ID_HERMES' | 'ID_HERMES_UTILISATEUR'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCreateAutoriteFourriereFieldUsingPOST(field: 'NUMERO_DEPARTEMENT' | 'LIBELLE_DEPARTEMENT' | 'TYPE' | 'LIBELLE' | 'CODE_POSTAL' | 'VILLE' | 'VOIE' | 'ID_HERMES' | 'ID_HERMES_UTILISATEUR', autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateCreateAutoriteFourriereFieldUsingPOST(field, autoriteFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'une autoritÃ© de fourriÃ¨re.
     * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCreateAutoriteFourriereUsingPOST(autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateCreateAutoriteFourriereUsingPOST(autoriteFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'un centre vhu.
     * @param {'RAISON_SOCIALE' | 'SIREN' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE' | 'EMAIL' | 'NUMERO_AGREMENT_VHU' | 'PREFECTURE_DELIVRANTE'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {CentreVHURequestDto} centreVHURequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCreateCentreVHUFieldUsingPOST(field: 'RAISON_SOCIALE' | 'SIREN' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE' | 'EMAIL' | 'NUMERO_AGREMENT_VHU' | 'PREFECTURE_DELIVRANTE', centreVHURequestDto: CentreVHURequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateCreateCentreVHUFieldUsingPOST(field, centreVHURequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'un centre VHU
     * @param {CentreVHURequestDto} centreVHURequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCreateCentreVHUUsingPOST(centreVHURequestDto: CentreVHURequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateCreateCentreVHUUsingPOST(centreVHURequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route validant le champ spÃ©cifiÃ© dans le formulaire de crÃ©ation d\'un compte.
     * @param {'PRENOM' | 'NOM' | 'EMAIL' | 'ENTITE_RATTACHEMENT' | 'PROFILE' | 'ACTIF'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {CreateUserRequestDto} createUserRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCreateFieldUsingPOST(field: 'PRENOM' | 'NOM' | 'EMAIL' | 'ENTITE_RATTACHEMENT' | 'PROFILE' | 'ACTIF', createUserRequestDto: CreateUserRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateCreateFieldUsingPOST(field, createUserRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'une fourriÃ¨re.
     * @param {'RAISON_SOCIALE' | 'RAISON_SOCIALE_DNID' | 'AF_RATTACHEMENT' | 'CODE_POSTAL' | 'ADRESSE' | 'NUMERO_VOIE' | 'REPETITION' | 'COMMUNE' | 'TELEPHONE_PORTABLE' | 'TELEPHONE_FIXE' | 'EMAIL' | 'EMAIL_RESPONSABLE' | 'ID_HERMES'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {FourriereRequestDto} fourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCreateFourriereFieldUsingPOST(field: 'RAISON_SOCIALE' | 'RAISON_SOCIALE_DNID' | 'AF_RATTACHEMENT' | 'CODE_POSTAL' | 'ADRESSE' | 'NUMERO_VOIE' | 'REPETITION' | 'COMMUNE' | 'TELEPHONE_PORTABLE' | 'TELEPHONE_FIXE' | 'EMAIL' | 'EMAIL_RESPONSABLE' | 'ID_HERMES', fourriereRequestDto: FourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateCreateFourriereFieldUsingPOST(field, fourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'une fourriÃ¨re.
     * @param {FourriereRequestDto} fourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCreateFourriereUsingPOST(fourriereRequestDto: FourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateCreateFourriereUsingPOST(fourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'un motif de MEF.
     * @param {'MOTIF_MEF' | 'BRANCHE' | 'NATURE' | 'DICEM' | 'RODEO'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {MotifMefRequestDto} motifMefRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCreateMotifMefFieldUsingPOST(field: 'MOTIF_MEF' | 'BRANCHE' | 'NATURE' | 'DICEM' | 'RODEO', motifMefRequestDto: MotifMefRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateCreateMotifMefFieldUsingPOST(field, motifMefRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'un motif de MEF.
     * @param {MotifMefRequestDto} motifMefRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCreateMotifMefUsingPOST(motifMefRequestDto: MotifMefRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateCreateMotifMefUsingPOST(motifMefRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'un motif de sortie.
     * @param {'MOTIF' | 'COMMENTAIRE' | 'DELAI_EFFACEMENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {MotifSortieRequestDto} motifSortieRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCreateMotifSortieFieldUsingPOST(field: 'MOTIF' | 'COMMENTAIRE' | 'DELAI_EFFACEMENT', motifSortieRequestDto: MotifSortieRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateCreateMotifSortieFieldUsingPOST(field, motifSortieRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'un motif de sortie.
     * @param {MotifSortieRequestDto} motifSortieRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCreateMotifSortieUsingPOST(motifSortieRequestDto: MotifSortieRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateCreateMotifSortieUsingPOST(motifSortieRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'un type d\'AF.
     * @param {'TYPE' | 'LIBELLE_NOTIFICATION' | 'CODE_TYPOLOGIE_NIVEAU_1' | 'CODE_TYPOLOGIE_NIVEAU_2'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCreateTypeAFFieldUsingPOST(field: 'TYPE' | 'LIBELLE_NOTIFICATION' | 'CODE_TYPOLOGIE_NIVEAU_1' | 'CODE_TYPOLOGIE_NIVEAU_2', typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateCreateTypeAFFieldUsingPOST(field, typeAutoriteFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'un type d\'AF.
     * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCreateTypeAfUsingPOST(typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateCreateTypeAfUsingPOST(typeAutoriteFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'une unitÃ© FO.
     * @param {'TYPE' | 'CODE' | 'UNITE' | 'ABREVIATION' | 'CODE_POSTAL' | 'EMAIL' | 'TELEPHONE' | 'CODE_INSEE' | 'PARENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {UniteFoRequestDto} uniteFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCreateUniteFoFieldUsingPOST(field: 'TYPE' | 'CODE' | 'UNITE' | 'ABREVIATION' | 'CODE_POSTAL' | 'EMAIL' | 'TELEPHONE' | 'CODE_INSEE' | 'PARENT', uniteFoRequestDto: UniteFoRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateCreateUniteFoFieldUsingPOST(field, uniteFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'une unite FO.
     * @param {UniteFoRequestDto} uniteFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCreateUniteFoUsingPOST(uniteFoRequestDto: UniteFoRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateCreateUniteFoUsingPOST(uniteFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route validant tous les champs du formulaire de crÃ©ation d\'un utilisateur
     * @param {CreateUserRequestDto} createUserRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCreateUsingPOST(createUserRequestDto: CreateUserRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateCreateUsingPOST(createUserRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'un domaine faq.
     * @param {'NAME'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {FaqDomainRequestDto} faqDomainRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFaqDomainFieldUsingPOST(field: 'NAME', faqDomainRequestDto: FaqDomainRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateFaqDomainFieldUsingPOST(field, faqDomainRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'un domaine faq
     * @param {FaqDomainRequestDto} faqDomainRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFaqDomainUsingPOST(faqDomainRequestDto: FaqDomainRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateFaqDomainUsingPOST(faqDomainRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de crÃ©ation d\'une question
     * @param {'DOMAIN_ID' | 'TITLE' | 'PROFILES' | 'CONTENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {FaqRequestDto} faqRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFaqFieldUsingPOST(field: 'DOMAIN_ID' | 'TITLE' | 'PROFILES' | 'CONTENT', faqRequestDto: FaqRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateFaqFieldUsingPOST(field, faqRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de crÃ©ation d\'une question
     * @param {FaqRequestDto} faqRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFaqUsingPOST(faqRequestDto: FaqRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateFaqUsingPOST(faqRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les modifications du classement d\'un dossier
     * @param {'JUSTIFICATION' | 'NEW_CLASSEMENT'} field 
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateClassementRequestDto} updateClassementRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFieldUpdateClassementUsingPOST(field: 'JUSTIFICATION' | 'NEW_CLASSEMENT', id: string, updateClassementRequestDto: UpdateClassementRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateFieldUpdateClassementUsingPOST(field, id, updateClassementRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les modifications de la notification manuelle d\'un dossier
     * @param {'NEW_DATE_ENVOI' | 'NEW_DATE_SIGNATURE' | 'NEW_ETAT_DISTRIBUTION' | 'JUSTIFICATION'} field 
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {FixDataNotificationManuelleRequestDto} fixDataNotificationManuelleRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFixDataNotificationManuelleFieldUsingPOST(field: 'NEW_DATE_ENVOI' | 'NEW_DATE_SIGNATURE' | 'NEW_ETAT_DISTRIBUTION' | 'JUSTIFICATION', id: string, fixDataNotificationManuelleRequestDto: FixDataNotificationManuelleRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateFixDataNotificationManuelleFieldUsingPOST(field, id, fixDataNotificationManuelleRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les modifications de la notification manuelle d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {FixDataNotificationManuelleRequestDto} fixDataNotificationManuelleRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFixDataNotificationManuelleUsingPOST(id: string, fixDataNotificationManuelleRequestDto: FixDataNotificationManuelleRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateFixDataNotificationManuelleUsingPOST(id, fixDataNotificationManuelleRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les modifications d\'un champ de la migration d\'unitÃ© FO
     * @param {'NEW_UNITE_FO_CORRELATION_ID' | 'OLD_UNITE_FO_CORRELATION_ID' | 'COMMENT'} field 
     * @param {MigrateUniteFoRequestDto} migrateUniteFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateMigrateUniteFoFieldUsingPOST(field: 'NEW_UNITE_FO_CORRELATION_ID' | 'OLD_UNITE_FO_CORRELATION_ID' | 'COMMENT', migrateUniteFoRequestDto: MigrateUniteFoRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateMigrateUniteFoFieldUsingPOST(field, migrateUniteFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les modifications de la migration d\'unitÃ© FO
     * @param {MigrateUniteFoRequestDto} migrateUniteFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateMigrateUniteFoUsingPOST(migrateUniteFoRequestDto: MigrateUniteFoRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateMigrateUniteFoUsingPOST(migrateUniteFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'une autoritÃ© fourriÃ¨re.
     * @param {'NUMERO_DEPARTEMENT' | 'LIBELLE_DEPARTEMENT' | 'TYPE' | 'LIBELLE' | 'CODE_POSTAL' | 'VILLE' | 'VOIE' | 'ID_HERMES' | 'ID_HERMES_UTILISATEUR'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {string} idCorrelation ID de l\'autorite de fourriÃ¨re, reprÃ©sentÃ© par un UUID
     * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateAutoriteFourriereFieldUsingPOST(field: 'NUMERO_DEPARTEMENT' | 'LIBELLE_DEPARTEMENT' | 'TYPE' | 'LIBELLE' | 'CODE_POSTAL' | 'VILLE' | 'VOIE' | 'ID_HERMES' | 'ID_HERMES_UTILISATEUR', idCorrelation: string, autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateAutoriteFourriereFieldUsingPOST(field, idCorrelation, autoriteFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'une autoritÃ© de fourriÃ¨re.
     * @param {string} idCorrelation ID de l\'autorite de fourriÃ¨re, reprÃ©sentÃ© par un UUID
     * @param {AutoriteFourriereRequestDto} autoriteFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateAutoriteFourriereUsingPOST(idCorrelation: string, autoriteFourriereRequestDto: AutoriteFourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateAutoriteFourriereUsingPOST(idCorrelation, autoriteFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'un centre vhu.
     * @param {'RAISON_SOCIALE' | 'SIREN' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE' | 'EMAIL' | 'NUMERO_AGREMENT_VHU' | 'PREFECTURE_DELIVRANTE'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {string} idCorrelation Id de corrÃ©lation du centre vhu Ã  mettre Ã  jour
     * @param {CentreVHURequestDto} centreVHURequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateCentreVHUFieldUsingPOST(field: 'RAISON_SOCIALE' | 'SIREN' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE' | 'EMAIL' | 'NUMERO_AGREMENT_VHU' | 'PREFECTURE_DELIVRANTE', idCorrelation: string, centreVHURequestDto: CentreVHURequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateCentreVHUFieldUsingPOST(field, idCorrelation, centreVHURequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'un centre vhu.
     * @param {string} idCorrelation Id de corrÃ©lation du centre vhu Ã  mettre Ã  jour
     * @param {CentreVHURequestDto} centreVHURequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateCentreVHUUsingPOST(idCorrelation: string, centreVHURequestDto: CentreVHURequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateCentreVHUUsingPOST(idCorrelation, centreVHURequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les modifications du classement d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateClassementRequestDto} updateClassementRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateClassementUsingPOST(id: string, updateClassementRequestDto: UpdateClassementRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateClassementUsingPOST(id, updateClassementRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les modifications des donnÃ©es du vÃ©hicule d\'un dossier
     * @param {'NEW_MOTIF_ENTREE_ID_CORRELATION' | 'NEW_IS_PAYS_ETRANGER' | 'NEW_IS_SANS_PLAQUE' | 'NEW_PLAQUE_IMMATRICULATION' | 'NEW_ETAT_ID_CORRELATION' | 'COMMENT'} field 
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateDonneesVehiculeRequestDto} updateDonneesVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateDonneesVehiculeFieldUsingPOST(field: 'NEW_MOTIF_ENTREE_ID_CORRELATION' | 'NEW_IS_PAYS_ETRANGER' | 'NEW_IS_SANS_PLAQUE' | 'NEW_PLAQUE_IMMATRICULATION' | 'NEW_ETAT_ID_CORRELATION' | 'COMMENT', id: string, updateDonneesVehiculeRequestDto: UpdateDonneesVehiculeRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateDonneesVehiculeFieldUsingPOST(field, id, updateDonneesVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les modifications des donnÃ©es d\'un vÃ©hicule et de l\'infraction d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateDonneesVehiculeRequestDto} updateDonneesVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateDonneesVehiculeUsingPOST(id: string, updateDonneesVehiculeRequestDto: UpdateDonneesVehiculeRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateDonneesVehiculeUsingPOST(id, updateDonneesVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les modifications des entitÃ©es de rattachement d\'un dossier
     * @param {'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT'} field 
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateEntitesRattachementFieldUsingPOST(field: 'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT', id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateEntitesRattachementFieldUsingPOST(field, id, updateEntitesRattachementRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les modifications des entitÃ©es de rattachement d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateEntitesRattachementUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateEntitesRattachementUsingPOST(id, updateEntitesRattachementRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de modification d\'une question
     * @param {'DOMAIN_ID' | 'TITLE' | 'PROFILES' | 'CONTENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {string} idCorrelation Id de corrÃ©lation de la question Ã  mettre Ã  jour
     * @param {FaqRequestDto} faqRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateFaqFieldUsingPOST(field: 'DOMAIN_ID' | 'TITLE' | 'PROFILES' | 'CONTENT', idCorrelation: string, faqRequestDto: FaqRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateFaqFieldUsingPOST(field, idCorrelation, faqRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de la modification d\'une question
     * @param {string} idCorrelation Id de corrÃ©lation de la question Ã  mettre Ã  jour
     * @param {FaqRequestDto} faqRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateFaqUsingPOST(idCorrelation: string, faqRequestDto: FaqRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateFaqUsingPOST(idCorrelation, faqRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route validant le champ spÃ©cifiÃ© dans le formulaire de mise Ã  jour d\'un compte.
     * @param {'FIRSTNAME' | 'LASTNAME' | 'EMAIL' | 'UNITE_RATTACHEMENT' | 'ACTIF'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {string} userId ID du compte, reprÃ©sentÃ© par une chaine de caractÃ¨res.
     * @param {UpdateUserRequestDto} updateUserRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateFieldUsingPOST(field: 'FIRSTNAME' | 'LASTNAME' | 'EMAIL' | 'UNITE_RATTACHEMENT' | 'ACTIF', userId: string, updateUserRequestDto: UpdateUserRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateFieldUsingPOST(field, userId, updateUserRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'une fourriÃ¨re.
     * @param {'RAISON_SOCIALE' | 'RAISON_SOCIALE_DNID' | 'AF_RATTACHEMENT' | 'CODE_POSTAL' | 'ADRESSE' | 'NUMERO_VOIE' | 'REPETITION' | 'COMMUNE' | 'TELEPHONE_PORTABLE' | 'TELEPHONE_FIXE' | 'EMAIL' | 'EMAIL_RESPONSABLE' | 'ID_HERMES'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {string} idCorrelation Id de corrÃ©lation de la fourriÃ¨re Ã  mettre Ã  jour
     * @param {FourriereRequestDto} fourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateFourriereFieldUsingPOST(field: 'RAISON_SOCIALE' | 'RAISON_SOCIALE_DNID' | 'AF_RATTACHEMENT' | 'CODE_POSTAL' | 'ADRESSE' | 'NUMERO_VOIE' | 'REPETITION' | 'COMMUNE' | 'TELEPHONE_PORTABLE' | 'TELEPHONE_FIXE' | 'EMAIL' | 'EMAIL_RESPONSABLE' | 'ID_HERMES', idCorrelation: string, fourriereRequestDto: FourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateFourriereFieldUsingPOST(field, idCorrelation, fourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'une fourriÃ¨re.
     * @param {string} idCorrelation Id de corrÃ©lation de la fourriÃ¨re Ã  mettre Ã  jour
     * @param {FourriereRequestDto} fourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateFourriereUsingPOST(idCorrelation: string, fourriereRequestDto: FourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateFourriereUsingPOST(idCorrelation, fourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'une marque.
     * @param {'CATEGORIE' | 'TYPE' | 'MARQUE_EXACTE'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {string} idCorrelation Id de corrÃ©lation de la marque Ã  mettre Ã  jour
     * @param {MarqueRequestDto} marqueRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateMarqueFieldUsingPOST(field: 'CATEGORIE' | 'TYPE' | 'MARQUE_EXACTE', idCorrelation: string, marqueRequestDto: MarqueRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateMarqueFieldUsingPOST(field, idCorrelation, marqueRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'une marque.
     * @param {string} idCorrelation Id de corrÃ©lation de la marque Ã  mettre Ã  jour
     * @param {MarqueRequestDto} marqueRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateMarqueUsingPOST(idCorrelation: string, marqueRequestDto: MarqueRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateMarqueUsingPOST(idCorrelation, marqueRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'un motif de mef.
     * @param {'MOTIF_MEF' | 'BRANCHE' | 'NATURE' | 'DICEM' | 'RODEO'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {string} idCorrelation Id de corrÃ©lation du motif de MEF Ã  mettre Ã  jour
     * @param {MotifMefRequestDto} motifMefRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateMotifMefFieldUsingPOST(field: 'MOTIF_MEF' | 'BRANCHE' | 'NATURE' | 'DICEM' | 'RODEO', idCorrelation: string, motifMefRequestDto: MotifMefRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateMotifMefFieldUsingPOST(field, idCorrelation, motifMefRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'un motif de MEF.
     * @param {string} idCorrelation Id de corrÃ©lation du motif Ã  mettre Ã  jour
     * @param {MotifMefRequestDto} motifMefRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateMotifMefUsingPOST(idCorrelation: string, motifMefRequestDto: MotifMefRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateMotifMefUsingPOST(idCorrelation, motifMefRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'un motif de sortie.
     * @param {'MOTIF' | 'COMMENTAIRE' | 'DELAI_EFFACEMENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {string} idCorrelation Identifiant de correlation du motif
     * @param {MotifSortieRequestDto} motifSortieRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateMotifSortieFieldUsingPUT(field: 'MOTIF' | 'COMMENTAIRE' | 'DELAI_EFFACEMENT', idCorrelation: string, motifSortieRequestDto: MotifSortieRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateMotifSortieFieldUsingPUT(field, idCorrelation, motifSortieRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'un motif de sortie.
     * @param {string} idCorrelation Identifiant de correlation du motif
     * @param {MotifSortieRequestDto} motifSortieRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateMotifSortieUsingPOST(idCorrelation: string, motifSortieRequestDto: MotifSortieRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateMotifSortieUsingPOST(idCorrelation, motifSortieRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les modifications des entitÃ©es de rattachement d\'un dossier
     * @param {'DATE_SORTIE' | 'MOTIF_SORTIE' | 'COMMENT'} field 
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateSortieVehiculeRequestDto} updateSortieVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateSortieVehiculeFieldUsingPOST(field: 'DATE_SORTIE' | 'MOTIF_SORTIE' | 'COMMENT', id: string, updateSortieVehiculeRequestDto: UpdateSortieVehiculeRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateSortieVehiculeFieldUsingPOST(field, id, updateSortieVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les modifications de la sortie d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateSortieVehiculeRequestDto} updateSortieVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateSortieVehiculeUsingPOST(id: string, updateSortieVehiculeRequestDto: UpdateSortieVehiculeRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateSortieVehiculeUsingPOST(id, updateSortieVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'un type d\'AF.
     * @param {'TYPE' | 'LIBELLE_NOTIFICATION' | 'CODE_TYPOLOGIE_NIVEAU_1' | 'CODE_TYPOLOGIE_NIVEAU_2'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {string} idCorrelation Identifiant de correlation du type
     * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateTypeAfFieldUsingPOST(field: 'TYPE' | 'LIBELLE_NOTIFICATION' | 'CODE_TYPOLOGIE_NIVEAU_1' | 'CODE_TYPOLOGIE_NIVEAU_2', idCorrelation: string, typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateTypeAfFieldUsingPOST(field, idCorrelation, typeAutoriteFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'un type d\'AF.
     * @param {string} idCorrelation Identifiant de correlation du type
     * @param {TypeAutoriteFourriereRequestDto} typeAutoriteFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateTypeAfUsingPOST(idCorrelation: string, typeAutoriteFourriereRequestDto: TypeAutoriteFourriereRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateTypeAfUsingPOST(idCorrelation, typeAutoriteFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des donnÃ©es de mise Ã  jour d\'une unitÃ© FO.
     * @param {'TYPE' | 'CODE' | 'UNITE' | 'ABREVIATION' | 'CODE_POSTAL' | 'EMAIL' | 'TELEPHONE' | 'CODE_INSEE' | 'PARENT'} field Nom du champ Ã  valider, dÃ©fini par un enum
     * @param {string} idCorrelation Id de corrÃ©lation de l\'unitÃ© FO Ã  mettre Ã  jour
     * @param {UniteFoRequestDto} uniteFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateUniteFoFieldUsingPOST(field: 'TYPE' | 'CODE' | 'UNITE' | 'ABREVIATION' | 'CODE_POSTAL' | 'EMAIL' | 'TELEPHONE' | 'CODE_INSEE' | 'PARENT', idCorrelation: string, uniteFoRequestDto: UniteFoRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateUniteFoFieldUsingPOST(field, idCorrelation, uniteFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider les donnÃ©es de mise Ã  jour d\'une unite FO.
     * @param {string} idCorrelation Id de corrÃ©lation de l\'unitÃ© FO Ã  mettre Ã  jour
     * @param {UniteFoRequestDto} uniteFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateUniteFoUsingPOST(idCorrelation: string, uniteFoRequestDto: UniteFoRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateUniteFoUsingPOST(idCorrelation, uniteFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route validant tous les champs du formulaire de mise Ã  jour d\'un utilisateur
     * @param {string} userId ID du compte, reprÃ©sentÃ© par une chaine de caractÃ¨res.
     * @param {UpdateUserRequestDto} updateUserRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateUsingPOST(userId: string, updateUserRequestDto: UpdateUserRequestDto, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).validateUpdateUsingPOST(userId, updateUserRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant Ã  l\'admin fonctionnel de rÃ©cupÃ©rer les warnings d\'un flux
     * @param {string} fluxId Id du flux, reprÃ©sentÃ© par un UUID.
     * @param {warningsOfFluxUsingGETQueryParams} queryParams objet contenant les query params de la route warningsOfFluxUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public warningsOfFluxUsingGET(fluxId: string, queryParams: WarningsOfFluxUsingGETQueryParams, options?: any) {
        return AdminFonctionnelControllerApiFp(this.configuration).warningsOfFluxUsingGET(fluxId, queryParams, options)(this.fetch, this.basePath);
    }

}

//////////////////////////////////// AuthControllerApi /////////////////////////////////////////////////////

/**
 * objet contenant les query params de la route authenticateUsingGET
 * @export
 * @interface AuthenticateUsingGETQueryParams
 */
export interface AuthenticateUsingGETQueryParams {
    /**
     * Code fourni par le fournisseur d\'identitÃ©s dans les queryparam
     * @type {string}
     * @memberof AuthenticateUsingGETQueryParams
     */
    code: string;
    /**
     * Uri de redirection, cette URL doit Ãªtre configurÃ©e dans le fournisseur d\'identitÃ©s
     * @type {string}
     * @memberof AuthenticateUsingGETQueryParams
     */
    redirectUri: string;
}

/**
 * objet contenant les query params de la route redirectToLoginUsingGET
 * @export
 * @interface RedirectToLoginUsingGETQueryParams
 */
export interface RedirectToLoginUsingGETQueryParams {
    /**
     * Uri de redirection, cette URL doit Ãªtre configurÃ©e dans le fournisseur d\'identitÃ©s
     * @type {string}
     * @memberof RedirectToLoginUsingGETQueryParams
     */
    redirectUri: string;
}

/**
 * objet contenant les query params de la route redirectToLogoutUsingGET
 * @export
 * @interface RedirectToLogoutUsingGETQueryParams
 */
export interface RedirectToLogoutUsingGETQueryParams {
    /**
     * Uri de redirection, cette URL doit Ãªtre configurÃ©e dans le fournisseur d\'identitÃ©s
     * @type {string}
     * @memberof RedirectToLogoutUsingGETQueryParams
     */
    redirectUri: string;
}

/**
 * objet contenant les query params de la route refreshUsingGET
 * @export
 * @interface RefreshUsingGETQueryParams
 */
export interface RefreshUsingGETQueryParams {
    /**
     * Jeton de rafraÃ®chissement
     * @type {string}
     * @memberof RefreshUsingGETQueryParams
     */
    refreshToken: string;
}

/**
 * AuthControllerApi - fetch parameter creator
 * @export
 */
export const AuthControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant de s\'authentifier depuis le portail OpenId connect.
         * @param {authenticateUsingGETQueryParams} queryParams objet contenant les query params de la route authenticateUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUsingGET(queryParams: AuthenticateUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'code' is not null or undefined
            if (queryParams.code === null || queryParams.code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling authenticateUsingGET.');
            }
            // verify required parameter 'redirectUri' is not null or undefined
            if (queryParams.redirectUri === null || queryParams.redirectUri === undefined) {
                throw new RequiredError('redirectUri','Required parameter redirectUri was null or undefined when calling authenticateUsingGET.');
            }
            const localVarPath = `/authentication`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (queryParams.code !== undefined) {
                localVarQueryParameter['code'] = queryParams.code;
            }

            if (queryParams.redirectUri !== undefined) {
                localVarQueryParameter['redirectUri'] = queryParams.redirectUri;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer l\'uri de redirection vers le portail OpenId Connect afin de s\'authentifier.
         * @param {redirectToLoginUsingGETQueryParams} queryParams objet contenant les query params de la route redirectToLoginUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectToLoginUsingGET(queryParams: RedirectToLoginUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'redirectUri' is not null or undefined
            if (queryParams.redirectUri === null || queryParams.redirectUri === undefined) {
                throw new RequiredError('redirectUri','Required parameter redirectUri was null or undefined when calling redirectToLoginUsingGET.');
            }
            const localVarPath = `/authentication/redirect/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (queryParams.redirectUri !== undefined) {
                localVarQueryParameter['redirectUri'] = queryParams.redirectUri;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer l\'uri de redirection vers le portail OpenId Connect afin de se dÃ©connecter.
         * @param {redirectToLogoutUsingGETQueryParams} queryParams objet contenant les query params de la route redirectToLogoutUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectToLogoutUsingGET(queryParams: RedirectToLogoutUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'redirectUri' is not null or undefined
            if (queryParams.redirectUri === null || queryParams.redirectUri === undefined) {
                throw new RequiredError('redirectUri','Required parameter redirectUri was null or undefined when calling redirectToLogoutUsingGET.');
            }
            const localVarPath = `/authentication/redirect/logout`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (queryParams.redirectUri !== undefined) {
                localVarQueryParameter['redirectUri'] = queryParams.redirectUri;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de rafraichir le token utilisateur
         * @param {refreshUsingGETQueryParams} queryParams objet contenant les query params de la route refreshUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshUsingGET(queryParams: RefreshUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'refreshToken' is not null or undefined
            if (queryParams.refreshToken === null || queryParams.refreshToken === undefined) {
                throw new RequiredError('refreshToken','Required parameter refreshToken was null or undefined when calling refreshUsingGET.');
            }
            const localVarPath = `/authentication/refresh`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (queryParams.refreshToken !== undefined) {
                localVarQueryParameter['refreshToken'] = queryParams.refreshToken;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant de s\'authentifier depuis le portail OpenId connect.
         * @param {authenticateUsingGETQueryParams} queryParams objet contenant les query params de la route authenticateUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUsingGET(queryParams: AuthenticateUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JwtDto> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).authenticateUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer l\'uri de redirection vers le portail OpenId Connect afin de s\'authentifier.
         * @param {redirectToLoginUsingGETQueryParams} queryParams objet contenant les query params de la route redirectToLoginUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectToLoginUsingGET(queryParams: RedirectToLoginUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthRedirectDto> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).redirectToLoginUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer l\'uri de redirection vers le portail OpenId Connect afin de se dÃ©connecter.
         * @param {redirectToLogoutUsingGETQueryParams} queryParams objet contenant les query params de la route redirectToLogoutUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectToLogoutUsingGET(queryParams: RedirectToLogoutUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthRedirectDto> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).redirectToLogoutUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de rafraichir le token utilisateur
         * @param {refreshUsingGETQueryParams} queryParams objet contenant les query params de la route refreshUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshUsingGET(queryParams: RefreshUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JwtDto> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).refreshUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
    /**
     * @summary Route permettant de s\'authentifier depuis le portail OpenId connect.
     * @param {authenticateUsingGETQueryParams} queryParams objet contenant les query params de la route authenticateUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public authenticateUsingGET(queryParams: AuthenticateUsingGETQueryParams, options?: any) {
        return AuthControllerApiFp(this.configuration).authenticateUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de rÃ©cupÃ©rer l\'uri de redirection vers le portail OpenId Connect afin de s\'authentifier.
     * @param {redirectToLoginUsingGETQueryParams} queryParams objet contenant les query params de la route redirectToLoginUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public redirectToLoginUsingGET(queryParams: RedirectToLoginUsingGETQueryParams, options?: any) {
        return AuthControllerApiFp(this.configuration).redirectToLoginUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de rÃ©cupÃ©rer l\'uri de redirection vers le portail OpenId Connect afin de se dÃ©connecter.
     * @param {redirectToLogoutUsingGETQueryParams} queryParams objet contenant les query params de la route redirectToLogoutUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public redirectToLogoutUsingGET(queryParams: RedirectToLogoutUsingGETQueryParams, options?: any) {
        return AuthControllerApiFp(this.configuration).redirectToLogoutUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de rafraichir le token utilisateur
     * @param {refreshUsingGETQueryParams} queryParams objet contenant les query params de la route refreshUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public refreshUsingGET(queryParams: RefreshUsingGETQueryParams, options?: any) {
        return AuthControllerApiFp(this.configuration).refreshUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

}

//////////////////////////////////// AutoriteFourriereControllerApi /////////////////////////////////////////////////////

/**
 * objet contenant les query params de la route getSourceImpossibiliteNotifierAfUsingGET
 * @export
 * @interface GetSourceImpossibiliteNotifierAfUsingGETQueryParams
 */
export interface GetSourceImpossibiliteNotifierAfUsingGETQueryParams {
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierAfUsingGETQueryParams
     */
    boitePostale?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierAfUsingGETQueryParams
     */
    codeCedex?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierAfUsingGETQueryParams
     */
    codePostal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierAfUsingGETQueryParams
     */
    complementAdresse?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierAfUsingGETQueryParams
     */
    extension?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierAfUsingGETQueryParams
     */
    libelleCedex?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierAfUsingGETQueryParams
     */
    libelleCommune?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierAfUsingGETQueryParams
     */
    libelleVoie?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierAfUsingGETQueryParams
     */
    lieuDit?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierAfUsingGETQueryParams
     */
    nom?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierAfUsingGETQueryParams
     */
    numero?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierAfUsingGETQueryParams
     */
    pays?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierAfUsingGETQueryParams
     */
    pointRemise?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierAfUsingGETQueryParams
     */
    prenom?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierAfUsingGETQueryParams
     */
    raisonSociale?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierAfUsingGETQueryParams
     */
    typeVoie?: string;
}

/**
 * objet contenant les query params de la route validateProlongationUsingPOST
 * @export
 * @interface ValidateProlongationUsingPOSTQueryParams
 */
export interface ValidateProlongationUsingPOSTQueryParams {
    /**
     * Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @type {'DATE_DECISION_PROCUREUR'}
     * @memberof ValidateProlongationUsingPOSTQueryParams
     */
    field?: 'DATE_DECISION_PROCUREUR';
}

/**
 * AutoriteFourriereControllerApi - fetch parameter creator
 * @export
 */
export const AutoriteFourriereControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant de confirmer les informations de son compte Keycloak
         * @param {string} username Nom d\'utilisateur du compte Keycloak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAccountUsingPUT(username: string, options: any = {}): FetchArgs {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username','Required parameter username was null or undefined when calling confirmAccountUsingPUT.');
            }
            const localVarPath = `/api/comptes-utilisateurs/{username}/confirm`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de confirmer la validitÃ© des informations d\'une autoritÃ© de fourriÃ¨re
         * @param {string} id Id de l\'autoritÃ© de fourriÃ¨re, et non son id de corrÃ©lation, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAutoriteFourriereUsingPUT(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling confirmAutoriteFourriereUsingPUT.');
            }
            const localVarPath = `/api/autorite-fourriere/profil/autorite-fourriere/{id}/confirm`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de calculer les violations menant Ã  une impossibilitÃ© de notifier
          * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {getSourceImpossibiliteNotifierAfUsingGETQueryParams} queryParams objet contenant les query params de la route getSourceImpossibiliteNotifierAfUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourceImpossibiliteNotifierAfUsingGET(id: string, queryParams: GetSourceImpossibiliteNotifierAfUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSourceImpossibiliteNotifierAfUsingGET.');
            }
            const localVarPath = `/api/autorite-fourriere/dossier/{id}/impossibilite-notifier`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.boitePostale !== undefined) {
                localVarQueryParameter['boitePostale'] = queryParams.boitePostale;
            }

            if (queryParams.codeCedex !== undefined) {
                localVarQueryParameter['codeCedex'] = queryParams.codeCedex;
            }

            if (queryParams.codePostal !== undefined) {
                localVarQueryParameter['codePostal'] = queryParams.codePostal;
            }

            if (queryParams.complementAdresse !== undefined) {
                localVarQueryParameter['complementAdresse'] = queryParams.complementAdresse;
            }

            if (queryParams.extension !== undefined) {
                localVarQueryParameter['extension'] = queryParams.extension;
            }

            if (queryParams.libelleCedex !== undefined) {
                localVarQueryParameter['libelleCedex'] = queryParams.libelleCedex;
            }

            if (queryParams.libelleCommune !== undefined) {
                localVarQueryParameter['libelleCommune'] = queryParams.libelleCommune;
            }

            if (queryParams.libelleVoie !== undefined) {
                localVarQueryParameter['libelleVoie'] = queryParams.libelleVoie;
            }

            if (queryParams.lieuDit !== undefined) {
                localVarQueryParameter['lieuDit'] = queryParams.lieuDit;
            }

            if (queryParams.nom !== undefined) {
                localVarQueryParameter['nom'] = queryParams.nom;
            }

            if (queryParams.numero !== undefined) {
                localVarQueryParameter['numero'] = queryParams.numero;
            }

            if (queryParams.pays !== undefined) {
                localVarQueryParameter['pays'] = queryParams.pays;
            }

            if (queryParams.pointRemise !== undefined) {
                localVarQueryParameter['pointRemise'] = queryParams.pointRemise;
            }

            if (queryParams.prenom !== undefined) {
                localVarQueryParameter['prenom'] = queryParams.prenom;
            }

            if (queryParams.raisonSociale !== undefined) {
                localVarQueryParameter['raisonSociale'] = queryParams.raisonSociale;
            }

            if (queryParams.typeVoie !== undefined) {
                localVarQueryParameter['typeVoie'] = queryParams.typeVoie;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route de rÃ©cupÃ©ration des coordonnÃ©es propriÃ©taire d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par son UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCoordonneesProprietaireUsingGET1(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling retrieveCoordonneesProprietaireUsingGET1.');
            }
            const localVarPath = `/api/autorite-fourriere/dossier/{id}/coordonnees-proprietaire`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route de rÃ©cupÃ©ration des donnÃ©es techniques d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par son UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInformationsTechniquesUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling retrieveInformationsTechniquesUsingGET.');
            }
            const localVarPath = `/api/autorite-fourriere/dossier/{id}/informations-techniques`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de prolonger la mise en fourriÃ¨re d\'un vÃ©hicule.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {ProlongationRequestDto} prolongationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitProlongationMiseEnFourriereUsingPOST(id: string, prolongationRequestDto: ProlongationRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling submitProlongationMiseEnFourriereUsingPOST.');
            }
            // verify required parameter 'prolongationRequestDto' is not null or undefined
            if (prolongationRequestDto === null || prolongationRequestDto === undefined) {
                throw new RequiredError('prolongationRequestDto','Required parameter prolongationRequestDto was null or undefined when calling submitProlongationMiseEnFourriereUsingPOST.');
            }
            const localVarPath = `/api/autorite-fourriere/dossier/{id}/prolongation-mef`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProlongationRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(prolongationRequestDto || {}) : (prolongationRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de modifier le statut de traitement en facturation du dossier.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {boolean} traite Boolean spÃ©cifiant si le dossier a Ã©tÃ© traitÃ© en facturation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTraitementFacturationUsingPOST(id: string, traite: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling submitTraitementFacturationUsingPOST.');
            }
            // verify required parameter 'traite' is not null or undefined
            if (traite === null || traite === undefined) {
                throw new RequiredError('traite','Required parameter traite was null or undefined when calling submitTraitementFacturationUsingPOST.');
            }
            const localVarPath = `/api/autorite-fourriere/dossier/{id}/traitement-facturation/{traite}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"traite"}}`, encodeURIComponent(String(traite)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour les donnÃ©es d\'une autoritÃ© de fourriÃ¨re.
         * @param {string} idCorrelation Id de corrÃ©lation de l\'autoritÃ© de fourriÃ¨re, representÃ© par un UUID.
         * @param {ProfilAutoriteFourriereRequestDto} profilAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAutoriteFourriereInfoUsingPOST(idCorrelation: string, profilAutoriteFourriereRequestDto: ProfilAutoriteFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling updateAutoriteFourriereInfoUsingPOST.');
            }
            // verify required parameter 'profilAutoriteFourriereRequestDto' is not null or undefined
            if (profilAutoriteFourriereRequestDto === null || profilAutoriteFourriereRequestDto === undefined) {
                throw new RequiredError('profilAutoriteFourriereRequestDto','Required parameter profilAutoriteFourriereRequestDto was null or undefined when calling updateAutoriteFourriereInfoUsingPOST.');
            }
            const localVarPath = `/api/autorite-fourriere/profil/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProfilAutoriteFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(profilAutoriteFourriereRequestDto || {}) : (profilAutoriteFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route mettant Ã  jour le classement manuel (non automatique).
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {ClassementManuelRequestDto} classementManuelRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClassementUsingPUT(id: string, classementManuelRequestDto: ClassementManuelRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateClassementUsingPUT.');
            }
            // verify required parameter 'classementManuelRequestDto' is not null or undefined
            if (classementManuelRequestDto === null || classementManuelRequestDto === undefined) {
                throw new RequiredError('classementManuelRequestDto','Required parameter classementManuelRequestDto was null or undefined when calling updateClassementUsingPUT.');
            }
            const localVarPath = `/api/autorite-fourriere/dossier/{id}/classement`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ClassementManuelRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(classementManuelRequestDto || {}) : (classementManuelRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de modifier les coordonnees du propriÃ©taire
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCoordonneesProprietairesAfUsingPOST(id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCoordonneesProprietairesAfUsingPOST.');
            }
            // verify required parameter 'coordonneesProprietaireRequestDto' is not null or undefined
            if (coordonneesProprietaireRequestDto === null || coordonneesProprietaireRequestDto === undefined) {
                throw new RequiredError('coordonneesProprietaireRequestDto','Required parameter coordonneesProprietaireRequestDto was null or undefined when calling updateCoordonneesProprietairesAfUsingPOST.');
            }
            const localVarPath = `/api/autorite-fourriere/dossier/{id}/coordonnees-proprietaires`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CoordonneesProprietaireRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(coordonneesProprietaireRequestDto || {}) : (coordonneesProprietaireRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de modifier les entitÃ©es de rattachement d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntitesRattachementAFUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateEntitesRattachementAFUsingPOST.');
            }
            // verify required parameter 'updateEntitesRattachementRequestDto' is not null or undefined
            if (updateEntitesRattachementRequestDto === null || updateEntitesRattachementRequestDto === undefined) {
                throw new RequiredError('updateEntitesRattachementRequestDto','Required parameter updateEntitesRattachementRequestDto was null or undefined when calling updateEntitesRattachementAFUsingPOST.');
            }
            const localVarPath = `/api/autorite-fourriere/entites-rattachement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateEntitesRattachementRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateEntitesRattachementRequestDto || {}) : (updateEntitesRattachementRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ du formulaire de mise Ã  jour du profile AutoritÃ© de FourriÃ¨re.
         * @param {'TYPE' | 'NOM_VOIE' | 'NUMERO_VOIE' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'CEDEX'} field IntitulÃ© du champ Ã  valider
         * @param {string} idCorrelation Id de l\'autoritÃ© de fourriÃ¨re, reprÃ©sentÃ© par un UUID.
         * @param {ProfilAutoriteFourriereRequestDto} profilAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAutoriteFourriereInfosFieldUsingPOST(field: 'TYPE' | 'NOM_VOIE' | 'NUMERO_VOIE' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'CEDEX', idCorrelation: string, profilAutoriteFourriereRequestDto: ProfilAutoriteFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateAutoriteFourriereInfosFieldUsingPOST.');
            }
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateAutoriteFourriereInfosFieldUsingPOST.');
            }
            // verify required parameter 'profilAutoriteFourriereRequestDto' is not null or undefined
            if (profilAutoriteFourriereRequestDto === null || profilAutoriteFourriereRequestDto === undefined) {
                throw new RequiredError('profilAutoriteFourriereRequestDto','Required parameter profilAutoriteFourriereRequestDto was null or undefined when calling validateAutoriteFourriereInfosFieldUsingPOST.');
            }
            const localVarPath = `/api/autorite-fourriere/profil/{idCorrelation}/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProfilAutoriteFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(profilAutoriteFourriereRequestDto || {}) : (profilAutoriteFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider tous les champs du formulaire de mise Ã  jour du profile AutoritÃ© de FourriÃ¨re.
         * @param {string} idCorrelation Id de l\'autoritÃ© de fourriÃ¨re, reprÃ©sentÃ© par un UUID.
         * @param {ProfilAutoriteFourriereRequestDto} profilAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAutoriteFourriereInfosUsingPOST(idCorrelation: string, profilAutoriteFourriereRequestDto: ProfilAutoriteFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateAutoriteFourriereInfosUsingPOST.');
            }
            // verify required parameter 'profilAutoriteFourriereRequestDto' is not null or undefined
            if (profilAutoriteFourriereRequestDto === null || profilAutoriteFourriereRequestDto === undefined) {
                throw new RequiredError('profilAutoriteFourriereRequestDto','Required parameter profilAutoriteFourriereRequestDto was null or undefined when calling validateAutoriteFourriereInfosUsingPOST.');
            }
            const localVarPath = `/api/autorite-fourriere/profil/{idCorrelation}/validation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProfilAutoriteFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(profilAutoriteFourriereRequestDto || {}) : (profilAutoriteFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route validant le champ spÃ©cifiÃ© dans le formulaire de classement manuel.
         * @param {'DANGEREUX' | 'REPARABLE' | 'ETRANGER' | 'PREMIERE_IMMATRICULATION' | 'PUISSANCE' | 'ENERGIE' | 'CARROSSERIE' | 'GENRE' | 'CLASSEMENT'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {ClassementManuelRequestDto} classementManuelRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateClassementManuelFieldUsingPOST(field: 'DANGEREUX' | 'REPARABLE' | 'ETRANGER' | 'PREMIERE_IMMATRICULATION' | 'PUISSANCE' | 'ENERGIE' | 'CARROSSERIE' | 'GENRE' | 'CLASSEMENT', id: string, classementManuelRequestDto: ClassementManuelRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateClassementManuelFieldUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateClassementManuelFieldUsingPOST.');
            }
            // verify required parameter 'classementManuelRequestDto' is not null or undefined
            if (classementManuelRequestDto === null || classementManuelRequestDto === undefined) {
                throw new RequiredError('classementManuelRequestDto','Required parameter classementManuelRequestDto was null or undefined when calling validateClassementManuelFieldUsingPOST.');
            }
            const localVarPath = `/api/autorite-fourriere/dossier/{id}/classement/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ClassementManuelRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(classementManuelRequestDto || {}) : (classementManuelRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route validant tous les champs du formulaire de classement manuel.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID.
         * @param {ClassementManuelRequestDto} classementManuelRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateClassementManuelUsingPOST(id: string, classementManuelRequestDto: ClassementManuelRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateClassementManuelUsingPOST.');
            }
            // verify required parameter 'classementManuelRequestDto' is not null or undefined
            if (classementManuelRequestDto === null || classementManuelRequestDto === undefined) {
                throw new RequiredError('classementManuelRequestDto','Required parameter classementManuelRequestDto was null or undefined when calling validateClassementManuelUsingPOST.');
            }
            const localVarPath = `/api/autorite-fourriere/dossier/{id}/classement/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ClassementManuelRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(classementManuelRequestDto || {}) : (classementManuelRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider tous les champs du formulaire de mise Ã  jour des coordonnÃ©es propriÃ©taires pour les autoritÃ© de fourriere
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCoordonneesProprietairesAfUsingPOST(id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateCoordonneesProprietairesAfUsingPOST.');
            }
            // verify required parameter 'coordonneesProprietaireRequestDto' is not null or undefined
            if (coordonneesProprietaireRequestDto === null || coordonneesProprietaireRequestDto === undefined) {
                throw new RequiredError('coordonneesProprietaireRequestDto','Required parameter coordonneesProprietaireRequestDto was null or undefined when calling validateCoordonneesProprietairesAfUsingPOST.');
            }
            const localVarPath = `/api/autorite-fourriere/dossier/{id}/coordonnees-proprietaires/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CoordonneesProprietaireRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(coordonneesProprietaireRequestDto || {}) : (coordonneesProprietaireRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ du formulaire de mise Ã  jour des CoordonnÃ©ees propriÃ©taires.
         * @param {'PRENOM' | 'NOM' | 'RAISON_SOCIALE' | 'POINT_REMISE' | 'NUMERO' | 'EXTENSION' | 'TYPEVOIE' | 'LIBELLE_VOIE' | 'COMPLEMENT_ADRESSE' | 'LIEUDIT' | 'BOITE_POSTALE' | 'CODE_POSTAL' | 'LIBELLE_COMMUNE' | 'CODE_CEDEX' | 'LIBELLE_CEDEX' | 'PAYS'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCoordonneesProprietairesFieldAfUsingPOST(field: 'PRENOM' | 'NOM' | 'RAISON_SOCIALE' | 'POINT_REMISE' | 'NUMERO' | 'EXTENSION' | 'TYPEVOIE' | 'LIBELLE_VOIE' | 'COMPLEMENT_ADRESSE' | 'LIEUDIT' | 'BOITE_POSTALE' | 'CODE_POSTAL' | 'LIBELLE_COMMUNE' | 'CODE_CEDEX' | 'LIBELLE_CEDEX' | 'PAYS', id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateCoordonneesProprietairesFieldAfUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateCoordonneesProprietairesFieldAfUsingPOST.');
            }
            // verify required parameter 'coordonneesProprietaireRequestDto' is not null or undefined
            if (coordonneesProprietaireRequestDto === null || coordonneesProprietaireRequestDto === undefined) {
                throw new RequiredError('coordonneesProprietaireRequestDto','Required parameter coordonneesProprietaireRequestDto was null or undefined when calling validateCoordonneesProprietairesFieldAfUsingPOST.');
            }
            const localVarPath = `/api/autorite-fourriere/dossier/{id}/coordonnees-proprietaires/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CoordonneesProprietaireRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(coordonneesProprietaireRequestDto || {}) : (coordonneesProprietaireRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route validant le champ spÃ©cifiÃ© dans le formulaire de mise en fourriÃ¨re. Si aucun champ n\'est spÃ©cifiÃ©, tous les champs remplis seront validÃ©s.
          * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID.
         * @param {validateProlongationUsingPOSTQueryParams} queryParams objet contenant les query params de la route validateProlongationUsingPOST
          * @param {ProlongationRequestDto} prolongationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateProlongationUsingPOST(id: string, queryParams: ValidateProlongationUsingPOSTQueryParams, prolongationRequestDto: ProlongationRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateProlongationUsingPOST.');
            }
            // verify required parameter 'prolongationRequestDto' is not null or undefined
            if (prolongationRequestDto === null || prolongationRequestDto === undefined) {
                throw new RequiredError('prolongationRequestDto','Required parameter prolongationRequestDto was null or undefined when calling validateProlongationUsingPOST.');
            }
            const localVarPath = `/api/autorite-fourriere/dossier/{id}/prolongation/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.field !== undefined) {
                localVarQueryParameter['field'] = queryParams.field;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProlongationRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(prolongationRequestDto || {}) : (prolongationRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider l\'ensemble des champs du formulaire de modifications des entitÃ©s de rattachement d\'un dossier.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateEntitesRattachementAFUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateUpdateEntitesRattachementAFUsingPOST.');
            }
            // verify required parameter 'updateEntitesRattachementRequestDto' is not null or undefined
            if (updateEntitesRattachementRequestDto === null || updateEntitesRattachementRequestDto === undefined) {
                throw new RequiredError('updateEntitesRattachementRequestDto','Required parameter updateEntitesRattachementRequestDto was null or undefined when calling validateUpdateEntitesRattachementAFUsingPOST.');
            }
            const localVarPath = `/api/autorite-fourriere/entites-rattachement/{id}/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateEntitesRattachementRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateEntitesRattachementRequestDto || {}) : (updateEntitesRattachementRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un des champs du formulaire de modifications des entitÃ©s de rattachement d\'un dossier.
         * @param {'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT'} field 
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateEntitesRattachementFieldAFUsingPOST(field: 'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT', id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUpdateEntitesRattachementFieldAFUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateUpdateEntitesRattachementFieldAFUsingPOST.');
            }
            // verify required parameter 'updateEntitesRattachementRequestDto' is not null or undefined
            if (updateEntitesRattachementRequestDto === null || updateEntitesRattachementRequestDto === undefined) {
                throw new RequiredError('updateEntitesRattachementRequestDto','Required parameter updateEntitesRattachementRequestDto was null or undefined when calling validateUpdateEntitesRattachementFieldAFUsingPOST.');
            }
            const localVarPath = `/api/autorite-fourriere/entites-rattachement/{id}/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateEntitesRattachementRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateEntitesRattachementRequestDto || {}) : (updateEntitesRattachementRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AutoriteFourriereControllerApi - functional programming interface
 * @export
 */
export const AutoriteFourriereControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant de confirmer les informations de son compte Keycloak
         * @param {string} username Nom d\'utilisateur du compte Keycloak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAccountUsingPUT(username: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).confirmAccountUsingPUT(username, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de confirmer la validitÃ© des informations d\'une autoritÃ© de fourriÃ¨re
         * @param {string} id Id de l\'autoritÃ© de fourriÃ¨re, et non son id de corrÃ©lation, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAutoriteFourriereUsingPUT(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AutoriteFourriereDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).confirmAutoriteFourriereUsingPUT(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de calculer les violations menant Ã  une impossibilitÃ© de notifier
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {getSourceImpossibiliteNotifierAfUsingGETQueryParams} queryParams objet contenant les query params de la route getSourceImpossibiliteNotifierAfUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourceImpossibiliteNotifierAfUsingGET(id: string, queryParams: GetSourceImpossibiliteNotifierAfUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SourcesImpossibiliteNotifierDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).getSourceImpossibiliteNotifierAfUsingGET(id, queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route de rÃ©cupÃ©ration des coordonnÃ©es propriÃ©taire d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par son UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCoordonneesProprietaireUsingGET1(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CoordonneesProprietaireResponseDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).retrieveCoordonneesProprietaireUsingGET1(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route de rÃ©cupÃ©ration des donnÃ©es techniques d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par son UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInformationsTechniquesUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InformationsTechniquesResponseDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).retrieveInformationsTechniquesUsingGET(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de prolonger la mise en fourriÃ¨re d\'un vÃ©hicule.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {ProlongationRequestDto} prolongationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitProlongationMiseEnFourriereUsingPOST(id: string, prolongationRequestDto: ProlongationRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).submitProlongationMiseEnFourriereUsingPOST(id, prolongationRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de modifier le statut de traitement en facturation du dossier.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {boolean} traite Boolean spÃ©cifiant si le dossier a Ã©tÃ© traitÃ© en facturation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTraitementFacturationUsingPOST(id: string, traite: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).submitTraitementFacturationUsingPOST(id, traite, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour les donnÃ©es d\'une autoritÃ© de fourriÃ¨re.
         * @param {string} idCorrelation Id de corrÃ©lation de l\'autoritÃ© de fourriÃ¨re, representÃ© par un UUID.
         * @param {ProfilAutoriteFourriereRequestDto} profilAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAutoriteFourriereInfoUsingPOST(idCorrelation: string, profilAutoriteFourriereRequestDto: ProfilAutoriteFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AutoriteFourriereDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).updateAutoriteFourriereInfoUsingPOST(idCorrelation, profilAutoriteFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route mettant Ã  jour le classement manuel (non automatique).
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {ClassementManuelRequestDto} classementManuelRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClassementUsingPUT(id: string, classementManuelRequestDto: ClassementManuelRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).updateClassementUsingPUT(id, classementManuelRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de modifier les coordonnees du propriÃ©taire
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCoordonneesProprietairesAfUsingPOST(id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).updateCoordonneesProprietairesAfUsingPOST(id, coordonneesProprietaireRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de modifier les entitÃ©es de rattachement d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntitesRattachementAFUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).updateEntitesRattachementAFUsingPOST(id, updateEntitesRattachementRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ du formulaire de mise Ã  jour du profile AutoritÃ© de FourriÃ¨re.
         * @param {'TYPE' | 'NOM_VOIE' | 'NUMERO_VOIE' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'CEDEX'} field IntitulÃ© du champ Ã  valider
         * @param {string} idCorrelation Id de l\'autoritÃ© de fourriÃ¨re, reprÃ©sentÃ© par un UUID.
         * @param {ProfilAutoriteFourriereRequestDto} profilAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAutoriteFourriereInfosFieldUsingPOST(field: 'TYPE' | 'NOM_VOIE' | 'NUMERO_VOIE' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'CEDEX', idCorrelation: string, profilAutoriteFourriereRequestDto: ProfilAutoriteFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProfilAutoriteFourriereViolationsDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).validateAutoriteFourriereInfosFieldUsingPOST(field, idCorrelation, profilAutoriteFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider tous les champs du formulaire de mise Ã  jour du profile AutoritÃ© de FourriÃ¨re.
         * @param {string} idCorrelation Id de l\'autoritÃ© de fourriÃ¨re, reprÃ©sentÃ© par un UUID.
         * @param {ProfilAutoriteFourriereRequestDto} profilAutoriteFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAutoriteFourriereInfosUsingPOST(idCorrelation: string, profilAutoriteFourriereRequestDto: ProfilAutoriteFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProfilAutoriteFourriereViolationsDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).validateAutoriteFourriereInfosUsingPOST(idCorrelation, profilAutoriteFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route validant le champ spÃ©cifiÃ© dans le formulaire de classement manuel.
         * @param {'DANGEREUX' | 'REPARABLE' | 'ETRANGER' | 'PREMIERE_IMMATRICULATION' | 'PUISSANCE' | 'ENERGIE' | 'CARROSSERIE' | 'GENRE' | 'CLASSEMENT'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {ClassementManuelRequestDto} classementManuelRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateClassementManuelFieldUsingPOST(field: 'DANGEREUX' | 'REPARABLE' | 'ETRANGER' | 'PREMIERE_IMMATRICULATION' | 'PUISSANCE' | 'ENERGIE' | 'CARROSSERIE' | 'GENRE' | 'CLASSEMENT', id: string, classementManuelRequestDto: ClassementManuelRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).validateClassementManuelFieldUsingPOST(field, id, classementManuelRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route validant tous les champs du formulaire de classement manuel.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID.
         * @param {ClassementManuelRequestDto} classementManuelRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateClassementManuelUsingPOST(id: string, classementManuelRequestDto: ClassementManuelRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).validateClassementManuelUsingPOST(id, classementManuelRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider tous les champs du formulaire de mise Ã  jour des coordonnÃ©es propriÃ©taires pour les autoritÃ© de fourriere
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCoordonneesProprietairesAfUsingPOST(id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).validateCoordonneesProprietairesAfUsingPOST(id, coordonneesProprietaireRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ du formulaire de mise Ã  jour des CoordonnÃ©ees propriÃ©taires.
         * @param {'PRENOM' | 'NOM' | 'RAISON_SOCIALE' | 'POINT_REMISE' | 'NUMERO' | 'EXTENSION' | 'TYPEVOIE' | 'LIBELLE_VOIE' | 'COMPLEMENT_ADRESSE' | 'LIEUDIT' | 'BOITE_POSTALE' | 'CODE_POSTAL' | 'LIBELLE_COMMUNE' | 'CODE_CEDEX' | 'LIBELLE_CEDEX' | 'PAYS'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCoordonneesProprietairesFieldAfUsingPOST(field: 'PRENOM' | 'NOM' | 'RAISON_SOCIALE' | 'POINT_REMISE' | 'NUMERO' | 'EXTENSION' | 'TYPEVOIE' | 'LIBELLE_VOIE' | 'COMPLEMENT_ADRESSE' | 'LIEUDIT' | 'BOITE_POSTALE' | 'CODE_POSTAL' | 'LIBELLE_COMMUNE' | 'CODE_CEDEX' | 'LIBELLE_CEDEX' | 'PAYS', id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).validateCoordonneesProprietairesFieldAfUsingPOST(field, id, coordonneesProprietaireRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route validant le champ spÃ©cifiÃ© dans le formulaire de mise en fourriÃ¨re. Si aucun champ n\'est spÃ©cifiÃ©, tous les champs remplis seront validÃ©s.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID.
         * @param {validateProlongationUsingPOSTQueryParams} queryParams objet contenant les query params de la route validateProlongationUsingPOST
         * @param {ProlongationRequestDto} prolongationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateProlongationUsingPOST(id: string, queryParams: ValidateProlongationUsingPOSTQueryParams, prolongationRequestDto: ProlongationRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).validateProlongationUsingPOST(id, queryParams, prolongationRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider l\'ensemble des champs du formulaire de modifications des entitÃ©s de rattachement d\'un dossier.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateEntitesRattachementAFUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierModificationViolationsDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).validateUpdateEntitesRattachementAFUsingPOST(id, updateEntitesRattachementRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un des champs du formulaire de modifications des entitÃ©s de rattachement d\'un dossier.
         * @param {'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT'} field 
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateEntitesRattachementFieldAFUsingPOST(field: 'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT', id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierModificationViolationsDto> {
            const localVarFetchArgs = AutoriteFourriereControllerApiFetchParamCreator(configuration).validateUpdateEntitesRattachementFieldAFUsingPOST(field, id, updateEntitesRattachementRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AutoriteFourriereControllerApi - object-oriented interface
 * @export
 * @class AutoriteFourriereControllerApi
 * @extends {BaseAPI}
 */
export class AutoriteFourriereControllerApi extends BaseAPI {
    /**
     * @summary Route permettant de confirmer les informations de son compte Keycloak
     * @param {string} username Nom d\'utilisateur du compte Keycloak
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public confirmAccountUsingPUT(username: string, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).confirmAccountUsingPUT(username, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de confirmer la validitÃ© des informations d\'une autoritÃ© de fourriÃ¨re
     * @param {string} id Id de l\'autoritÃ© de fourriÃ¨re, et non son id de corrÃ©lation, reprÃ©sentÃ© par un UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public confirmAutoriteFourriereUsingPUT(id: string, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).confirmAutoriteFourriereUsingPUT(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de calculer les violations menant Ã  une impossibilitÃ© de notifier
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {getSourceImpossibiliteNotifierAfUsingGETQueryParams} queryParams objet contenant les query params de la route getSourceImpossibiliteNotifierAfUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getSourceImpossibiliteNotifierAfUsingGET(id: string, queryParams: GetSourceImpossibiliteNotifierAfUsingGETQueryParams, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).getSourceImpossibiliteNotifierAfUsingGET(id, queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route de rÃ©cupÃ©ration des coordonnÃ©es propriÃ©taire d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par son UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public retrieveCoordonneesProprietaireUsingGET1(id: string, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).retrieveCoordonneesProprietaireUsingGET1(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route de rÃ©cupÃ©ration des donnÃ©es techniques d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par son UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public retrieveInformationsTechniquesUsingGET(id: string, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).retrieveInformationsTechniquesUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de prolonger la mise en fourriÃ¨re d\'un vÃ©hicule.
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {ProlongationRequestDto} prolongationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public submitProlongationMiseEnFourriereUsingPOST(id: string, prolongationRequestDto: ProlongationRequestDto, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).submitProlongationMiseEnFourriereUsingPOST(id, prolongationRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de modifier le statut de traitement en facturation du dossier.
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {boolean} traite Boolean spÃ©cifiant si le dossier a Ã©tÃ© traitÃ© en facturation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public submitTraitementFacturationUsingPOST(id: string, traite: boolean, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).submitTraitementFacturationUsingPOST(id, traite, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de mettre Ã  jour les donnÃ©es d\'une autoritÃ© de fourriÃ¨re.
     * @param {string} idCorrelation Id de corrÃ©lation de l\'autoritÃ© de fourriÃ¨re, representÃ© par un UUID.
     * @param {ProfilAutoriteFourriereRequestDto} profilAutoriteFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateAutoriteFourriereInfoUsingPOST(idCorrelation: string, profilAutoriteFourriereRequestDto: ProfilAutoriteFourriereRequestDto, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).updateAutoriteFourriereInfoUsingPOST(idCorrelation, profilAutoriteFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route mettant Ã  jour le classement manuel (non automatique).
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {ClassementManuelRequestDto} classementManuelRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateClassementUsingPUT(id: string, classementManuelRequestDto: ClassementManuelRequestDto, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).updateClassementUsingPUT(id, classementManuelRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de modifier les coordonnees du propriÃ©taire
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateCoordonneesProprietairesAfUsingPOST(id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).updateCoordonneesProprietairesAfUsingPOST(id, coordonneesProprietaireRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de modifier les entitÃ©es de rattachement d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateEntitesRattachementAFUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).updateEntitesRattachementAFUsingPOST(id, updateEntitesRattachementRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ du formulaire de mise Ã  jour du profile AutoritÃ© de FourriÃ¨re.
     * @param {'TYPE' | 'NOM_VOIE' | 'NUMERO_VOIE' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'CEDEX'} field IntitulÃ© du champ Ã  valider
     * @param {string} idCorrelation Id de l\'autoritÃ© de fourriÃ¨re, reprÃ©sentÃ© par un UUID.
     * @param {ProfilAutoriteFourriereRequestDto} profilAutoriteFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateAutoriteFourriereInfosFieldUsingPOST(field: 'TYPE' | 'NOM_VOIE' | 'NUMERO_VOIE' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'CEDEX', idCorrelation: string, profilAutoriteFourriereRequestDto: ProfilAutoriteFourriereRequestDto, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).validateAutoriteFourriereInfosFieldUsingPOST(field, idCorrelation, profilAutoriteFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider tous les champs du formulaire de mise Ã  jour du profile AutoritÃ© de FourriÃ¨re.
     * @param {string} idCorrelation Id de l\'autoritÃ© de fourriÃ¨re, reprÃ©sentÃ© par un UUID.
     * @param {ProfilAutoriteFourriereRequestDto} profilAutoriteFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateAutoriteFourriereInfosUsingPOST(idCorrelation: string, profilAutoriteFourriereRequestDto: ProfilAutoriteFourriereRequestDto, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).validateAutoriteFourriereInfosUsingPOST(idCorrelation, profilAutoriteFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route validant le champ spÃ©cifiÃ© dans le formulaire de classement manuel.
     * @param {'DANGEREUX' | 'REPARABLE' | 'ETRANGER' | 'PREMIERE_IMMATRICULATION' | 'PUISSANCE' | 'ENERGIE' | 'CARROSSERIE' | 'GENRE' | 'CLASSEMENT'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {ClassementManuelRequestDto} classementManuelRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateClassementManuelFieldUsingPOST(field: 'DANGEREUX' | 'REPARABLE' | 'ETRANGER' | 'PREMIERE_IMMATRICULATION' | 'PUISSANCE' | 'ENERGIE' | 'CARROSSERIE' | 'GENRE' | 'CLASSEMENT', id: string, classementManuelRequestDto: ClassementManuelRequestDto, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).validateClassementManuelFieldUsingPOST(field, id, classementManuelRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route validant tous les champs du formulaire de classement manuel.
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID.
     * @param {ClassementManuelRequestDto} classementManuelRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateClassementManuelUsingPOST(id: string, classementManuelRequestDto: ClassementManuelRequestDto, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).validateClassementManuelUsingPOST(id, classementManuelRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider tous les champs du formulaire de mise Ã  jour des coordonnÃ©es propriÃ©taires pour les autoritÃ© de fourriere
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCoordonneesProprietairesAfUsingPOST(id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).validateCoordonneesProprietairesAfUsingPOST(id, coordonneesProprietaireRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ du formulaire de mise Ã  jour des CoordonnÃ©ees propriÃ©taires.
     * @param {'PRENOM' | 'NOM' | 'RAISON_SOCIALE' | 'POINT_REMISE' | 'NUMERO' | 'EXTENSION' | 'TYPEVOIE' | 'LIBELLE_VOIE' | 'COMPLEMENT_ADRESSE' | 'LIEUDIT' | 'BOITE_POSTALE' | 'CODE_POSTAL' | 'LIBELLE_COMMUNE' | 'CODE_CEDEX' | 'LIBELLE_CEDEX' | 'PAYS'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCoordonneesProprietairesFieldAfUsingPOST(field: 'PRENOM' | 'NOM' | 'RAISON_SOCIALE' | 'POINT_REMISE' | 'NUMERO' | 'EXTENSION' | 'TYPEVOIE' | 'LIBELLE_VOIE' | 'COMPLEMENT_ADRESSE' | 'LIEUDIT' | 'BOITE_POSTALE' | 'CODE_POSTAL' | 'LIBELLE_COMMUNE' | 'CODE_CEDEX' | 'LIBELLE_CEDEX' | 'PAYS', id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).validateCoordonneesProprietairesFieldAfUsingPOST(field, id, coordonneesProprietaireRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route validant le champ spÃ©cifiÃ© dans le formulaire de mise en fourriÃ¨re. Si aucun champ n\'est spÃ©cifiÃ©, tous les champs remplis seront validÃ©s.
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID.
     * @param {validateProlongationUsingPOSTQueryParams} queryParams objet contenant les query params de la route validateProlongationUsingPOST
     * @param {ProlongationRequestDto} prolongationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateProlongationUsingPOST(id: string, queryParams: ValidateProlongationUsingPOSTQueryParams, prolongationRequestDto: ProlongationRequestDto, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).validateProlongationUsingPOST(id, queryParams, prolongationRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider l\'ensemble des champs du formulaire de modifications des entitÃ©s de rattachement d\'un dossier.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateEntitesRattachementAFUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).validateUpdateEntitesRattachementAFUsingPOST(id, updateEntitesRattachementRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un des champs du formulaire de modifications des entitÃ©s de rattachement d\'un dossier.
     * @param {'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT'} field 
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateEntitesRattachementFieldAFUsingPOST(field: 'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT', id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any) {
        return AutoriteFourriereControllerApiFp(this.configuration).validateUpdateEntitesRattachementFieldAFUsingPOST(field, id, updateEntitesRattachementRequestDto, options)(this.fetch, this.basePath);
    }

}

//////////////////////////////////// ComptesUtilisateursControllerApi /////////////////////////////////////////////////////

/**
 * ComptesUtilisateursControllerApi - fetch parameter creator
 * @export
 */
export const ComptesUtilisateursControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant de rÃ©cupÃ©rer les actions requises de l\'utilisateur
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequiredActionsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/comptes-utilisateurs/action-requises`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComptesUtilisateursControllerApi - functional programming interface
 * @export
 */
export const ComptesUtilisateursControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant de rÃ©cupÃ©rer les actions requises de l\'utilisateur
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequiredActionsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RequiredUserActionsDto> {
            const localVarFetchArgs = ComptesUtilisateursControllerApiFetchParamCreator(configuration).getRequiredActionsUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ComptesUtilisateursControllerApi - object-oriented interface
 * @export
 * @class ComptesUtilisateursControllerApi
 * @extends {BaseAPI}
 */
export class ComptesUtilisateursControllerApi extends BaseAPI {
    /**
     * @summary Route permettant de rÃ©cupÃ©rer les actions requises de l\'utilisateur
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getRequiredActionsUsingGET(options?: any) {
        return ComptesUtilisateursControllerApiFp(this.configuration).getRequiredActionsUsingGET(options)(this.fetch, this.basePath);
    }

}

//////////////////////////////////// DossierControllerApi /////////////////////////////////////////////////////

/**
 * objet contenant les query params de la route countDossierSummariesUsingGET
 * @export
 * @interface CountDossierSummariesUsingGETQueryParams
 */
export interface CountDossierSummariesUsingGETQueryParams {
    /**
     * objet contenant les dossierCriteriaDto de CountDossierSummariesUsingGETQueryParams
     * @type {CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto}
     * @memberof CountDossierSummariesUsingGETQueryParams
     */
    dossierCriteriaDto: CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto;
    /**
     * objet contenant les dossierSortDto de CountDossierSummariesUsingGETQueryParams
     * @type {CountDossierSummariesUsingGETQueryParamsDossierSortDto}
     * @memberof CountDossierSummariesUsingGETQueryParams
     */
    dossierSortDto: CountDossierSummariesUsingGETQueryParamsDossierSortDto;
    /**
     * 
     * @type {number}
     * @memberof CountDossierSummariesUsingGETQueryParams
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof CountDossierSummariesUsingGETQueryParams
     */
    pageSize?: number;
}

export interface CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto {
    /**
    * 
    * @type {Array<'PROLONGER_MEF' | 'CLASSER_MANUELLEMENT' | 'MODIFIER_COORDONNEES_PROPRIETAIRE' | 'CORRIGER_DONNEES_NOTIFICATION_MANUELLE' | 'RENSEIGNER_SORTIE_VEHICULE' | 'SAISIE_VHU' | 'SAISIE_NOTIFICATION_MANUELLE' | 'MAIN_LEVEE' | 'TRAITE_EN_FACTURATION' | 'NON_TRAITE_EN_FACTURATION' | 'ANNULATION_SORTIE_VEHICULE' | 'EXPORT_DONNEES_DOSSIER' | 'SUPPRESSION_DOSSIER' | 'UPDATE_ENTITES_RATTACHEMENT_ADMIN' | 'UPDATE_ENTITES_RATTACHEMENT_FO_AF' | 'MODIFICATION_SORTIE_VEHICULE' | 'UPDATE_DONNEES_VEHICULE' | 'UPDATE_CLASSEMENT' | 'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE' | 'ENREGISTRER_ENTREE' | 'CONTROLER_DONNEES_VEHICULE' | 'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE' | 'RELANCE_VENTE_VOL' | 'RELANCE_VENTE_IMMO_PJ' | 'RELANCE_VENTE_OPPOSITION' | 'RELANCE_VENTE_DOUBLON' | 'RELANCE_VENTE_MANQUANT' | 'RECUPERATION_AR_PND_AUTOMATIQUE' | 'CONTROLE_BLOCAGE_ARGOS' | 'CONTROLE_BLOCAGE_ANTAI' | 'CONTROLE_BLOCAGE_DNID' | 'RECUPERATION_COURRIER_AUTOMATIQUE' | 'RENSEIGNER_INFORMATIONS_SIV' | 'CONFIRMER_VOL_VVNR' | 'VALIDATION_ACTIONS_VVNR_HORS_SIF'> | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    actionsRequises?: Array<'PROLONGER_MEF' | 'CLASSER_MANUELLEMENT' | 'MODIFIER_COORDONNEES_PROPRIETAIRE' | 'CORRIGER_DONNEES_NOTIFICATION_MANUELLE' | 'RENSEIGNER_SORTIE_VEHICULE' | 'SAISIE_VHU' | 'SAISIE_NOTIFICATION_MANUELLE' | 'MAIN_LEVEE' | 'TRAITE_EN_FACTURATION' | 'NON_TRAITE_EN_FACTURATION' | 'ANNULATION_SORTIE_VEHICULE' | 'EXPORT_DONNEES_DOSSIER' | 'SUPPRESSION_DOSSIER' | 'UPDATE_ENTITES_RATTACHEMENT_ADMIN' | 'UPDATE_ENTITES_RATTACHEMENT_FO_AF' | 'MODIFICATION_SORTIE_VEHICULE' | 'UPDATE_DONNEES_VEHICULE' | 'UPDATE_CLASSEMENT' | 'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE' | 'ENREGISTRER_ENTREE' | 'CONTROLER_DONNEES_VEHICULE' | 'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE' | 'RELANCE_VENTE_VOL' | 'RELANCE_VENTE_IMMO_PJ' | 'RELANCE_VENTE_OPPOSITION' | 'RELANCE_VENTE_DOUBLON' | 'RELANCE_VENTE_MANQUANT' | 'RECUPERATION_AR_PND_AUTOMATIQUE' | 'CONTROLE_BLOCAGE_ARGOS' | 'CONTROLE_BLOCAGE_ANTAI' | 'CONTROLE_BLOCAGE_DNID' | 'RECUPERATION_COURRIER_AUTOMATIQUE' | 'RENSEIGNER_INFORMATIONS_SIV' | 'CONFIRMER_VOL_VVNR' | 'VALIDATION_ACTIONS_VVNR_HORS_SIF'>;
    /**
    * 
    * @type {string | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    antaiId?: string;
    /**
    * 
    * @type {Array<'ALIENATION' | 'DESTRUCTION' | 'MANUEL'> | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    classementList?: Array<'ALIENATION' | 'DESTRUCTION' | 'MANUEL'>;
    /**
    * 
    * @type {Array<string> | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    communeEnlevementList?: Array<string>;
    /**
    * 
    * @type {Date | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromAbandon?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromBonEnlevement?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromMiseAJour?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromMiseEnFourriere?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromOrdreDestruction?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromSortieDefinitive?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateToAbandon?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateToBonEnlevement?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateToMiseAJour?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateToMiseEnFourriere?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateToOrdreDestruction?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateToSortieDefinitive?: Date;
    /**
    * 
    * @type {string | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    idAutoriteFourriere?: string;
    /**
    * 
    * @type {Array<string> | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    idFourriereList?: Array<string>;
    /**
    * 
    * @type {Array<string> | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    idMotifSortieList?: Array<string>;
    /**
    * 
    * @type {Array<string> | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    idNatureBrancheList?: Array<string>;
    /**
    * 
    * @type {string | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    idUniteFO?: string;
    /**
    * 
    * @type {Array<string> | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    idUniteFOList?: Array<string>;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    includeDossiersAnonymises?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    includeDossiersSortis?: boolean;
    /**
    * 
    * @type {string | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    numeroImmatriculation?: string;
    /**
    * 
    * @type {Array<'A_REMPLIR' | 'ENREGISTRE' | 'NON_GERE' | 'NOTIFICATION_ENVOYEE' | 'NOTIFIE' | 'A_NOTIFIER' | 'EN_COURS_DE_NOTIFICATION' | 'A_REMETTRE_AUX_DOMAINES' | 'REMIS_AU_DOMAINE' | 'MIS_EN_VENTE' | 'VENDU' | 'A_RESTITUER' | 'VENTE_BLOQUEE' | 'A_DETRUIRE' | 'SORTI' | 'DETRUIT'> | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    statutDossierEnumList?: Array<'A_REMPLIR' | 'ENREGISTRE' | 'NON_GERE' | 'NOTIFICATION_ENVOYEE' | 'NOTIFIE' | 'A_NOTIFIER' | 'EN_COURS_DE_NOTIFICATION' | 'A_REMETTRE_AUX_DOMAINES' | 'REMIS_AU_DOMAINE' | 'MIS_EN_VENTE' | 'VENDU' | 'A_RESTITUER' | 'VENTE_BLOQUEE' | 'A_DETRUIRE' | 'SORTI' | 'DETRUIT'>;
    /**
    * 
    * @type {Array<'TRAITE' | 'NON_TRAITE' | 'NON_CONCERNE'> | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    statutsFacturation?: Array<'TRAITE' | 'NON_TRAITE' | 'NON_CONCERNE'>;
}

export interface CountDossierSummariesUsingGETQueryParamsDossierSortDto {
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    alertesIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    classementIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    couleurIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    dateAbandonIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    dateBonEnlevementIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    dateMiseAJourIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    dateMiseFourriereIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    dateOrdreDestructionIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    dateSortieDefinitiveIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    fourriereIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    immatriculationIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    marqueIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    modeleIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    natureMiseEnFourriereIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof CountDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    statutIsAsc?: boolean;
}

/**
 * objet contenant les query params de la route searchDossierSummariesUsingGET
 * @export
 * @interface SearchDossierSummariesUsingGETQueryParams
 */
export interface SearchDossierSummariesUsingGETQueryParams {
    /**
     * objet contenant les dossierCriteriaDto de SearchDossierSummariesUsingGETQueryParams
     * @type {SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto}
     * @memberof SearchDossierSummariesUsingGETQueryParams
     */
    dossierCriteriaDto: SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto;
    /**
     * objet contenant les dossierSortDto de SearchDossierSummariesUsingGETQueryParams
     * @type {SearchDossierSummariesUsingGETQueryParamsDossierSortDto}
     * @memberof SearchDossierSummariesUsingGETQueryParams
     */
    dossierSortDto: SearchDossierSummariesUsingGETQueryParamsDossierSortDto;
    /**
     * 
     * @type {number}
     * @memberof SearchDossierSummariesUsingGETQueryParams
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchDossierSummariesUsingGETQueryParams
     */
    pageSize?: number;
}

export interface SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto {
    /**
    * 
    * @type {Array<'PROLONGER_MEF' | 'CLASSER_MANUELLEMENT' | 'MODIFIER_COORDONNEES_PROPRIETAIRE' | 'CORRIGER_DONNEES_NOTIFICATION_MANUELLE' | 'RENSEIGNER_SORTIE_VEHICULE' | 'SAISIE_VHU' | 'SAISIE_NOTIFICATION_MANUELLE' | 'MAIN_LEVEE' | 'TRAITE_EN_FACTURATION' | 'NON_TRAITE_EN_FACTURATION' | 'ANNULATION_SORTIE_VEHICULE' | 'EXPORT_DONNEES_DOSSIER' | 'SUPPRESSION_DOSSIER' | 'UPDATE_ENTITES_RATTACHEMENT_ADMIN' | 'UPDATE_ENTITES_RATTACHEMENT_FO_AF' | 'MODIFICATION_SORTIE_VEHICULE' | 'UPDATE_DONNEES_VEHICULE' | 'UPDATE_CLASSEMENT' | 'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE' | 'ENREGISTRER_ENTREE' | 'CONTROLER_DONNEES_VEHICULE' | 'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE' | 'RELANCE_VENTE_VOL' | 'RELANCE_VENTE_IMMO_PJ' | 'RELANCE_VENTE_OPPOSITION' | 'RELANCE_VENTE_DOUBLON' | 'RELANCE_VENTE_MANQUANT' | 'RECUPERATION_AR_PND_AUTOMATIQUE' | 'CONTROLE_BLOCAGE_ARGOS' | 'CONTROLE_BLOCAGE_ANTAI' | 'CONTROLE_BLOCAGE_DNID' | 'RECUPERATION_COURRIER_AUTOMATIQUE' | 'RENSEIGNER_INFORMATIONS_SIV' | 'CONFIRMER_VOL_VVNR' | 'VALIDATION_ACTIONS_VVNR_HORS_SIF'> | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    actionsRequises?: Array<'PROLONGER_MEF' | 'CLASSER_MANUELLEMENT' | 'MODIFIER_COORDONNEES_PROPRIETAIRE' | 'CORRIGER_DONNEES_NOTIFICATION_MANUELLE' | 'RENSEIGNER_SORTIE_VEHICULE' | 'SAISIE_VHU' | 'SAISIE_NOTIFICATION_MANUELLE' | 'MAIN_LEVEE' | 'TRAITE_EN_FACTURATION' | 'NON_TRAITE_EN_FACTURATION' | 'ANNULATION_SORTIE_VEHICULE' | 'EXPORT_DONNEES_DOSSIER' | 'SUPPRESSION_DOSSIER' | 'UPDATE_ENTITES_RATTACHEMENT_ADMIN' | 'UPDATE_ENTITES_RATTACHEMENT_FO_AF' | 'MODIFICATION_SORTIE_VEHICULE' | 'UPDATE_DONNEES_VEHICULE' | 'UPDATE_CLASSEMENT' | 'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE' | 'ENREGISTRER_ENTREE' | 'CONTROLER_DONNEES_VEHICULE' | 'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE' | 'RELANCE_VENTE_VOL' | 'RELANCE_VENTE_IMMO_PJ' | 'RELANCE_VENTE_OPPOSITION' | 'RELANCE_VENTE_DOUBLON' | 'RELANCE_VENTE_MANQUANT' | 'RECUPERATION_AR_PND_AUTOMATIQUE' | 'CONTROLE_BLOCAGE_ARGOS' | 'CONTROLE_BLOCAGE_ANTAI' | 'CONTROLE_BLOCAGE_DNID' | 'RECUPERATION_COURRIER_AUTOMATIQUE' | 'RENSEIGNER_INFORMATIONS_SIV' | 'CONFIRMER_VOL_VVNR' | 'VALIDATION_ACTIONS_VVNR_HORS_SIF'>;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    antaiId?: string;
    /**
    * 
    * @type {Array<'ALIENATION' | 'DESTRUCTION' | 'MANUEL'> | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    classementList?: Array<'ALIENATION' | 'DESTRUCTION' | 'MANUEL'>;
    /**
    * 
    * @type {Array<string> | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    communeEnlevementList?: Array<string>;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromAbandon?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromBonEnlevement?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromMiseAJour?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromMiseEnFourriere?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromOrdreDestruction?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromSortieDefinitive?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateToAbandon?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateToBonEnlevement?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateToMiseAJour?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateToMiseEnFourriere?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateToOrdreDestruction?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    dateToSortieDefinitive?: Date;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    idAutoriteFourriere?: string;
    /**
    * 
    * @type {Array<string> | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    idFourriereList?: Array<string>;
    /**
    * 
    * @type {Array<string> | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    idMotifSortieList?: Array<string>;
    /**
    * 
    * @type {Array<string> | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    idNatureBrancheList?: Array<string>;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    idUniteFO?: string;
    /**
    * 
    * @type {Array<string> | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    idUniteFOList?: Array<string>;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    includeDossiersAnonymises?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    includeDossiersSortis?: boolean;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    numeroImmatriculation?: string;
    /**
    * 
    * @type {Array<'A_REMPLIR' | 'ENREGISTRE' | 'NON_GERE' | 'NOTIFICATION_ENVOYEE' | 'NOTIFIE' | 'A_NOTIFIER' | 'EN_COURS_DE_NOTIFICATION' | 'A_REMETTRE_AUX_DOMAINES' | 'REMIS_AU_DOMAINE' | 'MIS_EN_VENTE' | 'VENDU' | 'A_RESTITUER' | 'VENTE_BLOQUEE' | 'A_DETRUIRE' | 'SORTI' | 'DETRUIT'> | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    statutDossierEnumList?: Array<'A_REMPLIR' | 'ENREGISTRE' | 'NON_GERE' | 'NOTIFICATION_ENVOYEE' | 'NOTIFIE' | 'A_NOTIFIER' | 'EN_COURS_DE_NOTIFICATION' | 'A_REMETTRE_AUX_DOMAINES' | 'REMIS_AU_DOMAINE' | 'MIS_EN_VENTE' | 'VENDU' | 'A_RESTITUER' | 'VENTE_BLOQUEE' | 'A_DETRUIRE' | 'SORTI' | 'DETRUIT'>;
    /**
    * 
    * @type {Array<'TRAITE' | 'NON_TRAITE' | 'NON_CONCERNE'> | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierCriteriaDto
    */
    statutsFacturation?: Array<'TRAITE' | 'NON_TRAITE' | 'NON_CONCERNE'>;
}

export interface SearchDossierSummariesUsingGETQueryParamsDossierSortDto {
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    alertesIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    classementIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    couleurIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    dateAbandonIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    dateBonEnlevementIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    dateMiseAJourIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    dateMiseFourriereIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    dateOrdreDestructionIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    dateSortieDefinitiveIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    fourriereIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    immatriculationIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    marqueIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    modeleIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    natureMiseEnFourriereIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchDossierSummariesUsingGETQueryParamsDossierSortDto
    */
    statutIsAsc?: boolean;
}

/**
 * DossierControllerApi - fetch parameter creator
 * @export
 */
export const DossierControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * @summary Route pour compter des dossiers avec possibilitÃ© de filtres
         * @param {countDossierSummariesUsingGETQueryParams} queryParams objet contenant les query params de la route countDossierSummariesUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDossierSummariesUsingGET(queryParams: CountDossierSummariesUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'dossierCriteriaDto' is not null or undefined
            if (queryParams.dossierCriteriaDto === null || queryParams.dossierCriteriaDto === undefined) {
                throw new RequiredError('dossierCriteriaDto','Required parameter dossierCriteriaDto was null or undefined when calling countDossierSummariesUsingGET.');
            }
            // verify required parameter 'dossierSortDto' is not null or undefined
            if (queryParams.dossierSortDto === null || queryParams.dossierSortDto === undefined) {
                throw new RequiredError('dossierSortDto','Required parameter dossierSortDto was null or undefined when calling countDossierSummariesUsingGET.');
            }
            const localVarPath = `/api/dossier/count`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.dossierCriteriaDto.actionsRequises !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.actionsRequises'] = queryParams.dossierCriteriaDto.actionsRequises;
            }
            if (queryParams.dossierCriteriaDto.antaiId !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.antaiId'] = queryParams.dossierCriteriaDto.antaiId;
            }
            if (queryParams.dossierCriteriaDto.classementList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.classementList'] = queryParams.dossierCriteriaDto.classementList;
            }
            if (queryParams.dossierCriteriaDto.communeEnlevementList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.communeEnlevementList'] = queryParams.dossierCriteriaDto.communeEnlevementList;
            }
            if (queryParams.dossierCriteriaDto.dateFromAbandon !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromAbandon'] = (queryParams.dossierCriteriaDto.dateFromAbandon as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateFromBonEnlevement !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromBonEnlevement'] = (queryParams.dossierCriteriaDto.dateFromBonEnlevement as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateFromMiseAJour !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromMiseAJour'] = (queryParams.dossierCriteriaDto.dateFromMiseAJour as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateFromMiseEnFourriere !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromMiseEnFourriere'] = (queryParams.dossierCriteriaDto.dateFromMiseEnFourriere as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateFromOrdreDestruction !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromOrdreDestruction'] = (queryParams.dossierCriteriaDto.dateFromOrdreDestruction as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateFromSortieDefinitive !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromSortieDefinitive'] = (queryParams.dossierCriteriaDto.dateFromSortieDefinitive as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToAbandon !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToAbandon'] = (queryParams.dossierCriteriaDto.dateToAbandon as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToBonEnlevement !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToBonEnlevement'] = (queryParams.dossierCriteriaDto.dateToBonEnlevement as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToMiseAJour !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToMiseAJour'] = (queryParams.dossierCriteriaDto.dateToMiseAJour as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToMiseEnFourriere !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToMiseEnFourriere'] = (queryParams.dossierCriteriaDto.dateToMiseEnFourriere as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToOrdreDestruction !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToOrdreDestruction'] = (queryParams.dossierCriteriaDto.dateToOrdreDestruction as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToSortieDefinitive !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToSortieDefinitive'] = (queryParams.dossierCriteriaDto.dateToSortieDefinitive as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.idAutoriteFourriere !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idAutoriteFourriere'] = queryParams.dossierCriteriaDto.idAutoriteFourriere;
            }
            if (queryParams.dossierCriteriaDto.idFourriereList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idFourriereList'] = queryParams.dossierCriteriaDto.idFourriereList;
            }
            if (queryParams.dossierCriteriaDto.idMotifSortieList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idMotifSortieList'] = queryParams.dossierCriteriaDto.idMotifSortieList;
            }
            if (queryParams.dossierCriteriaDto.idNatureBrancheList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idNatureBrancheList'] = queryParams.dossierCriteriaDto.idNatureBrancheList;
            }
            if (queryParams.dossierCriteriaDto.idUniteFO !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idUniteFO'] = queryParams.dossierCriteriaDto.idUniteFO;
            }
            if (queryParams.dossierCriteriaDto.idUniteFOList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idUniteFOList'] = queryParams.dossierCriteriaDto.idUniteFOList;
            }
            if (queryParams.dossierCriteriaDto.includeDossiersAnonymises !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.includeDossiersAnonymises'] = queryParams.dossierCriteriaDto.includeDossiersAnonymises;
            }
            if (queryParams.dossierCriteriaDto.includeDossiersSortis !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.includeDossiersSortis'] = queryParams.dossierCriteriaDto.includeDossiersSortis;
            }
            if (queryParams.dossierCriteriaDto.numeroImmatriculation !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.numeroImmatriculation'] = queryParams.dossierCriteriaDto.numeroImmatriculation;
            }
            if (queryParams.dossierCriteriaDto.statutDossierEnumList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.statutDossierEnumList'] = queryParams.dossierCriteriaDto.statutDossierEnumList;
            }
            if (queryParams.dossierCriteriaDto.statutsFacturation !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.statutsFacturation'] = queryParams.dossierCriteriaDto.statutsFacturation;
            }

            if (queryParams.dossierSortDto.alertesIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.alertesIsAsc'] = queryParams.dossierSortDto.alertesIsAsc;
            }
            if (queryParams.dossierSortDto.classementIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.classementIsAsc'] = queryParams.dossierSortDto.classementIsAsc;
            }
            if (queryParams.dossierSortDto.couleurIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.couleurIsAsc'] = queryParams.dossierSortDto.couleurIsAsc;
            }
            if (queryParams.dossierSortDto.dateAbandonIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateAbandonIsAsc'] = queryParams.dossierSortDto.dateAbandonIsAsc;
            }
            if (queryParams.dossierSortDto.dateBonEnlevementIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateBonEnlevementIsAsc'] = queryParams.dossierSortDto.dateBonEnlevementIsAsc;
            }
            if (queryParams.dossierSortDto.dateMiseAJourIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateMiseAJourIsAsc'] = queryParams.dossierSortDto.dateMiseAJourIsAsc;
            }
            if (queryParams.dossierSortDto.dateMiseFourriereIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateMiseFourriereIsAsc'] = queryParams.dossierSortDto.dateMiseFourriereIsAsc;
            }
            if (queryParams.dossierSortDto.dateOrdreDestructionIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateOrdreDestructionIsAsc'] = queryParams.dossierSortDto.dateOrdreDestructionIsAsc;
            }
            if (queryParams.dossierSortDto.dateSortieDefinitiveIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateSortieDefinitiveIsAsc'] = queryParams.dossierSortDto.dateSortieDefinitiveIsAsc;
            }
            if (queryParams.dossierSortDto.fourriereIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.fourriereIsAsc'] = queryParams.dossierSortDto.fourriereIsAsc;
            }
            if (queryParams.dossierSortDto.immatriculationIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.immatriculationIsAsc'] = queryParams.dossierSortDto.immatriculationIsAsc;
            }
            if (queryParams.dossierSortDto.marqueIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.marqueIsAsc'] = queryParams.dossierSortDto.marqueIsAsc;
            }
            if (queryParams.dossierSortDto.modeleIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.modeleIsAsc'] = queryParams.dossierSortDto.modeleIsAsc;
            }
            if (queryParams.dossierSortDto.natureMiseEnFourriereIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.natureMiseEnFourriereIsAsc'] = queryParams.dossierSortDto.natureMiseEnFourriereIsAsc;
            }
            if (queryParams.dossierSortDto.statutIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.statutIsAsc'] = queryParams.dossierSortDto.statutIsAsc;
            }

            if (queryParams.page !== undefined) {
                localVarQueryParameter['page'] = queryParams.page;
            }

            if (queryParams.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = queryParams.pageSize;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de tÃ©lÃ©charger un des documents disponibles d\'un dossier
         * @param {'DECISION_CLASSEMENT' | 'DECISION_DESTRUCTION' | 'CONSTAT_ABANDON' | 'DECISION_REMISE_DOMAINE' | 'BON_ENLEVEMENT_DESTRUCTION' | 'FICHE_DESCRIPTIVE' | 'PRESCRIPTION_MEF' | 'MAIN_LEVEE_FO' | 'MAIN_LEVEE_USAGER' | 'COURRIER_NOTIFICATION' | 'AR_PND_NOTIFICATION'} document Document reprÃ©sentÃ© par un enum.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par son UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentUsingGET(document: 'DECISION_CLASSEMENT' | 'DECISION_DESTRUCTION' | 'CONSTAT_ABANDON' | 'DECISION_REMISE_DOMAINE' | 'BON_ENLEVEMENT_DESTRUCTION' | 'FICHE_DESCRIPTIVE' | 'PRESCRIPTION_MEF' | 'MAIN_LEVEE_FO' | 'MAIN_LEVEE_USAGER' | 'COURRIER_NOTIFICATION' | 'AR_PND_NOTIFICATION', id: string, options: any = {}): FetchArgs {
            // verify required parameter 'document' is not null or undefined
            if (document === null || document === undefined) {
                throw new RequiredError('document','Required parameter document was null or undefined when calling downloadDocumentUsingGET.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadDocumentUsingGET.');
            }
            const localVarPath = `/api/dossier/{id}/document/{document}`
                .replace(`{${"document"}}`, encodeURIComponent(String(document)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant d\'obtenir la liste des actions requises sur un dossier, activÃ©es Ã  partir du profil.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionsRequisesByProfileUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/dossier/actions-requises`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant d\'accÃ©der aux donnÃ©es d\'un dossier.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par son UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getByIdUsingGET.');
            }
            const localVarPath = `/api/dossier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer le suivi de mise en fourriere d\'un dossier.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuiviMefUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSuiviMefUsingGET.');
            }
            const localVarPath = `/api/dossier/{id}/suivi-mef`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route de recherche des dossiers avec possibilitÃ© de filtres et de pagination de la requÃªte, seulement une partie des informations des dossiers est renvoyÃ©e
         * @param {searchDossierSummariesUsingGETQueryParams} queryParams objet contenant les query params de la route searchDossierSummariesUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDossierSummariesUsingGET(queryParams: SearchDossierSummariesUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'dossierCriteriaDto' is not null or undefined
            if (queryParams.dossierCriteriaDto === null || queryParams.dossierCriteriaDto === undefined) {
                throw new RequiredError('dossierCriteriaDto','Required parameter dossierCriteriaDto was null or undefined when calling searchDossierSummariesUsingGET.');
            }
            // verify required parameter 'dossierSortDto' is not null or undefined
            if (queryParams.dossierSortDto === null || queryParams.dossierSortDto === undefined) {
                throw new RequiredError('dossierSortDto','Required parameter dossierSortDto was null or undefined when calling searchDossierSummariesUsingGET.');
            }
            const localVarPath = `/api/dossier`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.dossierCriteriaDto.actionsRequises !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.actionsRequises'] = queryParams.dossierCriteriaDto.actionsRequises;
            }
            if (queryParams.dossierCriteriaDto.antaiId !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.antaiId'] = queryParams.dossierCriteriaDto.antaiId;
            }
            if (queryParams.dossierCriteriaDto.classementList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.classementList'] = queryParams.dossierCriteriaDto.classementList;
            }
            if (queryParams.dossierCriteriaDto.communeEnlevementList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.communeEnlevementList'] = queryParams.dossierCriteriaDto.communeEnlevementList;
            }
            if (queryParams.dossierCriteriaDto.dateFromAbandon !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromAbandon'] = (queryParams.dossierCriteriaDto.dateFromAbandon as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateFromBonEnlevement !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromBonEnlevement'] = (queryParams.dossierCriteriaDto.dateFromBonEnlevement as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateFromMiseAJour !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromMiseAJour'] = (queryParams.dossierCriteriaDto.dateFromMiseAJour as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateFromMiseEnFourriere !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromMiseEnFourriere'] = (queryParams.dossierCriteriaDto.dateFromMiseEnFourriere as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateFromOrdreDestruction !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromOrdreDestruction'] = (queryParams.dossierCriteriaDto.dateFromOrdreDestruction as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateFromSortieDefinitive !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromSortieDefinitive'] = (queryParams.dossierCriteriaDto.dateFromSortieDefinitive as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToAbandon !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToAbandon'] = (queryParams.dossierCriteriaDto.dateToAbandon as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToBonEnlevement !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToBonEnlevement'] = (queryParams.dossierCriteriaDto.dateToBonEnlevement as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToMiseAJour !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToMiseAJour'] = (queryParams.dossierCriteriaDto.dateToMiseAJour as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToMiseEnFourriere !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToMiseEnFourriere'] = (queryParams.dossierCriteriaDto.dateToMiseEnFourriere as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToOrdreDestruction !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToOrdreDestruction'] = (queryParams.dossierCriteriaDto.dateToOrdreDestruction as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToSortieDefinitive !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToSortieDefinitive'] = (queryParams.dossierCriteriaDto.dateToSortieDefinitive as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.idAutoriteFourriere !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idAutoriteFourriere'] = queryParams.dossierCriteriaDto.idAutoriteFourriere;
            }
            if (queryParams.dossierCriteriaDto.idFourriereList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idFourriereList'] = queryParams.dossierCriteriaDto.idFourriereList;
            }
            if (queryParams.dossierCriteriaDto.idMotifSortieList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idMotifSortieList'] = queryParams.dossierCriteriaDto.idMotifSortieList;
            }
            if (queryParams.dossierCriteriaDto.idNatureBrancheList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idNatureBrancheList'] = queryParams.dossierCriteriaDto.idNatureBrancheList;
            }
            if (queryParams.dossierCriteriaDto.idUniteFO !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idUniteFO'] = queryParams.dossierCriteriaDto.idUniteFO;
            }
            if (queryParams.dossierCriteriaDto.idUniteFOList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idUniteFOList'] = queryParams.dossierCriteriaDto.idUniteFOList;
            }
            if (queryParams.dossierCriteriaDto.includeDossiersAnonymises !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.includeDossiersAnonymises'] = queryParams.dossierCriteriaDto.includeDossiersAnonymises;
            }
            if (queryParams.dossierCriteriaDto.includeDossiersSortis !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.includeDossiersSortis'] = queryParams.dossierCriteriaDto.includeDossiersSortis;
            }
            if (queryParams.dossierCriteriaDto.numeroImmatriculation !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.numeroImmatriculation'] = queryParams.dossierCriteriaDto.numeroImmatriculation;
            }
            if (queryParams.dossierCriteriaDto.statutDossierEnumList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.statutDossierEnumList'] = queryParams.dossierCriteriaDto.statutDossierEnumList;
            }
            if (queryParams.dossierCriteriaDto.statutsFacturation !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.statutsFacturation'] = queryParams.dossierCriteriaDto.statutsFacturation;
            }

            if (queryParams.dossierSortDto.alertesIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.alertesIsAsc'] = queryParams.dossierSortDto.alertesIsAsc;
            }
            if (queryParams.dossierSortDto.classementIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.classementIsAsc'] = queryParams.dossierSortDto.classementIsAsc;
            }
            if (queryParams.dossierSortDto.couleurIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.couleurIsAsc'] = queryParams.dossierSortDto.couleurIsAsc;
            }
            if (queryParams.dossierSortDto.dateAbandonIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateAbandonIsAsc'] = queryParams.dossierSortDto.dateAbandonIsAsc;
            }
            if (queryParams.dossierSortDto.dateBonEnlevementIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateBonEnlevementIsAsc'] = queryParams.dossierSortDto.dateBonEnlevementIsAsc;
            }
            if (queryParams.dossierSortDto.dateMiseAJourIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateMiseAJourIsAsc'] = queryParams.dossierSortDto.dateMiseAJourIsAsc;
            }
            if (queryParams.dossierSortDto.dateMiseFourriereIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateMiseFourriereIsAsc'] = queryParams.dossierSortDto.dateMiseFourriereIsAsc;
            }
            if (queryParams.dossierSortDto.dateOrdreDestructionIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateOrdreDestructionIsAsc'] = queryParams.dossierSortDto.dateOrdreDestructionIsAsc;
            }
            if (queryParams.dossierSortDto.dateSortieDefinitiveIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateSortieDefinitiveIsAsc'] = queryParams.dossierSortDto.dateSortieDefinitiveIsAsc;
            }
            if (queryParams.dossierSortDto.fourriereIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.fourriereIsAsc'] = queryParams.dossierSortDto.fourriereIsAsc;
            }
            if (queryParams.dossierSortDto.immatriculationIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.immatriculationIsAsc'] = queryParams.dossierSortDto.immatriculationIsAsc;
            }
            if (queryParams.dossierSortDto.marqueIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.marqueIsAsc'] = queryParams.dossierSortDto.marqueIsAsc;
            }
            if (queryParams.dossierSortDto.modeleIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.modeleIsAsc'] = queryParams.dossierSortDto.modeleIsAsc;
            }
            if (queryParams.dossierSortDto.natureMiseEnFourriereIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.natureMiseEnFourriereIsAsc'] = queryParams.dossierSortDto.natureMiseEnFourriereIsAsc;
            }
            if (queryParams.dossierSortDto.statutIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.statutIsAsc'] = queryParams.dossierSortDto.statutIsAsc;
            }

            if (queryParams.page !== undefined) {
                localVarQueryParameter['page'] = queryParams.page;
            }

            if (queryParams.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = queryParams.pageSize;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DossierControllerApi - functional programming interface
 * @export
 */
export const DossierControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * @summary Route pour compter des dossiers avec possibilitÃ© de filtres
         * @param {countDossierSummariesUsingGETQueryParams} queryParams objet contenant les query params de la route countDossierSummariesUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDossierSummariesUsingGET(queryParams: CountDossierSummariesUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CountDossiersResultDto> {
            const localVarFetchArgs = DossierControllerApiFetchParamCreator(configuration).countDossierSummariesUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de tÃ©lÃ©charger un des documents disponibles d\'un dossier
         * @param {'DECISION_CLASSEMENT' | 'DECISION_DESTRUCTION' | 'CONSTAT_ABANDON' | 'DECISION_REMISE_DOMAINE' | 'BON_ENLEVEMENT_DESTRUCTION' | 'FICHE_DESCRIPTIVE' | 'PRESCRIPTION_MEF' | 'MAIN_LEVEE_FO' | 'MAIN_LEVEE_USAGER' | 'COURRIER_NOTIFICATION' | 'AR_PND_NOTIFICATION'} document Document reprÃ©sentÃ© par un enum.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par son UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentUsingGET(document: 'DECISION_CLASSEMENT' | 'DECISION_DESTRUCTION' | 'CONSTAT_ABANDON' | 'DECISION_REMISE_DOMAINE' | 'BON_ENLEVEMENT_DESTRUCTION' | 'FICHE_DESCRIPTIVE' | 'PRESCRIPTION_MEF' | 'MAIN_LEVEE_FO' | 'MAIN_LEVEE_USAGER' | 'COURRIER_NOTIFICATION' | 'AR_PND_NOTIFICATION', id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DossierControllerApiFetchParamCreator(configuration).downloadDocumentUsingGET(document, id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant d\'obtenir la liste des actions requises sur un dossier, activÃ©es Ã  partir du profil.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionsRequisesByProfileUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ActionsRequisesDto> {
            const localVarFetchArgs = DossierControllerApiFetchParamCreator(configuration).getActionsRequisesByProfileUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant d\'accÃ©der aux donnÃ©es d\'un dossier.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par son UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = DossierControllerApiFetchParamCreator(configuration).getByIdUsingGET(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer le suivi de mise en fourriere d\'un dossier.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuiviMefUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SummaryEventDto>> {
            const localVarFetchArgs = DossierControllerApiFetchParamCreator(configuration).getSuiviMefUsingGET(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route de recherche des dossiers avec possibilitÃ© de filtres et de pagination de la requÃªte, seulement une partie des informations des dossiers est renvoyÃ©e
         * @param {searchDossierSummariesUsingGETQueryParams} queryParams objet contenant les query params de la route searchDossierSummariesUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDossierSummariesUsingGET(queryParams: SearchDossierSummariesUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SearchDossiersResponseDto> {
            const localVarFetchArgs = DossierControllerApiFetchParamCreator(configuration).searchDossierSummariesUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DossierControllerApi - object-oriented interface
 * @export
 * @class DossierControllerApi
 * @extends {BaseAPI}
 */
export class DossierControllerApi extends BaseAPI {
    /**
     * @summary Route pour compter des dossiers avec possibilitÃ© de filtres
     * @param {countDossierSummariesUsingGETQueryParams} queryParams objet contenant les query params de la route countDossierSummariesUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public countDossierSummariesUsingGET(queryParams: CountDossierSummariesUsingGETQueryParams, options?: any) {
        return DossierControllerApiFp(this.configuration).countDossierSummariesUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de tÃ©lÃ©charger un des documents disponibles d\'un dossier
     * @param {'DECISION_CLASSEMENT' | 'DECISION_DESTRUCTION' | 'CONSTAT_ABANDON' | 'DECISION_REMISE_DOMAINE' | 'BON_ENLEVEMENT_DESTRUCTION' | 'FICHE_DESCRIPTIVE' | 'PRESCRIPTION_MEF' | 'MAIN_LEVEE_FO' | 'MAIN_LEVEE_USAGER' | 'COURRIER_NOTIFICATION' | 'AR_PND_NOTIFICATION'} document Document reprÃ©sentÃ© par un enum.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par son UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public downloadDocumentUsingGET(document: 'DECISION_CLASSEMENT' | 'DECISION_DESTRUCTION' | 'CONSTAT_ABANDON' | 'DECISION_REMISE_DOMAINE' | 'BON_ENLEVEMENT_DESTRUCTION' | 'FICHE_DESCRIPTIVE' | 'PRESCRIPTION_MEF' | 'MAIN_LEVEE_FO' | 'MAIN_LEVEE_USAGER' | 'COURRIER_NOTIFICATION' | 'AR_PND_NOTIFICATION', id: string, options?: any) {
        return DossierControllerApiFp(this.configuration).downloadDocumentUsingGET(document, id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant d\'obtenir la liste des actions requises sur un dossier, activÃ©es Ã  partir du profil.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getActionsRequisesByProfileUsingGET(options?: any) {
        return DossierControllerApiFp(this.configuration).getActionsRequisesByProfileUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant d\'accÃ©der aux donnÃ©es d\'un dossier.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par son UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getByIdUsingGET(id: string, options?: any) {
        return DossierControllerApiFp(this.configuration).getByIdUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de rÃ©cupÃ©rer le suivi de mise en fourriere d\'un dossier.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getSuiviMefUsingGET(id: string, options?: any) {
        return DossierControllerApiFp(this.configuration).getSuiviMefUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route de recherche des dossiers avec possibilitÃ© de filtres et de pagination de la requÃªte, seulement une partie des informations des dossiers est renvoyÃ©e
     * @param {searchDossierSummariesUsingGETQueryParams} queryParams objet contenant les query params de la route searchDossierSummariesUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchDossierSummariesUsingGET(queryParams: SearchDossierSummariesUsingGETQueryParams, options?: any) {
        return DossierControllerApiFp(this.configuration).searchDossierSummariesUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

}

//////////////////////////////////// ExportControllerApi /////////////////////////////////////////////////////

/**
 * objet contenant les query params de la route exportDashboardUsingGET
 * @export
 * @interface ExportDashboardUsingGETQueryParams
 */
export interface ExportDashboardUsingGETQueryParams {
    /**
     * objet contenant les dossierCriteriaDto de ExportDashboardUsingGETQueryParams
     * @type {ExportDashboardUsingGETQueryParamsDossierCriteriaDto}
     * @memberof ExportDashboardUsingGETQueryParams
     */
    dossierCriteriaDto: ExportDashboardUsingGETQueryParamsDossierCriteriaDto;
    /**
     * objet contenant les dossierSortDto de ExportDashboardUsingGETQueryParams
     * @type {ExportDashboardUsingGETQueryParamsDossierSortDto}
     * @memberof ExportDashboardUsingGETQueryParams
     */
    dossierSortDto: ExportDashboardUsingGETQueryParamsDossierSortDto;
    /**
     * 
     * @type {number}
     * @memberof ExportDashboardUsingGETQueryParams
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportDashboardUsingGETQueryParams
     */
    pageSize?: number;
}

export interface ExportDashboardUsingGETQueryParamsDossierCriteriaDto {
    /**
    * 
    * @type {Array<'PROLONGER_MEF' | 'CLASSER_MANUELLEMENT' | 'MODIFIER_COORDONNEES_PROPRIETAIRE' | 'CORRIGER_DONNEES_NOTIFICATION_MANUELLE' | 'RENSEIGNER_SORTIE_VEHICULE' | 'SAISIE_VHU' | 'SAISIE_NOTIFICATION_MANUELLE' | 'MAIN_LEVEE' | 'TRAITE_EN_FACTURATION' | 'NON_TRAITE_EN_FACTURATION' | 'ANNULATION_SORTIE_VEHICULE' | 'EXPORT_DONNEES_DOSSIER' | 'SUPPRESSION_DOSSIER' | 'UPDATE_ENTITES_RATTACHEMENT_ADMIN' | 'UPDATE_ENTITES_RATTACHEMENT_FO_AF' | 'MODIFICATION_SORTIE_VEHICULE' | 'UPDATE_DONNEES_VEHICULE' | 'UPDATE_CLASSEMENT' | 'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE' | 'ENREGISTRER_ENTREE' | 'CONTROLER_DONNEES_VEHICULE' | 'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE' | 'RELANCE_VENTE_VOL' | 'RELANCE_VENTE_IMMO_PJ' | 'RELANCE_VENTE_OPPOSITION' | 'RELANCE_VENTE_DOUBLON' | 'RELANCE_VENTE_MANQUANT' | 'RECUPERATION_AR_PND_AUTOMATIQUE' | 'CONTROLE_BLOCAGE_ARGOS' | 'CONTROLE_BLOCAGE_ANTAI' | 'CONTROLE_BLOCAGE_DNID' | 'RECUPERATION_COURRIER_AUTOMATIQUE' | 'RENSEIGNER_INFORMATIONS_SIV' | 'CONFIRMER_VOL_VVNR' | 'VALIDATION_ACTIONS_VVNR_HORS_SIF'> | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    actionsRequises?: Array<'PROLONGER_MEF' | 'CLASSER_MANUELLEMENT' | 'MODIFIER_COORDONNEES_PROPRIETAIRE' | 'CORRIGER_DONNEES_NOTIFICATION_MANUELLE' | 'RENSEIGNER_SORTIE_VEHICULE' | 'SAISIE_VHU' | 'SAISIE_NOTIFICATION_MANUELLE' | 'MAIN_LEVEE' | 'TRAITE_EN_FACTURATION' | 'NON_TRAITE_EN_FACTURATION' | 'ANNULATION_SORTIE_VEHICULE' | 'EXPORT_DONNEES_DOSSIER' | 'SUPPRESSION_DOSSIER' | 'UPDATE_ENTITES_RATTACHEMENT_ADMIN' | 'UPDATE_ENTITES_RATTACHEMENT_FO_AF' | 'MODIFICATION_SORTIE_VEHICULE' | 'UPDATE_DONNEES_VEHICULE' | 'UPDATE_CLASSEMENT' | 'RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE' | 'ENREGISTRER_ENTREE' | 'CONTROLER_DONNEES_VEHICULE' | 'CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE' | 'RELANCE_VENTE_VOL' | 'RELANCE_VENTE_IMMO_PJ' | 'RELANCE_VENTE_OPPOSITION' | 'RELANCE_VENTE_DOUBLON' | 'RELANCE_VENTE_MANQUANT' | 'RECUPERATION_AR_PND_AUTOMATIQUE' | 'CONTROLE_BLOCAGE_ARGOS' | 'CONTROLE_BLOCAGE_ANTAI' | 'CONTROLE_BLOCAGE_DNID' | 'RECUPERATION_COURRIER_AUTOMATIQUE' | 'RENSEIGNER_INFORMATIONS_SIV' | 'CONFIRMER_VOL_VVNR' | 'VALIDATION_ACTIONS_VVNR_HORS_SIF'>;
    /**
    * 
    * @type {string | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    antaiId?: string;
    /**
    * 
    * @type {Array<'ALIENATION' | 'DESTRUCTION' | 'MANUEL'> | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    classementList?: Array<'ALIENATION' | 'DESTRUCTION' | 'MANUEL'>;
    /**
    * 
    * @type {Array<string> | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    communeEnlevementList?: Array<string>;
    /**
    * 
    * @type {Date | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromAbandon?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromBonEnlevement?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromMiseAJour?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromMiseEnFourriere?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromOrdreDestruction?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    dateFromSortieDefinitive?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    dateToAbandon?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    dateToBonEnlevement?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    dateToMiseAJour?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    dateToMiseEnFourriere?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    dateToOrdreDestruction?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    dateToSortieDefinitive?: Date;
    /**
    * 
    * @type {string | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    idAutoriteFourriere?: string;
    /**
    * 
    * @type {Array<string> | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    idFourriereList?: Array<string>;
    /**
    * 
    * @type {Array<string> | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    idMotifSortieList?: Array<string>;
    /**
    * 
    * @type {Array<string> | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    idNatureBrancheList?: Array<string>;
    /**
    * 
    * @type {string | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    idUniteFO?: string;
    /**
    * 
    * @type {Array<string> | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    idUniteFOList?: Array<string>;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    includeDossiersAnonymises?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    includeDossiersSortis?: boolean;
    /**
    * 
    * @type {string | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    numeroImmatriculation?: string;
    /**
    * 
    * @type {Array<'A_REMPLIR' | 'ENREGISTRE' | 'NON_GERE' | 'NOTIFICATION_ENVOYEE' | 'NOTIFIE' | 'A_NOTIFIER' | 'EN_COURS_DE_NOTIFICATION' | 'A_REMETTRE_AUX_DOMAINES' | 'REMIS_AU_DOMAINE' | 'MIS_EN_VENTE' | 'VENDU' | 'A_RESTITUER' | 'VENTE_BLOQUEE' | 'A_DETRUIRE' | 'SORTI' | 'DETRUIT'> | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    statutDossierEnumList?: Array<'A_REMPLIR' | 'ENREGISTRE' | 'NON_GERE' | 'NOTIFICATION_ENVOYEE' | 'NOTIFIE' | 'A_NOTIFIER' | 'EN_COURS_DE_NOTIFICATION' | 'A_REMETTRE_AUX_DOMAINES' | 'REMIS_AU_DOMAINE' | 'MIS_EN_VENTE' | 'VENDU' | 'A_RESTITUER' | 'VENTE_BLOQUEE' | 'A_DETRUIRE' | 'SORTI' | 'DETRUIT'>;
    /**
    * 
    * @type {Array<'TRAITE' | 'NON_TRAITE' | 'NON_CONCERNE'> | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierCriteriaDto
    */
    statutsFacturation?: Array<'TRAITE' | 'NON_TRAITE' | 'NON_CONCERNE'>;
}

export interface ExportDashboardUsingGETQueryParamsDossierSortDto {
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierSortDto
    */
    alertesIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierSortDto
    */
    classementIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierSortDto
    */
    couleurIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierSortDto
    */
    dateAbandonIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierSortDto
    */
    dateBonEnlevementIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierSortDto
    */
    dateMiseAJourIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierSortDto
    */
    dateMiseFourriereIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierSortDto
    */
    dateOrdreDestructionIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierSortDto
    */
    dateSortieDefinitiveIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierSortDto
    */
    fourriereIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierSortDto
    */
    immatriculationIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierSortDto
    */
    marqueIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierSortDto
    */
    modeleIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierSortDto
    */
    natureMiseEnFourriereIsAsc?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof ExportDashboardUsingGETQueryParamsDossierSortDto
    */
    statutIsAsc?: boolean;
}

/**
 * ExportControllerApi - fetch parameter creator
 * @export
 */
export const ExportControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant de tÃ©lÃ©charger un fichier dont l\'export a prÃ©cÃ©demment Ã©tÃ© demandÃ©.
         * @param {string} exportId Id de l\'export, reprÃ©sentÃ© par son UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadExportUsingGET(exportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'exportId' is not null or undefined
            if (exportId === null || exportId === undefined) {
                throw new RequiredError('exportId','Required parameter exportId was null or undefined when calling downloadExportUsingGET.');
            }
            const localVarPath = `/api/export/download/{exportId}`
                .replace(`{${"exportId"}}`, encodeURIComponent(String(exportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant d\'initialiser l\'export des dossiers au format CSV.
         * @param {exportDashboardUsingGETQueryParams} queryParams objet contenant les query params de la route exportDashboardUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDashboardUsingGET(queryParams: ExportDashboardUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'dossierCriteriaDto' is not null or undefined
            if (queryParams.dossierCriteriaDto === null || queryParams.dossierCriteriaDto === undefined) {
                throw new RequiredError('dossierCriteriaDto','Required parameter dossierCriteriaDto was null or undefined when calling exportDashboardUsingGET.');
            }
            // verify required parameter 'dossierSortDto' is not null or undefined
            if (queryParams.dossierSortDto === null || queryParams.dossierSortDto === undefined) {
                throw new RequiredError('dossierSortDto','Required parameter dossierSortDto was null or undefined when calling exportDashboardUsingGET.');
            }
            const localVarPath = `/api/export/dossier`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.dossierCriteriaDto.actionsRequises !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.actionsRequises'] = queryParams.dossierCriteriaDto.actionsRequises;
            }
            if (queryParams.dossierCriteriaDto.antaiId !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.antaiId'] = queryParams.dossierCriteriaDto.antaiId;
            }
            if (queryParams.dossierCriteriaDto.classementList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.classementList'] = queryParams.dossierCriteriaDto.classementList;
            }
            if (queryParams.dossierCriteriaDto.communeEnlevementList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.communeEnlevementList'] = queryParams.dossierCriteriaDto.communeEnlevementList;
            }
            if (queryParams.dossierCriteriaDto.dateFromAbandon !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromAbandon'] = (queryParams.dossierCriteriaDto.dateFromAbandon as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateFromBonEnlevement !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromBonEnlevement'] = (queryParams.dossierCriteriaDto.dateFromBonEnlevement as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateFromMiseAJour !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromMiseAJour'] = (queryParams.dossierCriteriaDto.dateFromMiseAJour as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateFromMiseEnFourriere !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromMiseEnFourriere'] = (queryParams.dossierCriteriaDto.dateFromMiseEnFourriere as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateFromOrdreDestruction !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromOrdreDestruction'] = (queryParams.dossierCriteriaDto.dateFromOrdreDestruction as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateFromSortieDefinitive !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateFromSortieDefinitive'] = (queryParams.dossierCriteriaDto.dateFromSortieDefinitive as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToAbandon !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToAbandon'] = (queryParams.dossierCriteriaDto.dateToAbandon as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToBonEnlevement !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToBonEnlevement'] = (queryParams.dossierCriteriaDto.dateToBonEnlevement as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToMiseAJour !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToMiseAJour'] = (queryParams.dossierCriteriaDto.dateToMiseAJour as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToMiseEnFourriere !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToMiseEnFourriere'] = (queryParams.dossierCriteriaDto.dateToMiseEnFourriere as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToOrdreDestruction !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToOrdreDestruction'] = (queryParams.dossierCriteriaDto.dateToOrdreDestruction as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.dateToSortieDefinitive !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.dateToSortieDefinitive'] = (queryParams.dossierCriteriaDto.dateToSortieDefinitive as any).toISOString();
            }
            if (queryParams.dossierCriteriaDto.idAutoriteFourriere !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idAutoriteFourriere'] = queryParams.dossierCriteriaDto.idAutoriteFourriere;
            }
            if (queryParams.dossierCriteriaDto.idFourriereList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idFourriereList'] = queryParams.dossierCriteriaDto.idFourriereList;
            }
            if (queryParams.dossierCriteriaDto.idMotifSortieList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idMotifSortieList'] = queryParams.dossierCriteriaDto.idMotifSortieList;
            }
            if (queryParams.dossierCriteriaDto.idNatureBrancheList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idNatureBrancheList'] = queryParams.dossierCriteriaDto.idNatureBrancheList;
            }
            if (queryParams.dossierCriteriaDto.idUniteFO !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idUniteFO'] = queryParams.dossierCriteriaDto.idUniteFO;
            }
            if (queryParams.dossierCriteriaDto.idUniteFOList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.idUniteFOList'] = queryParams.dossierCriteriaDto.idUniteFOList;
            }
            if (queryParams.dossierCriteriaDto.includeDossiersAnonymises !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.includeDossiersAnonymises'] = queryParams.dossierCriteriaDto.includeDossiersAnonymises;
            }
            if (queryParams.dossierCriteriaDto.includeDossiersSortis !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.includeDossiersSortis'] = queryParams.dossierCriteriaDto.includeDossiersSortis;
            }
            if (queryParams.dossierCriteriaDto.numeroImmatriculation !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.numeroImmatriculation'] = queryParams.dossierCriteriaDto.numeroImmatriculation;
            }
            if (queryParams.dossierCriteriaDto.statutDossierEnumList !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.statutDossierEnumList'] = queryParams.dossierCriteriaDto.statutDossierEnumList;
            }
            if (queryParams.dossierCriteriaDto.statutsFacturation !== undefined) {
                localVarQueryParameter['dossierCriteriaDto.statutsFacturation'] = queryParams.dossierCriteriaDto.statutsFacturation;
            }

            if (queryParams.dossierSortDto.alertesIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.alertesIsAsc'] = queryParams.dossierSortDto.alertesIsAsc;
            }
            if (queryParams.dossierSortDto.classementIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.classementIsAsc'] = queryParams.dossierSortDto.classementIsAsc;
            }
            if (queryParams.dossierSortDto.couleurIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.couleurIsAsc'] = queryParams.dossierSortDto.couleurIsAsc;
            }
            if (queryParams.dossierSortDto.dateAbandonIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateAbandonIsAsc'] = queryParams.dossierSortDto.dateAbandonIsAsc;
            }
            if (queryParams.dossierSortDto.dateBonEnlevementIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateBonEnlevementIsAsc'] = queryParams.dossierSortDto.dateBonEnlevementIsAsc;
            }
            if (queryParams.dossierSortDto.dateMiseAJourIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateMiseAJourIsAsc'] = queryParams.dossierSortDto.dateMiseAJourIsAsc;
            }
            if (queryParams.dossierSortDto.dateMiseFourriereIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateMiseFourriereIsAsc'] = queryParams.dossierSortDto.dateMiseFourriereIsAsc;
            }
            if (queryParams.dossierSortDto.dateOrdreDestructionIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateOrdreDestructionIsAsc'] = queryParams.dossierSortDto.dateOrdreDestructionIsAsc;
            }
            if (queryParams.dossierSortDto.dateSortieDefinitiveIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.dateSortieDefinitiveIsAsc'] = queryParams.dossierSortDto.dateSortieDefinitiveIsAsc;
            }
            if (queryParams.dossierSortDto.fourriereIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.fourriereIsAsc'] = queryParams.dossierSortDto.fourriereIsAsc;
            }
            if (queryParams.dossierSortDto.immatriculationIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.immatriculationIsAsc'] = queryParams.dossierSortDto.immatriculationIsAsc;
            }
            if (queryParams.dossierSortDto.marqueIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.marqueIsAsc'] = queryParams.dossierSortDto.marqueIsAsc;
            }
            if (queryParams.dossierSortDto.modeleIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.modeleIsAsc'] = queryParams.dossierSortDto.modeleIsAsc;
            }
            if (queryParams.dossierSortDto.natureMiseEnFourriereIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.natureMiseEnFourriereIsAsc'] = queryParams.dossierSortDto.natureMiseEnFourriereIsAsc;
            }
            if (queryParams.dossierSortDto.statutIsAsc !== undefined) {
                localVarQueryParameter['dossierSortDto.statutIsAsc'] = queryParams.dossierSortDto.statutIsAsc;
            }

            if (queryParams.page !== undefined) {
                localVarQueryParameter['page'] = queryParams.page;
            }

            if (queryParams.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = queryParams.pageSize;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer la liste des demandes d\'export.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchExportFilesUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/export`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportControllerApi - functional programming interface
 * @export
 */
export const ExportControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant de tÃ©lÃ©charger un fichier dont l\'export a prÃ©cÃ©demment Ã©tÃ© demandÃ©.
         * @param {string} exportId Id de l\'export, reprÃ©sentÃ© par son UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadExportUsingGET(exportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ExportControllerApiFetchParamCreator(configuration).downloadExportUsingGET(exportId, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant d\'initialiser l\'export des dossiers au format CSV.
         * @param {exportDashboardUsingGETQueryParams} queryParams objet contenant les query params de la route exportDashboardUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDashboardUsingGET(queryParams: ExportDashboardUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreateExportResponseDto> {
            const localVarFetchArgs = ExportControllerApiFetchParamCreator(configuration).exportDashboardUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer la liste des demandes d\'export.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchExportFilesUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ExportFilesDto>> {
            const localVarFetchArgs = ExportControllerApiFetchParamCreator(configuration).searchExportFilesUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ExportControllerApi - object-oriented interface
 * @export
 * @class ExportControllerApi
 * @extends {BaseAPI}
 */
export class ExportControllerApi extends BaseAPI {
    /**
     * @summary Route permettant de tÃ©lÃ©charger un fichier dont l\'export a prÃ©cÃ©demment Ã©tÃ© demandÃ©.
     * @param {string} exportId Id de l\'export, reprÃ©sentÃ© par son UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public downloadExportUsingGET(exportId: string, options?: any) {
        return ExportControllerApiFp(this.configuration).downloadExportUsingGET(exportId, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant d\'initialiser l\'export des dossiers au format CSV.
     * @param {exportDashboardUsingGETQueryParams} queryParams objet contenant les query params de la route exportDashboardUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public exportDashboardUsingGET(queryParams: ExportDashboardUsingGETQueryParams, options?: any) {
        return ExportControllerApiFp(this.configuration).exportDashboardUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de rÃ©cupÃ©rer la liste des demandes d\'export.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchExportFilesUsingGET(options?: any) {
        return ExportControllerApiFp(this.configuration).searchExportFilesUsingGET(options)(this.fetch, this.basePath);
    }

}

//////////////////////////////////// FicheDescriptiveControllerApi /////////////////////////////////////////////////////

/**
 * FicheDescriptiveControllerApi - fetch parameter creator
 * @export
 */
export const FicheDescriptiveControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant de tÃ©lÃ©charger la fiche descriptive d\'un vÃ©hicule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFicheDescriptiveUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/fiche-descriptive/download`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FicheDescriptiveControllerApi - functional programming interface
 * @export
 */
export const FicheDescriptiveControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant de tÃ©lÃ©charger la fiche descriptive d\'un vÃ©hicule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFicheDescriptiveUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = FicheDescriptiveControllerApiFetchParamCreator(configuration).downloadFicheDescriptiveUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FicheDescriptiveControllerApi - object-oriented interface
 * @export
 * @class FicheDescriptiveControllerApi
 * @extends {BaseAPI}
 */
export class FicheDescriptiveControllerApi extends BaseAPI {
    /**
     * @summary Route permettant de tÃ©lÃ©charger la fiche descriptive d\'un vÃ©hicule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public downloadFicheDescriptiveUsingGET(options?: any) {
        return FicheDescriptiveControllerApiFp(this.configuration).downloadFicheDescriptiveUsingGET(options)(this.fetch, this.basePath);
    }

}

//////////////////////////////////// ForceOrdreControllerApi /////////////////////////////////////////////////////

/**
 * objet contenant les query params de la route getSourceImpossibiliteNotifierFoUsingGET
 * @export
 * @interface GetSourceImpossibiliteNotifierFoUsingGETQueryParams
 */
export interface GetSourceImpossibiliteNotifierFoUsingGETQueryParams {
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierFoUsingGETQueryParams
     */
    boitePostale?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierFoUsingGETQueryParams
     */
    codeCedex?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierFoUsingGETQueryParams
     */
    codePostal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierFoUsingGETQueryParams
     */
    complementAdresse?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierFoUsingGETQueryParams
     */
    extension?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierFoUsingGETQueryParams
     */
    libelleCedex?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierFoUsingGETQueryParams
     */
    libelleCommune?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierFoUsingGETQueryParams
     */
    libelleVoie?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierFoUsingGETQueryParams
     */
    lieuDit?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierFoUsingGETQueryParams
     */
    nom?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierFoUsingGETQueryParams
     */
    numero?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierFoUsingGETQueryParams
     */
    pays?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierFoUsingGETQueryParams
     */
    pointRemise?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierFoUsingGETQueryParams
     */
    prenom?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierFoUsingGETQueryParams
     */
    raisonSociale?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceImpossibiliteNotifierFoUsingGETQueryParams
     */
    typeVoie?: string;
}

/**
 * ForceOrdreControllerApi - fetch parameter creator
 * @export
 */
export const ForceOrdreControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * @summary   Route effectuant un contrÃ´le des donnÃ©es du vÃ©hicule   relanÃ§ant la vente si aucune modification n\'est constatÃ©e   sinon en relanÃ§ant l\'identification au SIV 
         * @param {string} id ID du dossier
         * @param {ControleDonneesVehiculeBlocageVenteRequestDto} controleDonneesVehiculeBlocageVenteRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        computeControleDonneesVehiculeBlocageVenteUsingPOST(id: string, controleDonneesVehiculeBlocageVenteRequestDto: ControleDonneesVehiculeBlocageVenteRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling computeControleDonneesVehiculeBlocageVenteUsingPOST.');
            }
            // verify required parameter 'controleDonneesVehiculeBlocageVenteRequestDto' is not null or undefined
            if (controleDonneesVehiculeBlocageVenteRequestDto === null || controleDonneesVehiculeBlocageVenteRequestDto === undefined) {
                throw new RequiredError('controleDonneesVehiculeBlocageVenteRequestDto','Required parameter controleDonneesVehiculeBlocageVenteRequestDto was null or undefined when calling computeControleDonneesVehiculeBlocageVenteUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/controle-blocage-vente`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ControleDonneesVehiculeBlocageVenteRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(controleDonneesVehiculeBlocageVenteRequestDto || {}) : (controleDonneesVehiculeBlocageVenteRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de confirmer le vol d\'un vÃ©hicule identifiÃ© vvnr
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmVolVvnrUsingPOST(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling confirmVolVvnrUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/vol-vvnr`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary RÃ©cupÃ©ration des donnÃ©es Ã  contrÃ´ler, dans le cas oÃ¹ les donnÃ©es du vÃ©hicule ne correspondent pas aux donnÃ©es du siv.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDonneesAControlerUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDonneesAControlerUsingGET.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/donnees-vehicule`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de calculer les violations menant Ã  une impossibilitÃ© de notifier
          * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {getSourceImpossibiliteNotifierFoUsingGETQueryParams} queryParams objet contenant les query params de la route getSourceImpossibiliteNotifierFoUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourceImpossibiliteNotifierFoUsingGET(id: string, queryParams: GetSourceImpossibiliteNotifierFoUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSourceImpossibiliteNotifierFoUsingGET.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/impossibilite-notifier`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.boitePostale !== undefined) {
                localVarQueryParameter['boitePostale'] = queryParams.boitePostale;
            }

            if (queryParams.codeCedex !== undefined) {
                localVarQueryParameter['codeCedex'] = queryParams.codeCedex;
            }

            if (queryParams.codePostal !== undefined) {
                localVarQueryParameter['codePostal'] = queryParams.codePostal;
            }

            if (queryParams.complementAdresse !== undefined) {
                localVarQueryParameter['complementAdresse'] = queryParams.complementAdresse;
            }

            if (queryParams.extension !== undefined) {
                localVarQueryParameter['extension'] = queryParams.extension;
            }

            if (queryParams.libelleCedex !== undefined) {
                localVarQueryParameter['libelleCedex'] = queryParams.libelleCedex;
            }

            if (queryParams.libelleCommune !== undefined) {
                localVarQueryParameter['libelleCommune'] = queryParams.libelleCommune;
            }

            if (queryParams.libelleVoie !== undefined) {
                localVarQueryParameter['libelleVoie'] = queryParams.libelleVoie;
            }

            if (queryParams.lieuDit !== undefined) {
                localVarQueryParameter['lieuDit'] = queryParams.lieuDit;
            }

            if (queryParams.nom !== undefined) {
                localVarQueryParameter['nom'] = queryParams.nom;
            }

            if (queryParams.numero !== undefined) {
                localVarQueryParameter['numero'] = queryParams.numero;
            }

            if (queryParams.pays !== undefined) {
                localVarQueryParameter['pays'] = queryParams.pays;
            }

            if (queryParams.pointRemise !== undefined) {
                localVarQueryParameter['pointRemise'] = queryParams.pointRemise;
            }

            if (queryParams.prenom !== undefined) {
                localVarQueryParameter['prenom'] = queryParams.prenom;
            }

            if (queryParams.raisonSociale !== undefined) {
                localVarQueryParameter['raisonSociale'] = queryParams.raisonSociale;
            }

            if (queryParams.typeVoie !== undefined) {
                localVarQueryParameter['typeVoie'] = queryParams.typeVoie;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer les PDFs de notification manuelle.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualNotificationPdfUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling manualNotificationPdfUsingGET.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/notification/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary CrÃ©ation d\'un dossier via une fiche descriptive pour un profil force de l\'ordre
         * @param {CreateDossierFromForceOrdreDto} createDossierFromForceOrdreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDossierWithInitialInfoUsingPOST(createDossierFromForceOrdreDto: CreateDossierFromForceOrdreDto, options: any = {}): FetchArgs {
            // verify required parameter 'createDossierFromForceOrdreDto' is not null or undefined
            if (createDossierFromForceOrdreDto === null || createDossierFromForceOrdreDto === undefined) {
                throw new RequiredError('createDossierFromForceOrdreDto','Required parameter createDossierFromForceOrdreDto was null or undefined when calling postDossierWithInitialInfoUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateDossierFromForceOrdreDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(createDossierFromForceOrdreDto || {}) : (createDossierFromForceOrdreDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route de rÃ©cupÃ©ration des coordonnÃ©es propriÃ©taire d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par son UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCoordonneesProprietaireUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling retrieveCoordonneesProprietaireUsingGET.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/coordonnees-proprietaire`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer les informations d\'un utilisateur de profile FO.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFoUserInformationUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/force-ordre/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary RÃ©cupÃ©ration des donnÃ©es de la main levÃ©e d\'un dossier.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveMainleveeUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling retrieveMainleveeUsingGET.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/main-levee`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour les donnÃ©es d\'une unitÃ© FO.
         * @param {string} idCorrelation Id de l\'unite FO, reprÃ©sentÃ© par un UUID.
         * @param {ProfilUniteFoRequestDto} profilUniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUniteFoInfosUsingPOST(idCorrelation: string, profilUniteFoRequestDto: ProfilUniteFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling setUniteFoInfosUsingPOST.');
            }
            // verify required parameter 'profilUniteFoRequestDto' is not null or undefined
            if (profilUniteFoRequestDto === null || profilUniteFoRequestDto === undefined) {
                throw new RequiredError('profilUniteFoRequestDto','Required parameter profilUniteFoRequestDto was null or undefined when calling setUniteFoInfosUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/profil/unite/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProfilUniteFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(profilUniteFoRequestDto || {}) : (profilUniteFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Soumission du formulaire de notification manuelle
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {NotificationInfosRequestDto} notificationInfosRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitNotificationInfosUsingPOST(id: string, notificationInfosRequestDto: NotificationInfosRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling submitNotificationInfosUsingPOST.');
            }
            // verify required parameter 'notificationInfosRequestDto' is not null or undefined
            if (notificationInfosRequestDto === null || notificationInfosRequestDto === undefined) {
                throw new RequiredError('notificationInfosRequestDto','Required parameter notificationInfosRequestDto was null or undefined when calling submitNotificationInfosUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/notification-infos`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NotificationInfosRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(notificationInfosRequestDto || {}) : (notificationInfosRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de modifier les donnÃ©es d\'un dossier, dans le cas oÃ¹ les donnÃ©es du vÃ©hicule ne correspondent pas aux donnÃ©es du siv.
         * @param {string} id 
         * @param {ControleDonneesVehiculeRequestDto} controleDonneesVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateControleDonneesVehiculeUsingPOST(id: string, controleDonneesVehiculeRequestDto: ControleDonneesVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateControleDonneesVehiculeUsingPOST.');
            }
            // verify required parameter 'controleDonneesVehiculeRequestDto' is not null or undefined
            if (controleDonneesVehiculeRequestDto === null || controleDonneesVehiculeRequestDto === undefined) {
                throw new RequiredError('controleDonneesVehiculeRequestDto','Required parameter controleDonneesVehiculeRequestDto was null or undefined when calling updateControleDonneesVehiculeUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/donnees-vehicule`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ControleDonneesVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(controleDonneesVehiculeRequestDto || {}) : (controleDonneesVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de modifier les coordonnees du propriÃ©taire pour les fo
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCoordonneesProprietairesFoUsingPOST(id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCoordonneesProprietairesFoUsingPOST.');
            }
            // verify required parameter 'coordonneesProprietaireRequestDto' is not null or undefined
            if (coordonneesProprietaireRequestDto === null || coordonneesProprietaireRequestDto === undefined) {
                throw new RequiredError('coordonneesProprietaireRequestDto','Required parameter coordonneesProprietaireRequestDto was null or undefined when calling updateCoordonneesProprietairesFoUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/coordonnees-proprietaires`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CoordonneesProprietaireRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(coordonneesProprietaireRequestDto || {}) : (coordonneesProprietaireRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de modifier les entitÃ©es de rattachement d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntitesRattachementFOUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateEntitesRattachementFOUsingPOST.');
            }
            // verify required parameter 'updateEntitesRattachementRequestDto' is not null or undefined
            if (updateEntitesRattachementRequestDto === null || updateEntitesRattachementRequestDto === undefined) {
                throw new RequiredError('updateEntitesRattachementRequestDto','Required parameter updateEntitesRattachementRequestDto was null or undefined when calling updateEntitesRattachementFOUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/entites-rattachement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateEntitesRattachementRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateEntitesRattachementRequestDto || {}) : (updateEntitesRattachementRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Soumission du formulaire d\'expÃ©diteur de notification manuelle.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {ExpediteurNotificationRequestDto} expediteurNotificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExpediteurNotificationUsingPOST(id: string, expediteurNotificationRequestDto: ExpediteurNotificationRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateExpediteurNotificationUsingPOST.');
            }
            // verify required parameter 'expediteurNotificationRequestDto' is not null or undefined
            if (expediteurNotificationRequestDto === null || expediteurNotificationRequestDto === undefined) {
                throw new RequiredError('expediteurNotificationRequestDto','Required parameter expediteurNotificationRequestDto was null or undefined when calling updateExpediteurNotificationUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/expediteur`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ExpediteurNotificationRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(expediteurNotificationRequestDto || {}) : (expediteurNotificationRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour la main levÃ©e
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {UpdateMainLeveeRequestDto} updateMainLeveeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMainLeveeUsingPOST(id: string, updateMainLeveeRequestDto: UpdateMainLeveeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateMainLeveeUsingPOST.');
            }
            // verify required parameter 'updateMainLeveeRequestDto' is not null or undefined
            if (updateMainLeveeRequestDto === null || updateMainLeveeRequestDto === undefined) {
                throw new RequiredError('updateMainLeveeRequestDto','Required parameter updateMainLeveeRequestDto was null or undefined when calling updateMainLeveeUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/main-levee`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateMainLeveeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateMainLeveeRequestDto || {}) : (updateMainLeveeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary API permettant d\'uploader une photo et les informations associÃ©es d\'un cÃ´tÃ© d\'un vÃ©hicule.
         * @param {'AVANT' | 'ARRIERE' | 'DROITE' | 'GAUCHE' | 'AUTRE'} coteVehicule cÃ´tÃ© du vÃ©hicule de la photo
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {any} file Fiche contenant la photo
         * @param {string} photoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPhotoVehiculeUsingPOST(coteVehicule: 'AVANT' | 'ARRIERE' | 'DROITE' | 'GAUCHE' | 'AUTRE', id: string, file: any, photoRequestDto: string, options: any = {}): FetchArgs {
            // verify required parameter 'coteVehicule' is not null or undefined
            if (coteVehicule === null || coteVehicule === undefined) {
                throw new RequiredError('coteVehicule','Required parameter coteVehicule was null or undefined when calling uploadPhotoVehiculeUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadPhotoVehiculeUsingPOST.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadPhotoVehiculeUsingPOST.');
            }
            // verify required parameter 'photoRequestDto' is not null or undefined
            if (photoRequestDto === null || photoRequestDto === undefined) {
                throw new RequiredError('photoRequestDto','Required parameter photoRequestDto was null or undefined when calling uploadPhotoVehiculeUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/photo/{coteVehicule}`
                .replace(`{${"coteVehicule"}}`, encodeURIComponent(String(coteVehicule)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            if (photoRequestDto !== undefined) {
                localVarFormParams.append('photoRequestDto', photoRequestDto as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider que les actions hors SIF ont Ã©tÃ© effectuÃ©es
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateActionsHorsSifUsingPOST(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateActionsHorsSifUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/vol-vvnr/actions-hors-sif/validate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider la modification lors du contrÃ´le des donnÃ©es d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {ControleDonneesVehiculeRequestDto} controleDonneesVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateControleDonneesVehiculeUsingPOST(id: string, controleDonneesVehiculeRequestDto: ControleDonneesVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateControleDonneesVehiculeUsingPOST.');
            }
            // verify required parameter 'controleDonneesVehiculeRequestDto' is not null or undefined
            if (controleDonneesVehiculeRequestDto === null || controleDonneesVehiculeRequestDto === undefined) {
                throw new RequiredError('controleDonneesVehiculeRequestDto','Required parameter controleDonneesVehiculeRequestDto was null or undefined when calling validateControleDonneesVehiculeUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/donnees-vehicule/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ControleDonneesVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(controleDonneesVehiculeRequestDto || {}) : (controleDonneesVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ du formulaire de mise Ã  jour des CoordonnÃ©ees propriÃ©taires.
         * @param {'PRENOM' | 'NOM' | 'RAISON_SOCIALE' | 'POINT_REMISE' | 'NUMERO' | 'EXTENSION' | 'TYPEVOIE' | 'LIBELLE_VOIE' | 'COMPLEMENT_ADRESSE' | 'LIEUDIT' | 'BOITE_POSTALE' | 'CODE_POSTAL' | 'LIBELLE_COMMUNE' | 'CODE_CEDEX' | 'LIBELLE_CEDEX' | 'PAYS'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCoordonneesProprietairesFieldFoUsingPOST(field: 'PRENOM' | 'NOM' | 'RAISON_SOCIALE' | 'POINT_REMISE' | 'NUMERO' | 'EXTENSION' | 'TYPEVOIE' | 'LIBELLE_VOIE' | 'COMPLEMENT_ADRESSE' | 'LIEUDIT' | 'BOITE_POSTALE' | 'CODE_POSTAL' | 'LIBELLE_COMMUNE' | 'CODE_CEDEX' | 'LIBELLE_CEDEX' | 'PAYS', id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateCoordonneesProprietairesFieldFoUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateCoordonneesProprietairesFieldFoUsingPOST.');
            }
            // verify required parameter 'coordonneesProprietaireRequestDto' is not null or undefined
            if (coordonneesProprietaireRequestDto === null || coordonneesProprietaireRequestDto === undefined) {
                throw new RequiredError('coordonneesProprietaireRequestDto','Required parameter coordonneesProprietaireRequestDto was null or undefined when calling validateCoordonneesProprietairesFieldFoUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/coordonnees-proprietaires/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CoordonneesProprietaireRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(coordonneesProprietaireRequestDto || {}) : (coordonneesProprietaireRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider tous les champs du formulaire de mise Ã  jour des coordonnÃ©es propriÃ©taires pour les fo
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCoordonneesProprietairesFoUsingPOST(id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateCoordonneesProprietairesFoUsingPOST.');
            }
            // verify required parameter 'coordonneesProprietaireRequestDto' is not null or undefined
            if (coordonneesProprietaireRequestDto === null || coordonneesProprietaireRequestDto === undefined) {
                throw new RequiredError('coordonneesProprietaireRequestDto','Required parameter coordonneesProprietaireRequestDto was null or undefined when calling validateCoordonneesProprietairesFoUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/coordonnees-proprietaires/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CoordonneesProprietaireRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(coordonneesProprietaireRequestDto || {}) : (coordonneesProprietaireRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation d\'un dossier pour une crÃ©ation FO via une fiche descriptive
         * @param {CreateDossierFromForceOrdreDto} createDossierFromForceOrdreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateDossierWithInitialInfoUsingPOST(createDossierFromForceOrdreDto: CreateDossierFromForceOrdreDto, options: any = {}): FetchArgs {
            // verify required parameter 'createDossierFromForceOrdreDto' is not null or undefined
            if (createDossierFromForceOrdreDto === null || createDossierFromForceOrdreDto === undefined) {
                throw new RequiredError('createDossierFromForceOrdreDto','Required parameter createDossierFromForceOrdreDto was null or undefined when calling validateDossierWithInitialInfoUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateDossierFromForceOrdreDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(createDossierFromForceOrdreDto || {}) : (createDossierFromForceOrdreDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation d\'un champ des informations de l\'expÃ©diteur de la notification manuelle pour un dossier existant.
         * @param {'UNITE' | 'ADRESSE' | 'CODE_POSTAL' | 'COMMUNE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {ExpediteurNotificationRequestDto} expediteurNotificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateExpediteurNotificationFieldUsingPOST(field: 'UNITE' | 'ADRESSE' | 'CODE_POSTAL' | 'COMMUNE', id: string, expediteurNotificationRequestDto: ExpediteurNotificationRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateExpediteurNotificationFieldUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateExpediteurNotificationFieldUsingPOST.');
            }
            // verify required parameter 'expediteurNotificationRequestDto' is not null or undefined
            if (expediteurNotificationRequestDto === null || expediteurNotificationRequestDto === undefined) {
                throw new RequiredError('expediteurNotificationRequestDto','Required parameter expediteurNotificationRequestDto was null or undefined when calling validateExpediteurNotificationFieldUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/expediteur/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ExpediteurNotificationRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(expediteurNotificationRequestDto || {}) : (expediteurNotificationRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs des informations de l\'expÃ©diteur de la notification manuelle pour un dossier existant.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {ExpediteurNotificationRequestDto} expediteurNotificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateExpediteurNotificationUsingPOST(id: string, expediteurNotificationRequestDto: ExpediteurNotificationRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateExpediteurNotificationUsingPOST.');
            }
            // verify required parameter 'expediteurNotificationRequestDto' is not null or undefined
            if (expediteurNotificationRequestDto === null || expediteurNotificationRequestDto === undefined) {
                throw new RequiredError('expediteurNotificationRequestDto','Required parameter expediteurNotificationRequestDto was null or undefined when calling validateExpediteurNotificationUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/expediteur/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ExpediteurNotificationRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(expediteurNotificationRequestDto || {}) : (expediteurNotificationRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation d\'un seul champ de la fiche conditions pour un dossier non crÃ©Ã©.
         * @param {'DATE_CONSTATATION' | 'MOTIVATION_MESURE' | 'ADRESSE' | 'COMMUNE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {FicheConditionsRequestDto} ficheConditionsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheConditionsFieldWithoutDossierUsingPOST(field: 'DATE_CONSTATATION' | 'MOTIVATION_MESURE' | 'ADRESSE' | 'COMMUNE', ficheConditionsRequestDto: FicheConditionsRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateFicheConditionsFieldWithoutDossierUsingPOST.');
            }
            // verify required parameter 'ficheConditionsRequestDto' is not null or undefined
            if (ficheConditionsRequestDto === null || ficheConditionsRequestDto === undefined) {
                throw new RequiredError('ficheConditionsRequestDto','Required parameter ficheConditionsRequestDto was null or undefined when calling validateFicheConditionsFieldWithoutDossierUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/fiche-conditions/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FicheConditionsRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ficheConditionsRequestDto || {}) : (ficheConditionsRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche conditions pour un dossier non crÃ©Ã©.
         * @param {FicheConditionsRequestDto} ficheConditionsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheConditionsWithoutDossierUsingPOST(ficheConditionsRequestDto: FicheConditionsRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'ficheConditionsRequestDto' is not null or undefined
            if (ficheConditionsRequestDto === null || ficheConditionsRequestDto === undefined) {
                throw new RequiredError('ficheConditionsRequestDto','Required parameter ficheConditionsRequestDto was null or undefined when calling validateFicheConditionsWithoutDossierUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/fiche-conditions/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FicheConditionsRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ficheConditionsRequestDto || {}) : (ficheConditionsRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation d\'un seul champ de la fiche de dÃ©tails vÃ©hicule pour un dossier non crÃ©Ã©.
         * @param {'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {FicheDetailsVehiculeFoRequestDto} ficheDetailsVehiculeFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheDetailsVehiculeFieldWithoutDossierUsingPOST(field: 'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS', ficheDetailsVehiculeFoRequestDto: FicheDetailsVehiculeFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateFicheDetailsVehiculeFieldWithoutDossierUsingPOST.');
            }
            // verify required parameter 'ficheDetailsVehiculeFoRequestDto' is not null or undefined
            if (ficheDetailsVehiculeFoRequestDto === null || ficheDetailsVehiculeFoRequestDto === undefined) {
                throw new RequiredError('ficheDetailsVehiculeFoRequestDto','Required parameter ficheDetailsVehiculeFoRequestDto was null or undefined when calling validateFicheDetailsVehiculeFieldWithoutDossierUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/fiche-details-vehicule/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FicheDetailsVehiculeFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ficheDetailsVehiculeFoRequestDto || {}) : (ficheDetailsVehiculeFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche de dÃ©tails vÃ©hicule pour un dossier non crÃ©Ã©.
         * @param {FicheDetailsVehiculeFoRequestDto} ficheDetailsVehiculeFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheDetailsVehiculeWithoutDossierUsingPOST(ficheDetailsVehiculeFoRequestDto: FicheDetailsVehiculeFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'ficheDetailsVehiculeFoRequestDto' is not null or undefined
            if (ficheDetailsVehiculeFoRequestDto === null || ficheDetailsVehiculeFoRequestDto === undefined) {
                throw new RequiredError('ficheDetailsVehiculeFoRequestDto','Required parameter ficheDetailsVehiculeFoRequestDto was null or undefined when calling validateFicheDetailsVehiculeWithoutDossierUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/fiche-details-vehicule/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FicheDetailsVehiculeFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ficheDetailsVehiculeFoRequestDto || {}) : (ficheDetailsVehiculeFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation d\'un seul champ de la fiche Ã©tat gÃ©nÃ©ral pour un dossier non crÃ©Ã©.
         * @param {'ETAT_LIBELLE' | 'ETAT_PRECISION'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {FicheEtatGeneralFoRequestDto} ficheEtatGeneralFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheEtatGeneralFieldWithoutDossierUsingPOST(field: 'ETAT_LIBELLE' | 'ETAT_PRECISION', ficheEtatGeneralFoRequestDto: FicheEtatGeneralFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateFicheEtatGeneralFieldWithoutDossierUsingPOST.');
            }
            // verify required parameter 'ficheEtatGeneralFoRequestDto' is not null or undefined
            if (ficheEtatGeneralFoRequestDto === null || ficheEtatGeneralFoRequestDto === undefined) {
                throw new RequiredError('ficheEtatGeneralFoRequestDto','Required parameter ficheEtatGeneralFoRequestDto was null or undefined when calling validateFicheEtatGeneralFieldWithoutDossierUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/fiche-etat-general/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FicheEtatGeneralFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ficheEtatGeneralFoRequestDto || {}) : (ficheEtatGeneralFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche etat gÃ©nÃ©ral pour un dossier non crÃ©Ã©.
         * @param {FicheEtatGeneralFoRequestDto} ficheEtatGeneralFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheEtatGeneralWithoutDossierUsingPOST(ficheEtatGeneralFoRequestDto: FicheEtatGeneralFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'ficheEtatGeneralFoRequestDto' is not null or undefined
            if (ficheEtatGeneralFoRequestDto === null || ficheEtatGeneralFoRequestDto === undefined) {
                throw new RequiredError('ficheEtatGeneralFoRequestDto','Required parameter ficheEtatGeneralFoRequestDto was null or undefined when calling validateFicheEtatGeneralWithoutDossierUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/fiche-etat-general/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FicheEtatGeneralFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ficheEtatGeneralFoRequestDto || {}) : (ficheEtatGeneralFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation d\'un champ de la fiche fourriÃ¨re pour un dossier non crÃ©Ã©.
         * @param {'FOURRIERE' | 'AUTORITE_FOURRIERE' | 'AUTORITE_QUALIFIEE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {FicheFourriereFoRequestDto} ficheFourriereFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheFourriereFieldWithoutDossierUsingPOST1(field: 'FOURRIERE' | 'AUTORITE_FOURRIERE' | 'AUTORITE_QUALIFIEE', ficheFourriereFoRequestDto: FicheFourriereFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateFicheFourriereFieldWithoutDossierUsingPOST1.');
            }
            // verify required parameter 'ficheFourriereFoRequestDto' is not null or undefined
            if (ficheFourriereFoRequestDto === null || ficheFourriereFoRequestDto === undefined) {
                throw new RequiredError('ficheFourriereFoRequestDto','Required parameter ficheFourriereFoRequestDto was null or undefined when calling validateFicheFourriereFieldWithoutDossierUsingPOST1.');
            }
            const localVarPath = `/api/force-ordre/dossier/fiche-fourriere/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FicheFourriereFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ficheFourriereFoRequestDto || {}) : (ficheFourriereFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche fourriÃ¨re pour un dossier non crÃ©Ã©.
         * @param {FicheFourriereFoRequestDto} ficheFourriereFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheFourriereWithoutDossierUsingPOST1(ficheFourriereFoRequestDto: FicheFourriereFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'ficheFourriereFoRequestDto' is not null or undefined
            if (ficheFourriereFoRequestDto === null || ficheFourriereFoRequestDto === undefined) {
                throw new RequiredError('ficheFourriereFoRequestDto','Required parameter ficheFourriereFoRequestDto was null or undefined when calling validateFicheFourriereWithoutDossierUsingPOST1.');
            }
            const localVarPath = `/api/force-ordre/dossier/fiche-fourriere/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FicheFourriereFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ficheFourriereFoRequestDto || {}) : (ficheFourriereFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation d\'un seul champ de la fiche vÃ©hicule pour un dossier non crÃ©Ã©.
         * @param {'DESCRIPTION_GENRE' | 'DESCRIPTION_MARQUE' | 'DESCRIPTION_MODELE' | 'DESCRIPTION_COULEUR' | 'ETAT_LIBELLE' | 'ETAT_PRECISION' | 'IMMATRICULATION_SANS_PLAQUE' | 'IMMATRICULATION_PAYS_ETRANGER' | 'TYPE_PLAQUE' | 'IMMATRICULATION_NUMERO' | 'IMMATRICULATION_NUMERO_VISIBLE' | 'IMMATRICULATION_VIN' | 'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_AUTO_RADIO_VISIBLE' | 'ACCESSOIRES_POSTE_CB_VISIBLE' | 'ACCESSOIRES_TELEPHONE_PORTABLE' | 'ACCESSOIRES_NOMBRE_ANTENNES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {FicheVehiculeFoValidationDto} ficheVehiculeFoValidationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheVehiculeFieldWithoutDossierUsingPOST1(field: 'DESCRIPTION_GENRE' | 'DESCRIPTION_MARQUE' | 'DESCRIPTION_MODELE' | 'DESCRIPTION_COULEUR' | 'ETAT_LIBELLE' | 'ETAT_PRECISION' | 'IMMATRICULATION_SANS_PLAQUE' | 'IMMATRICULATION_PAYS_ETRANGER' | 'TYPE_PLAQUE' | 'IMMATRICULATION_NUMERO' | 'IMMATRICULATION_NUMERO_VISIBLE' | 'IMMATRICULATION_VIN' | 'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_AUTO_RADIO_VISIBLE' | 'ACCESSOIRES_POSTE_CB_VISIBLE' | 'ACCESSOIRES_TELEPHONE_PORTABLE' | 'ACCESSOIRES_NOMBRE_ANTENNES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS', ficheVehiculeFoValidationDto: FicheVehiculeFoValidationDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateFicheVehiculeFieldWithoutDossierUsingPOST1.');
            }
            // verify required parameter 'ficheVehiculeFoValidationDto' is not null or undefined
            if (ficheVehiculeFoValidationDto === null || ficheVehiculeFoValidationDto === undefined) {
                throw new RequiredError('ficheVehiculeFoValidationDto','Required parameter ficheVehiculeFoValidationDto was null or undefined when calling validateFicheVehiculeFieldWithoutDossierUsingPOST1.');
            }
            const localVarPath = `/api/force-ordre/dossier/fiche-vehicule/v2/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FicheVehiculeFoValidationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ficheVehiculeFoValidationDto || {}) : (ficheVehiculeFoValidationDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche vÃ©hicule pour un dossier non crÃ©Ã©.
         * @param {FicheVehiculeFoValidationDto} ficheVehiculeFoValidationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheVehiculeWithoutDossierUsingPOST1(ficheVehiculeFoValidationDto: FicheVehiculeFoValidationDto, options: any = {}): FetchArgs {
            // verify required parameter 'ficheVehiculeFoValidationDto' is not null or undefined
            if (ficheVehiculeFoValidationDto === null || ficheVehiculeFoValidationDto === undefined) {
                throw new RequiredError('ficheVehiculeFoValidationDto','Required parameter ficheVehiculeFoValidationDto was null or undefined when calling validateFicheVehiculeWithoutDossierUsingPOST1.');
            }
            const localVarPath = `/api/force-ordre/dossier/fiche-vehicule/v2/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FicheVehiculeFoValidationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ficheVehiculeFoValidationDto || {}) : (ficheVehiculeFoValidationDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider la modification d\'un champ lors du contrÃ´le des donnÃ©es d\'un dossier
         * @param {'NEW_MARQUE' | 'NEW_MODELE' | 'NEW_GENRE' | 'NEW_PAYS_ETRANGER' | 'NEW_SANS_PLAQUE' | 'NEW_PLAQUE' | 'NEW_PLAQUE_VISIBLE' | 'NEW_VIN'} field 
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {ControleDonneesVehiculeRequestDto} controleDonneesVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFieldControleDonneesVehiculeUsingPOST(field: 'NEW_MARQUE' | 'NEW_MODELE' | 'NEW_GENRE' | 'NEW_PAYS_ETRANGER' | 'NEW_SANS_PLAQUE' | 'NEW_PLAQUE' | 'NEW_PLAQUE_VISIBLE' | 'NEW_VIN', id: string, controleDonneesVehiculeRequestDto: ControleDonneesVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateFieldControleDonneesVehiculeUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateFieldControleDonneesVehiculeUsingPOST.');
            }
            // verify required parameter 'controleDonneesVehiculeRequestDto' is not null or undefined
            if (controleDonneesVehiculeRequestDto === null || controleDonneesVehiculeRequestDto === undefined) {
                throw new RequiredError('controleDonneesVehiculeRequestDto','Required parameter controleDonneesVehiculeRequestDto was null or undefined when calling validateFieldControleDonneesVehiculeUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/donnees-vehicule/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ControleDonneesVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(controleDonneesVehiculeRequestDto || {}) : (controleDonneesVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ des informations de notification manuelle pour un dossier existant.
         * @param {'DATE_ENVOI' | 'DATE_RETOUR' | 'ETAT_DISTRIBUTION'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {NotificationInfosRequestDto} notificationInfosRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateNotificationInfosFieldUsingPOST(field: 'DATE_ENVOI' | 'DATE_RETOUR' | 'ETAT_DISTRIBUTION', id: string, notificationInfosRequestDto: NotificationInfosRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateNotificationInfosFieldUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateNotificationInfosFieldUsingPOST.');
            }
            // verify required parameter 'notificationInfosRequestDto' is not null or undefined
            if (notificationInfosRequestDto === null || notificationInfosRequestDto === undefined) {
                throw new RequiredError('notificationInfosRequestDto','Required parameter notificationInfosRequestDto was null or undefined when calling validateNotificationInfosFieldUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/notification-infos/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NotificationInfosRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(notificationInfosRequestDto || {}) : (notificationInfosRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs des informations d\'une notification manuelle pour un dossier existant.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {NotificationInfosRequestDto} notificationInfosRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateNotificationInfosUsingPOST(id: string, notificationInfosRequestDto: NotificationInfosRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateNotificationInfosUsingPOST.');
            }
            // verify required parameter 'notificationInfosRequestDto' is not null or undefined
            if (notificationInfosRequestDto === null || notificationInfosRequestDto === undefined) {
                throw new RequiredError('notificationInfosRequestDto','Required parameter notificationInfosRequestDto was null or undefined when calling validateNotificationInfosUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/notification-infos/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NotificationInfosRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(notificationInfosRequestDto || {}) : (notificationInfosRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ du formulaire de mise Ã  jour du profile FO.
         * @param {'ADRESSE' | 'CODE_POSTAL' | 'VILLE' | 'EMAIL' | 'TELEPHONE'} field IntitulÃ© du champ Ã  valider
         * @param {string} idCorrelation Id de l\'unitÃ©, reprÃ©sentÃ© par un UUID.
         * @param {ProfilUniteFoRequestDto} profilUniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUniteFoInfosFieldUsingPOST(field: 'ADRESSE' | 'CODE_POSTAL' | 'VILLE' | 'EMAIL' | 'TELEPHONE', idCorrelation: string, profilUniteFoRequestDto: ProfilUniteFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUniteFoInfosFieldUsingPOST.');
            }
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUniteFoInfosFieldUsingPOST.');
            }
            // verify required parameter 'profilUniteFoRequestDto' is not null or undefined
            if (profilUniteFoRequestDto === null || profilUniteFoRequestDto === undefined) {
                throw new RequiredError('profilUniteFoRequestDto','Required parameter profilUniteFoRequestDto was null or undefined when calling validateUniteFoInfosFieldUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/profil/unite/{idCorrelation}/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProfilUniteFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(profilUniteFoRequestDto || {}) : (profilUniteFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider tous les champs du formulaire de mise Ã  jour du profile FO.
         * @param {string} idCorrelation Id de l\'unitÃ©, reprÃ©sentÃ© par un UUID.
         * @param {ProfilUniteFoRequestDto} profilUniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUniteFoInfosUsingPOST(idCorrelation: string, profilUniteFoRequestDto: ProfilUniteFoRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateUniteFoInfosUsingPOST.');
            }
            // verify required parameter 'profilUniteFoRequestDto' is not null or undefined
            if (profilUniteFoRequestDto === null || profilUniteFoRequestDto === undefined) {
                throw new RequiredError('profilUniteFoRequestDto','Required parameter profilUniteFoRequestDto was null or undefined when calling validateUniteFoInfosUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/profil/unite/{idCorrelation}/validation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProfilUniteFoRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(profilUniteFoRequestDto || {}) : (profilUniteFoRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider l\'ensemble des champs du formulaire de modifications des entitÃ©s de rattachement d\'un dossier.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateEntitesRattachementFOUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateUpdateEntitesRattachementFOUsingPOST.');
            }
            // verify required parameter 'updateEntitesRattachementRequestDto' is not null or undefined
            if (updateEntitesRattachementRequestDto === null || updateEntitesRattachementRequestDto === undefined) {
                throw new RequiredError('updateEntitesRattachementRequestDto','Required parameter updateEntitesRattachementRequestDto was null or undefined when calling validateUpdateEntitesRattachementFOUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/entites-rattachement/{id}/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateEntitesRattachementRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateEntitesRattachementRequestDto || {}) : (updateEntitesRattachementRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un des champs du formulaire de modifications des entitÃ©s de rattachement d\'un dossier.
         * @param {'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT'} field 
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateEntitesRattachementFieldFOUsingPOST(field: 'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT', id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUpdateEntitesRattachementFieldFOUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateUpdateEntitesRattachementFieldFOUsingPOST.');
            }
            // verify required parameter 'updateEntitesRattachementRequestDto' is not null or undefined
            if (updateEntitesRattachementRequestDto === null || updateEntitesRattachementRequestDto === undefined) {
                throw new RequiredError('updateEntitesRattachementRequestDto','Required parameter updateEntitesRattachementRequestDto was null or undefined when calling validateUpdateEntitesRattachementFieldFOUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/entites-rattachement/{id}/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateEntitesRattachementRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateEntitesRattachementRequestDto || {}) : (updateEntitesRattachementRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ du formulaire de mise Ã  jour de la main levÃ©e.
         * @param {'NUM_PROCES_VERBAL' | 'DATE_PROCES_VERBAL' | 'GRADE_AGENT' | 'MATRICULE_AGENT' | 'COMMUNE_FONCTION' | 'TYPE_RESTITUTION' | 'GENRE_SIMPLIFIE_ID' | 'IMMATRICULATION' | 'MARQUE_ID' | 'NOM_PROPRIETAIRE' | 'LIEU_ADRESSE_PROPRIETAIRE' | 'CODE_POSTAL_PROPRIETAIRE' | 'COMMUNE_ADRESSE_PROPRIETAIRE' | 'FOURRIERE_ID' | 'DEBUT_AUTORISATION' | 'FIN_AUTORISATION' | 'COMMUNE_REDACTION' | 'JUSTIFICATION_SORTIE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {UpdateMainLeveeRequestDto} updateMainLeveeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateMainLeveeFieldUsingPOST(field: 'NUM_PROCES_VERBAL' | 'DATE_PROCES_VERBAL' | 'GRADE_AGENT' | 'MATRICULE_AGENT' | 'COMMUNE_FONCTION' | 'TYPE_RESTITUTION' | 'GENRE_SIMPLIFIE_ID' | 'IMMATRICULATION' | 'MARQUE_ID' | 'NOM_PROPRIETAIRE' | 'LIEU_ADRESSE_PROPRIETAIRE' | 'CODE_POSTAL_PROPRIETAIRE' | 'COMMUNE_ADRESSE_PROPRIETAIRE' | 'FOURRIERE_ID' | 'DEBUT_AUTORISATION' | 'FIN_AUTORISATION' | 'COMMUNE_REDACTION' | 'JUSTIFICATION_SORTIE', id: string, updateMainLeveeRequestDto: UpdateMainLeveeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateUpdateMainLeveeFieldUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateUpdateMainLeveeFieldUsingPOST.');
            }
            // verify required parameter 'updateMainLeveeRequestDto' is not null or undefined
            if (updateMainLeveeRequestDto === null || updateMainLeveeRequestDto === undefined) {
                throw new RequiredError('updateMainLeveeRequestDto','Required parameter updateMainLeveeRequestDto was null or undefined when calling validateUpdateMainLeveeFieldUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/main-levee/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateMainLeveeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateMainLeveeRequestDto || {}) : (updateMainLeveeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider tous les champs du formulaire de mise Ã  jour de la main levÃ©e.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {UpdateMainLeveeRequestDto} updateMainLeveeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateMainLeveeUsingPOST(id: string, updateMainLeveeRequestDto: UpdateMainLeveeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateUpdateMainLeveeUsingPOST.');
            }
            // verify required parameter 'updateMainLeveeRequestDto' is not null or undefined
            if (updateMainLeveeRequestDto === null || updateMainLeveeRequestDto === undefined) {
                throw new RequiredError('updateMainLeveeRequestDto','Required parameter updateMainLeveeRequestDto was null or undefined when calling validateUpdateMainLeveeUsingPOST.');
            }
            const localVarPath = `/api/force-ordre/dossier/{id}/main-levee/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateMainLeveeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateMainLeveeRequestDto || {}) : (updateMainLeveeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForceOrdreControllerApi - functional programming interface
 * @export
 */
export const ForceOrdreControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * @summary   Route effectuant un contrÃ´le des donnÃ©es du vÃ©hicule   relanÃ§ant la vente si aucune modification n\'est constatÃ©e   sinon en relanÃ§ant l\'identification au SIV 
         * @param {string} id ID du dossier
         * @param {ControleDonneesVehiculeBlocageVenteRequestDto} controleDonneesVehiculeBlocageVenteRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        computeControleDonneesVehiculeBlocageVenteUsingPOST(id: string, controleDonneesVehiculeBlocageVenteRequestDto: ControleDonneesVehiculeBlocageVenteRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).computeControleDonneesVehiculeBlocageVenteUsingPOST(id, controleDonneesVehiculeBlocageVenteRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de confirmer le vol d\'un vÃ©hicule identifiÃ© vvnr
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmVolVvnrUsingPOST(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).confirmVolVvnrUsingPOST(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary RÃ©cupÃ©ration des donnÃ©es Ã  contrÃ´ler, dans le cas oÃ¹ les donnÃ©es du vÃ©hicule ne correspondent pas aux donnÃ©es du siv.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDonneesAControlerUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DonneesAControlerResponseDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).getDonneesAControlerUsingGET(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de calculer les violations menant Ã  une impossibilitÃ© de notifier
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {getSourceImpossibiliteNotifierFoUsingGETQueryParams} queryParams objet contenant les query params de la route getSourceImpossibiliteNotifierFoUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourceImpossibiliteNotifierFoUsingGET(id: string, queryParams: GetSourceImpossibiliteNotifierFoUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SourcesImpossibiliteNotifierDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).getSourceImpossibiliteNotifierFoUsingGET(id, queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer les PDFs de notification manuelle.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualNotificationPdfUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).manualNotificationPdfUsingGET(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary CrÃ©ation d\'un dossier via une fiche descriptive pour un profil force de l\'ordre
         * @param {CreateDossierFromForceOrdreDto} createDossierFromForceOrdreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDossierWithInitialInfoUsingPOST(createDossierFromForceOrdreDto: CreateDossierFromForceOrdreDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).postDossierWithInitialInfoUsingPOST(createDossierFromForceOrdreDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route de rÃ©cupÃ©ration des coordonnÃ©es propriÃ©taire d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par son UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCoordonneesProprietaireUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CoordonneesProprietaireResponseDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).retrieveCoordonneesProprietaireUsingGET(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de rÃ©cupÃ©rer les informations d\'un utilisateur de profile FO.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFoUserInformationUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FoFullUserDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).retrieveFoUserInformationUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary RÃ©cupÃ©ration des donnÃ©es de la main levÃ©e d\'un dossier.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveMainleveeUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MainleveeResponseDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).retrieveMainleveeUsingGET(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour les donnÃ©es d\'une unitÃ© FO.
         * @param {string} idCorrelation Id de l\'unite FO, reprÃ©sentÃ© par un UUID.
         * @param {ProfilUniteFoRequestDto} profilUniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUniteFoInfosUsingPOST(idCorrelation: string, profilUniteFoRequestDto: ProfilUniteFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UniteFOResponseDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).setUniteFoInfosUsingPOST(idCorrelation, profilUniteFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Soumission du formulaire de notification manuelle
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {NotificationInfosRequestDto} notificationInfosRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitNotificationInfosUsingPOST(id: string, notificationInfosRequestDto: NotificationInfosRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).submitNotificationInfosUsingPOST(id, notificationInfosRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de modifier les donnÃ©es d\'un dossier, dans le cas oÃ¹ les donnÃ©es du vÃ©hicule ne correspondent pas aux donnÃ©es du siv.
         * @param {string} id 
         * @param {ControleDonneesVehiculeRequestDto} controleDonneesVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateControleDonneesVehiculeUsingPOST(id: string, controleDonneesVehiculeRequestDto: ControleDonneesVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).updateControleDonneesVehiculeUsingPOST(id, controleDonneesVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de modifier les coordonnees du propriÃ©taire pour les fo
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCoordonneesProprietairesFoUsingPOST(id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).updateCoordonneesProprietairesFoUsingPOST(id, coordonneesProprietaireRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de modifier les entitÃ©es de rattachement d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntitesRattachementFOUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).updateEntitesRattachementFOUsingPOST(id, updateEntitesRattachementRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Soumission du formulaire d\'expÃ©diteur de notification manuelle.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {ExpediteurNotificationRequestDto} expediteurNotificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExpediteurNotificationUsingPOST(id: string, expediteurNotificationRequestDto: ExpediteurNotificationRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).updateExpediteurNotificationUsingPOST(id, expediteurNotificationRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour la main levÃ©e
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {UpdateMainLeveeRequestDto} updateMainLeveeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMainLeveeUsingPOST(id: string, updateMainLeveeRequestDto: UpdateMainLeveeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).updateMainLeveeUsingPOST(id, updateMainLeveeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary API permettant d\'uploader une photo et les informations associÃ©es d\'un cÃ´tÃ© d\'un vÃ©hicule.
         * @param {'AVANT' | 'ARRIERE' | 'DROITE' | 'GAUCHE' | 'AUTRE'} coteVehicule cÃ´tÃ© du vÃ©hicule de la photo
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {any} file Fiche contenant la photo
         * @param {string} photoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPhotoVehiculeUsingPOST(coteVehicule: 'AVANT' | 'ARRIERE' | 'DROITE' | 'GAUCHE' | 'AUTRE', id: string, file: any, photoRequestDto: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).uploadPhotoVehiculeUsingPOST(coteVehicule, id, file, photoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider que les actions hors SIF ont Ã©tÃ© effectuÃ©es
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateActionsHorsSifUsingPOST(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateActionsHorsSifUsingPOST(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider la modification lors du contrÃ´le des donnÃ©es d\'un dossier
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {ControleDonneesVehiculeRequestDto} controleDonneesVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateControleDonneesVehiculeUsingPOST(id: string, controleDonneesVehiculeRequestDto: ControleDonneesVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierModificationViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateControleDonneesVehiculeUsingPOST(id, controleDonneesVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ du formulaire de mise Ã  jour des CoordonnÃ©ees propriÃ©taires.
         * @param {'PRENOM' | 'NOM' | 'RAISON_SOCIALE' | 'POINT_REMISE' | 'NUMERO' | 'EXTENSION' | 'TYPEVOIE' | 'LIBELLE_VOIE' | 'COMPLEMENT_ADRESSE' | 'LIEUDIT' | 'BOITE_POSTALE' | 'CODE_POSTAL' | 'LIBELLE_COMMUNE' | 'CODE_CEDEX' | 'LIBELLE_CEDEX' | 'PAYS'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCoordonneesProprietairesFieldFoUsingPOST(field: 'PRENOM' | 'NOM' | 'RAISON_SOCIALE' | 'POINT_REMISE' | 'NUMERO' | 'EXTENSION' | 'TYPEVOIE' | 'LIBELLE_VOIE' | 'COMPLEMENT_ADRESSE' | 'LIEUDIT' | 'BOITE_POSTALE' | 'CODE_POSTAL' | 'LIBELLE_COMMUNE' | 'CODE_CEDEX' | 'LIBELLE_CEDEX' | 'PAYS', id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateCoordonneesProprietairesFieldFoUsingPOST(field, id, coordonneesProprietaireRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider tous les champs du formulaire de mise Ã  jour des coordonnÃ©es propriÃ©taires pour les fo
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCoordonneesProprietairesFoUsingPOST(id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateCoordonneesProprietairesFoUsingPOST(id, coordonneesProprietaireRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation d\'un dossier pour une crÃ©ation FO via une fiche descriptive
         * @param {CreateDossierFromForceOrdreDto} createDossierFromForceOrdreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateDossierWithInitialInfoUsingPOST(createDossierFromForceOrdreDto: CreateDossierFromForceOrdreDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierCreationFoViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateDossierWithInitialInfoUsingPOST(createDossierFromForceOrdreDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation d\'un champ des informations de l\'expÃ©diteur de la notification manuelle pour un dossier existant.
         * @param {'UNITE' | 'ADRESSE' | 'CODE_POSTAL' | 'COMMUNE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {ExpediteurNotificationRequestDto} expediteurNotificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateExpediteurNotificationFieldUsingPOST(field: 'UNITE' | 'ADRESSE' | 'CODE_POSTAL' | 'COMMUNE', id: string, expediteurNotificationRequestDto: ExpediteurNotificationRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateExpediteurNotificationFieldUsingPOST(field, id, expediteurNotificationRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs des informations de l\'expÃ©diteur de la notification manuelle pour un dossier existant.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {ExpediteurNotificationRequestDto} expediteurNotificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateExpediteurNotificationUsingPOST(id: string, expediteurNotificationRequestDto: ExpediteurNotificationRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateExpediteurNotificationUsingPOST(id, expediteurNotificationRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation d\'un seul champ de la fiche conditions pour un dossier non crÃ©Ã©.
         * @param {'DATE_CONSTATATION' | 'MOTIVATION_MESURE' | 'ADRESSE' | 'COMMUNE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {FicheConditionsRequestDto} ficheConditionsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheConditionsFieldWithoutDossierUsingPOST(field: 'DATE_CONSTATATION' | 'MOTIVATION_MESURE' | 'ADRESSE' | 'COMMUNE', ficheConditionsRequestDto: FicheConditionsRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierCreationFoViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateFicheConditionsFieldWithoutDossierUsingPOST(field, ficheConditionsRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche conditions pour un dossier non crÃ©Ã©.
         * @param {FicheConditionsRequestDto} ficheConditionsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheConditionsWithoutDossierUsingPOST(ficheConditionsRequestDto: FicheConditionsRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierCreationFoViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateFicheConditionsWithoutDossierUsingPOST(ficheConditionsRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation d\'un seul champ de la fiche de dÃ©tails vÃ©hicule pour un dossier non crÃ©Ã©.
         * @param {'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {FicheDetailsVehiculeFoRequestDto} ficheDetailsVehiculeFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheDetailsVehiculeFieldWithoutDossierUsingPOST(field: 'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS', ficheDetailsVehiculeFoRequestDto: FicheDetailsVehiculeFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierCreationFoViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateFicheDetailsVehiculeFieldWithoutDossierUsingPOST(field, ficheDetailsVehiculeFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche de dÃ©tails vÃ©hicule pour un dossier non crÃ©Ã©.
         * @param {FicheDetailsVehiculeFoRequestDto} ficheDetailsVehiculeFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheDetailsVehiculeWithoutDossierUsingPOST(ficheDetailsVehiculeFoRequestDto: FicheDetailsVehiculeFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierCreationFoViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateFicheDetailsVehiculeWithoutDossierUsingPOST(ficheDetailsVehiculeFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation d\'un seul champ de la fiche Ã©tat gÃ©nÃ©ral pour un dossier non crÃ©Ã©.
         * @param {'ETAT_LIBELLE' | 'ETAT_PRECISION'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {FicheEtatGeneralFoRequestDto} ficheEtatGeneralFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheEtatGeneralFieldWithoutDossierUsingPOST(field: 'ETAT_LIBELLE' | 'ETAT_PRECISION', ficheEtatGeneralFoRequestDto: FicheEtatGeneralFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierCreationFoViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateFicheEtatGeneralFieldWithoutDossierUsingPOST(field, ficheEtatGeneralFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche etat gÃ©nÃ©ral pour un dossier non crÃ©Ã©.
         * @param {FicheEtatGeneralFoRequestDto} ficheEtatGeneralFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheEtatGeneralWithoutDossierUsingPOST(ficheEtatGeneralFoRequestDto: FicheEtatGeneralFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierCreationFoViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateFicheEtatGeneralWithoutDossierUsingPOST(ficheEtatGeneralFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation d\'un champ de la fiche fourriÃ¨re pour un dossier non crÃ©Ã©.
         * @param {'FOURRIERE' | 'AUTORITE_FOURRIERE' | 'AUTORITE_QUALIFIEE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {FicheFourriereFoRequestDto} ficheFourriereFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheFourriereFieldWithoutDossierUsingPOST1(field: 'FOURRIERE' | 'AUTORITE_FOURRIERE' | 'AUTORITE_QUALIFIEE', ficheFourriereFoRequestDto: FicheFourriereFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierCreationFoViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateFicheFourriereFieldWithoutDossierUsingPOST1(field, ficheFourriereFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche fourriÃ¨re pour un dossier non crÃ©Ã©.
         * @param {FicheFourriereFoRequestDto} ficheFourriereFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheFourriereWithoutDossierUsingPOST1(ficheFourriereFoRequestDto: FicheFourriereFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierCreationFoViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateFicheFourriereWithoutDossierUsingPOST1(ficheFourriereFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation d\'un seul champ de la fiche vÃ©hicule pour un dossier non crÃ©Ã©.
         * @param {'DESCRIPTION_GENRE' | 'DESCRIPTION_MARQUE' | 'DESCRIPTION_MODELE' | 'DESCRIPTION_COULEUR' | 'ETAT_LIBELLE' | 'ETAT_PRECISION' | 'IMMATRICULATION_SANS_PLAQUE' | 'IMMATRICULATION_PAYS_ETRANGER' | 'TYPE_PLAQUE' | 'IMMATRICULATION_NUMERO' | 'IMMATRICULATION_NUMERO_VISIBLE' | 'IMMATRICULATION_VIN' | 'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_AUTO_RADIO_VISIBLE' | 'ACCESSOIRES_POSTE_CB_VISIBLE' | 'ACCESSOIRES_TELEPHONE_PORTABLE' | 'ACCESSOIRES_NOMBRE_ANTENNES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {FicheVehiculeFoValidationDto} ficheVehiculeFoValidationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheVehiculeFieldWithoutDossierUsingPOST1(field: 'DESCRIPTION_GENRE' | 'DESCRIPTION_MARQUE' | 'DESCRIPTION_MODELE' | 'DESCRIPTION_COULEUR' | 'ETAT_LIBELLE' | 'ETAT_PRECISION' | 'IMMATRICULATION_SANS_PLAQUE' | 'IMMATRICULATION_PAYS_ETRANGER' | 'TYPE_PLAQUE' | 'IMMATRICULATION_NUMERO' | 'IMMATRICULATION_NUMERO_VISIBLE' | 'IMMATRICULATION_VIN' | 'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_AUTO_RADIO_VISIBLE' | 'ACCESSOIRES_POSTE_CB_VISIBLE' | 'ACCESSOIRES_TELEPHONE_PORTABLE' | 'ACCESSOIRES_NOMBRE_ANTENNES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS', ficheVehiculeFoValidationDto: FicheVehiculeFoValidationDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierCreationFoViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateFicheVehiculeFieldWithoutDossierUsingPOST1(field, ficheVehiculeFoValidationDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche vÃ©hicule pour un dossier non crÃ©Ã©.
         * @param {FicheVehiculeFoValidationDto} ficheVehiculeFoValidationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheVehiculeWithoutDossierUsingPOST1(ficheVehiculeFoValidationDto: FicheVehiculeFoValidationDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierCreationFoViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateFicheVehiculeWithoutDossierUsingPOST1(ficheVehiculeFoValidationDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider la modification d\'un champ lors du contrÃ´le des donnÃ©es d\'un dossier
         * @param {'NEW_MARQUE' | 'NEW_MODELE' | 'NEW_GENRE' | 'NEW_PAYS_ETRANGER' | 'NEW_SANS_PLAQUE' | 'NEW_PLAQUE' | 'NEW_PLAQUE_VISIBLE' | 'NEW_VIN'} field 
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {ControleDonneesVehiculeRequestDto} controleDonneesVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFieldControleDonneesVehiculeUsingPOST(field: 'NEW_MARQUE' | 'NEW_MODELE' | 'NEW_GENRE' | 'NEW_PAYS_ETRANGER' | 'NEW_SANS_PLAQUE' | 'NEW_PLAQUE' | 'NEW_PLAQUE_VISIBLE' | 'NEW_VIN', id: string, controleDonneesVehiculeRequestDto: ControleDonneesVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierModificationViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateFieldControleDonneesVehiculeUsingPOST(field, id, controleDonneesVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ des informations de notification manuelle pour un dossier existant.
         * @param {'DATE_ENVOI' | 'DATE_RETOUR' | 'ETAT_DISTRIBUTION'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {NotificationInfosRequestDto} notificationInfosRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateNotificationInfosFieldUsingPOST(field: 'DATE_ENVOI' | 'DATE_RETOUR' | 'ETAT_DISTRIBUTION', id: string, notificationInfosRequestDto: NotificationInfosRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateNotificationInfosFieldUsingPOST(field, id, notificationInfosRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs des informations d\'une notification manuelle pour un dossier existant.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {NotificationInfosRequestDto} notificationInfosRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateNotificationInfosUsingPOST(id: string, notificationInfosRequestDto: NotificationInfosRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateNotificationInfosUsingPOST(id, notificationInfosRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ du formulaire de mise Ã  jour du profile FO.
         * @param {'ADRESSE' | 'CODE_POSTAL' | 'VILLE' | 'EMAIL' | 'TELEPHONE'} field IntitulÃ© du champ Ã  valider
         * @param {string} idCorrelation Id de l\'unitÃ©, reprÃ©sentÃ© par un UUID.
         * @param {ProfilUniteFoRequestDto} profilUniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUniteFoInfosFieldUsingPOST(field: 'ADRESSE' | 'CODE_POSTAL' | 'VILLE' | 'EMAIL' | 'TELEPHONE', idCorrelation: string, profilUniteFoRequestDto: ProfilUniteFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProfilUniteFoViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateUniteFoInfosFieldUsingPOST(field, idCorrelation, profilUniteFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider tous les champs du formulaire de mise Ã  jour du profile FO.
         * @param {string} idCorrelation Id de l\'unitÃ©, reprÃ©sentÃ© par un UUID.
         * @param {ProfilUniteFoRequestDto} profilUniteFoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUniteFoInfosUsingPOST(idCorrelation: string, profilUniteFoRequestDto: ProfilUniteFoRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProfilUniteFoViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateUniteFoInfosUsingPOST(idCorrelation, profilUniteFoRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider l\'ensemble des champs du formulaire de modifications des entitÃ©s de rattachement d\'un dossier.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateEntitesRattachementFOUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierModificationViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateUpdateEntitesRattachementFOUsingPOST(id, updateEntitesRattachementRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un des champs du formulaire de modifications des entitÃ©s de rattachement d\'un dossier.
         * @param {'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT'} field 
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateEntitesRattachementFieldFOUsingPOST(field: 'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT', id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierModificationViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateUpdateEntitesRattachementFieldFOUsingPOST(field, id, updateEntitesRattachementRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ du formulaire de mise Ã  jour de la main levÃ©e.
         * @param {'NUM_PROCES_VERBAL' | 'DATE_PROCES_VERBAL' | 'GRADE_AGENT' | 'MATRICULE_AGENT' | 'COMMUNE_FONCTION' | 'TYPE_RESTITUTION' | 'GENRE_SIMPLIFIE_ID' | 'IMMATRICULATION' | 'MARQUE_ID' | 'NOM_PROPRIETAIRE' | 'LIEU_ADRESSE_PROPRIETAIRE' | 'CODE_POSTAL_PROPRIETAIRE' | 'COMMUNE_ADRESSE_PROPRIETAIRE' | 'FOURRIERE_ID' | 'DEBUT_AUTORISATION' | 'FIN_AUTORISATION' | 'COMMUNE_REDACTION' | 'JUSTIFICATION_SORTIE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {UpdateMainLeveeRequestDto} updateMainLeveeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateMainLeveeFieldUsingPOST(field: 'NUM_PROCES_VERBAL' | 'DATE_PROCES_VERBAL' | 'GRADE_AGENT' | 'MATRICULE_AGENT' | 'COMMUNE_FONCTION' | 'TYPE_RESTITUTION' | 'GENRE_SIMPLIFIE_ID' | 'IMMATRICULATION' | 'MARQUE_ID' | 'NOM_PROPRIETAIRE' | 'LIEU_ADRESSE_PROPRIETAIRE' | 'CODE_POSTAL_PROPRIETAIRE' | 'COMMUNE_ADRESSE_PROPRIETAIRE' | 'FOURRIERE_ID' | 'DEBUT_AUTORISATION' | 'FIN_AUTORISATION' | 'COMMUNE_REDACTION' | 'JUSTIFICATION_SORTIE', id: string, updateMainLeveeRequestDto: UpdateMainLeveeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateUpdateMainLeveeFieldUsingPOST(field, id, updateMainLeveeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider tous les champs du formulaire de mise Ã  jour de la main levÃ©e.
         * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
         * @param {UpdateMainLeveeRequestDto} updateMainLeveeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUpdateMainLeveeUsingPOST(id: string, updateMainLeveeRequestDto: UpdateMainLeveeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = ForceOrdreControllerApiFetchParamCreator(configuration).validateUpdateMainLeveeUsingPOST(id, updateMainLeveeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ForceOrdreControllerApi - object-oriented interface
 * @export
 * @class ForceOrdreControllerApi
 * @extends {BaseAPI}
 */
export class ForceOrdreControllerApi extends BaseAPI {
    /**
     * @summary   Route effectuant un contrÃ´le des donnÃ©es du vÃ©hicule   relanÃ§ant la vente si aucune modification n\'est constatÃ©e   sinon en relanÃ§ant l\'identification au SIV 
     * @param {string} id ID du dossier
     * @param {ControleDonneesVehiculeBlocageVenteRequestDto} controleDonneesVehiculeBlocageVenteRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public computeControleDonneesVehiculeBlocageVenteUsingPOST(id: string, controleDonneesVehiculeBlocageVenteRequestDto: ControleDonneesVehiculeBlocageVenteRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).computeControleDonneesVehiculeBlocageVenteUsingPOST(id, controleDonneesVehiculeBlocageVenteRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de confirmer le vol d\'un vÃ©hicule identifiÃ© vvnr
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public confirmVolVvnrUsingPOST(id: string, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).confirmVolVvnrUsingPOST(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary RÃ©cupÃ©ration des donnÃ©es Ã  contrÃ´ler, dans le cas oÃ¹ les donnÃ©es du vÃ©hicule ne correspondent pas aux donnÃ©es du siv.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getDonneesAControlerUsingGET(id: string, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).getDonneesAControlerUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de calculer les violations menant Ã  une impossibilitÃ© de notifier
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {getSourceImpossibiliteNotifierFoUsingGETQueryParams} queryParams objet contenant les query params de la route getSourceImpossibiliteNotifierFoUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getSourceImpossibiliteNotifierFoUsingGET(id: string, queryParams: GetSourceImpossibiliteNotifierFoUsingGETQueryParams, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).getSourceImpossibiliteNotifierFoUsingGET(id, queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de rÃ©cupÃ©rer les PDFs de notification manuelle.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public manualNotificationPdfUsingGET(id: string, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).manualNotificationPdfUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary CrÃ©ation d\'un dossier via une fiche descriptive pour un profil force de l\'ordre
     * @param {CreateDossierFromForceOrdreDto} createDossierFromForceOrdreDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public postDossierWithInitialInfoUsingPOST(createDossierFromForceOrdreDto: CreateDossierFromForceOrdreDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).postDossierWithInitialInfoUsingPOST(createDossierFromForceOrdreDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route de rÃ©cupÃ©ration des coordonnÃ©es propriÃ©taire d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par son UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public retrieveCoordonneesProprietaireUsingGET(id: string, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).retrieveCoordonneesProprietaireUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de rÃ©cupÃ©rer les informations d\'un utilisateur de profile FO.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public retrieveFoUserInformationUsingGET(options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).retrieveFoUserInformationUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary RÃ©cupÃ©ration des donnÃ©es de la main levÃ©e d\'un dossier.
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public retrieveMainleveeUsingGET(id: string, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).retrieveMainleveeUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de mettre Ã  jour les donnÃ©es d\'une unitÃ© FO.
     * @param {string} idCorrelation Id de l\'unite FO, reprÃ©sentÃ© par un UUID.
     * @param {ProfilUniteFoRequestDto} profilUniteFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public setUniteFoInfosUsingPOST(idCorrelation: string, profilUniteFoRequestDto: ProfilUniteFoRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).setUniteFoInfosUsingPOST(idCorrelation, profilUniteFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Soumission du formulaire de notification manuelle
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {NotificationInfosRequestDto} notificationInfosRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public submitNotificationInfosUsingPOST(id: string, notificationInfosRequestDto: NotificationInfosRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).submitNotificationInfosUsingPOST(id, notificationInfosRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de modifier les donnÃ©es d\'un dossier, dans le cas oÃ¹ les donnÃ©es du vÃ©hicule ne correspondent pas aux donnÃ©es du siv.
     * @param {string} id 
     * @param {ControleDonneesVehiculeRequestDto} controleDonneesVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateControleDonneesVehiculeUsingPOST(id: string, controleDonneesVehiculeRequestDto: ControleDonneesVehiculeRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).updateControleDonneesVehiculeUsingPOST(id, controleDonneesVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de modifier les coordonnees du propriÃ©taire pour les fo
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateCoordonneesProprietairesFoUsingPOST(id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).updateCoordonneesProprietairesFoUsingPOST(id, coordonneesProprietaireRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de modifier les entitÃ©es de rattachement d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateEntitesRattachementFOUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).updateEntitesRattachementFOUsingPOST(id, updateEntitesRattachementRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Soumission du formulaire d\'expÃ©diteur de notification manuelle.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {ExpediteurNotificationRequestDto} expediteurNotificationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateExpediteurNotificationUsingPOST(id: string, expediteurNotificationRequestDto: ExpediteurNotificationRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).updateExpediteurNotificationUsingPOST(id, expediteurNotificationRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de mettre Ã  jour la main levÃ©e
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {UpdateMainLeveeRequestDto} updateMainLeveeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateMainLeveeUsingPOST(id: string, updateMainLeveeRequestDto: UpdateMainLeveeRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).updateMainLeveeUsingPOST(id, updateMainLeveeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary API permettant d\'uploader une photo et les informations associÃ©es d\'un cÃ´tÃ© d\'un vÃ©hicule.
     * @param {'AVANT' | 'ARRIERE' | 'DROITE' | 'GAUCHE' | 'AUTRE'} coteVehicule cÃ´tÃ© du vÃ©hicule de la photo
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {any} file Fiche contenant la photo
     * @param {string} photoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public uploadPhotoVehiculeUsingPOST(coteVehicule: 'AVANT' | 'ARRIERE' | 'DROITE' | 'GAUCHE' | 'AUTRE', id: string, file: any, photoRequestDto: string, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).uploadPhotoVehiculeUsingPOST(coteVehicule, id, file, photoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider que les actions hors SIF ont Ã©tÃ© effectuÃ©es
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateActionsHorsSifUsingPOST(id: string, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateActionsHorsSifUsingPOST(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider la modification lors du contrÃ´le des donnÃ©es d\'un dossier
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {ControleDonneesVehiculeRequestDto} controleDonneesVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateControleDonneesVehiculeUsingPOST(id: string, controleDonneesVehiculeRequestDto: ControleDonneesVehiculeRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateControleDonneesVehiculeUsingPOST(id, controleDonneesVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ du formulaire de mise Ã  jour des CoordonnÃ©ees propriÃ©taires.
     * @param {'PRENOM' | 'NOM' | 'RAISON_SOCIALE' | 'POINT_REMISE' | 'NUMERO' | 'EXTENSION' | 'TYPEVOIE' | 'LIBELLE_VOIE' | 'COMPLEMENT_ADRESSE' | 'LIEUDIT' | 'BOITE_POSTALE' | 'CODE_POSTAL' | 'LIBELLE_COMMUNE' | 'CODE_CEDEX' | 'LIBELLE_CEDEX' | 'PAYS'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCoordonneesProprietairesFieldFoUsingPOST(field: 'PRENOM' | 'NOM' | 'RAISON_SOCIALE' | 'POINT_REMISE' | 'NUMERO' | 'EXTENSION' | 'TYPEVOIE' | 'LIBELLE_VOIE' | 'COMPLEMENT_ADRESSE' | 'LIEUDIT' | 'BOITE_POSTALE' | 'CODE_POSTAL' | 'LIBELLE_COMMUNE' | 'CODE_CEDEX' | 'LIBELLE_CEDEX' | 'PAYS', id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateCoordonneesProprietairesFieldFoUsingPOST(field, id, coordonneesProprietaireRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider tous les champs du formulaire de mise Ã  jour des coordonnÃ©es propriÃ©taires pour les fo
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {CoordonneesProprietaireRequestDto} coordonneesProprietaireRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateCoordonneesProprietairesFoUsingPOST(id: string, coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateCoordonneesProprietairesFoUsingPOST(id, coordonneesProprietaireRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation d\'un dossier pour une crÃ©ation FO via une fiche descriptive
     * @param {CreateDossierFromForceOrdreDto} createDossierFromForceOrdreDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateDossierWithInitialInfoUsingPOST(createDossierFromForceOrdreDto: CreateDossierFromForceOrdreDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateDossierWithInitialInfoUsingPOST(createDossierFromForceOrdreDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation d\'un champ des informations de l\'expÃ©diteur de la notification manuelle pour un dossier existant.
     * @param {'UNITE' | 'ADRESSE' | 'CODE_POSTAL' | 'COMMUNE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {ExpediteurNotificationRequestDto} expediteurNotificationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateExpediteurNotificationFieldUsingPOST(field: 'UNITE' | 'ADRESSE' | 'CODE_POSTAL' | 'COMMUNE', id: string, expediteurNotificationRequestDto: ExpediteurNotificationRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateExpediteurNotificationFieldUsingPOST(field, id, expediteurNotificationRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation de l\'ensemble des champs des informations de l\'expÃ©diteur de la notification manuelle pour un dossier existant.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {ExpediteurNotificationRequestDto} expediteurNotificationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateExpediteurNotificationUsingPOST(id: string, expediteurNotificationRequestDto: ExpediteurNotificationRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateExpediteurNotificationUsingPOST(id, expediteurNotificationRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation d\'un seul champ de la fiche conditions pour un dossier non crÃ©Ã©.
     * @param {'DATE_CONSTATATION' | 'MOTIVATION_MESURE' | 'ADRESSE' | 'COMMUNE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {FicheConditionsRequestDto} ficheConditionsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFicheConditionsFieldWithoutDossierUsingPOST(field: 'DATE_CONSTATATION' | 'MOTIVATION_MESURE' | 'ADRESSE' | 'COMMUNE', ficheConditionsRequestDto: FicheConditionsRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateFicheConditionsFieldWithoutDossierUsingPOST(field, ficheConditionsRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation de l\'ensemble des champs de la fiche conditions pour un dossier non crÃ©Ã©.
     * @param {FicheConditionsRequestDto} ficheConditionsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFicheConditionsWithoutDossierUsingPOST(ficheConditionsRequestDto: FicheConditionsRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateFicheConditionsWithoutDossierUsingPOST(ficheConditionsRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation d\'un seul champ de la fiche de dÃ©tails vÃ©hicule pour un dossier non crÃ©Ã©.
     * @param {'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {FicheDetailsVehiculeFoRequestDto} ficheDetailsVehiculeFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFicheDetailsVehiculeFieldWithoutDossierUsingPOST(field: 'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS', ficheDetailsVehiculeFoRequestDto: FicheDetailsVehiculeFoRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateFicheDetailsVehiculeFieldWithoutDossierUsingPOST(field, ficheDetailsVehiculeFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation de l\'ensemble des champs de la fiche de dÃ©tails vÃ©hicule pour un dossier non crÃ©Ã©.
     * @param {FicheDetailsVehiculeFoRequestDto} ficheDetailsVehiculeFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFicheDetailsVehiculeWithoutDossierUsingPOST(ficheDetailsVehiculeFoRequestDto: FicheDetailsVehiculeFoRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateFicheDetailsVehiculeWithoutDossierUsingPOST(ficheDetailsVehiculeFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation d\'un seul champ de la fiche Ã©tat gÃ©nÃ©ral pour un dossier non crÃ©Ã©.
     * @param {'ETAT_LIBELLE' | 'ETAT_PRECISION'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {FicheEtatGeneralFoRequestDto} ficheEtatGeneralFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFicheEtatGeneralFieldWithoutDossierUsingPOST(field: 'ETAT_LIBELLE' | 'ETAT_PRECISION', ficheEtatGeneralFoRequestDto: FicheEtatGeneralFoRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateFicheEtatGeneralFieldWithoutDossierUsingPOST(field, ficheEtatGeneralFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation de l\'ensemble des champs de la fiche etat gÃ©nÃ©ral pour un dossier non crÃ©Ã©.
     * @param {FicheEtatGeneralFoRequestDto} ficheEtatGeneralFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFicheEtatGeneralWithoutDossierUsingPOST(ficheEtatGeneralFoRequestDto: FicheEtatGeneralFoRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateFicheEtatGeneralWithoutDossierUsingPOST(ficheEtatGeneralFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation d\'un champ de la fiche fourriÃ¨re pour un dossier non crÃ©Ã©.
     * @param {'FOURRIERE' | 'AUTORITE_FOURRIERE' | 'AUTORITE_QUALIFIEE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {FicheFourriereFoRequestDto} ficheFourriereFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFicheFourriereFieldWithoutDossierUsingPOST1(field: 'FOURRIERE' | 'AUTORITE_FOURRIERE' | 'AUTORITE_QUALIFIEE', ficheFourriereFoRequestDto: FicheFourriereFoRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateFicheFourriereFieldWithoutDossierUsingPOST1(field, ficheFourriereFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation de l\'ensemble des champs de la fiche fourriÃ¨re pour un dossier non crÃ©Ã©.
     * @param {FicheFourriereFoRequestDto} ficheFourriereFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFicheFourriereWithoutDossierUsingPOST1(ficheFourriereFoRequestDto: FicheFourriereFoRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateFicheFourriereWithoutDossierUsingPOST1(ficheFourriereFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation d\'un seul champ de la fiche vÃ©hicule pour un dossier non crÃ©Ã©.
     * @param {'DESCRIPTION_GENRE' | 'DESCRIPTION_MARQUE' | 'DESCRIPTION_MODELE' | 'DESCRIPTION_COULEUR' | 'ETAT_LIBELLE' | 'ETAT_PRECISION' | 'IMMATRICULATION_SANS_PLAQUE' | 'IMMATRICULATION_PAYS_ETRANGER' | 'TYPE_PLAQUE' | 'IMMATRICULATION_NUMERO' | 'IMMATRICULATION_NUMERO_VISIBLE' | 'IMMATRICULATION_VIN' | 'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_AUTO_RADIO_VISIBLE' | 'ACCESSOIRES_POSTE_CB_VISIBLE' | 'ACCESSOIRES_TELEPHONE_PORTABLE' | 'ACCESSOIRES_NOMBRE_ANTENNES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {FicheVehiculeFoValidationDto} ficheVehiculeFoValidationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFicheVehiculeFieldWithoutDossierUsingPOST1(field: 'DESCRIPTION_GENRE' | 'DESCRIPTION_MARQUE' | 'DESCRIPTION_MODELE' | 'DESCRIPTION_COULEUR' | 'ETAT_LIBELLE' | 'ETAT_PRECISION' | 'IMMATRICULATION_SANS_PLAQUE' | 'IMMATRICULATION_PAYS_ETRANGER' | 'TYPE_PLAQUE' | 'IMMATRICULATION_NUMERO' | 'IMMATRICULATION_NUMERO_VISIBLE' | 'IMMATRICULATION_VIN' | 'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_AUTO_RADIO_VISIBLE' | 'ACCESSOIRES_POSTE_CB_VISIBLE' | 'ACCESSOIRES_TELEPHONE_PORTABLE' | 'ACCESSOIRES_NOMBRE_ANTENNES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS', ficheVehiculeFoValidationDto: FicheVehiculeFoValidationDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateFicheVehiculeFieldWithoutDossierUsingPOST1(field, ficheVehiculeFoValidationDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation de l\'ensemble des champs de la fiche vÃ©hicule pour un dossier non crÃ©Ã©.
     * @param {FicheVehiculeFoValidationDto} ficheVehiculeFoValidationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFicheVehiculeWithoutDossierUsingPOST1(ficheVehiculeFoValidationDto: FicheVehiculeFoValidationDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateFicheVehiculeWithoutDossierUsingPOST1(ficheVehiculeFoValidationDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider la modification d\'un champ lors du contrÃ´le des donnÃ©es d\'un dossier
     * @param {'NEW_MARQUE' | 'NEW_MODELE' | 'NEW_GENRE' | 'NEW_PAYS_ETRANGER' | 'NEW_SANS_PLAQUE' | 'NEW_PLAQUE' | 'NEW_PLAQUE_VISIBLE' | 'NEW_VIN'} field 
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {ControleDonneesVehiculeRequestDto} controleDonneesVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFieldControleDonneesVehiculeUsingPOST(field: 'NEW_MARQUE' | 'NEW_MODELE' | 'NEW_GENRE' | 'NEW_PAYS_ETRANGER' | 'NEW_SANS_PLAQUE' | 'NEW_PLAQUE' | 'NEW_PLAQUE_VISIBLE' | 'NEW_VIN', id: string, controleDonneesVehiculeRequestDto: ControleDonneesVehiculeRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateFieldControleDonneesVehiculeUsingPOST(field, id, controleDonneesVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ des informations de notification manuelle pour un dossier existant.
     * @param {'DATE_ENVOI' | 'DATE_RETOUR' | 'ETAT_DISTRIBUTION'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {NotificationInfosRequestDto} notificationInfosRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateNotificationInfosFieldUsingPOST(field: 'DATE_ENVOI' | 'DATE_RETOUR' | 'ETAT_DISTRIBUTION', id: string, notificationInfosRequestDto: NotificationInfosRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateNotificationInfosFieldUsingPOST(field, id, notificationInfosRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation de l\'ensemble des champs des informations d\'une notification manuelle pour un dossier existant.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {NotificationInfosRequestDto} notificationInfosRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateNotificationInfosUsingPOST(id: string, notificationInfosRequestDto: NotificationInfosRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateNotificationInfosUsingPOST(id, notificationInfosRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ du formulaire de mise Ã  jour du profile FO.
     * @param {'ADRESSE' | 'CODE_POSTAL' | 'VILLE' | 'EMAIL' | 'TELEPHONE'} field IntitulÃ© du champ Ã  valider
     * @param {string} idCorrelation Id de l\'unitÃ©, reprÃ©sentÃ© par un UUID.
     * @param {ProfilUniteFoRequestDto} profilUniteFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUniteFoInfosFieldUsingPOST(field: 'ADRESSE' | 'CODE_POSTAL' | 'VILLE' | 'EMAIL' | 'TELEPHONE', idCorrelation: string, profilUniteFoRequestDto: ProfilUniteFoRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateUniteFoInfosFieldUsingPOST(field, idCorrelation, profilUniteFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider tous les champs du formulaire de mise Ã  jour du profile FO.
     * @param {string} idCorrelation Id de l\'unitÃ©, reprÃ©sentÃ© par un UUID.
     * @param {ProfilUniteFoRequestDto} profilUniteFoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUniteFoInfosUsingPOST(idCorrelation: string, profilUniteFoRequestDto: ProfilUniteFoRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateUniteFoInfosUsingPOST(idCorrelation, profilUniteFoRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider l\'ensemble des champs du formulaire de modifications des entitÃ©s de rattachement d\'un dossier.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateEntitesRattachementFOUsingPOST(id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateUpdateEntitesRattachementFOUsingPOST(id, updateEntitesRattachementRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un des champs du formulaire de modifications des entitÃ©s de rattachement d\'un dossier.
     * @param {'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT'} field 
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {UpdateEntitesRattachementRequestDto} updateEntitesRattachementRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateEntitesRattachementFieldFOUsingPOST(field: 'NEW_FOURRIERE_CORRELATION_ID' | 'NEW_AUTORITE_FOURRIERE_CORRELATION_ID' | 'NEW_UNITE_FO_CORRELATION_ID' | 'COMMENT', id: string, updateEntitesRattachementRequestDto: UpdateEntitesRattachementRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateUpdateEntitesRattachementFieldFOUsingPOST(field, id, updateEntitesRattachementRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ du formulaire de mise Ã  jour de la main levÃ©e.
     * @param {'NUM_PROCES_VERBAL' | 'DATE_PROCES_VERBAL' | 'GRADE_AGENT' | 'MATRICULE_AGENT' | 'COMMUNE_FONCTION' | 'TYPE_RESTITUTION' | 'GENRE_SIMPLIFIE_ID' | 'IMMATRICULATION' | 'MARQUE_ID' | 'NOM_PROPRIETAIRE' | 'LIEU_ADRESSE_PROPRIETAIRE' | 'CODE_POSTAL_PROPRIETAIRE' | 'COMMUNE_ADRESSE_PROPRIETAIRE' | 'FOURRIERE_ID' | 'DEBUT_AUTORISATION' | 'FIN_AUTORISATION' | 'COMMUNE_REDACTION' | 'JUSTIFICATION_SORTIE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {UpdateMainLeveeRequestDto} updateMainLeveeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateMainLeveeFieldUsingPOST(field: 'NUM_PROCES_VERBAL' | 'DATE_PROCES_VERBAL' | 'GRADE_AGENT' | 'MATRICULE_AGENT' | 'COMMUNE_FONCTION' | 'TYPE_RESTITUTION' | 'GENRE_SIMPLIFIE_ID' | 'IMMATRICULATION' | 'MARQUE_ID' | 'NOM_PROPRIETAIRE' | 'LIEU_ADRESSE_PROPRIETAIRE' | 'CODE_POSTAL_PROPRIETAIRE' | 'COMMUNE_ADRESSE_PROPRIETAIRE' | 'FOURRIERE_ID' | 'DEBUT_AUTORISATION' | 'FIN_AUTORISATION' | 'COMMUNE_REDACTION' | 'JUSTIFICATION_SORTIE', id: string, updateMainLeveeRequestDto: UpdateMainLeveeRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateUpdateMainLeveeFieldUsingPOST(field, id, updateMainLeveeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider tous les champs du formulaire de mise Ã  jour de la main levÃ©e.
     * @param {string} id ID du dossier, reprÃ©sentÃ© par un UUID
     * @param {UpdateMainLeveeRequestDto} updateMainLeveeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateUpdateMainLeveeUsingPOST(id: string, updateMainLeveeRequestDto: UpdateMainLeveeRequestDto, options?: any) {
        return ForceOrdreControllerApiFp(this.configuration).validateUpdateMainLeveeUsingPOST(id, updateMainLeveeRequestDto, options)(this.fetch, this.basePath);
    }

}

//////////////////////////////////// GardienFourriereControllerApi /////////////////////////////////////////////////////

/**
 * GardienFourriereControllerApi - fetch parameter creator
 * @export
 */
export const GardienFourriereControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant de confirmer les informations de son compte Keycloak
         * @param {string} username Nom d\'utilisateur du compte Keycloak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAccountUsingPUT(username: string, options: any = {}): FetchArgs {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username','Required parameter username was null or undefined when calling confirmAccountUsingPUT.');
            }
            const localVarPath = `/api/comptes-utilisateurs/{username}/confirm`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de confirmer la validitÃ© des informations d\'une fourriÃ¨re
         * @param {string} id Id de la fourriÃ¨re, et non son id de corrÃ©lation, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmFourriereUsingPUT(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling confirmFourriereUsingPUT.');
            }
            const localVarPath = `/api/gardien-fourriere/profil/fourriere/{id}/confirm`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary CrÃ©ation d\'un dossier via une fiche descriptive
         * @param {CreateDossierFromGardienFourriereDto} createDossierFromGardienFourriereDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDossierWithInitialInfoUsingPOST(createDossierFromGardienFourriereDto: CreateDossierFromGardienFourriereDto, options: any = {}): FetchArgs {
            // verify required parameter 'createDossierFromGardienFourriereDto' is not null or undefined
            if (createDossierFromGardienFourriereDto === null || createDossierFromGardienFourriereDto === undefined) {
                throw new RequiredError('createDossierFromGardienFourriereDto','Required parameter createDossierFromGardienFourriereDto was null or undefined when calling createDossierWithInitialInfoUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/dossier`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateDossierFromGardienFourriereDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(createDossierFromGardienFourriereDto || {}) : (createDossierFromGardienFourriereDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de renseigner l\'entrÃ©e d\'un vÃ©hicule.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {EntreeVehiculeRequestDto} entreeVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entreeVehiculeWithFormulaireEntreeUsingPOST(id: string, entreeVehiculeRequestDto: EntreeVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling entreeVehiculeWithFormulaireEntreeUsingPOST.');
            }
            // verify required parameter 'entreeVehiculeRequestDto' is not null or undefined
            if (entreeVehiculeRequestDto === null || entreeVehiculeRequestDto === undefined) {
                throw new RequiredError('entreeVehiculeRequestDto','Required parameter entreeVehiculeRequestDto was null or undefined when calling entreeVehiculeWithFormulaireEntreeUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/dossier/{id}/entree`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EntreeVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(entreeVehiculeRequestDto || {}) : (entreeVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary RÃ©cupÃ©ration des donnÃ©es d\'une fourriÃ¨re.
         * @param {string} idCorrelation Id de la fourriÃ¨re, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFourriereInfoUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling getFourriereInfoUsingGET.');
            }
            const localVarPath = `/api/gardien-fourriere/profil/fourriere/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Mise Ã  jour des donnÃ©es d\'une fourriÃ¨re.
         * @param {string} idCorrelation Id de la fourriÃ¨re, reprÃ©sentÃ© par un UUID.
         * @param {ProfilFourriereRequestDto} profilFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFourriereInfoUsingPOST(idCorrelation: string, profilFourriereRequestDto: ProfilFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling setFourriereInfoUsingPOST.');
            }
            // verify required parameter 'profilFourriereRequestDto' is not null or undefined
            if (profilFourriereRequestDto === null || profilFourriereRequestDto === undefined) {
                throw new RequiredError('profilFourriereRequestDto','Required parameter profilFourriereRequestDto was null or undefined when calling setFourriereInfoUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/profil/fourriere/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProfilFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(profilFourriereRequestDto || {}) : (profilFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour la fiche sortie d\'un vÃ©hicule.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {SortieVehiculeRequestDto} sortieVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortieVehiculeWithFormulaireSortieUsingPOST(id: string, sortieVehiculeRequestDto: SortieVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling sortieVehiculeWithFormulaireSortieUsingPOST.');
            }
            // verify required parameter 'sortieVehiculeRequestDto' is not null or undefined
            if (sortieVehiculeRequestDto === null || sortieVehiculeRequestDto === undefined) {
                throw new RequiredError('sortieVehiculeRequestDto','Required parameter sortieVehiculeRequestDto was null or undefined when calling sortieVehiculeWithFormulaireSortieUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/dossier/{id}/sortie`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SortieVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(sortieVehiculeRequestDto || {}) : (sortieVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Mise Ã  jour des donnÃ©es d\'un bon d\'enlÃ¨vement gardien.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {BonEnlevementGardienRequestDto} bonEnlevementGardienRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBonEnlevementGardienUsingPOST(id: string, bonEnlevementGardienRequestDto: BonEnlevementGardienRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateBonEnlevementGardienUsingPOST.');
            }
            // verify required parameter 'bonEnlevementGardienRequestDto' is not null or undefined
            if (bonEnlevementGardienRequestDto === null || bonEnlevementGardienRequestDto === undefined) {
                throw new RequiredError('bonEnlevementGardienRequestDto','Required parameter bonEnlevementGardienRequestDto was null or undefined when calling updateBonEnlevementGardienUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/dossier/{id}/bon-enlevement-gardien`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BonEnlevementGardienRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(bonEnlevementGardienRequestDto || {}) : (bonEnlevementGardienRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ spÃ©cifique d\'un bon d\'enlÃ¨vement gardien.
         * @param {'ID_CORRELATION_CENTRE_VHU' | 'FLAG_CARTE_GRISE_RETIREE' | 'MOTIF_ABSENCE_IMMATRICULATION' | 'RAISON_SOCIALE' | 'NUMERO_SIREN' | 'NUMERO_VOIE' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'NUMERO_AGREMENT' | 'PREFECTURE_AGREMENT'} field IntitulÃ© du champ Ã  vÃ©rifier.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {BonEnlevementGardienRequestDto} bonEnlevementGardienRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateBonEnlevementGardienFieldUsingPOST(field: 'ID_CORRELATION_CENTRE_VHU' | 'FLAG_CARTE_GRISE_RETIREE' | 'MOTIF_ABSENCE_IMMATRICULATION' | 'RAISON_SOCIALE' | 'NUMERO_SIREN' | 'NUMERO_VOIE' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'NUMERO_AGREMENT' | 'PREFECTURE_AGREMENT', id: string, bonEnlevementGardienRequestDto: BonEnlevementGardienRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateBonEnlevementGardienFieldUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateBonEnlevementGardienFieldUsingPOST.');
            }
            // verify required parameter 'bonEnlevementGardienRequestDto' is not null or undefined
            if (bonEnlevementGardienRequestDto === null || bonEnlevementGardienRequestDto === undefined) {
                throw new RequiredError('bonEnlevementGardienRequestDto','Required parameter bonEnlevementGardienRequestDto was null or undefined when calling validateBonEnlevementGardienFieldUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/dossier/{id}/bon-enlevement-gardien/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BonEnlevementGardienRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(bonEnlevementGardienRequestDto || {}) : (bonEnlevementGardienRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation des donnÃ©es d\'un bon d\'enlÃ¨vement gardien.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {BonEnlevementGardienRequestDto} bonEnlevementGardienRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateBonEnlevementGardienUsingPOST(id: string, bonEnlevementGardienRequestDto: BonEnlevementGardienRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateBonEnlevementGardienUsingPOST.');
            }
            // verify required parameter 'bonEnlevementGardienRequestDto' is not null or undefined
            if (bonEnlevementGardienRequestDto === null || bonEnlevementGardienRequestDto === undefined) {
                throw new RequiredError('bonEnlevementGardienRequestDto','Required parameter bonEnlevementGardienRequestDto was null or undefined when calling validateBonEnlevementGardienUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/dossier/{id}/bon-enlevement-gardien/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BonEnlevementGardienRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(bonEnlevementGardienRequestDto || {}) : (bonEnlevementGardienRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ de la fiche entrÃ©e pour un dossier existant.
         * @param {'ID_CORRELATION_AUTORITE_FOURRIERE' | 'DATE_ENLEVEMENT' | 'DATE_ENTREE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {EntreeVehiculeRequestDto} entreeVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEntreeVehiculeFormFieldUsingPOST(field: 'ID_CORRELATION_AUTORITE_FOURRIERE' | 'DATE_ENLEVEMENT' | 'DATE_ENTREE', id: string, entreeVehiculeRequestDto: EntreeVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateEntreeVehiculeFormFieldUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateEntreeVehiculeFormFieldUsingPOST.');
            }
            // verify required parameter 'entreeVehiculeRequestDto' is not null or undefined
            if (entreeVehiculeRequestDto === null || entreeVehiculeRequestDto === undefined) {
                throw new RequiredError('entreeVehiculeRequestDto','Required parameter entreeVehiculeRequestDto was null or undefined when calling validateEntreeVehiculeFormFieldUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/dossier/{id}/entree/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EntreeVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(entreeVehiculeRequestDto || {}) : (entreeVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche entrÃ©e pour un dossier existant.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {EntreeVehiculeRequestDto} entreeVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEntreeVehiculeFormUsingPOST(id: string, entreeVehiculeRequestDto: EntreeVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateEntreeVehiculeFormUsingPOST.');
            }
            // verify required parameter 'entreeVehiculeRequestDto' is not null or undefined
            if (entreeVehiculeRequestDto === null || entreeVehiculeRequestDto === undefined) {
                throw new RequiredError('entreeVehiculeRequestDto','Required parameter entreeVehiculeRequestDto was null or undefined when calling validateEntreeVehiculeFormUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/dossier/{id}/entree/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EntreeVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(entreeVehiculeRequestDto || {}) : (entreeVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation d\'un champ de la fiche fourriÃ¨re pour un dossier non crÃ©Ã©.
         * @param {'ENTREE' | 'ENLEVEMENT' | 'FOURRIERE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {FichesInfractionAndFourriereRequestsWrapperDto} fichesInfractionAndFourriereRequestsWrapperDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheFourriereFieldWithoutDossierUsingPOST(field: 'ENTREE' | 'ENLEVEMENT' | 'FOURRIERE', fichesInfractionAndFourriereRequestsWrapperDto: FichesInfractionAndFourriereRequestsWrapperDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateFicheFourriereFieldWithoutDossierUsingPOST.');
            }
            // verify required parameter 'fichesInfractionAndFourriereRequestsWrapperDto' is not null or undefined
            if (fichesInfractionAndFourriereRequestsWrapperDto === null || fichesInfractionAndFourriereRequestsWrapperDto === undefined) {
                throw new RequiredError('fichesInfractionAndFourriereRequestsWrapperDto','Required parameter fichesInfractionAndFourriereRequestsWrapperDto was null or undefined when calling validateFicheFourriereFieldWithoutDossierUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/dossier/fiche-fourriere/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FichesInfractionAndFourriereRequestsWrapperDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fichesInfractionAndFourriereRequestsWrapperDto || {}) : (fichesInfractionAndFourriereRequestsWrapperDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche fourriÃ¨re pour un dossier non crÃ©Ã©.
         * @param {FichesInfractionAndFourriereRequestsWrapperDto} fichesInfractionAndFourriereRequestsWrapperDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheFourriereWithoutDossierUsingPOST(fichesInfractionAndFourriereRequestsWrapperDto: FichesInfractionAndFourriereRequestsWrapperDto, options: any = {}): FetchArgs {
            // verify required parameter 'fichesInfractionAndFourriereRequestsWrapperDto' is not null or undefined
            if (fichesInfractionAndFourriereRequestsWrapperDto === null || fichesInfractionAndFourriereRequestsWrapperDto === undefined) {
                throw new RequiredError('fichesInfractionAndFourriereRequestsWrapperDto','Required parameter fichesInfractionAndFourriereRequestsWrapperDto was null or undefined when calling validateFicheFourriereWithoutDossierUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/dossier/fiche-fourriere/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FichesInfractionAndFourriereRequestsWrapperDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fichesInfractionAndFourriereRequestsWrapperDto || {}) : (fichesInfractionAndFourriereRequestsWrapperDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation d\'un champ de la fiche infraction pour un dossier non crÃ©Ã©.
         * @param {'AUTORITE_FOURRIERE' | 'FOURRIERE' | 'DATE_CONSTATATION' | 'NOM_AUTORITE_PRESCRIPTRICE' | 'QUALITE_AUTORITE_PRESCRIPTRICE' | 'NOM_AGENT' | 'NIGEND' | 'UNITE' | 'MAIL_UNITE' | 'MOTIVATION_MESURE' | 'LIEU_PRIVE' | 'LIEU_ENLEVEMENT' | 'COMMUNE' | 'NUIT' | 'PLUIE_NEIGE' | 'AUTORITE_POUR_LEVER_MESURE' | 'DATE_REDACTION'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {FicheInfractionRequestDto} ficheInfractionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheInfractionFieldWithoutDossierUsingPOST(field: 'AUTORITE_FOURRIERE' | 'FOURRIERE' | 'DATE_CONSTATATION' | 'NOM_AUTORITE_PRESCRIPTRICE' | 'QUALITE_AUTORITE_PRESCRIPTRICE' | 'NOM_AGENT' | 'NIGEND' | 'UNITE' | 'MAIL_UNITE' | 'MOTIVATION_MESURE' | 'LIEU_PRIVE' | 'LIEU_ENLEVEMENT' | 'COMMUNE' | 'NUIT' | 'PLUIE_NEIGE' | 'AUTORITE_POUR_LEVER_MESURE' | 'DATE_REDACTION', ficheInfractionRequestDto: FicheInfractionRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateFicheInfractionFieldWithoutDossierUsingPOST.');
            }
            // verify required parameter 'ficheInfractionRequestDto' is not null or undefined
            if (ficheInfractionRequestDto === null || ficheInfractionRequestDto === undefined) {
                throw new RequiredError('ficheInfractionRequestDto','Required parameter ficheInfractionRequestDto was null or undefined when calling validateFicheInfractionFieldWithoutDossierUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/dossier/fiche-infraction/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FicheInfractionRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ficheInfractionRequestDto || {}) : (ficheInfractionRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation de tous les champs de la fiche infraction pour un dossier non crÃ©Ã©.
         * @param {FicheInfractionRequestDto} ficheInfractionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheInfractionWithoutDossierUsingPOST(ficheInfractionRequestDto: FicheInfractionRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'ficheInfractionRequestDto' is not null or undefined
            if (ficheInfractionRequestDto === null || ficheInfractionRequestDto === undefined) {
                throw new RequiredError('ficheInfractionRequestDto','Required parameter ficheInfractionRequestDto was null or undefined when calling validateFicheInfractionWithoutDossierUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/dossier/fiche-infraction/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FicheInfractionRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ficheInfractionRequestDto || {}) : (ficheInfractionRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation d\'un seul champ de la fiche vÃ©hicule pour un dossier non crÃ©Ã©.
         * @param {'DESCRIPTION_GENRE' | 'DESCRIPTION_MARQUE' | 'DESCRIPTION_MODELE' | 'DESCRIPTION_COULEUR' | 'ETAT_LIBELLE' | 'ETAT_PRECISION' | 'IMMATRICULATION_SANS_PLAQUE' | 'IMMATRICULATION_PAYS_ETRANGER' | 'IMMATRICULATION_NUMERO' | 'IMMATRICULATION_NUMERO_VISIBLE' | 'IMMATRICULATION_VIN' | 'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_AUTO_RADIO_VISIBLE' | 'ACCESSOIRES_POSTE_CB_VISIBLE' | 'ACCESSOIRES_TELEPHONE_PORTABLE' | 'ACCESSOIRES_NOMBRE_ANTENNES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {FicheVehiculeRequestDto} ficheVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheVehiculeFieldWithoutDossierUsingPOST(field: 'DESCRIPTION_GENRE' | 'DESCRIPTION_MARQUE' | 'DESCRIPTION_MODELE' | 'DESCRIPTION_COULEUR' | 'ETAT_LIBELLE' | 'ETAT_PRECISION' | 'IMMATRICULATION_SANS_PLAQUE' | 'IMMATRICULATION_PAYS_ETRANGER' | 'IMMATRICULATION_NUMERO' | 'IMMATRICULATION_NUMERO_VISIBLE' | 'IMMATRICULATION_VIN' | 'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_AUTO_RADIO_VISIBLE' | 'ACCESSOIRES_POSTE_CB_VISIBLE' | 'ACCESSOIRES_TELEPHONE_PORTABLE' | 'ACCESSOIRES_NOMBRE_ANTENNES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS', ficheVehiculeRequestDto: FicheVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateFicheVehiculeFieldWithoutDossierUsingPOST.');
            }
            // verify required parameter 'ficheVehiculeRequestDto' is not null or undefined
            if (ficheVehiculeRequestDto === null || ficheVehiculeRequestDto === undefined) {
                throw new RequiredError('ficheVehiculeRequestDto','Required parameter ficheVehiculeRequestDto was null or undefined when calling validateFicheVehiculeFieldWithoutDossierUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/dossier/fiche-vehicule/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FicheVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ficheVehiculeRequestDto || {}) : (ficheVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche vÃ©hicule pour un dossier non crÃ©Ã©.
         * @param {FicheVehiculeRequestDto} ficheVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheVehiculeWithoutDossierUsingPOST(ficheVehiculeRequestDto: FicheVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'ficheVehiculeRequestDto' is not null or undefined
            if (ficheVehiculeRequestDto === null || ficheVehiculeRequestDto === undefined) {
                throw new RequiredError('ficheVehiculeRequestDto','Required parameter ficheVehiculeRequestDto was null or undefined when calling validateFicheVehiculeWithoutDossierUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/dossier/fiche-vehicule/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FicheVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ficheVehiculeRequestDto || {}) : (ficheVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ spÃ©cifique d\'une page profil fourriÃ¨re.
         * @param {'ID' | 'ID_CORRELATION' | 'AUTORITES_FOURRIERE_IDS' | 'RAISON_SOCIALE' | 'NUMERO_AGREMENT' | 'DATE_AGREMENT_DEBUT' | 'DATE_AGREMENT_FIN' | 'NOMBRE_EMPLACEMENTS' | 'NOM_VOIE' | 'NUMERO_VOIE' | 'REPETITION' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE_FIXE' | 'TELEPHONE_MOBILE' | 'ADRESSE_MAIL' | 'COORDONNEES_GPS' | 'NOM_RESPONSABLE' | 'ADRESSE_MAIL_RESPONSABLE' | 'HORAIRES'} field IntitulÃ© du champ Ã  vÃ©rifier.
         * @param {string} idCorrelation Id de la fourriÃ¨re, reprÃ©sentÃ© par un UUID.
         * @param {ProfilFourriereRequestDto} profilFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFourriereInfoFieldUsingPOST(field: 'ID' | 'ID_CORRELATION' | 'AUTORITES_FOURRIERE_IDS' | 'RAISON_SOCIALE' | 'NUMERO_AGREMENT' | 'DATE_AGREMENT_DEBUT' | 'DATE_AGREMENT_FIN' | 'NOMBRE_EMPLACEMENTS' | 'NOM_VOIE' | 'NUMERO_VOIE' | 'REPETITION' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE_FIXE' | 'TELEPHONE_MOBILE' | 'ADRESSE_MAIL' | 'COORDONNEES_GPS' | 'NOM_RESPONSABLE' | 'ADRESSE_MAIL_RESPONSABLE' | 'HORAIRES', idCorrelation: string, profilFourriereRequestDto: ProfilFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateFourriereInfoFieldUsingPOST.');
            }
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateFourriereInfoFieldUsingPOST.');
            }
            // verify required parameter 'profilFourriereRequestDto' is not null or undefined
            if (profilFourriereRequestDto === null || profilFourriereRequestDto === undefined) {
                throw new RequiredError('profilFourriereRequestDto','Required parameter profilFourriereRequestDto was null or undefined when calling validateFourriereInfoFieldUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/profil/fourriere/{idCorrelation}/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProfilFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(profilFourriereRequestDto || {}) : (profilFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation des donnÃ©es d\'une fourriere.
         * @param {string} idCorrelation Id de la fourriÃ¨re, reprÃ©sentÃ© par un UUID.
         * @param {ProfilFourriereRequestDto} profilFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFourriereInfoUsingPOST(idCorrelation: string, profilFourriereRequestDto: ProfilFourriereRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling validateFourriereInfoUsingPOST.');
            }
            // verify required parameter 'profilFourriereRequestDto' is not null or undefined
            if (profilFourriereRequestDto === null || profilFourriereRequestDto === undefined) {
                throw new RequiredError('profilFourriereRequestDto','Required parameter profilFourriereRequestDto was null or undefined when calling validateFourriereInfoUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/profil/fourriere/{idCorrelation}/validation`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProfilFourriereRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(profilFourriereRequestDto || {}) : (profilFourriereRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de valider un champ de la fiche sortie pour un dossier existant.
         * @param {'DATE_SORTIE' | 'MOTIF_SORTIE' | 'COMMENT'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {SortieVehiculeRequestDto} sortieVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSortieVehiculeFormFieldUsingPOST(field: 'DATE_SORTIE' | 'MOTIF_SORTIE' | 'COMMENT', id: string, sortieVehiculeRequestDto: SortieVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling validateSortieVehiculeFormFieldUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateSortieVehiculeFormFieldUsingPOST.');
            }
            // verify required parameter 'sortieVehiculeRequestDto' is not null or undefined
            if (sortieVehiculeRequestDto === null || sortieVehiculeRequestDto === undefined) {
                throw new RequiredError('sortieVehiculeRequestDto','Required parameter sortieVehiculeRequestDto was null or undefined when calling validateSortieVehiculeFormFieldUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/dossier/{id}/sortie/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SortieVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(sortieVehiculeRequestDto || {}) : (sortieVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche sortie pour un dossier existant.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {SortieVehiculeRequestDto} sortieVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSortieVehiculeFormUsingPOST(id: string, sortieVehiculeRequestDto: SortieVehiculeRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validateSortieVehiculeFormUsingPOST.');
            }
            // verify required parameter 'sortieVehiculeRequestDto' is not null or undefined
            if (sortieVehiculeRequestDto === null || sortieVehiculeRequestDto === undefined) {
                throw new RequiredError('sortieVehiculeRequestDto','Required parameter sortieVehiculeRequestDto was null or undefined when calling validateSortieVehiculeFormUsingPOST.');
            }
            const localVarPath = `/api/gardien-fourriere/dossier/{id}/sortie/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SortieVehiculeRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(sortieVehiculeRequestDto || {}) : (sortieVehiculeRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GardienFourriereControllerApi - functional programming interface
 * @export
 */
export const GardienFourriereControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant de confirmer les informations de son compte Keycloak
         * @param {string} username Nom d\'utilisateur du compte Keycloak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAccountUsingPUT(username: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).confirmAccountUsingPUT(username, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de confirmer la validitÃ© des informations d\'une fourriÃ¨re
         * @param {string} id Id de la fourriÃ¨re, et non son id de corrÃ©lation, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmFourriereUsingPUT(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FourriereDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).confirmFourriereUsingPUT(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary CrÃ©ation d\'un dossier via une fiche descriptive
         * @param {CreateDossierFromGardienFourriereDto} createDossierFromGardienFourriereDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDossierWithInitialInfoUsingPOST(createDossierFromGardienFourriereDto: CreateDossierFromGardienFourriereDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).createDossierWithInitialInfoUsingPOST(createDossierFromGardienFourriereDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de renseigner l\'entrÃ©e d\'un vÃ©hicule.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {EntreeVehiculeRequestDto} entreeVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entreeVehiculeWithFormulaireEntreeUsingPOST(id: string, entreeVehiculeRequestDto: EntreeVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).entreeVehiculeWithFormulaireEntreeUsingPOST(id, entreeVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary RÃ©cupÃ©ration des donnÃ©es d\'une fourriÃ¨re.
         * @param {string} idCorrelation Id de la fourriÃ¨re, reprÃ©sentÃ© par un UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFourriereInfoUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FourriereDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).getFourriereInfoUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Mise Ã  jour des donnÃ©es d\'une fourriÃ¨re.
         * @param {string} idCorrelation Id de la fourriÃ¨re, reprÃ©sentÃ© par un UUID.
         * @param {ProfilFourriereRequestDto} profilFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFourriereInfoUsingPOST(idCorrelation: string, profilFourriereRequestDto: ProfilFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FourriereDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).setFourriereInfoUsingPOST(idCorrelation, profilFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de mettre Ã  jour la fiche sortie d\'un vÃ©hicule.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {SortieVehiculeRequestDto} sortieVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortieVehiculeWithFormulaireSortieUsingPOST(id: string, sortieVehiculeRequestDto: SortieVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).sortieVehiculeWithFormulaireSortieUsingPOST(id, sortieVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Mise Ã  jour des donnÃ©es d\'un bon d\'enlÃ¨vement gardien.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {BonEnlevementGardienRequestDto} bonEnlevementGardienRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBonEnlevementGardienUsingPOST(id: string, bonEnlevementGardienRequestDto: BonEnlevementGardienRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).updateBonEnlevementGardienUsingPOST(id, bonEnlevementGardienRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ spÃ©cifique d\'un bon d\'enlÃ¨vement gardien.
         * @param {'ID_CORRELATION_CENTRE_VHU' | 'FLAG_CARTE_GRISE_RETIREE' | 'MOTIF_ABSENCE_IMMATRICULATION' | 'RAISON_SOCIALE' | 'NUMERO_SIREN' | 'NUMERO_VOIE' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'NUMERO_AGREMENT' | 'PREFECTURE_AGREMENT'} field IntitulÃ© du champ Ã  vÃ©rifier.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {BonEnlevementGardienRequestDto} bonEnlevementGardienRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateBonEnlevementGardienFieldUsingPOST(field: 'ID_CORRELATION_CENTRE_VHU' | 'FLAG_CARTE_GRISE_RETIREE' | 'MOTIF_ABSENCE_IMMATRICULATION' | 'RAISON_SOCIALE' | 'NUMERO_SIREN' | 'NUMERO_VOIE' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'NUMERO_AGREMENT' | 'PREFECTURE_AGREMENT', id: string, bonEnlevementGardienRequestDto: BonEnlevementGardienRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).validateBonEnlevementGardienFieldUsingPOST(field, id, bonEnlevementGardienRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation des donnÃ©es d\'un bon d\'enlÃ¨vement gardien.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {BonEnlevementGardienRequestDto} bonEnlevementGardienRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateBonEnlevementGardienUsingPOST(id: string, bonEnlevementGardienRequestDto: BonEnlevementGardienRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).validateBonEnlevementGardienUsingPOST(id, bonEnlevementGardienRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ de la fiche entrÃ©e pour un dossier existant.
         * @param {'ID_CORRELATION_AUTORITE_FOURRIERE' | 'DATE_ENLEVEMENT' | 'DATE_ENTREE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {EntreeVehiculeRequestDto} entreeVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEntreeVehiculeFormFieldUsingPOST(field: 'ID_CORRELATION_AUTORITE_FOURRIERE' | 'DATE_ENLEVEMENT' | 'DATE_ENTREE', id: string, entreeVehiculeRequestDto: EntreeVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierEntreeViolationsDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).validateEntreeVehiculeFormFieldUsingPOST(field, id, entreeVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche entrÃ©e pour un dossier existant.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {EntreeVehiculeRequestDto} entreeVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEntreeVehiculeFormUsingPOST(id: string, entreeVehiculeRequestDto: EntreeVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierEntreeViolationsDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).validateEntreeVehiculeFormUsingPOST(id, entreeVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation d\'un champ de la fiche fourriÃ¨re pour un dossier non crÃ©Ã©.
         * @param {'ENTREE' | 'ENLEVEMENT' | 'FOURRIERE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {FichesInfractionAndFourriereRequestsWrapperDto} fichesInfractionAndFourriereRequestsWrapperDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheFourriereFieldWithoutDossierUsingPOST(field: 'ENTREE' | 'ENLEVEMENT' | 'FOURRIERE', fichesInfractionAndFourriereRequestsWrapperDto: FichesInfractionAndFourriereRequestsWrapperDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).validateFicheFourriereFieldWithoutDossierUsingPOST(field, fichesInfractionAndFourriereRequestsWrapperDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche fourriÃ¨re pour un dossier non crÃ©Ã©.
         * @param {FichesInfractionAndFourriereRequestsWrapperDto} fichesInfractionAndFourriereRequestsWrapperDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheFourriereWithoutDossierUsingPOST(fichesInfractionAndFourriereRequestsWrapperDto: FichesInfractionAndFourriereRequestsWrapperDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).validateFicheFourriereWithoutDossierUsingPOST(fichesInfractionAndFourriereRequestsWrapperDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation d\'un champ de la fiche infraction pour un dossier non crÃ©Ã©.
         * @param {'AUTORITE_FOURRIERE' | 'FOURRIERE' | 'DATE_CONSTATATION' | 'NOM_AUTORITE_PRESCRIPTRICE' | 'QUALITE_AUTORITE_PRESCRIPTRICE' | 'NOM_AGENT' | 'NIGEND' | 'UNITE' | 'MAIL_UNITE' | 'MOTIVATION_MESURE' | 'LIEU_PRIVE' | 'LIEU_ENLEVEMENT' | 'COMMUNE' | 'NUIT' | 'PLUIE_NEIGE' | 'AUTORITE_POUR_LEVER_MESURE' | 'DATE_REDACTION'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {FicheInfractionRequestDto} ficheInfractionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheInfractionFieldWithoutDossierUsingPOST(field: 'AUTORITE_FOURRIERE' | 'FOURRIERE' | 'DATE_CONSTATATION' | 'NOM_AUTORITE_PRESCRIPTRICE' | 'QUALITE_AUTORITE_PRESCRIPTRICE' | 'NOM_AGENT' | 'NIGEND' | 'UNITE' | 'MAIL_UNITE' | 'MOTIVATION_MESURE' | 'LIEU_PRIVE' | 'LIEU_ENLEVEMENT' | 'COMMUNE' | 'NUIT' | 'PLUIE_NEIGE' | 'AUTORITE_POUR_LEVER_MESURE' | 'DATE_REDACTION', ficheInfractionRequestDto: FicheInfractionRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).validateFicheInfractionFieldWithoutDossierUsingPOST(field, ficheInfractionRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation de tous les champs de la fiche infraction pour un dossier non crÃ©Ã©.
         * @param {FicheInfractionRequestDto} ficheInfractionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheInfractionWithoutDossierUsingPOST(ficheInfractionRequestDto: FicheInfractionRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).validateFicheInfractionWithoutDossierUsingPOST(ficheInfractionRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation d\'un seul champ de la fiche vÃ©hicule pour un dossier non crÃ©Ã©.
         * @param {'DESCRIPTION_GENRE' | 'DESCRIPTION_MARQUE' | 'DESCRIPTION_MODELE' | 'DESCRIPTION_COULEUR' | 'ETAT_LIBELLE' | 'ETAT_PRECISION' | 'IMMATRICULATION_SANS_PLAQUE' | 'IMMATRICULATION_PAYS_ETRANGER' | 'IMMATRICULATION_NUMERO' | 'IMMATRICULATION_NUMERO_VISIBLE' | 'IMMATRICULATION_VIN' | 'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_AUTO_RADIO_VISIBLE' | 'ACCESSOIRES_POSTE_CB_VISIBLE' | 'ACCESSOIRES_TELEPHONE_PORTABLE' | 'ACCESSOIRES_NOMBRE_ANTENNES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {FicheVehiculeRequestDto} ficheVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheVehiculeFieldWithoutDossierUsingPOST(field: 'DESCRIPTION_GENRE' | 'DESCRIPTION_MARQUE' | 'DESCRIPTION_MODELE' | 'DESCRIPTION_COULEUR' | 'ETAT_LIBELLE' | 'ETAT_PRECISION' | 'IMMATRICULATION_SANS_PLAQUE' | 'IMMATRICULATION_PAYS_ETRANGER' | 'IMMATRICULATION_NUMERO' | 'IMMATRICULATION_NUMERO_VISIBLE' | 'IMMATRICULATION_VIN' | 'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_AUTO_RADIO_VISIBLE' | 'ACCESSOIRES_POSTE_CB_VISIBLE' | 'ACCESSOIRES_TELEPHONE_PORTABLE' | 'ACCESSOIRES_NOMBRE_ANTENNES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS', ficheVehiculeRequestDto: FicheVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).validateFicheVehiculeFieldWithoutDossierUsingPOST(field, ficheVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche vÃ©hicule pour un dossier non crÃ©Ã©.
         * @param {FicheVehiculeRequestDto} ficheVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFicheVehiculeWithoutDossierUsingPOST(ficheVehiculeRequestDto: FicheVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).validateFicheVehiculeWithoutDossierUsingPOST(ficheVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ spÃ©cifique d\'une page profil fourriÃ¨re.
         * @param {'ID' | 'ID_CORRELATION' | 'AUTORITES_FOURRIERE_IDS' | 'RAISON_SOCIALE' | 'NUMERO_AGREMENT' | 'DATE_AGREMENT_DEBUT' | 'DATE_AGREMENT_FIN' | 'NOMBRE_EMPLACEMENTS' | 'NOM_VOIE' | 'NUMERO_VOIE' | 'REPETITION' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE_FIXE' | 'TELEPHONE_MOBILE' | 'ADRESSE_MAIL' | 'COORDONNEES_GPS' | 'NOM_RESPONSABLE' | 'ADRESSE_MAIL_RESPONSABLE' | 'HORAIRES'} field IntitulÃ© du champ Ã  vÃ©rifier.
         * @param {string} idCorrelation Id de la fourriÃ¨re, reprÃ©sentÃ© par un UUID.
         * @param {ProfilFourriereRequestDto} profilFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFourriereInfoFieldUsingPOST(field: 'ID' | 'ID_CORRELATION' | 'AUTORITES_FOURRIERE_IDS' | 'RAISON_SOCIALE' | 'NUMERO_AGREMENT' | 'DATE_AGREMENT_DEBUT' | 'DATE_AGREMENT_FIN' | 'NOMBRE_EMPLACEMENTS' | 'NOM_VOIE' | 'NUMERO_VOIE' | 'REPETITION' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE_FIXE' | 'TELEPHONE_MOBILE' | 'ADRESSE_MAIL' | 'COORDONNEES_GPS' | 'NOM_RESPONSABLE' | 'ADRESSE_MAIL_RESPONSABLE' | 'HORAIRES', idCorrelation: string, profilFourriereRequestDto: ProfilFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProfilFourriereViolationsDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).validateFourriereInfoFieldUsingPOST(field, idCorrelation, profilFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation des donnÃ©es d\'une fourriere.
         * @param {string} idCorrelation Id de la fourriÃ¨re, reprÃ©sentÃ© par un UUID.
         * @param {ProfilFourriereRequestDto} profilFourriereRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFourriereInfoUsingPOST(idCorrelation: string, profilFourriereRequestDto: ProfilFourriereRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProfilFourriereViolationsDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).validateFourriereInfoUsingPOST(idCorrelation, profilFourriereRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de valider un champ de la fiche sortie pour un dossier existant.
         * @param {'DATE_SORTIE' | 'MOTIF_SORTIE' | 'COMMENT'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {SortieVehiculeRequestDto} sortieVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSortieVehiculeFormFieldUsingPOST(field: 'DATE_SORTIE' | 'MOTIF_SORTIE' | 'COMMENT', id: string, sortieVehiculeRequestDto: SortieVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).validateSortieVehiculeFormFieldUsingPOST(field, id, sortieVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Validation de l\'ensemble des champs de la fiche sortie pour un dossier existant.
         * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
         * @param {SortieVehiculeRequestDto} sortieVehiculeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSortieVehiculeFormUsingPOST(id: string, sortieVehiculeRequestDto: SortieVehiculeRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierViolationsDto> {
            const localVarFetchArgs = GardienFourriereControllerApiFetchParamCreator(configuration).validateSortieVehiculeFormUsingPOST(id, sortieVehiculeRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GardienFourriereControllerApi - object-oriented interface
 * @export
 * @class GardienFourriereControllerApi
 * @extends {BaseAPI}
 */
export class GardienFourriereControllerApi extends BaseAPI {
    /**
     * @summary Route permettant de confirmer les informations de son compte Keycloak
     * @param {string} username Nom d\'utilisateur du compte Keycloak
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public confirmAccountUsingPUT(username: string, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).confirmAccountUsingPUT(username, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de confirmer la validitÃ© des informations d\'une fourriÃ¨re
     * @param {string} id Id de la fourriÃ¨re, et non son id de corrÃ©lation, reprÃ©sentÃ© par un UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public confirmFourriereUsingPUT(id: string, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).confirmFourriereUsingPUT(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary CrÃ©ation d\'un dossier via une fiche descriptive
     * @param {CreateDossierFromGardienFourriereDto} createDossierFromGardienFourriereDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public createDossierWithInitialInfoUsingPOST(createDossierFromGardienFourriereDto: CreateDossierFromGardienFourriereDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).createDossierWithInitialInfoUsingPOST(createDossierFromGardienFourriereDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de renseigner l\'entrÃ©e d\'un vÃ©hicule.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {EntreeVehiculeRequestDto} entreeVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public entreeVehiculeWithFormulaireEntreeUsingPOST(id: string, entreeVehiculeRequestDto: EntreeVehiculeRequestDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).entreeVehiculeWithFormulaireEntreeUsingPOST(id, entreeVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary RÃ©cupÃ©ration des donnÃ©es d\'une fourriÃ¨re.
     * @param {string} idCorrelation Id de la fourriÃ¨re, reprÃ©sentÃ© par un UUID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getFourriereInfoUsingGET(idCorrelation: string, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).getFourriereInfoUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Mise Ã  jour des donnÃ©es d\'une fourriÃ¨re.
     * @param {string} idCorrelation Id de la fourriÃ¨re, reprÃ©sentÃ© par un UUID.
     * @param {ProfilFourriereRequestDto} profilFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public setFourriereInfoUsingPOST(idCorrelation: string, profilFourriereRequestDto: ProfilFourriereRequestDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).setFourriereInfoUsingPOST(idCorrelation, profilFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de mettre Ã  jour la fiche sortie d\'un vÃ©hicule.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {SortieVehiculeRequestDto} sortieVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public sortieVehiculeWithFormulaireSortieUsingPOST(id: string, sortieVehiculeRequestDto: SortieVehiculeRequestDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).sortieVehiculeWithFormulaireSortieUsingPOST(id, sortieVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Mise Ã  jour des donnÃ©es d\'un bon d\'enlÃ¨vement gardien.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {BonEnlevementGardienRequestDto} bonEnlevementGardienRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateBonEnlevementGardienUsingPOST(id: string, bonEnlevementGardienRequestDto: BonEnlevementGardienRequestDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).updateBonEnlevementGardienUsingPOST(id, bonEnlevementGardienRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ spÃ©cifique d\'un bon d\'enlÃ¨vement gardien.
     * @param {'ID_CORRELATION_CENTRE_VHU' | 'FLAG_CARTE_GRISE_RETIREE' | 'MOTIF_ABSENCE_IMMATRICULATION' | 'RAISON_SOCIALE' | 'NUMERO_SIREN' | 'NUMERO_VOIE' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'NUMERO_AGREMENT' | 'PREFECTURE_AGREMENT'} field IntitulÃ© du champ Ã  vÃ©rifier.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {BonEnlevementGardienRequestDto} bonEnlevementGardienRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateBonEnlevementGardienFieldUsingPOST(field: 'ID_CORRELATION_CENTRE_VHU' | 'FLAG_CARTE_GRISE_RETIREE' | 'MOTIF_ABSENCE_IMMATRICULATION' | 'RAISON_SOCIALE' | 'NUMERO_SIREN' | 'NUMERO_VOIE' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'NUMERO_AGREMENT' | 'PREFECTURE_AGREMENT', id: string, bonEnlevementGardienRequestDto: BonEnlevementGardienRequestDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).validateBonEnlevementGardienFieldUsingPOST(field, id, bonEnlevementGardienRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation des donnÃ©es d\'un bon d\'enlÃ¨vement gardien.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {BonEnlevementGardienRequestDto} bonEnlevementGardienRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateBonEnlevementGardienUsingPOST(id: string, bonEnlevementGardienRequestDto: BonEnlevementGardienRequestDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).validateBonEnlevementGardienUsingPOST(id, bonEnlevementGardienRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ de la fiche entrÃ©e pour un dossier existant.
     * @param {'ID_CORRELATION_AUTORITE_FOURRIERE' | 'DATE_ENLEVEMENT' | 'DATE_ENTREE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {EntreeVehiculeRequestDto} entreeVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateEntreeVehiculeFormFieldUsingPOST(field: 'ID_CORRELATION_AUTORITE_FOURRIERE' | 'DATE_ENLEVEMENT' | 'DATE_ENTREE', id: string, entreeVehiculeRequestDto: EntreeVehiculeRequestDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).validateEntreeVehiculeFormFieldUsingPOST(field, id, entreeVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation de l\'ensemble des champs de la fiche entrÃ©e pour un dossier existant.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {EntreeVehiculeRequestDto} entreeVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateEntreeVehiculeFormUsingPOST(id: string, entreeVehiculeRequestDto: EntreeVehiculeRequestDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).validateEntreeVehiculeFormUsingPOST(id, entreeVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation d\'un champ de la fiche fourriÃ¨re pour un dossier non crÃ©Ã©.
     * @param {'ENTREE' | 'ENLEVEMENT' | 'FOURRIERE'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {FichesInfractionAndFourriereRequestsWrapperDto} fichesInfractionAndFourriereRequestsWrapperDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFicheFourriereFieldWithoutDossierUsingPOST(field: 'ENTREE' | 'ENLEVEMENT' | 'FOURRIERE', fichesInfractionAndFourriereRequestsWrapperDto: FichesInfractionAndFourriereRequestsWrapperDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).validateFicheFourriereFieldWithoutDossierUsingPOST(field, fichesInfractionAndFourriereRequestsWrapperDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation de l\'ensemble des champs de la fiche fourriÃ¨re pour un dossier non crÃ©Ã©.
     * @param {FichesInfractionAndFourriereRequestsWrapperDto} fichesInfractionAndFourriereRequestsWrapperDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFicheFourriereWithoutDossierUsingPOST(fichesInfractionAndFourriereRequestsWrapperDto: FichesInfractionAndFourriereRequestsWrapperDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).validateFicheFourriereWithoutDossierUsingPOST(fichesInfractionAndFourriereRequestsWrapperDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation d\'un champ de la fiche infraction pour un dossier non crÃ©Ã©.
     * @param {'AUTORITE_FOURRIERE' | 'FOURRIERE' | 'DATE_CONSTATATION' | 'NOM_AUTORITE_PRESCRIPTRICE' | 'QUALITE_AUTORITE_PRESCRIPTRICE' | 'NOM_AGENT' | 'NIGEND' | 'UNITE' | 'MAIL_UNITE' | 'MOTIVATION_MESURE' | 'LIEU_PRIVE' | 'LIEU_ENLEVEMENT' | 'COMMUNE' | 'NUIT' | 'PLUIE_NEIGE' | 'AUTORITE_POUR_LEVER_MESURE' | 'DATE_REDACTION'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {FicheInfractionRequestDto} ficheInfractionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFicheInfractionFieldWithoutDossierUsingPOST(field: 'AUTORITE_FOURRIERE' | 'FOURRIERE' | 'DATE_CONSTATATION' | 'NOM_AUTORITE_PRESCRIPTRICE' | 'QUALITE_AUTORITE_PRESCRIPTRICE' | 'NOM_AGENT' | 'NIGEND' | 'UNITE' | 'MAIL_UNITE' | 'MOTIVATION_MESURE' | 'LIEU_PRIVE' | 'LIEU_ENLEVEMENT' | 'COMMUNE' | 'NUIT' | 'PLUIE_NEIGE' | 'AUTORITE_POUR_LEVER_MESURE' | 'DATE_REDACTION', ficheInfractionRequestDto: FicheInfractionRequestDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).validateFicheInfractionFieldWithoutDossierUsingPOST(field, ficheInfractionRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation de tous les champs de la fiche infraction pour un dossier non crÃ©Ã©.
     * @param {FicheInfractionRequestDto} ficheInfractionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFicheInfractionWithoutDossierUsingPOST(ficheInfractionRequestDto: FicheInfractionRequestDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).validateFicheInfractionWithoutDossierUsingPOST(ficheInfractionRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation d\'un seul champ de la fiche vÃ©hicule pour un dossier non crÃ©Ã©.
     * @param {'DESCRIPTION_GENRE' | 'DESCRIPTION_MARQUE' | 'DESCRIPTION_MODELE' | 'DESCRIPTION_COULEUR' | 'ETAT_LIBELLE' | 'ETAT_PRECISION' | 'IMMATRICULATION_SANS_PLAQUE' | 'IMMATRICULATION_PAYS_ETRANGER' | 'IMMATRICULATION_NUMERO' | 'IMMATRICULATION_NUMERO_VISIBLE' | 'IMMATRICULATION_VIN' | 'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_AUTO_RADIO_VISIBLE' | 'ACCESSOIRES_POSTE_CB_VISIBLE' | 'ACCESSOIRES_TELEPHONE_PORTABLE' | 'ACCESSOIRES_NOMBRE_ANTENNES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {FicheVehiculeRequestDto} ficheVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFicheVehiculeFieldWithoutDossierUsingPOST(field: 'DESCRIPTION_GENRE' | 'DESCRIPTION_MARQUE' | 'DESCRIPTION_MODELE' | 'DESCRIPTION_COULEUR' | 'ETAT_LIBELLE' | 'ETAT_PRECISION' | 'IMMATRICULATION_SANS_PLAQUE' | 'IMMATRICULATION_PAYS_ETRANGER' | 'IMMATRICULATION_NUMERO' | 'IMMATRICULATION_NUMERO_VISIBLE' | 'IMMATRICULATION_VIN' | 'VERROUILLAGE_PORTES' | 'VERROUILLAGE_COFFRES' | 'ACCESSOIRES_AUTO_RADIO_VISIBLE' | 'ACCESSOIRES_POSTE_CB_VISIBLE' | 'ACCESSOIRES_TELEPHONE_PORTABLE' | 'ACCESSOIRES_NOMBRE_ANTENNES' | 'ACCESSOIRES_OBJETS_VISIBLES' | 'ACCESSOIRES_OBSERVATIONS', ficheVehiculeRequestDto: FicheVehiculeRequestDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).validateFicheVehiculeFieldWithoutDossierUsingPOST(field, ficheVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation de l\'ensemble des champs de la fiche vÃ©hicule pour un dossier non crÃ©Ã©.
     * @param {FicheVehiculeRequestDto} ficheVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFicheVehiculeWithoutDossierUsingPOST(ficheVehiculeRequestDto: FicheVehiculeRequestDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).validateFicheVehiculeWithoutDossierUsingPOST(ficheVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ spÃ©cifique d\'une page profil fourriÃ¨re.
     * @param {'ID' | 'ID_CORRELATION' | 'AUTORITES_FOURRIERE_IDS' | 'RAISON_SOCIALE' | 'NUMERO_AGREMENT' | 'DATE_AGREMENT_DEBUT' | 'DATE_AGREMENT_FIN' | 'NOMBRE_EMPLACEMENTS' | 'NOM_VOIE' | 'NUMERO_VOIE' | 'REPETITION' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE_FIXE' | 'TELEPHONE_MOBILE' | 'ADRESSE_MAIL' | 'COORDONNEES_GPS' | 'NOM_RESPONSABLE' | 'ADRESSE_MAIL_RESPONSABLE' | 'HORAIRES'} field IntitulÃ© du champ Ã  vÃ©rifier.
     * @param {string} idCorrelation Id de la fourriÃ¨re, reprÃ©sentÃ© par un UUID.
     * @param {ProfilFourriereRequestDto} profilFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFourriereInfoFieldUsingPOST(field: 'ID' | 'ID_CORRELATION' | 'AUTORITES_FOURRIERE_IDS' | 'RAISON_SOCIALE' | 'NUMERO_AGREMENT' | 'DATE_AGREMENT_DEBUT' | 'DATE_AGREMENT_FIN' | 'NOMBRE_EMPLACEMENTS' | 'NOM_VOIE' | 'NUMERO_VOIE' | 'REPETITION' | 'ADRESSE' | 'COMPLEMENT_ADRESSE' | 'CODE_POSTAL' | 'COMMUNE' | 'TELEPHONE_FIXE' | 'TELEPHONE_MOBILE' | 'ADRESSE_MAIL' | 'COORDONNEES_GPS' | 'NOM_RESPONSABLE' | 'ADRESSE_MAIL_RESPONSABLE' | 'HORAIRES', idCorrelation: string, profilFourriereRequestDto: ProfilFourriereRequestDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).validateFourriereInfoFieldUsingPOST(field, idCorrelation, profilFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation des donnÃ©es d\'une fourriere.
     * @param {string} idCorrelation Id de la fourriÃ¨re, reprÃ©sentÃ© par un UUID.
     * @param {ProfilFourriereRequestDto} profilFourriereRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateFourriereInfoUsingPOST(idCorrelation: string, profilFourriereRequestDto: ProfilFourriereRequestDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).validateFourriereInfoUsingPOST(idCorrelation, profilFourriereRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de valider un champ de la fiche sortie pour un dossier existant.
     * @param {'DATE_SORTIE' | 'MOTIF_SORTIE' | 'COMMENT'} field Le champ Ã  valider, reprÃ©sentÃ© par une Ã©numÃ©ration.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {SortieVehiculeRequestDto} sortieVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateSortieVehiculeFormFieldUsingPOST(field: 'DATE_SORTIE' | 'MOTIF_SORTIE' | 'COMMENT', id: string, sortieVehiculeRequestDto: SortieVehiculeRequestDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).validateSortieVehiculeFormFieldUsingPOST(field, id, sortieVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Validation de l\'ensemble des champs de la fiche sortie pour un dossier existant.
     * @param {string} id Id du dossier, reprÃ©sentÃ© par un UUID.
     * @param {SortieVehiculeRequestDto} sortieVehiculeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public validateSortieVehiculeFormUsingPOST(id: string, sortieVehiculeRequestDto: SortieVehiculeRequestDto, options?: any) {
        return GardienFourriereControllerApiFp(this.configuration).validateSortieVehiculeFormUsingPOST(id, sortieVehiculeRequestDto, options)(this.fetch, this.basePath);
    }

}

//////////////////////////////////// HealthCheckControllerApi /////////////////////////////////////////////////////

/**
 * HealthCheckControllerApi - fetch parameter creator
 * @export
 */
export const HealthCheckControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * @summary RÃ©cupÃ©ration de l\'Ã©tat de fonctionnement des MS dossiers et rÃ©fÃ©rentiels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMicroServiceStatusUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/health`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthCheckControllerApi - functional programming interface
 * @export
 */
export const HealthCheckControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * @summary RÃ©cupÃ©ration de l\'Ã©tat de fonctionnement des MS dossiers et rÃ©fÃ©rentiels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMicroServiceStatusUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = HealthCheckControllerApiFetchParamCreator(configuration).getMicroServiceStatusUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * HealthCheckControllerApi - object-oriented interface
 * @export
 * @class HealthCheckControllerApi
 * @extends {BaseAPI}
 */
export class HealthCheckControllerApi extends BaseAPI {
    /**
     * @summary RÃ©cupÃ©ration de l\'Ã©tat de fonctionnement des MS dossiers et rÃ©fÃ©rentiels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getMicroServiceStatusUsingGET(options?: any) {
        return HealthCheckControllerApiFp(this.configuration).getMicroServiceStatusUsingGET(options)(this.fetch, this.basePath);
    }

}

//////////////////////////////////// ManuelControllerApi /////////////////////////////////////////////////////

/**
 * ManuelControllerApi - fetch parameter creator
 * @export
 */
export const ManuelControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant de tÃ©lÃ©charger un manuel Bdr
         * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile L\'utilisateur voulant tÃ©lÃ©charger le manuel, reprÃ©sentÃ© par un UserProfile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadManualBdrUsingGET(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', options: any = {}): FetchArgs {
            // verify required parameter 'userProfile' is not null or undefined
            if (userProfile === null || userProfile === undefined) {
                throw new RequiredError('userProfile','Required parameter userProfile was null or undefined when calling downloadManualBdrUsingGET.');
            }
            const localVarPath = `/api/manual/bdr/download/{userProfile}`
                .replace(`{${"userProfile"}}`, encodeURIComponent(String(userProfile)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de tÃ©lÃ©charger un manuel utilisateur
         * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile L\'utilisateur voulant tÃ©lÃ©charger le manuel, reprÃ©sentÃ© par un UserProfile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadManualUtilisateurUsingGET(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', options: any = {}): FetchArgs {
            // verify required parameter 'userProfile' is not null or undefined
            if (userProfile === null || userProfile === undefined) {
                throw new RequiredError('userProfile','Required parameter userProfile was null or undefined when calling downloadManualUtilisateurUsingGET.');
            }
            const localVarPath = `/api/manual/download/{userProfile}`
                .replace(`{${"userProfile"}}`, encodeURIComponent(String(userProfile)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de sauvegarder un manuel Bdr
         * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile Le type d\'utilisateur visÃ© par le manuel, reprÃ©sentÃ© par un UserProfile.
         * @param {any} file Le manuel, reprÃ©sentÃ© par un InputStream.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveManualBdrUsingPOST(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', file: any, options: any = {}): FetchArgs {
            // verify required parameter 'userProfile' is not null or undefined
            if (userProfile === null || userProfile === undefined) {
                throw new RequiredError('userProfile','Required parameter userProfile was null or undefined when calling saveManualBdrUsingPOST.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling saveManualBdrUsingPOST.');
            }
            const localVarPath = `/api/manual/bdr/{userProfile}`
                .replace(`{${"userProfile"}}`, encodeURIComponent(String(userProfile)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant de sauvegarder un manuel utilisateur
         * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile Le type d\'utilisateur visÃ© par le manuel, reprÃ©sentÃ© par un UserProfile.
         * @param {any} file Le manuel, reprÃ©sentÃ© par un InputStream.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveManualUtilisateurUsingPOST(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', file: any, options: any = {}): FetchArgs {
            // verify required parameter 'userProfile' is not null or undefined
            if (userProfile === null || userProfile === undefined) {
                throw new RequiredError('userProfile','Required parameter userProfile was null or undefined when calling saveManualUtilisateurUsingPOST.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling saveManualUtilisateurUsingPOST.');
            }
            const localVarPath = `/api/manual/{userProfile}`
                .replace(`{${"userProfile"}}`, encodeURIComponent(String(userProfile)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManuelControllerApi - functional programming interface
 * @export
 */
export const ManuelControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant de tÃ©lÃ©charger un manuel Bdr
         * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile L\'utilisateur voulant tÃ©lÃ©charger le manuel, reprÃ©sentÃ© par un UserProfile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadManualBdrUsingGET(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ManuelControllerApiFetchParamCreator(configuration).downloadManualBdrUsingGET(userProfile, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de tÃ©lÃ©charger un manuel utilisateur
         * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile L\'utilisateur voulant tÃ©lÃ©charger le manuel, reprÃ©sentÃ© par un UserProfile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadManualUtilisateurUsingGET(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ManuelControllerApiFetchParamCreator(configuration).downloadManualUtilisateurUsingGET(userProfile, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de sauvegarder un manuel Bdr
         * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile Le type d\'utilisateur visÃ© par le manuel, reprÃ©sentÃ© par un UserProfile.
         * @param {any} file Le manuel, reprÃ©sentÃ© par un InputStream.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveManualBdrUsingPOST(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', file: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ManuelControllerApiFetchParamCreator(configuration).saveManualBdrUsingPOST(userProfile, file, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant de sauvegarder un manuel utilisateur
         * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile Le type d\'utilisateur visÃ© par le manuel, reprÃ©sentÃ© par un UserProfile.
         * @param {any} file Le manuel, reprÃ©sentÃ© par un InputStream.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveManualUtilisateurUsingPOST(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', file: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ManuelControllerApiFetchParamCreator(configuration).saveManualUtilisateurUsingPOST(userProfile, file, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ManuelControllerApi - object-oriented interface
 * @export
 * @class ManuelControllerApi
 * @extends {BaseAPI}
 */
export class ManuelControllerApi extends BaseAPI {
    /**
     * @summary Route permettant de tÃ©lÃ©charger un manuel Bdr
     * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile L\'utilisateur voulant tÃ©lÃ©charger le manuel, reprÃ©sentÃ© par un UserProfile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public downloadManualBdrUsingGET(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', options?: any) {
        return ManuelControllerApiFp(this.configuration).downloadManualBdrUsingGET(userProfile, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de tÃ©lÃ©charger un manuel utilisateur
     * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile L\'utilisateur voulant tÃ©lÃ©charger le manuel, reprÃ©sentÃ© par un UserProfile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public downloadManualUtilisateurUsingGET(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', options?: any) {
        return ManuelControllerApiFp(this.configuration).downloadManualUtilisateurUsingGET(userProfile, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de sauvegarder un manuel Bdr
     * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile Le type d\'utilisateur visÃ© par le manuel, reprÃ©sentÃ© par un UserProfile.
     * @param {any} file Le manuel, reprÃ©sentÃ© par un InputStream.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public saveManualBdrUsingPOST(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', file: any, options?: any) {
        return ManuelControllerApiFp(this.configuration).saveManualBdrUsingPOST(userProfile, file, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant de sauvegarder un manuel utilisateur
     * @param {'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU'} userProfile Le type d\'utilisateur visÃ© par le manuel, reprÃ©sentÃ© par un UserProfile.
     * @param {any} file Le manuel, reprÃ©sentÃ© par un InputStream.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public saveManualUtilisateurUsingPOST(userProfile: 'AUTORITE_FOURRIERE' | 'DSR_BLR' | 'FORCE_DE_L_ORDRE' | 'GARDIEN_FOURRIERE' | 'ADMIN_FONCTIONNEL' | 'CSU', file: any, options?: any) {
        return ManuelControllerApiFp(this.configuration).saveManualUtilisateurUsingPOST(userProfile, file, options)(this.fetch, this.basePath);
    }

}

//////////////////////////////////// ReferentielControllerApi /////////////////////////////////////////////////////

/**
 * objet contenant les query params de la route getAllInformationMessageUsingGET
 * @export
 * @interface GetAllInformationMessageUsingGETQueryParams
 */
export interface GetAllInformationMessageUsingGETQueryParams {
    /**
     * 
     * @type {number}
     * @memberof GetAllInformationMessageUsingGETQueryParams
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAllInformationMessageUsingGETQueryParams
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAllInformationMessageUsingGETQueryParams
     */
    search?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllInformationMessageUsingGETQueryParams
     */
    sortByEndDate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllInformationMessageUsingGETQueryParams
     */
    sortByStartDate?: boolean;
}

/**
 * objet contenant les query params de la route getOnlyValidFaqsUsingGET
 * @export
 * @interface GetOnlyValidFaqsUsingGETQueryParams
 */
export interface GetOnlyValidFaqsUsingGETQueryParams {
    /**
     * Si spÃ©cifiÃ©, filtre les faqs qui sont associÃ©es ou non Ã  un domaine
     * @type {boolean}
     * @memberof GetOnlyValidFaqsUsingGETQueryParams
     */
    domaine: boolean;
}

/**
 * objet contenant les query params de la route searchAutoriteFourriereUsingSpecsUsingGET
 * @export
 * @interface SearchAutoriteFourriereUsingSpecsUsingGETQueryParams
 */
export interface SearchAutoriteFourriereUsingSpecsUsingGETQueryParams {
    /**
     * objet contenant les filters de SearchAutoriteFourriereUsingSpecsUsingGETQueryParams
     * @type {SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters}
     * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParams
     */
    filters: SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters;
    /**
     * objet contenant les sorts de SearchAutoriteFourriereUsingSpecsUsingGETQueryParams
     * @type {SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsSorts}
     * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParams
     */
    sorts: SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsSorts;
}

export interface SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters {
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    codeDepartement?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    codePostal?: string;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    dateFromStartOfValidity?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    dateToStartOfValidity?: Date;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    emptyIdHermes?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    emptyIdHermesUtilisateur?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    enabled?: boolean;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    idCorrelation?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    idCorrelationTypeAutorite?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    idHermes?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    idHermesUtilisateur?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    libelle?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    libelleDepartement?: string;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    page?: number;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    pageSize?: number;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    ville?: string;
}

export interface SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsSorts {
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsSorts
    */
    codePostal?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsSorts
    */
    departement?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsSorts
    */
    libelle?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsSorts
    */
    type?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchAutoriteFourriereUsingSpecsUsingGETQueryParamsSorts
    */
    ville?: boolean;
}

/**
 * objet contenant les query params de la route searchCentreVHUUsingSpecsUsingGET
 * @export
 * @interface SearchCentreVHUUsingSpecsUsingGETQueryParams
 */
export interface SearchCentreVHUUsingSpecsUsingGETQueryParams {
    /**
     * objet contenant les filters de SearchCentreVHUUsingSpecsUsingGETQueryParams
     * @type {SearchCentreVHUUsingSpecsUsingGETQueryParamsFilters}
     * @memberof SearchCentreVHUUsingSpecsUsingGETQueryParams
     */
    filters: SearchCentreVHUUsingSpecsUsingGETQueryParamsFilters;
    /**
     * objet contenant les sorts de SearchCentreVHUUsingSpecsUsingGETQueryParams
     * @type {SearchCentreVHUUsingSpecsUsingGETQueryParamsSorts}
     * @memberof SearchCentreVHUUsingSpecsUsingGETQueryParams
     */
    sorts: SearchCentreVHUUsingSpecsUsingGETQueryParamsSorts;
}

export interface SearchCentreVHUUsingSpecsUsingGETQueryParamsFilters {
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchCentreVHUUsingSpecsUsingGETQueryParamsFilters
    */
    codePostal?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchCentreVHUUsingSpecsUsingGETQueryParamsFilters
    */
    commune?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchCentreVHUUsingSpecsUsingGETQueryParamsFilters
    */
    idCorrelation?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchCentreVHUUsingSpecsUsingGETQueryParamsFilters
    */
    numeroAgrementVHU?: string;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchCentreVHUUsingSpecsUsingGETQueryParamsFilters
    */
    page?: number;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchCentreVHUUsingSpecsUsingGETQueryParamsFilters
    */
    pageSize?: number;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchCentreVHUUsingSpecsUsingGETQueryParamsFilters
    */
    raisonSociale?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchCentreVHUUsingSpecsUsingGETQueryParamsFilters
    */
    siren?: string;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchCentreVHUUsingSpecsUsingGETQueryParamsFilters
    */
    valid?: boolean;
}

export interface SearchCentreVHUUsingSpecsUsingGETQueryParamsSorts {
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchCentreVHUUsingSpecsUsingGETQueryParamsSorts
    */
    codePostal?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchCentreVHUUsingSpecsUsingGETQueryParamsSorts
    */
    commune?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchCentreVHUUsingSpecsUsingGETQueryParamsSorts
    */
    numeroAgrementVHU?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchCentreVHUUsingSpecsUsingGETQueryParamsSorts
    */
    raisonSociale?: boolean;
}

/**
 * objet contenant les query params de la route searchCommuneEnlevementUsingGET
 * @export
 * @interface SearchCommuneEnlevementUsingGETQueryParams
 */
export interface SearchCommuneEnlevementUsingGETQueryParams {
    /**
     * Chaine de caractÃ¨re de recherche d\'une commune
     * @type {string}
     * @memberof SearchCommuneEnlevementUsingGETQueryParams
     */
    searchStr: string;
}

/**
 * objet contenant les query params de la route searchFourriereUsingSpecsUsingGET
 * @export
 * @interface SearchFourriereUsingSpecsUsingGETQueryParams
 */
export interface SearchFourriereUsingSpecsUsingGETQueryParams {
    /**
     * objet contenant les filters de SearchFourriereUsingSpecsUsingGETQueryParams
     * @type {SearchFourriereUsingSpecsUsingGETQueryParamsFilters}
     * @memberof SearchFourriereUsingSpecsUsingGETQueryParams
     */
    filters: SearchFourriereUsingSpecsUsingGETQueryParamsFilters;
    /**
     * objet contenant les sorts de SearchFourriereUsingSpecsUsingGETQueryParams
     * @type {SearchFourriereUsingSpecsUsingGETQueryParamsSorts}
     * @memberof SearchFourriereUsingSpecsUsingGETQueryParams
     */
    sorts: SearchFourriereUsingSpecsUsingGETQueryParamsSorts;
}

export interface SearchFourriereUsingSpecsUsingGETQueryParamsFilters {
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    codePostal?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    commune?: string;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    dateFromStartOfValidity?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    dateToStartOfValidity?: Date;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    emptyIdHermes?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    enabled?: boolean;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    idCorrelation?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    idCorrelationAF?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    idHermes?: string;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    page?: number;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    pageSize?: number;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchFourriereUsingSpecsUsingGETQueryParamsFilters
    */
    raisonSociale?: string;
}

export interface SearchFourriereUsingSpecsUsingGETQueryParamsSorts {
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchFourriereUsingSpecsUsingGETQueryParamsSorts
    */
    codePostal?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchFourriereUsingSpecsUsingGETQueryParamsSorts
    */
    commune?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchFourriereUsingSpecsUsingGETQueryParamsSorts
    */
    raisonSociale?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchFourriereUsingSpecsUsingGETQueryParamsSorts
    */
    raisonSocialeDnid?: boolean;
}

/**
 * objet contenant les query params de la route searchMarqueUsingSpecsUsingGET
 * @export
 * @interface SearchMarqueUsingSpecsUsingGETQueryParams
 */
export interface SearchMarqueUsingSpecsUsingGETQueryParams {
    /**
     * objet contenant les filters de SearchMarqueUsingSpecsUsingGETQueryParams
     * @type {SearchMarqueUsingSpecsUsingGETQueryParamsFilters}
     * @memberof SearchMarqueUsingSpecsUsingGETQueryParams
     */
    filters: SearchMarqueUsingSpecsUsingGETQueryParamsFilters;
    /**
     * objet contenant les sorts de SearchMarqueUsingSpecsUsingGETQueryParams
     * @type {SearchMarqueUsingSpecsUsingGETQueryParamsSorts}
     * @memberof SearchMarqueUsingSpecsUsingGETQueryParams
     */
    sorts: SearchMarqueUsingSpecsUsingGETQueryParamsSorts;
}

export interface SearchMarqueUsingSpecsUsingGETQueryParamsFilters {
    /**
    * 
    * @type {'A_CATEGORISER' | 'PREMIUM' | 'NON_VALORISABLE' | undefined}
    * @memberof SearchMarqueUsingSpecsUsingGETQueryParamsFilters
    */
    categorie?: 'A_CATEGORISER' | 'PREMIUM' | 'NON_VALORISABLE';
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchMarqueUsingSpecsUsingGETQueryParamsFilters
    */
    dateFromEndOfValidity?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchMarqueUsingSpecsUsingGETQueryParamsFilters
    */
    dateFromStartOfValidity?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchMarqueUsingSpecsUsingGETQueryParamsFilters
    */
    dateToEndOfValidity?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchMarqueUsingSpecsUsingGETQueryParamsFilters
    */
    dateToStartOfValidity?: Date;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchMarqueUsingSpecsUsingGETQueryParamsFilters
    */
    id?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchMarqueUsingSpecsUsingGETQueryParamsFilters
    */
    libelle?: string;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchMarqueUsingSpecsUsingGETQueryParamsFilters
    */
    page?: number;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchMarqueUsingSpecsUsingGETQueryParamsFilters
    */
    pageSize?: number;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchMarqueUsingSpecsUsingGETQueryParamsFilters
    */
    prioritaire?: boolean;
    /**
    * 
    * @type {Array<'EXACTE' | 'APPROXIMATIVE' | 'INCONNUE'> | undefined}
    * @memberof SearchMarqueUsingSpecsUsingGETQueryParamsFilters
    */
    types?: Array<'EXACTE' | 'APPROXIMATIVE' | 'INCONNUE'>;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchMarqueUsingSpecsUsingGETQueryParamsFilters
    */
    valid?: boolean;
}

export interface SearchMarqueUsingSpecsUsingGETQueryParamsSorts {
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchMarqueUsingSpecsUsingGETQueryParamsSorts
    */
    categorie?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchMarqueUsingSpecsUsingGETQueryParamsSorts
    */
    libelle?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchMarqueUsingSpecsUsingGETQueryParamsSorts
    */
    type?: boolean;
}

/**
 * objet contenant les query params de la route searchModeleUsingSpecsUsingGET
 * @export
 * @interface SearchModeleUsingSpecsUsingGETQueryParams
 */
export interface SearchModeleUsingSpecsUsingGETQueryParams {
    /**
     * objet contenant les filters de SearchModeleUsingSpecsUsingGETQueryParams
     * @type {SearchModeleUsingSpecsUsingGETQueryParamsFilters}
     * @memberof SearchModeleUsingSpecsUsingGETQueryParams
     */
    filters: SearchModeleUsingSpecsUsingGETQueryParamsFilters;
    /**
     * objet contenant les sorts de SearchModeleUsingSpecsUsingGETQueryParams
     * @type {SearchModeleUsingSpecsUsingGETQueryParamsSorts}
     * @memberof SearchModeleUsingSpecsUsingGETQueryParams
     */
    sorts: SearchModeleUsingSpecsUsingGETQueryParamsSorts;
}

export interface SearchModeleUsingSpecsUsingGETQueryParamsFilters {
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchModeleUsingSpecsUsingGETQueryParamsFilters
    */
    dateFromEndOfValidity?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchModeleUsingSpecsUsingGETQueryParamsFilters
    */
    dateFromStartOfValidity?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchModeleUsingSpecsUsingGETQueryParamsFilters
    */
    dateToEndOfValidity?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchModeleUsingSpecsUsingGETQueryParamsFilters
    */
    dateToStartOfValidity?: Date;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchModeleUsingSpecsUsingGETQueryParamsFilters
    */
    libelle?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchModeleUsingSpecsUsingGETQueryParamsFilters
    */
    marqueId?: string;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchModeleUsingSpecsUsingGETQueryParamsFilters
    */
    page?: number;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchModeleUsingSpecsUsingGETQueryParamsFilters
    */
    pageSize?: number;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchModeleUsingSpecsUsingGETQueryParamsFilters
    */
    prioritaire?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchModeleUsingSpecsUsingGETQueryParamsFilters
    */
    valid?: boolean;
}

export interface SearchModeleUsingSpecsUsingGETQueryParamsSorts {
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchModeleUsingSpecsUsingGETQueryParamsSorts
    */
    libelle?: boolean;
}

/**
 * objet contenant les query params de la route searchMotifMefUsingSpecsUsingGET
 * @export
 * @interface SearchMotifMefUsingSpecsUsingGETQueryParams
 */
export interface SearchMotifMefUsingSpecsUsingGETQueryParams {
    /**
     * objet contenant les filters de SearchMotifMefUsingSpecsUsingGETQueryParams
     * @type {SearchMotifMefUsingSpecsUsingGETQueryParamsFilters}
     * @memberof SearchMotifMefUsingSpecsUsingGETQueryParams
     */
    filters: SearchMotifMefUsingSpecsUsingGETQueryParamsFilters;
    /**
     * objet contenant les sorts de SearchMotifMefUsingSpecsUsingGETQueryParams
     * @type {SearchMotifMefUsingSpecsUsingGETQueryParamsSorts}
     * @memberof SearchMotifMefUsingSpecsUsingGETQueryParams
     */
    sorts: SearchMotifMefUsingSpecsUsingGETQueryParamsSorts;
}

export interface SearchMotifMefUsingSpecsUsingGETQueryParamsFilters {
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchMotifMefUsingSpecsUsingGETQueryParamsFilters
    */
    dateFromStartOfValidity?: Date;
    /**
    * 
    * @type {Date | undefined}
    * @memberof SearchMotifMefUsingSpecsUsingGETQueryParamsFilters
    */
    dateToStartOfValidity?: Date;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchMotifMefUsingSpecsUsingGETQueryParamsFilters
    */
    enabled?: boolean;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchMotifMefUsingSpecsUsingGETQueryParamsFilters
    */
    idCorrelation?: string;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchMotifMefUsingSpecsUsingGETQueryParamsFilters
    */
    isDicem?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchMotifMefUsingSpecsUsingGETQueryParamsFilters
    */
    isLieuDitEnabled?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchMotifMefUsingSpecsUsingGETQueryParamsFilters
    */
    isRodeo?: boolean;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchMotifMefUsingSpecsUsingGETQueryParamsFilters
    */
    libelle?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchMotifMefUsingSpecsUsingGETQueryParamsFilters
    */
    natureLibelleCourt?: string;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchMotifMefUsingSpecsUsingGETQueryParamsFilters
    */
    page?: number;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchMotifMefUsingSpecsUsingGETQueryParamsFilters
    */
    pageSize?: number;
}

export interface SearchMotifMefUsingSpecsUsingGETQueryParamsSorts {
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchMotifMefUsingSpecsUsingGETQueryParamsSorts
    */
    libelle?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchMotifMefUsingSpecsUsingGETQueryParamsSorts
    */
    natureMef?: boolean;
}

/**
 * objet contenant les query params de la route searchUniteFoUsingSpecsUsingGET
 * @export
 * @interface SearchUniteFoUsingSpecsUsingGETQueryParams
 */
export interface SearchUniteFoUsingSpecsUsingGETQueryParams {
    /**
     * objet contenant les filters de SearchUniteFoUsingSpecsUsingGETQueryParams
     * @type {SearchUniteFoUsingSpecsUsingGETQueryParamsFilters}
     * @memberof SearchUniteFoUsingSpecsUsingGETQueryParams
     */
    filters: SearchUniteFoUsingSpecsUsingGETQueryParamsFilters;
    /**
     * objet contenant les sorts de SearchUniteFoUsingSpecsUsingGETQueryParams
     * @type {SearchUniteFoUsingSpecsUsingGETQueryParamsSorts}
     * @memberof SearchUniteFoUsingSpecsUsingGETQueryParams
     */
    sorts: SearchUniteFoUsingSpecsUsingGETQueryParamsSorts;
}

export interface SearchUniteFoUsingSpecsUsingGETQueryParamsFilters {
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsFilters
    */
    abreviation?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsFilters
    */
    code?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsFilters
    */
    codePostal?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsFilters
    */
    idCorrelation?: string;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsFilters
    */
    page?: number;
    /**
    * 
    * @type {number | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsFilters
    */
    pageSize?: number;
    /**
    * 
    * @type {'NOUVELLE_UNITE' | 'ECHEC_DESACTIVATION_DOSSIER_ATTACHE' | 'ECHEC_DESACTIVATION_UNITE_PARENT' | 'MISE_A_JOUR_ERREUR_VALIDATION' | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsFilters
    */
    rpsiStatus?: 'NOUVELLE_UNITE' | 'ECHEC_DESACTIVATION_DOSSIER_ATTACHE' | 'ECHEC_DESACTIVATION_UNITE_PARENT' | 'MISE_A_JOUR_ERREUR_VALIDATION';
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsFilters
    */
    type?: string;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsFilters
    */
    unite?: string;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsFilters
    */
    valid?: boolean;
    /**
    * 
    * @type {string | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsFilters
    */
    ville?: string;
}

export interface SearchUniteFoUsingSpecsUsingGETQueryParamsSorts {
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsSorts
    */
    abreviation?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsSorts
    */
    code?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsSorts
    */
    codeInsee?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsSorts
    */
    codePostal?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsSorts
    */
    type?: boolean;
    /**
    * 
    * @type {boolean | undefined}
    * @memberof SearchUniteFoUsingSpecsUsingGETQueryParamsSorts
    */
    ville?: boolean;
}

/**
 * objet contenant les query params de la route searchedValidUniteFOUsingGET
 * @export
 * @interface SearchedValidUniteFOUsingGETQueryParams
 */
export interface SearchedValidUniteFOUsingGETQueryParams {
    /**
     * Champs du rÃ©fÃ©rentiel unitÃ© sur lequel la recherche est effectuÃ©e
     * @type {'CODE' | 'UNITE' | 'ABREVIATION'}
     * @memberof SearchedValidUniteFOUsingGETQueryParams
     */
    field: 'CODE' | 'UNITE' | 'ABREVIATION';
    /**
     * Mots clef de recherche.
     * @type {string}
     * @memberof SearchedValidUniteFOUsingGETQueryParams
     */
    searched: string;
}

/**
 * ReferentielControllerApi - fetch parameter creator
 * @export
 */
export const ReferentielControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * @summary Route pour crÃ©er un bandeau d\'information Ã  afficher.
         * @param {InformationMessageRequestDto} informationMessageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInformationMessageUsingPOST(informationMessageRequestDto: InformationMessageRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'informationMessageRequestDto' is not null or undefined
            if (informationMessageRequestDto === null || informationMessageRequestDto === undefined) {
                throw new RequiredError('informationMessageRequestDto','Required parameter informationMessageRequestDto was null or undefined when calling createInformationMessageUsingPOST.');
            }
            const localVarPath = `/api/referentiel/message-information`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InformationMessageRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(informationMessageRequestDto || {}) : (informationMessageRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant d\'exporter un rÃ©fÃ©rentiel au format csv.
         * @param {'AUTORITE_FOURRIERE' | 'BRANCHE' | 'CENTRE_VHU' | 'ETAT' | 'FOURRIERE' | 'FOOTER_LINK' | 'GENRE_SIMPLIFIE' | 'MOTIF_MEF' | 'MOTIF_SORTIE' | 'TYPE_AF' | 'UNITE_FO' | 'FAQ' | 'VIDEO_TUTORIEL'} referentiel Nom du referentiel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCSVReferentielUsingGET(referentiel: 'AUTORITE_FOURRIERE' | 'BRANCHE' | 'CENTRE_VHU' | 'ETAT' | 'FOURRIERE' | 'FOOTER_LINK' | 'GENRE_SIMPLIFIE' | 'MOTIF_MEF' | 'MOTIF_SORTIE' | 'TYPE_AF' | 'UNITE_FO' | 'FAQ' | 'VIDEO_TUTORIEL', options: any = {}): FetchArgs {
            // verify required parameter 'referentiel' is not null or undefined
            if (referentiel === null || referentiel === undefined) {
                throw new RequiredError('referentiel','Required parameter referentiel was null or undefined when calling exportCSVReferentielUsingGET.');
            }
            const localVarPath = `/api/referentiel/export/csv/{referentiel}`
                .replace(`{${"referentiel"}}`, encodeURIComponent(String(referentiel)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant d\'exporter un rÃ©fÃ©rentiel au format json.
         * @param {'CATEGORIE'} referentiel Nom du referentiel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportJSONReferentielUsingGET(referentiel: 'CATEGORIE', options: any = {}): FetchArgs {
            // verify required parameter 'referentiel' is not null or undefined
            if (referentiel === null || referentiel === undefined) {
                throw new RequiredError('referentiel','Required parameter referentiel was null or undefined when calling exportJSONReferentielUsingGET.');
            }
            const localVarPath = `/api/referentiel/export/json/{referentiel}`
                .replace(`{${"referentiel"}}`, encodeURIComponent(String(referentiel)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les couleurs de vÃ©hicule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCouleurUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/couleur`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les Ã©nergies.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEnergieUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/energie`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les bandeaux d\'information
         * @param {getAllInformationMessageUsingGETQueryParams} queryParams objet contenant les query params de la route getAllInformationMessageUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInformationMessageUsingGET(queryParams: GetAllInformationMessageUsingGETQueryParams, options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/message-information`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.page !== undefined) {
                localVarQueryParameter['page'] = queryParams.page;
            }

            if (queryParams.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = queryParams.pageSize;
            }

            if (queryParams.search !== undefined) {
                localVarQueryParameter['search'] = queryParams.search;
            }

            if (queryParams.sortByEndDate !== undefined) {
                localVarQueryParameter['sortByEndDate'] = queryParams.sortByEndDate;
            }

            if (queryParams.sortByStartDate !== undefined) {
                localVarQueryParameter['sortByStartDate'] = queryParams.sortByStartDate;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les modÃ¨les de vÃ©hicule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMarqueModeleUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/modele`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les centres VHU valides.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllValidCentreVhuUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/centre-vhu`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les marques valides.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllValidMarqueUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/marque`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les natures de mise en fourriÃ¨re et les branches de workflow associÃ©es.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllValidNaturesUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/nature-mef-branche`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les prÃ©cisions pour un Ã©tat donnÃ©.
         * @param {'BON_ETAT' | 'DEGRADE' | 'DOMMAGES_GRAVES'} etat LibellÃ© de l\'Ã©tat.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailFromEtatUsingGET(etat: 'BON_ETAT' | 'DEGRADE' | 'DOMMAGES_GRAVES', options: any = {}): FetchArgs {
            // verify required parameter 'etat' is not null or undefined
            if (etat === null || etat === undefined) {
                throw new RequiredError('etat','Required parameter etat was null or undefined when calling getDetailFromEtatUsingGET.');
            }
            const localVarPath = `/api/referentiel/etat-detail/{etat}`
                .replace(`{${"etat"}}`, encodeURIComponent(String(etat)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant un Ã©tat par son id.
         * @param {string} id ID de l\'Ã©tat, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEtatByIdUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getEtatByIdUsingGET.');
            }
            const localVarPath = `/api/referentiel/etat/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les diffÃ©rents Ã©tats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEtatsVehiculeUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/etats-vehicule`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rercherchant une question en fonction de son ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqByIdUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getFaqByIdUsingGET.');
            }
            const localVarPath = `/api/referentiel/foire-aux-questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant une marque par son id.
         * @param {string} id ID de la marque, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarqueByIdUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getMarqueByIdUsingGET.');
            }
            const localVarPath = `/api/referentiel/marque/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les modÃ¨les de vÃ©hicule pour l\'idCorrelation d\'une marque donnÃ©e.
         * @param {string} idCorrelation ID de la marque, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModeleByMarqueIdCorrelationUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling getModeleByMarqueIdCorrelationUsingGET.');
            }
            const localVarPath = `/api/referentiel/modele/marqueIdCorrelation/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les modÃ¨les de vÃ©hicule pour l\'id d\'une marque donnÃ©e.
         * @param {string} id ID de la marque, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModeleByMarqueUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getModeleByMarqueUsingGET.');
            }
            const localVarPath = `/api/referentiel/modele/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant un motif de mise en fourriÃ¨re par son id.
         * @param {string} id ID du motif de mef, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMotifMefByIdUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getMotifMefByIdUsingGET.');
            }
            const localVarPath = `/api/referentiel/motif-mef/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les questions valides, regroupÃ©es par domaines.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlyValidFaqByDomainsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/foire-aux-questions/domaine`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rercherchant une question en fonction de son idCorrelation.
         * @param {string} idCorrelation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlyValidFaqDtoUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling getOnlyValidFaqDtoUsingGET.');
            }
            const localVarPath = `/api/referentiel/foire-aux-questions/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les questions valides, avec ou sans domaine.
         * @param {getOnlyValidFaqsUsingGETQueryParams} queryParams objet contenant les query params de la route getOnlyValidFaqsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlyValidFaqsUsingGET(queryParams: GetOnlyValidFaqsUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'domaine' is not null or undefined
            if (queryParams.domaine === null || queryParams.domaine === undefined) {
                throw new RequiredError('domaine','Required parameter domaine was null or undefined when calling getOnlyValidFaqsUsingGET.');
            }
            const localVarPath = `/api/referentiel/foire-aux-questions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.domaine !== undefined) {
                localVarQueryParameter['domaine'] = queryParams.domaine;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les liens du pied de page valides.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlyValidFooterLinkUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/lien-footer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les bandeaux d\'information actif pour le profil donnÃ©.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlyValidInformationMessageByProfileUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/message-information/profile`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les bandeaux d\'information actif pour Bord de Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlyValidInformationMessageForBDRUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/message-information/bord-de-route`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les statuts consultables selon le profil.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatutsConsultablesUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/statut-consultable`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route recherchant une autoritÃ©s de fourriÃ¨re par son idCorrelation.
         * @param {string} idCorrelation ID de l\'autoritÃ©s de fourriÃ¨re, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidAutoriteFourriereByIdCorrelationUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling getValidAutoriteFourriereByIdCorrelationUsingGET.');
            }
            const localVarPath = `/api/referentiel/autorite-fourriere/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les autoritÃ©s de fourriÃ¨re.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidAutoriteFourriereUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/autorite-fourriere`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les autoritÃ©s fourriÃ¨re d\'une fourriÃ¨re via son idCorrelation.
         * @param {string} idCorrelationFourriere IdCorrelation de la fourriÃ¨re.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidAutoritesFourriereOfFourriereUsingGET(idCorrelationFourriere: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelationFourriere' is not null or undefined
            if (idCorrelationFourriere === null || idCorrelationFourriere === undefined) {
                throw new RequiredError('idCorrelationFourriere','Required parameter idCorrelationFourriere was null or undefined when calling getValidAutoritesFourriereOfFourriereUsingGET.');
            }
            const localVarPath = `/api/referentiel/fourriere/{idCorrelationFourriere}/autoritesfourriere`
                .replace(`{${"idCorrelationFourriere"}}`, encodeURIComponent(String(idCorrelationFourriere)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les carrosseries utilisÃ©es pour le classement manuel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidCarrosserieClassementManuelUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/carrosserie/classement-manuel`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les carrosseries de vÃ©hicule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidCarrosserieUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/carrosserie`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les fourriÃ¨re.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidFourriereUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/fourriere`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les genres de vÃ©hicule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidGenreUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/genre`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les genres simplifiÃ©s de vÃ©hicules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidGenresSimplifiesUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/genre-simplifie`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les motifs de mise en fourriÃ¨re.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidMotifMefUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/motif-mef`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les motifs de sortie.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidMotifSortieUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/motif-sortie`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les types d\'autoritÃ©s de fourriÃ¨re.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidTypeAutoriteFourriereUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/type-autorite-fourriere`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route recherchant une unitÃ© force de l\'ordre via son idCorrelation.
         * @param {string} idCorrelation ID de l\'unitÃ©, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidUniteFOByIdCorrelationUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling getValidUniteFOByIdCorrelationUsingGET.');
            }
            const localVarPath = `/api/referentiel/unite-fo/{idCorrelation}`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les unitÃ©s FO liÃ©es au profil FO utilisÃ© (unitÃ© parente + enfants), via l\'ideCorrelation de l\'unitÃ© parente
         * @param {string} idCorrelation idCorrelation de l\'unitÃ© parente, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidUniteFOWithChildrenUsingGET(idCorrelation: string, options: any = {}): FetchArgs {
            // verify required parameter 'idCorrelation' is not null or undefined
            if (idCorrelation === null || idCorrelation === undefined) {
                throw new RequiredError('idCorrelation','Required parameter idCorrelation was null or undefined when calling getValidUniteFOWithChildrenUsingGET.');
            }
            const localVarPath = `/api/referentiel/unite-fo/{idCorrelation}/parents-et-enfants`
                .replace(`{${"idCorrelation"}}`, encodeURIComponent(String(idCorrelation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les vidÃ©os tutoriels.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoTutorielUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/referentiel/video-tutoriel`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant d\'importer un rÃ©fÃ©rentiel au format csv.
         * @param {'BRANCHE' | 'ETAT' | 'FOOTER_LINK' | 'GENRE_SIMPLIFIE' | 'VIDEO_TUTORIEL'} referentiel Nom du referentiel.
         * @param {any} file Fichier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCSVReferentielUsingPOST(referentiel: 'BRANCHE' | 'ETAT' | 'FOOTER_LINK' | 'GENRE_SIMPLIFIE' | 'VIDEO_TUTORIEL', file: any, options: any = {}): FetchArgs {
            // verify required parameter 'referentiel' is not null or undefined
            if (referentiel === null || referentiel === undefined) {
                throw new RequiredError('referentiel','Required parameter referentiel was null or undefined when calling importCSVReferentielUsingPOST.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling importCSVReferentielUsingPOST.');
            }
            const localVarPath = `/api/referentiel/import/csv/{referentiel}`
                .replace(`{${"referentiel"}}`, encodeURIComponent(String(referentiel)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route permettant d\'importer un rÃ©fÃ©rentiel au format json.
         * @param {'CATEGORIE'} referentiel Nom du referentiel.
         * @param {any} file Fichier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importJSONReferentielUsingPOST(referentiel: 'CATEGORIE', file: any, options: any = {}): FetchArgs {
            // verify required parameter 'referentiel' is not null or undefined
            if (referentiel === null || referentiel === undefined) {
                throw new RequiredError('referentiel','Required parameter referentiel was null or undefined when calling importJSONReferentielUsingPOST.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling importJSONReferentielUsingPOST.');
            }
            const localVarPath = `/api/referentiel/import/json/{referentiel}`
                .replace(`{${"referentiel"}}`, encodeURIComponent(String(referentiel)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route pour valider un champ du formulaire de mise Ã  jour du bandeau d\'information Ã  afficher.
         * @param {'TITRE' | 'CONTENT' | 'START_DATE' | 'END_DATE' | 'VALID' | 'PROFILES'} field 
         * @param {string} id 
         * @param {InformationMessageRequestDto} informationMessageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        informationMessageValidateFieldUsingPOST(field: 'TITRE' | 'CONTENT' | 'START_DATE' | 'END_DATE' | 'VALID' | 'PROFILES', id: string, informationMessageRequestDto: InformationMessageRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling informationMessageValidateFieldUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling informationMessageValidateFieldUsingPOST.');
            }
            // verify required parameter 'informationMessageRequestDto' is not null or undefined
            if (informationMessageRequestDto === null || informationMessageRequestDto === undefined) {
                throw new RequiredError('informationMessageRequestDto','Required parameter informationMessageRequestDto was null or undefined when calling informationMessageValidateFieldUsingPOST.');
            }
            const localVarPath = `/api/referentiel/message-information/{id}/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InformationMessageRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(informationMessageRequestDto || {}) : (informationMessageRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route pour valider un champ du formulaire de crÃ©ation d\'un bandeau d\'information Ã  afficher.
         * @param {'TITRE' | 'CONTENT' | 'START_DATE' | 'END_DATE' | 'VALID' | 'PROFILES'} field 
         * @param {InformationMessageRequestDto} informationMessageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        informationMessageValidateFieldUsingPOST1(field: 'TITRE' | 'CONTENT' | 'START_DATE' | 'END_DATE' | 'VALID' | 'PROFILES', informationMessageRequestDto: InformationMessageRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling informationMessageValidateFieldUsingPOST1.');
            }
            // verify required parameter 'informationMessageRequestDto' is not null or undefined
            if (informationMessageRequestDto === null || informationMessageRequestDto === undefined) {
                throw new RequiredError('informationMessageRequestDto','Required parameter informationMessageRequestDto was null or undefined when calling informationMessageValidateFieldUsingPOST1.');
            }
            const localVarPath = `/api/referentiel/message-information/validation/{field}`
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InformationMessageRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(informationMessageRequestDto || {}) : (informationMessageRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route pour valider un formulaire de crÃ©ation d\'un bandeau d\'information Ã  afficher.
         * @param {InformationMessageRequestDto} informationMessageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        informationMessageValidateUsingPOST(informationMessageRequestDto: InformationMessageRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'informationMessageRequestDto' is not null or undefined
            if (informationMessageRequestDto === null || informationMessageRequestDto === undefined) {
                throw new RequiredError('informationMessageRequestDto','Required parameter informationMessageRequestDto was null or undefined when calling informationMessageValidateUsingPOST.');
            }
            const localVarPath = `/api/referentiel/message-information/validation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InformationMessageRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(informationMessageRequestDto || {}) : (informationMessageRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche d\'une liste d\'autoritÃ©s de fourriÃ¨re filtrÃ©e et triÃ©e
         * @param {searchAutoriteFourriereUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchAutoriteFourriereUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAutoriteFourriereUsingSpecsUsingGET(queryParams: SearchAutoriteFourriereUsingSpecsUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'filters' is not null or undefined
            if (queryParams.filters === null || queryParams.filters === undefined) {
                throw new RequiredError('filters','Required parameter filters was null or undefined when calling searchAutoriteFourriereUsingSpecsUsingGET.');
            }
            // verify required parameter 'sorts' is not null or undefined
            if (queryParams.sorts === null || queryParams.sorts === undefined) {
                throw new RequiredError('sorts','Required parameter sorts was null or undefined when calling searchAutoriteFourriereUsingSpecsUsingGET.');
            }
            const localVarPath = `/api/referentiel/autorite-fourriere/with-specs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.filters.codeDepartement !== undefined) {
                localVarQueryParameter['filters.codeDepartement'] = queryParams.filters.codeDepartement;
            }
            if (queryParams.filters.codePostal !== undefined) {
                localVarQueryParameter['filters.codePostal'] = queryParams.filters.codePostal;
            }
            if (queryParams.filters.dateFromStartOfValidity !== undefined) {
                localVarQueryParameter['filters.dateFromStartOfValidity'] = (queryParams.filters.dateFromStartOfValidity as any).toISOString();
            }
            if (queryParams.filters.dateToStartOfValidity !== undefined) {
                localVarQueryParameter['filters.dateToStartOfValidity'] = (queryParams.filters.dateToStartOfValidity as any).toISOString();
            }
            if (queryParams.filters.emptyIdHermes !== undefined) {
                localVarQueryParameter['filters.emptyIdHermes'] = queryParams.filters.emptyIdHermes;
            }
            if (queryParams.filters.emptyIdHermesUtilisateur !== undefined) {
                localVarQueryParameter['filters.emptyIdHermesUtilisateur'] = queryParams.filters.emptyIdHermesUtilisateur;
            }
            if (queryParams.filters.enabled !== undefined) {
                localVarQueryParameter['filters.enabled'] = queryParams.filters.enabled;
            }
            if (queryParams.filters.idCorrelation !== undefined) {
                localVarQueryParameter['filters.idCorrelation'] = queryParams.filters.idCorrelation;
            }
            if (queryParams.filters.idCorrelationTypeAutorite !== undefined) {
                localVarQueryParameter['filters.idCorrelationTypeAutorite'] = queryParams.filters.idCorrelationTypeAutorite;
            }
            if (queryParams.filters.idHermes !== undefined) {
                localVarQueryParameter['filters.idHermes'] = queryParams.filters.idHermes;
            }
            if (queryParams.filters.idHermesUtilisateur !== undefined) {
                localVarQueryParameter['filters.idHermesUtilisateur'] = queryParams.filters.idHermesUtilisateur;
            }
            if (queryParams.filters.libelle !== undefined) {
                localVarQueryParameter['filters.libelle'] = queryParams.filters.libelle;
            }
            if (queryParams.filters.libelleDepartement !== undefined) {
                localVarQueryParameter['filters.libelleDepartement'] = queryParams.filters.libelleDepartement;
            }
            if (queryParams.filters.page !== undefined) {
                localVarQueryParameter['filters.page'] = queryParams.filters.page;
            }
            if (queryParams.filters.pageSize !== undefined) {
                localVarQueryParameter['filters.pageSize'] = queryParams.filters.pageSize;
            }
            if (queryParams.filters.ville !== undefined) {
                localVarQueryParameter['filters.ville'] = queryParams.filters.ville;
            }

            if (queryParams.sorts.codePostal !== undefined) {
                localVarQueryParameter['sorts.codePostal'] = queryParams.sorts.codePostal;
            }
            if (queryParams.sorts.departement !== undefined) {
                localVarQueryParameter['sorts.departement'] = queryParams.sorts.departement;
            }
            if (queryParams.sorts.libelle !== undefined) {
                localVarQueryParameter['sorts.libelle'] = queryParams.sorts.libelle;
            }
            if (queryParams.sorts.type !== undefined) {
                localVarQueryParameter['sorts.type'] = queryParams.sorts.type;
            }
            if (queryParams.sorts.ville !== undefined) {
                localVarQueryParameter['sorts.ville'] = queryParams.sorts.ville;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche d\'une liste de centreVHU filtrÃ©e et triÃ©e
         * @param {searchCentreVHUUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchCentreVHUUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCentreVHUUsingSpecsUsingGET(queryParams: SearchCentreVHUUsingSpecsUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'filters' is not null or undefined
            if (queryParams.filters === null || queryParams.filters === undefined) {
                throw new RequiredError('filters','Required parameter filters was null or undefined when calling searchCentreVHUUsingSpecsUsingGET.');
            }
            // verify required parameter 'sorts' is not null or undefined
            if (queryParams.sorts === null || queryParams.sorts === undefined) {
                throw new RequiredError('sorts','Required parameter sorts was null or undefined when calling searchCentreVHUUsingSpecsUsingGET.');
            }
            const localVarPath = `/api/referentiel/centre-vhu/with-specs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.filters.codePostal !== undefined) {
                localVarQueryParameter['filters.codePostal'] = queryParams.filters.codePostal;
            }
            if (queryParams.filters.commune !== undefined) {
                localVarQueryParameter['filters.commune'] = queryParams.filters.commune;
            }
            if (queryParams.filters.idCorrelation !== undefined) {
                localVarQueryParameter['filters.idCorrelation'] = queryParams.filters.idCorrelation;
            }
            if (queryParams.filters.numeroAgrementVHU !== undefined) {
                localVarQueryParameter['filters.numeroAgrementVHU'] = queryParams.filters.numeroAgrementVHU;
            }
            if (queryParams.filters.page !== undefined) {
                localVarQueryParameter['filters.page'] = queryParams.filters.page;
            }
            if (queryParams.filters.pageSize !== undefined) {
                localVarQueryParameter['filters.pageSize'] = queryParams.filters.pageSize;
            }
            if (queryParams.filters.raisonSociale !== undefined) {
                localVarQueryParameter['filters.raisonSociale'] = queryParams.filters.raisonSociale;
            }
            if (queryParams.filters.siren !== undefined) {
                localVarQueryParameter['filters.siren'] = queryParams.filters.siren;
            }
            if (queryParams.filters.valid !== undefined) {
                localVarQueryParameter['filters.valid'] = queryParams.filters.valid;
            }

            if (queryParams.sorts.codePostal !== undefined) {
                localVarQueryParameter['sorts.codePostal'] = queryParams.sorts.codePostal;
            }
            if (queryParams.sorts.commune !== undefined) {
                localVarQueryParameter['sorts.commune'] = queryParams.sorts.commune;
            }
            if (queryParams.sorts.numeroAgrementVHU !== undefined) {
                localVarQueryParameter['sorts.numeroAgrementVHU'] = queryParams.sorts.numeroAgrementVHU;
            }
            if (queryParams.sorts.raisonSociale !== undefined) {
                localVarQueryParameter['sorts.raisonSociale'] = queryParams.sorts.raisonSociale;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route de recherche des commune d\'enlevement des dossiers accessibles par l\'utilisateur
         * @param {searchCommuneEnlevementUsingGETQueryParams} queryParams objet contenant les query params de la route searchCommuneEnlevementUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCommuneEnlevementUsingGET(queryParams: SearchCommuneEnlevementUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'searchStr' is not null or undefined
            if (queryParams.searchStr === null || queryParams.searchStr === undefined) {
                throw new RequiredError('searchStr','Required parameter searchStr was null or undefined when calling searchCommuneEnlevementUsingGET.');
            }
            const localVarPath = `/api/referentiel/communes-enlevement`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.searchStr !== undefined) {
                localVarQueryParameter['searchStr'] = queryParams.searchStr;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche d\'une liste des fourriÃ¨res filtrÃ©e et triÃ©e
         * @param {searchFourriereUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchFourriereUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFourriereUsingSpecsUsingGET(queryParams: SearchFourriereUsingSpecsUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'filters' is not null or undefined
            if (queryParams.filters === null || queryParams.filters === undefined) {
                throw new RequiredError('filters','Required parameter filters was null or undefined when calling searchFourriereUsingSpecsUsingGET.');
            }
            // verify required parameter 'sorts' is not null or undefined
            if (queryParams.sorts === null || queryParams.sorts === undefined) {
                throw new RequiredError('sorts','Required parameter sorts was null or undefined when calling searchFourriereUsingSpecsUsingGET.');
            }
            const localVarPath = `/api/referentiel/fourriere/with-specs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.filters.codePostal !== undefined) {
                localVarQueryParameter['filters.codePostal'] = queryParams.filters.codePostal;
            }
            if (queryParams.filters.commune !== undefined) {
                localVarQueryParameter['filters.commune'] = queryParams.filters.commune;
            }
            if (queryParams.filters.dateFromStartOfValidity !== undefined) {
                localVarQueryParameter['filters.dateFromStartOfValidity'] = (queryParams.filters.dateFromStartOfValidity as any).toISOString();
            }
            if (queryParams.filters.dateToStartOfValidity !== undefined) {
                localVarQueryParameter['filters.dateToStartOfValidity'] = (queryParams.filters.dateToStartOfValidity as any).toISOString();
            }
            if (queryParams.filters.emptyIdHermes !== undefined) {
                localVarQueryParameter['filters.emptyIdHermes'] = queryParams.filters.emptyIdHermes;
            }
            if (queryParams.filters.enabled !== undefined) {
                localVarQueryParameter['filters.enabled'] = queryParams.filters.enabled;
            }
            if (queryParams.filters.idCorrelation !== undefined) {
                localVarQueryParameter['filters.idCorrelation'] = queryParams.filters.idCorrelation;
            }
            if (queryParams.filters.idCorrelationAF !== undefined) {
                localVarQueryParameter['filters.idCorrelationAF'] = queryParams.filters.idCorrelationAF;
            }
            if (queryParams.filters.idHermes !== undefined) {
                localVarQueryParameter['filters.idHermes'] = queryParams.filters.idHermes;
            }
            if (queryParams.filters.page !== undefined) {
                localVarQueryParameter['filters.page'] = queryParams.filters.page;
            }
            if (queryParams.filters.pageSize !== undefined) {
                localVarQueryParameter['filters.pageSize'] = queryParams.filters.pageSize;
            }
            if (queryParams.filters.raisonSociale !== undefined) {
                localVarQueryParameter['filters.raisonSociale'] = queryParams.filters.raisonSociale;
            }

            if (queryParams.sorts.codePostal !== undefined) {
                localVarQueryParameter['sorts.codePostal'] = queryParams.sorts.codePostal;
            }
            if (queryParams.sorts.commune !== undefined) {
                localVarQueryParameter['sorts.commune'] = queryParams.sorts.commune;
            }
            if (queryParams.sorts.raisonSociale !== undefined) {
                localVarQueryParameter['sorts.raisonSociale'] = queryParams.sorts.raisonSociale;
            }
            if (queryParams.sorts.raisonSocialeDnid !== undefined) {
                localVarQueryParameter['sorts.raisonSocialeDnid'] = queryParams.sorts.raisonSocialeDnid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche d\'une liste de marques filtrÃ©e et triÃ©e
         * @param {searchMarqueUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchMarqueUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMarqueUsingSpecsUsingGET(queryParams: SearchMarqueUsingSpecsUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'filters' is not null or undefined
            if (queryParams.filters === null || queryParams.filters === undefined) {
                throw new RequiredError('filters','Required parameter filters was null or undefined when calling searchMarqueUsingSpecsUsingGET.');
            }
            // verify required parameter 'sorts' is not null or undefined
            if (queryParams.sorts === null || queryParams.sorts === undefined) {
                throw new RequiredError('sorts','Required parameter sorts was null or undefined when calling searchMarqueUsingSpecsUsingGET.');
            }
            const localVarPath = `/api/referentiel/marque/with-specs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.filters.categorie !== undefined) {
                localVarQueryParameter['filters.categorie'] = queryParams.filters.categorie;
            }
            if (queryParams.filters.dateFromEndOfValidity !== undefined) {
                localVarQueryParameter['filters.dateFromEndOfValidity'] = (queryParams.filters.dateFromEndOfValidity as any).toISOString();
            }
            if (queryParams.filters.dateFromStartOfValidity !== undefined) {
                localVarQueryParameter['filters.dateFromStartOfValidity'] = (queryParams.filters.dateFromStartOfValidity as any).toISOString();
            }
            if (queryParams.filters.dateToEndOfValidity !== undefined) {
                localVarQueryParameter['filters.dateToEndOfValidity'] = (queryParams.filters.dateToEndOfValidity as any).toISOString();
            }
            if (queryParams.filters.dateToStartOfValidity !== undefined) {
                localVarQueryParameter['filters.dateToStartOfValidity'] = (queryParams.filters.dateToStartOfValidity as any).toISOString();
            }
            if (queryParams.filters.id !== undefined) {
                localVarQueryParameter['filters.id'] = queryParams.filters.id;
            }
            if (queryParams.filters.libelle !== undefined) {
                localVarQueryParameter['filters.libelle'] = queryParams.filters.libelle;
            }
            if (queryParams.filters.page !== undefined) {
                localVarQueryParameter['filters.page'] = queryParams.filters.page;
            }
            if (queryParams.filters.pageSize !== undefined) {
                localVarQueryParameter['filters.pageSize'] = queryParams.filters.pageSize;
            }
            if (queryParams.filters.prioritaire !== undefined) {
                localVarQueryParameter['filters.prioritaire'] = queryParams.filters.prioritaire;
            }
            if (queryParams.filters.types !== undefined) {
                localVarQueryParameter['filters.types'] = queryParams.filters.types;
            }
            if (queryParams.filters.valid !== undefined) {
                localVarQueryParameter['filters.valid'] = queryParams.filters.valid;
            }

            if (queryParams.sorts.categorie !== undefined) {
                localVarQueryParameter['sorts.categorie'] = queryParams.sorts.categorie;
            }
            if (queryParams.sorts.libelle !== undefined) {
                localVarQueryParameter['sorts.libelle'] = queryParams.sorts.libelle;
            }
            if (queryParams.sorts.type !== undefined) {
                localVarQueryParameter['sorts.type'] = queryParams.sorts.type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche d\'une liste de modÃ¨les filtrÃ©e filtrÃ©e et triÃ©e
         * @param {searchModeleUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchModeleUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchModeleUsingSpecsUsingGET(queryParams: SearchModeleUsingSpecsUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'filters' is not null or undefined
            if (queryParams.filters === null || queryParams.filters === undefined) {
                throw new RequiredError('filters','Required parameter filters was null or undefined when calling searchModeleUsingSpecsUsingGET.');
            }
            // verify required parameter 'sorts' is not null or undefined
            if (queryParams.sorts === null || queryParams.sorts === undefined) {
                throw new RequiredError('sorts','Required parameter sorts was null or undefined when calling searchModeleUsingSpecsUsingGET.');
            }
            const localVarPath = `/api/referentiel/modele/with-specs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.filters.dateFromEndOfValidity !== undefined) {
                localVarQueryParameter['filters.dateFromEndOfValidity'] = (queryParams.filters.dateFromEndOfValidity as any).toISOString();
            }
            if (queryParams.filters.dateFromStartOfValidity !== undefined) {
                localVarQueryParameter['filters.dateFromStartOfValidity'] = (queryParams.filters.dateFromStartOfValidity as any).toISOString();
            }
            if (queryParams.filters.dateToEndOfValidity !== undefined) {
                localVarQueryParameter['filters.dateToEndOfValidity'] = (queryParams.filters.dateToEndOfValidity as any).toISOString();
            }
            if (queryParams.filters.dateToStartOfValidity !== undefined) {
                localVarQueryParameter['filters.dateToStartOfValidity'] = (queryParams.filters.dateToStartOfValidity as any).toISOString();
            }
            if (queryParams.filters.libelle !== undefined) {
                localVarQueryParameter['filters.libelle'] = queryParams.filters.libelle;
            }
            if (queryParams.filters.marqueId !== undefined) {
                localVarQueryParameter['filters.marqueId'] = queryParams.filters.marqueId;
            }
            if (queryParams.filters.page !== undefined) {
                localVarQueryParameter['filters.page'] = queryParams.filters.page;
            }
            if (queryParams.filters.pageSize !== undefined) {
                localVarQueryParameter['filters.pageSize'] = queryParams.filters.pageSize;
            }
            if (queryParams.filters.prioritaire !== undefined) {
                localVarQueryParameter['filters.prioritaire'] = queryParams.filters.prioritaire;
            }
            if (queryParams.filters.valid !== undefined) {
                localVarQueryParameter['filters.valid'] = queryParams.filters.valid;
            }

            if (queryParams.sorts.libelle !== undefined) {
                localVarQueryParameter['sorts.libelle'] = queryParams.sorts.libelle;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche d\'une liste de motifs de mise en fourriÃ¨re filtrÃ©e et triÃ©e
         * @param {searchMotifMefUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchMotifMefUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMotifMefUsingSpecsUsingGET(queryParams: SearchMotifMefUsingSpecsUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'filters' is not null or undefined
            if (queryParams.filters === null || queryParams.filters === undefined) {
                throw new RequiredError('filters','Required parameter filters was null or undefined when calling searchMotifMefUsingSpecsUsingGET.');
            }
            // verify required parameter 'sorts' is not null or undefined
            if (queryParams.sorts === null || queryParams.sorts === undefined) {
                throw new RequiredError('sorts','Required parameter sorts was null or undefined when calling searchMotifMefUsingSpecsUsingGET.');
            }
            const localVarPath = `/api/referentiel/motif-mef/with-specs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.filters.dateFromStartOfValidity !== undefined) {
                localVarQueryParameter['filters.dateFromStartOfValidity'] = (queryParams.filters.dateFromStartOfValidity as any).toISOString();
            }
            if (queryParams.filters.dateToStartOfValidity !== undefined) {
                localVarQueryParameter['filters.dateToStartOfValidity'] = (queryParams.filters.dateToStartOfValidity as any).toISOString();
            }
            if (queryParams.filters.enabled !== undefined) {
                localVarQueryParameter['filters.enabled'] = queryParams.filters.enabled;
            }
            if (queryParams.filters.idCorrelation !== undefined) {
                localVarQueryParameter['filters.idCorrelation'] = queryParams.filters.idCorrelation;
            }
            if (queryParams.filters.isDicem !== undefined) {
                localVarQueryParameter['filters.isDicem'] = queryParams.filters.isDicem;
            }
            if (queryParams.filters.isLieuDitEnabled !== undefined) {
                localVarQueryParameter['filters.isLieuDitEnabled'] = queryParams.filters.isLieuDitEnabled;
            }
            if (queryParams.filters.isRodeo !== undefined) {
                localVarQueryParameter['filters.isRodeo'] = queryParams.filters.isRodeo;
            }
            if (queryParams.filters.libelle !== undefined) {
                localVarQueryParameter['filters.libelle'] = queryParams.filters.libelle;
            }
            if (queryParams.filters.natureLibelleCourt !== undefined) {
                localVarQueryParameter['filters.natureLibelleCourt'] = queryParams.filters.natureLibelleCourt;
            }
            if (queryParams.filters.page !== undefined) {
                localVarQueryParameter['filters.page'] = queryParams.filters.page;
            }
            if (queryParams.filters.pageSize !== undefined) {
                localVarQueryParameter['filters.pageSize'] = queryParams.filters.pageSize;
            }

            if (queryParams.sorts.libelle !== undefined) {
                localVarQueryParameter['sorts.libelle'] = queryParams.sorts.libelle;
            }
            if (queryParams.sorts.natureMef !== undefined) {
                localVarQueryParameter['sorts.natureMef'] = queryParams.sorts.natureMef;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Recherche d\'une liste d\'UniteFo filtrÃ©e et triÃ©e
         * @param {searchUniteFoUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchUniteFoUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUniteFoUsingSpecsUsingGET(queryParams: SearchUniteFoUsingSpecsUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'filters' is not null or undefined
            if (queryParams.filters === null || queryParams.filters === undefined) {
                throw new RequiredError('filters','Required parameter filters was null or undefined when calling searchUniteFoUsingSpecsUsingGET.');
            }
            // verify required parameter 'sorts' is not null or undefined
            if (queryParams.sorts === null || queryParams.sorts === undefined) {
                throw new RequiredError('sorts','Required parameter sorts was null or undefined when calling searchUniteFoUsingSpecsUsingGET.');
            }
            const localVarPath = `/api/referentiel/unite-fo/with-specs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.filters.abreviation !== undefined) {
                localVarQueryParameter['filters.abreviation'] = queryParams.filters.abreviation;
            }
            if (queryParams.filters.code !== undefined) {
                localVarQueryParameter['filters.code'] = queryParams.filters.code;
            }
            if (queryParams.filters.codePostal !== undefined) {
                localVarQueryParameter['filters.codePostal'] = queryParams.filters.codePostal;
            }
            if (queryParams.filters.idCorrelation !== undefined) {
                localVarQueryParameter['filters.idCorrelation'] = queryParams.filters.idCorrelation;
            }
            if (queryParams.filters.page !== undefined) {
                localVarQueryParameter['filters.page'] = queryParams.filters.page;
            }
            if (queryParams.filters.pageSize !== undefined) {
                localVarQueryParameter['filters.pageSize'] = queryParams.filters.pageSize;
            }
            if (queryParams.filters.rpsiStatus !== undefined) {
                localVarQueryParameter['filters.rpsiStatus'] = queryParams.filters.rpsiStatus;
            }
            if (queryParams.filters.type !== undefined) {
                localVarQueryParameter['filters.type'] = queryParams.filters.type;
            }
            if (queryParams.filters.unite !== undefined) {
                localVarQueryParameter['filters.unite'] = queryParams.filters.unite;
            }
            if (queryParams.filters.valid !== undefined) {
                localVarQueryParameter['filters.valid'] = queryParams.filters.valid;
            }
            if (queryParams.filters.ville !== undefined) {
                localVarQueryParameter['filters.ville'] = queryParams.filters.ville;
            }

            if (queryParams.sorts.abreviation !== undefined) {
                localVarQueryParameter['sorts.abreviation'] = queryParams.sorts.abreviation;
            }
            if (queryParams.sorts.code !== undefined) {
                localVarQueryParameter['sorts.code'] = queryParams.sorts.code;
            }
            if (queryParams.sorts.codeInsee !== undefined) {
                localVarQueryParameter['sorts.codeInsee'] = queryParams.sorts.codeInsee;
            }
            if (queryParams.sorts.codePostal !== undefined) {
                localVarQueryParameter['sorts.codePostal'] = queryParams.sorts.codePostal;
            }
            if (queryParams.sorts.type !== undefined) {
                localVarQueryParameter['sorts.type'] = queryParams.sorts.type;
            }
            if (queryParams.sorts.ville !== undefined) {
                localVarQueryParameter['sorts.ville'] = queryParams.sorts.ville;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route recherchant une unitÃ© force de l\'ordre.
         * @param {searchedValidUniteFOUsingGETQueryParams} queryParams objet contenant les query params de la route searchedValidUniteFOUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchedValidUniteFOUsingGET(queryParams: SearchedValidUniteFOUsingGETQueryParams, options: any = {}): FetchArgs {
            // verify required parameter 'field' is not null or undefined
            if (queryParams.field === null || queryParams.field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling searchedValidUniteFOUsingGET.');
            }
            // verify required parameter 'searched' is not null or undefined
            if (queryParams.searched === null || queryParams.searched === undefined) {
                throw new RequiredError('searched','Required parameter searched was null or undefined when calling searchedValidUniteFOUsingGET.');
            }
            const localVarPath = `/api/referentiel/unite-fo`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryParams.field !== undefined) {
                localVarQueryParameter['field'] = queryParams.field;
            }

            if (queryParams.searched !== undefined) {
                localVarQueryParameter['searched'] = queryParams.searched;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route pour Ã©diter un bandeau d\'information Ã  afficher.
         * @param {string} id 
         * @param {InformationMessageRequestDto} informationMessageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInformationMessageUsingPOST(id: string, informationMessageRequestDto: InformationMessageRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateInformationMessageUsingPOST.');
            }
            // verify required parameter 'informationMessageRequestDto' is not null or undefined
            if (informationMessageRequestDto === null || informationMessageRequestDto === undefined) {
                throw new RequiredError('informationMessageRequestDto','Required parameter informationMessageRequestDto was null or undefined when calling updateInformationMessageUsingPOST.');
            }
            const localVarPath = `/api/referentiel/message-information/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InformationMessageRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(informationMessageRequestDto || {}) : (informationMessageRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Route pour valider le formulaire de mise Ã  jour d\'un bandeau d\'information.
         * @param {string} id 
         * @param {InformationMessageRequestDto} informationMessageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInformationMessageValidateUsingPOST(id: string, informationMessageRequestDto: InformationMessageRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateInformationMessageValidateUsingPOST.');
            }
            // verify required parameter 'informationMessageRequestDto' is not null or undefined
            if (informationMessageRequestDto === null || informationMessageRequestDto === undefined) {
                throw new RequiredError('informationMessageRequestDto','Required parameter informationMessageRequestDto was null or undefined when calling updateInformationMessageValidateUsingPOST.');
            }
            const localVarPath = `/api/referentiel/message-information/{id}/validation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InformationMessageRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(informationMessageRequestDto || {}) : (informationMessageRequestDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferentielControllerApi - functional programming interface
 * @export
 */
export const ReferentielControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * @summary Route pour crÃ©er un bandeau d\'information Ã  afficher.
         * @param {InformationMessageRequestDto} informationMessageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInformationMessageUsingPOST(informationMessageRequestDto: InformationMessageRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).createInformationMessageUsingPOST(informationMessageRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant d\'exporter un rÃ©fÃ©rentiel au format csv.
         * @param {'AUTORITE_FOURRIERE' | 'BRANCHE' | 'CENTRE_VHU' | 'ETAT' | 'FOURRIERE' | 'FOOTER_LINK' | 'GENRE_SIMPLIFIE' | 'MOTIF_MEF' | 'MOTIF_SORTIE' | 'TYPE_AF' | 'UNITE_FO' | 'FAQ' | 'VIDEO_TUTORIEL'} referentiel Nom du referentiel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCSVReferentielUsingGET(referentiel: 'AUTORITE_FOURRIERE' | 'BRANCHE' | 'CENTRE_VHU' | 'ETAT' | 'FOURRIERE' | 'FOOTER_LINK' | 'GENRE_SIMPLIFIE' | 'MOTIF_MEF' | 'MOTIF_SORTIE' | 'TYPE_AF' | 'UNITE_FO' | 'FAQ' | 'VIDEO_TUTORIEL', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).exportCSVReferentielUsingGET(referentiel, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant d\'exporter un rÃ©fÃ©rentiel au format json.
         * @param {'CATEGORIE'} referentiel Nom du referentiel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportJSONReferentielUsingGET(referentiel: 'CATEGORIE', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).exportJSONReferentielUsingGET(referentiel, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les couleurs de vÃ©hicule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCouleurUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoCouleurDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getAllCouleurUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les Ã©nergies.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEnergieUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoEnergieDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getAllEnergieUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les bandeaux d\'information
         * @param {getAllInformationMessageUsingGETQueryParams} queryParams objet contenant les query params de la route getAllInformationMessageUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInformationMessageUsingGET(queryParams: GetAllInformationMessageUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SearchInformationMessageResultDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getAllInformationMessageUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les modÃ¨les de vÃ©hicule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMarqueModeleUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoModeleDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getAllMarqueModeleUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les centres VHU valides.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllValidCentreVhuUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoCentreVHUDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getAllValidCentreVhuUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les marques valides.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllValidMarqueUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoMarqueDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getAllValidMarqueUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les natures de mise en fourriÃ¨re et les branches de workflow associÃ©es.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllValidNaturesUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoBrancheDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getAllValidNaturesUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les prÃ©cisions pour un Ã©tat donnÃ©.
         * @param {'BON_ETAT' | 'DEGRADE' | 'DOMMAGES_GRAVES'} etat LibellÃ© de l\'Ã©tat.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailFromEtatUsingGET(etat: 'BON_ETAT' | 'DEGRADE' | 'DOMMAGES_GRAVES', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoEtatDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getDetailFromEtatUsingGET(etat, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant un Ã©tat par son id.
         * @param {string} id ID de l\'Ã©tat, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEtatByIdUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EtatDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getEtatByIdUsingGET(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les diffÃ©rents Ã©tats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEtatsVehiculeUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoEtatDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getEtatsVehiculeUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rercherchant une question en fonction de son ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqByIdUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FaqDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getFaqByIdUsingGET(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant une marque par son id.
         * @param {string} id ID de la marque, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarqueByIdUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MarqueDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getMarqueByIdUsingGET(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les modÃ¨les de vÃ©hicule pour l\'idCorrelation d\'une marque donnÃ©e.
         * @param {string} idCorrelation ID de la marque, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModeleByMarqueIdCorrelationUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoModeleDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getModeleByMarqueIdCorrelationUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les modÃ¨les de vÃ©hicule pour l\'id d\'une marque donnÃ©e.
         * @param {string} id ID de la marque, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModeleByMarqueUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoModeleDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getModeleByMarqueUsingGET(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant un motif de mise en fourriÃ¨re par son id.
         * @param {string} id ID du motif de mef, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMotifMefByIdUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MotifMefDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getMotifMefByIdUsingGET(id, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les questions valides, regroupÃ©es par domaines.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlyValidFaqByDomainsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoFaqDomainDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getOnlyValidFaqByDomainsUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rercherchant une question en fonction de son idCorrelation.
         * @param {string} idCorrelation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlyValidFaqDtoUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FaqDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getOnlyValidFaqDtoUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les questions valides, avec ou sans domaine.
         * @param {getOnlyValidFaqsUsingGETQueryParams} queryParams objet contenant les query params de la route getOnlyValidFaqsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlyValidFaqsUsingGET(queryParams: GetOnlyValidFaqsUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoFaqDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getOnlyValidFaqsUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les liens du pied de page valides.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlyValidFooterLinkUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoFooterLinkDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getOnlyValidFooterLinkUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les bandeaux d\'information actif pour le profil donnÃ©.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlyValidInformationMessageByProfileUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoInformationMessageDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getOnlyValidInformationMessageByProfileUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les bandeaux d\'information actif pour Bord de Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlyValidInformationMessageForBDRUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoInformationMessageDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getOnlyValidInformationMessageForBDRUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les statuts consultables selon le profil.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatutsConsultablesUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoStatutDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getStatutsConsultablesUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route recherchant une autoritÃ©s de fourriÃ¨re par son idCorrelation.
         * @param {string} idCorrelation ID de l\'autoritÃ©s de fourriÃ¨re, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidAutoriteFourriereByIdCorrelationUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AutoriteFourriereDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getValidAutoriteFourriereByIdCorrelationUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les autoritÃ©s de fourriÃ¨re.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidAutoriteFourriereUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoAutoriteFourriereDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getValidAutoriteFourriereUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les autoritÃ©s fourriÃ¨re d\'une fourriÃ¨re via son idCorrelation.
         * @param {string} idCorrelationFourriere IdCorrelation de la fourriÃ¨re.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidAutoritesFourriereOfFourriereUsingGET(idCorrelationFourriere: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoAutoriteFourriereDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getValidAutoritesFourriereOfFourriereUsingGET(idCorrelationFourriere, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les carrosseries utilisÃ©es pour le classement manuel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidCarrosserieClassementManuelUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoCarrosserieClassementManuelDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getValidCarrosserieClassementManuelUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les carrosseries de vÃ©hicule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidCarrosserieUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoCarrosserieDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getValidCarrosserieUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les fourriÃ¨re.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidFourriereUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoFourriereDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getValidFourriereUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les genres de vÃ©hicule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidGenreUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoGenreDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getValidGenreUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les genres simplifiÃ©s de vÃ©hicules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidGenresSimplifiesUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoGenreSimplifieDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getValidGenresSimplifiesUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les motifs de mise en fourriÃ¨re.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidMotifMefUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoMotifMefDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getValidMotifMefUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les motifs de sortie.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidMotifSortieUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoMotifSortieDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getValidMotifSortieUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant tous les types d\'autoritÃ©s de fourriÃ¨re.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidTypeAutoriteFourriereUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoTypeAutoriteFourriereDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getValidTypeAutoriteFourriereUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route recherchant une unitÃ© force de l\'ordre via son idCorrelation.
         * @param {string} idCorrelation ID de l\'unitÃ©, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidUniteFOByIdCorrelationUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UniteFOResponseDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getValidUniteFOByIdCorrelationUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant les unitÃ©s FO liÃ©es au profil FO utilisÃ© (unitÃ© parente + enfants), via l\'ideCorrelation de l\'unitÃ© parente
         * @param {string} idCorrelation idCorrelation de l\'unitÃ© parente, reprÃ©sentÃ© par un UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidUniteFOWithChildrenUsingGET(idCorrelation: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UniteFOResponseDto>> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getValidUniteFOWithChildrenUsingGET(idCorrelation, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route rÃ©cupÃ©rant toutes les vidÃ©os tutoriels.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoTutorielUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDtoVideoTutorielDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).getVideoTutorielUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant d\'importer un rÃ©fÃ©rentiel au format csv.
         * @param {'BRANCHE' | 'ETAT' | 'FOOTER_LINK' | 'GENRE_SIMPLIFIE' | 'VIDEO_TUTORIEL'} referentiel Nom du referentiel.
         * @param {any} file Fichier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCSVReferentielUsingPOST(referentiel: 'BRANCHE' | 'ETAT' | 'FOOTER_LINK' | 'GENRE_SIMPLIFIE' | 'VIDEO_TUTORIEL', file: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).importCSVReferentielUsingPOST(referentiel, file, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route permettant d\'importer un rÃ©fÃ©rentiel au format json.
         * @param {'CATEGORIE'} referentiel Nom du referentiel.
         * @param {any} file Fichier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importJSONReferentielUsingPOST(referentiel: 'CATEGORIE', file: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).importJSONReferentielUsingPOST(referentiel, file, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route pour valider un champ du formulaire de mise Ã  jour du bandeau d\'information Ã  afficher.
         * @param {'TITRE' | 'CONTENT' | 'START_DATE' | 'END_DATE' | 'VALID' | 'PROFILES'} field 
         * @param {string} id 
         * @param {InformationMessageRequestDto} informationMessageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        informationMessageValidateFieldUsingPOST(field: 'TITRE' | 'CONTENT' | 'START_DATE' | 'END_DATE' | 'VALID' | 'PROFILES', id: string, informationMessageRequestDto: InformationMessageRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InformationMessageViolationsDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).informationMessageValidateFieldUsingPOST(field, id, informationMessageRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route pour valider un champ du formulaire de crÃ©ation d\'un bandeau d\'information Ã  afficher.
         * @param {'TITRE' | 'CONTENT' | 'START_DATE' | 'END_DATE' | 'VALID' | 'PROFILES'} field 
         * @param {InformationMessageRequestDto} informationMessageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        informationMessageValidateFieldUsingPOST1(field: 'TITRE' | 'CONTENT' | 'START_DATE' | 'END_DATE' | 'VALID' | 'PROFILES', informationMessageRequestDto: InformationMessageRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InformationMessageViolationsDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).informationMessageValidateFieldUsingPOST1(field, informationMessageRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route pour valider un formulaire de crÃ©ation d\'un bandeau d\'information Ã  afficher.
         * @param {InformationMessageRequestDto} informationMessageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        informationMessageValidateUsingPOST(informationMessageRequestDto: InformationMessageRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InformationMessageViolationsDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).informationMessageValidateUsingPOST(informationMessageRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche d\'une liste d\'autoritÃ©s de fourriÃ¨re filtrÃ©e et triÃ©e
         * @param {searchAutoriteFourriereUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchAutoriteFourriereUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAutoriteFourriereUsingSpecsUsingGET(queryParams: SearchAutoriteFourriereUsingSpecsUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielSearchResultDtoAutoriteFourriereDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).searchAutoriteFourriereUsingSpecsUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche d\'une liste de centreVHU filtrÃ©e et triÃ©e
         * @param {searchCentreVHUUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchCentreVHUUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCentreVHUUsingSpecsUsingGET(queryParams: SearchCentreVHUUsingSpecsUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielSearchResultDtoCentreVHUDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).searchCentreVHUUsingSpecsUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route de recherche des commune d\'enlevement des dossiers accessibles par l\'utilisateur
         * @param {searchCommuneEnlevementUsingGETQueryParams} queryParams objet contenant les query params de la route searchCommuneEnlevementUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCommuneEnlevementUsingGET(queryParams: SearchCommuneEnlevementUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SearchCommuneEnlevementResultDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).searchCommuneEnlevementUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche d\'une liste des fourriÃ¨res filtrÃ©e et triÃ©e
         * @param {searchFourriereUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchFourriereUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFourriereUsingSpecsUsingGET(queryParams: SearchFourriereUsingSpecsUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielSearchResultDtoFourriereDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).searchFourriereUsingSpecsUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche d\'une liste de marques filtrÃ©e et triÃ©e
         * @param {searchMarqueUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchMarqueUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMarqueUsingSpecsUsingGET(queryParams: SearchMarqueUsingSpecsUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielSearchResultDtoMarqueDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).searchMarqueUsingSpecsUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche d\'une liste de modÃ¨les filtrÃ©e filtrÃ©e et triÃ©e
         * @param {searchModeleUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchModeleUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchModeleUsingSpecsUsingGET(queryParams: SearchModeleUsingSpecsUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielSearchResultDtoModeleDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).searchModeleUsingSpecsUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche d\'une liste de motifs de mise en fourriÃ¨re filtrÃ©e et triÃ©e
         * @param {searchMotifMefUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchMotifMefUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMotifMefUsingSpecsUsingGET(queryParams: SearchMotifMefUsingSpecsUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielSearchResultDtoMotifMefDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).searchMotifMefUsingSpecsUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Recherche d\'une liste d\'UniteFo filtrÃ©e et triÃ©e
         * @param {searchUniteFoUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchUniteFoUsingSpecsUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUniteFoUsingSpecsUsingGET(queryParams: SearchUniteFoUsingSpecsUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferentielSearchResultDtoUniteFOAdminResponseDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).searchUniteFoUsingSpecsUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route recherchant une unitÃ© force de l\'ordre.
         * @param {searchedValidUniteFOUsingGETQueryParams} queryParams objet contenant les query params de la route searchedValidUniteFOUsingGET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchedValidUniteFOUsingGET(queryParams: SearchedValidUniteFOUsingGETQueryParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AutocompleteUniteFOResultDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).searchedValidUniteFOUsingGET(queryParams, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route pour Ã©diter un bandeau d\'information Ã  afficher.
         * @param {string} id 
         * @param {InformationMessageRequestDto} informationMessageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInformationMessageUsingPOST(id: string, informationMessageRequestDto: InformationMessageRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).updateInformationMessageUsingPOST(id, informationMessageRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * @summary Route pour valider le formulaire de mise Ã  jour d\'un bandeau d\'information.
         * @param {string} id 
         * @param {InformationMessageRequestDto} informationMessageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInformationMessageValidateUsingPOST(id: string, informationMessageRequestDto: InformationMessageRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InformationMessageViolationsDto> {
            const localVarFetchArgs = ReferentielControllerApiFetchParamCreator(configuration).updateInformationMessageValidateUsingPOST(id, informationMessageRequestDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReferentielControllerApi - object-oriented interface
 * @export
 * @class ReferentielControllerApi
 * @extends {BaseAPI}
 */
export class ReferentielControllerApi extends BaseAPI {
    /**
     * @summary Route pour crÃ©er un bandeau d\'information Ã  afficher.
     * @param {InformationMessageRequestDto} informationMessageRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public createInformationMessageUsingPOST(informationMessageRequestDto: InformationMessageRequestDto, options?: any) {
        return ReferentielControllerApiFp(this.configuration).createInformationMessageUsingPOST(informationMessageRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant d\'exporter un rÃ©fÃ©rentiel au format csv.
     * @param {'AUTORITE_FOURRIERE' | 'BRANCHE' | 'CENTRE_VHU' | 'ETAT' | 'FOURRIERE' | 'FOOTER_LINK' | 'GENRE_SIMPLIFIE' | 'MOTIF_MEF' | 'MOTIF_SORTIE' | 'TYPE_AF' | 'UNITE_FO' | 'FAQ' | 'VIDEO_TUTORIEL'} referentiel Nom du referentiel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public exportCSVReferentielUsingGET(referentiel: 'AUTORITE_FOURRIERE' | 'BRANCHE' | 'CENTRE_VHU' | 'ETAT' | 'FOURRIERE' | 'FOOTER_LINK' | 'GENRE_SIMPLIFIE' | 'MOTIF_MEF' | 'MOTIF_SORTIE' | 'TYPE_AF' | 'UNITE_FO' | 'FAQ' | 'VIDEO_TUTORIEL', options?: any) {
        return ReferentielControllerApiFp(this.configuration).exportCSVReferentielUsingGET(referentiel, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant d\'exporter un rÃ©fÃ©rentiel au format json.
     * @param {'CATEGORIE'} referentiel Nom du referentiel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public exportJSONReferentielUsingGET(referentiel: 'CATEGORIE', options?: any) {
        return ReferentielControllerApiFp(this.configuration).exportJSONReferentielUsingGET(referentiel, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant toutes les couleurs de vÃ©hicule.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getAllCouleurUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getAllCouleurUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant toutes les Ã©nergies.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getAllEnergieUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getAllEnergieUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant tous les bandeaux d\'information
     * @param {getAllInformationMessageUsingGETQueryParams} queryParams objet contenant les query params de la route getAllInformationMessageUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getAllInformationMessageUsingGET(queryParams: GetAllInformationMessageUsingGETQueryParams, options?: any) {
        return ReferentielControllerApiFp(this.configuration).getAllInformationMessageUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant tous les modÃ¨les de vÃ©hicule.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getAllMarqueModeleUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getAllMarqueModeleUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant tous les centres VHU valides.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getAllValidCentreVhuUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getAllValidCentreVhuUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant toutes les marques valides.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getAllValidMarqueUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getAllValidMarqueUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant toutes les natures de mise en fourriÃ¨re et les branches de workflow associÃ©es.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getAllValidNaturesUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getAllValidNaturesUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant les prÃ©cisions pour un Ã©tat donnÃ©.
     * @param {'BON_ETAT' | 'DEGRADE' | 'DOMMAGES_GRAVES'} etat LibellÃ© de l\'Ã©tat.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getDetailFromEtatUsingGET(etat: 'BON_ETAT' | 'DEGRADE' | 'DOMMAGES_GRAVES', options?: any) {
        return ReferentielControllerApiFp(this.configuration).getDetailFromEtatUsingGET(etat, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant un Ã©tat par son id.
     * @param {string} id ID de l\'Ã©tat, reprÃ©sentÃ© par un UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getEtatByIdUsingGET(id: string, options?: any) {
        return ReferentielControllerApiFp(this.configuration).getEtatByIdUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant les diffÃ©rents Ã©tats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getEtatsVehiculeUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getEtatsVehiculeUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rercherchant une question en fonction de son ID.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getFaqByIdUsingGET(id: string, options?: any) {
        return ReferentielControllerApiFp(this.configuration).getFaqByIdUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant une marque par son id.
     * @param {string} id ID de la marque, reprÃ©sentÃ© par un UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getMarqueByIdUsingGET(id: string, options?: any) {
        return ReferentielControllerApiFp(this.configuration).getMarqueByIdUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant tous les modÃ¨les de vÃ©hicule pour l\'idCorrelation d\'une marque donnÃ©e.
     * @param {string} idCorrelation ID de la marque, reprÃ©sentÃ© par un UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getModeleByMarqueIdCorrelationUsingGET(idCorrelation: string, options?: any) {
        return ReferentielControllerApiFp(this.configuration).getModeleByMarqueIdCorrelationUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant tous les modÃ¨les de vÃ©hicule pour l\'id d\'une marque donnÃ©e.
     * @param {string} id ID de la marque, reprÃ©sentÃ© par un UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getModeleByMarqueUsingGET(id: string, options?: any) {
        return ReferentielControllerApiFp(this.configuration).getModeleByMarqueUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant un motif de mise en fourriÃ¨re par son id.
     * @param {string} id ID du motif de mef, reprÃ©sentÃ© par un UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getMotifMefByIdUsingGET(id: string, options?: any) {
        return ReferentielControllerApiFp(this.configuration).getMotifMefByIdUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant les questions valides, regroupÃ©es par domaines.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getOnlyValidFaqByDomainsUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getOnlyValidFaqByDomainsUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rercherchant une question en fonction de son idCorrelation.
     * @param {string} idCorrelation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getOnlyValidFaqDtoUsingGET(idCorrelation: string, options?: any) {
        return ReferentielControllerApiFp(this.configuration).getOnlyValidFaqDtoUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant les questions valides, avec ou sans domaine.
     * @param {getOnlyValidFaqsUsingGETQueryParams} queryParams objet contenant les query params de la route getOnlyValidFaqsUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getOnlyValidFaqsUsingGET(queryParams: GetOnlyValidFaqsUsingGETQueryParams, options?: any) {
        return ReferentielControllerApiFp(this.configuration).getOnlyValidFaqsUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant les liens du pied de page valides.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getOnlyValidFooterLinkUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getOnlyValidFooterLinkUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant les bandeaux d\'information actif pour le profil donnÃ©.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getOnlyValidInformationMessageByProfileUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getOnlyValidInformationMessageByProfileUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant les bandeaux d\'information actif pour Bord de Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getOnlyValidInformationMessageForBDRUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getOnlyValidInformationMessageForBDRUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant les statuts consultables selon le profil.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getStatutsConsultablesUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getStatutsConsultablesUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route recherchant une autoritÃ©s de fourriÃ¨re par son idCorrelation.
     * @param {string} idCorrelation ID de l\'autoritÃ©s de fourriÃ¨re, reprÃ©sentÃ© par un UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getValidAutoriteFourriereByIdCorrelationUsingGET(idCorrelation: string, options?: any) {
        return ReferentielControllerApiFp(this.configuration).getValidAutoriteFourriereByIdCorrelationUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant toutes les autoritÃ©s de fourriÃ¨re.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getValidAutoriteFourriereUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getValidAutoriteFourriereUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant toutes les autoritÃ©s fourriÃ¨re d\'une fourriÃ¨re via son idCorrelation.
     * @param {string} idCorrelationFourriere IdCorrelation de la fourriÃ¨re.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getValidAutoritesFourriereOfFourriereUsingGET(idCorrelationFourriere: string, options?: any) {
        return ReferentielControllerApiFp(this.configuration).getValidAutoritesFourriereOfFourriereUsingGET(idCorrelationFourriere, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant les carrosseries utilisÃ©es pour le classement manuel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getValidCarrosserieClassementManuelUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getValidCarrosserieClassementManuelUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant toutes les carrosseries de vÃ©hicule.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getValidCarrosserieUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getValidCarrosserieUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant toutes les fourriÃ¨re.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getValidFourriereUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getValidFourriereUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant tous les genres de vÃ©hicule.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getValidGenreUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getValidGenreUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant tous les genres simplifiÃ©s de vÃ©hicules
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getValidGenresSimplifiesUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getValidGenresSimplifiesUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant tous les motifs de mise en fourriÃ¨re.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getValidMotifMefUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getValidMotifMefUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant tous les motifs de sortie.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getValidMotifSortieUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getValidMotifSortieUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant tous les types d\'autoritÃ©s de fourriÃ¨re.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getValidTypeAutoriteFourriereUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getValidTypeAutoriteFourriereUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route recherchant une unitÃ© force de l\'ordre via son idCorrelation.
     * @param {string} idCorrelation ID de l\'unitÃ©, reprÃ©sentÃ© par un UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getValidUniteFOByIdCorrelationUsingGET(idCorrelation: string, options?: any) {
        return ReferentielControllerApiFp(this.configuration).getValidUniteFOByIdCorrelationUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant les unitÃ©s FO liÃ©es au profil FO utilisÃ© (unitÃ© parente + enfants), via l\'ideCorrelation de l\'unitÃ© parente
     * @param {string} idCorrelation idCorrelation de l\'unitÃ© parente, reprÃ©sentÃ© par un UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getValidUniteFOWithChildrenUsingGET(idCorrelation: string, options?: any) {
        return ReferentielControllerApiFp(this.configuration).getValidUniteFOWithChildrenUsingGET(idCorrelation, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route rÃ©cupÃ©rant toutes les vidÃ©os tutoriels.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getVideoTutorielUsingGET(options?: any) {
        return ReferentielControllerApiFp(this.configuration).getVideoTutorielUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant d\'importer un rÃ©fÃ©rentiel au format csv.
     * @param {'BRANCHE' | 'ETAT' | 'FOOTER_LINK' | 'GENRE_SIMPLIFIE' | 'VIDEO_TUTORIEL'} referentiel Nom du referentiel.
     * @param {any} file Fichier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public importCSVReferentielUsingPOST(referentiel: 'BRANCHE' | 'ETAT' | 'FOOTER_LINK' | 'GENRE_SIMPLIFIE' | 'VIDEO_TUTORIEL', file: any, options?: any) {
        return ReferentielControllerApiFp(this.configuration).importCSVReferentielUsingPOST(referentiel, file, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route permettant d\'importer un rÃ©fÃ©rentiel au format json.
     * @param {'CATEGORIE'} referentiel Nom du referentiel.
     * @param {any} file Fichier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public importJSONReferentielUsingPOST(referentiel: 'CATEGORIE', file: any, options?: any) {
        return ReferentielControllerApiFp(this.configuration).importJSONReferentielUsingPOST(referentiel, file, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route pour valider un champ du formulaire de mise Ã  jour du bandeau d\'information Ã  afficher.
     * @param {'TITRE' | 'CONTENT' | 'START_DATE' | 'END_DATE' | 'VALID' | 'PROFILES'} field 
     * @param {string} id 
     * @param {InformationMessageRequestDto} informationMessageRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public informationMessageValidateFieldUsingPOST(field: 'TITRE' | 'CONTENT' | 'START_DATE' | 'END_DATE' | 'VALID' | 'PROFILES', id: string, informationMessageRequestDto: InformationMessageRequestDto, options?: any) {
        return ReferentielControllerApiFp(this.configuration).informationMessageValidateFieldUsingPOST(field, id, informationMessageRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route pour valider un champ du formulaire de crÃ©ation d\'un bandeau d\'information Ã  afficher.
     * @param {'TITRE' | 'CONTENT' | 'START_DATE' | 'END_DATE' | 'VALID' | 'PROFILES'} field 
     * @param {InformationMessageRequestDto} informationMessageRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public informationMessageValidateFieldUsingPOST1(field: 'TITRE' | 'CONTENT' | 'START_DATE' | 'END_DATE' | 'VALID' | 'PROFILES', informationMessageRequestDto: InformationMessageRequestDto, options?: any) {
        return ReferentielControllerApiFp(this.configuration).informationMessageValidateFieldUsingPOST1(field, informationMessageRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route pour valider un formulaire de crÃ©ation d\'un bandeau d\'information Ã  afficher.
     * @param {InformationMessageRequestDto} informationMessageRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public informationMessageValidateUsingPOST(informationMessageRequestDto: InformationMessageRequestDto, options?: any) {
        return ReferentielControllerApiFp(this.configuration).informationMessageValidateUsingPOST(informationMessageRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche d\'une liste d\'autoritÃ©s de fourriÃ¨re filtrÃ©e et triÃ©e
     * @param {searchAutoriteFourriereUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchAutoriteFourriereUsingSpecsUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchAutoriteFourriereUsingSpecsUsingGET(queryParams: SearchAutoriteFourriereUsingSpecsUsingGETQueryParams, options?: any) {
        return ReferentielControllerApiFp(this.configuration).searchAutoriteFourriereUsingSpecsUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche d\'une liste de centreVHU filtrÃ©e et triÃ©e
     * @param {searchCentreVHUUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchCentreVHUUsingSpecsUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchCentreVHUUsingSpecsUsingGET(queryParams: SearchCentreVHUUsingSpecsUsingGETQueryParams, options?: any) {
        return ReferentielControllerApiFp(this.configuration).searchCentreVHUUsingSpecsUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route de recherche des commune d\'enlevement des dossiers accessibles par l\'utilisateur
     * @param {searchCommuneEnlevementUsingGETQueryParams} queryParams objet contenant les query params de la route searchCommuneEnlevementUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchCommuneEnlevementUsingGET(queryParams: SearchCommuneEnlevementUsingGETQueryParams, options?: any) {
        return ReferentielControllerApiFp(this.configuration).searchCommuneEnlevementUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche d\'une liste des fourriÃ¨res filtrÃ©e et triÃ©e
     * @param {searchFourriereUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchFourriereUsingSpecsUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchFourriereUsingSpecsUsingGET(queryParams: SearchFourriereUsingSpecsUsingGETQueryParams, options?: any) {
        return ReferentielControllerApiFp(this.configuration).searchFourriereUsingSpecsUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche d\'une liste de marques filtrÃ©e et triÃ©e
     * @param {searchMarqueUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchMarqueUsingSpecsUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchMarqueUsingSpecsUsingGET(queryParams: SearchMarqueUsingSpecsUsingGETQueryParams, options?: any) {
        return ReferentielControllerApiFp(this.configuration).searchMarqueUsingSpecsUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche d\'une liste de modÃ¨les filtrÃ©e filtrÃ©e et triÃ©e
     * @param {searchModeleUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchModeleUsingSpecsUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchModeleUsingSpecsUsingGET(queryParams: SearchModeleUsingSpecsUsingGETQueryParams, options?: any) {
        return ReferentielControllerApiFp(this.configuration).searchModeleUsingSpecsUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche d\'une liste de motifs de mise en fourriÃ¨re filtrÃ©e et triÃ©e
     * @param {searchMotifMefUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchMotifMefUsingSpecsUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchMotifMefUsingSpecsUsingGET(queryParams: SearchMotifMefUsingSpecsUsingGETQueryParams, options?: any) {
        return ReferentielControllerApiFp(this.configuration).searchMotifMefUsingSpecsUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Recherche d\'une liste d\'UniteFo filtrÃ©e et triÃ©e
     * @param {searchUniteFoUsingSpecsUsingGETQueryParams} queryParams objet contenant les query params de la route searchUniteFoUsingSpecsUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchUniteFoUsingSpecsUsingGET(queryParams: SearchUniteFoUsingSpecsUsingGETQueryParams, options?: any) {
        return ReferentielControllerApiFp(this.configuration).searchUniteFoUsingSpecsUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route recherchant une unitÃ© force de l\'ordre.
     * @param {searchedValidUniteFOUsingGETQueryParams} queryParams objet contenant les query params de la route searchedValidUniteFOUsingGET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public searchedValidUniteFOUsingGET(queryParams: SearchedValidUniteFOUsingGETQueryParams, options?: any) {
        return ReferentielControllerApiFp(this.configuration).searchedValidUniteFOUsingGET(queryParams, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route pour Ã©diter un bandeau d\'information Ã  afficher.
     * @param {string} id 
     * @param {InformationMessageRequestDto} informationMessageRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateInformationMessageUsingPOST(id: string, informationMessageRequestDto: InformationMessageRequestDto, options?: any) {
        return ReferentielControllerApiFp(this.configuration).updateInformationMessageUsingPOST(id, informationMessageRequestDto, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Route pour valider le formulaire de mise Ã  jour d\'un bandeau d\'information.
     * @param {string} id 
     * @param {InformationMessageRequestDto} informationMessageRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public updateInformationMessageValidateUsingPOST(id: string, informationMessageRequestDto: InformationMessageRequestDto, options?: any) {
        return ReferentielControllerApiFp(this.configuration).updateInformationMessageValidateUsingPOST(id, informationMessageRequestDto, options)(this.fetch, this.basePath);
    }

}

//////////////////////////////////// RelanceVenteControllerApi /////////////////////////////////////////////////////

/**
 * RelanceVenteControllerApi - fetch parameter creator
 * @export
 */
export const RelanceVenteControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * @summary Route dÃ©clenchant une relance du processus de vente chez le service des domaines, engendrant une nouvelle remise aux domaines.
         * @param {RelanceVenteDto} relanceVenteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relanceVenteUsingPOST(relanceVenteDto: RelanceVenteDto, options: any = {}): FetchArgs {
            // verify required parameter 'relanceVenteDto' is not null or undefined
            if (relanceVenteDto === null || relanceVenteDto === undefined) {
                throw new RequiredError('relanceVenteDto','Required parameter relanceVenteDto was null or undefined when calling relanceVenteUsingPOST.');
            }
            const localVarPath = `/api/relance-vente/dossier/relance-vente`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RelanceVenteDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(relanceVenteDto || {}) : (relanceVenteDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RelanceVenteControllerApi - functional programming interface
 * @export
 */
export const RelanceVenteControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * @summary Route dÃ©clenchant une relance du processus de vente chez le service des domaines, engendrant une nouvelle remise aux domaines.
         * @param {RelanceVenteDto} relanceVenteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relanceVenteUsingPOST(relanceVenteDto: RelanceVenteDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DossierResponseDto> {
            const localVarFetchArgs = RelanceVenteControllerApiFetchParamCreator(configuration).relanceVenteUsingPOST(relanceVenteDto, options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RelanceVenteControllerApi - object-oriented interface
 * @export
 * @class RelanceVenteControllerApi
 * @extends {BaseAPI}
 */
export class RelanceVenteControllerApi extends BaseAPI {
    /**
     * @summary Route dÃ©clenchant une relance du processus de vente chez le service des domaines, engendrant une nouvelle remise aux domaines.
     * @param {RelanceVenteDto} relanceVenteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public relanceVenteUsingPOST(relanceVenteDto: RelanceVenteDto, options?: any) {
        return RelanceVenteControllerApiFp(this.configuration).relanceVenteUsingPOST(relanceVenteDto, options)(this.fetch, this.basePath);
    }

}

//////////////////////////////////// VersionControllerApi /////////////////////////////////////////////////////

/**
 * VersionControllerApi - fetch parameter creator
 * @export
 */
export const VersionControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant d\'avoir la version du SI FourriÃ¨res.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/version`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionControllerApi - functional programming interface
 * @export
 */
export const VersionControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * @summary Route permettant d\'avoir la version du SI FourriÃ¨res.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VersionDto> {
            const localVarFetchArgs = VersionControllerApiFetchParamCreator(configuration).getVersionUsingGET(options);
            return (fetch: FetchAPI = window.fetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * VersionControllerApi - object-oriented interface
 * @export
 * @class VersionControllerApi
 * @extends {BaseAPI}
 */
export class VersionControllerApi extends BaseAPI {
    /**
     * @summary Route permettant d\'avoir la version du SI FourriÃ¨res.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    public getVersionUsingGET(options?: any) {
        return VersionControllerApiFp(this.configuration).getVersionUsingGET(options)(this.fetch, this.basePath);
    }

}

