import moment from 'moment';

import {
  DossierResponseDto,
  GardienFourriereControllerApi,
  SortieVehiculeRequestDto,
  SortieVehiculeViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';

import { SortieVehicule } from 'types/SortieVehicule';
import { UNIX_TIMESTAMP_FORMAT } from 'utils/formats';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { defaultErrorMessage } from 'utils/ErrorMessages';

export async function validateSortieVehiculeRule(
  dossier: string,
  controller: GardienFourriereControllerApi,
  values: SortieVehiculeRequestDto,
  field: SortieVehiculeViolationDtoFormFieldEnum,
): Promise<void> {
  try {
    const response = await controller.validateSortieVehiculeFormFieldUsingPOST(
      field,
      dossier,
      values,
    );
    if (response.sortieVehiculeViolationDtoList) {
      const fieldViolations = response.sortieVehiculeViolationDtoList.filter(
        violation => violation.formField === field,
      );

      if (fieldViolations.length > 0) {
        return Promise.reject(
          fieldViolations.map(violation => violation.message).join(', '),
        );
      }
    }
  } catch (e) {
    if (e instanceof Response) {
      return Promise.reject((await backAlertMessage(e)).description);
    }
    return Promise.reject(defaultErrorMessage);
  }
  return Promise.resolve();
}

/**
 * Generate a SortieVehicule object from a DossierDto
 *
 * @param dossier: DossierDto we want to extract SortieVehicule data
 */
export function generateSortieVehiculeFromDossier(
  dossier: DossierResponseDto | null,
): SortieVehicule {
  return {
    motifSortieIdCorrelation:
      dossier?.body?.sortieVehicule?.motifSortie?.idCorrelation || undefined,
    dateSortie: moment(dossier?.body?.sortieVehicule?.dateSortie || undefined),
  };
}

/**
 * Used to map SortieVehicule to SortieVehiculeRequestDto
 * We can't use SortieVehiculeRequestDto in form because:
 * DatePicker use Moment and api.ts a string
 * motifSortie SortieVehicule and SortieVehiculeRequest are different in api.ts
 *
 * @param values SortieVeihcule object we want to map
 */
export const generateSortieVehiculeRequestDtoFromSortieVehicule = (
  values: SortieVehicule,
): SortieVehiculeRequestDto => {
  return {
    dateSortie: values.dateSortie?.format(UNIX_TIMESTAMP_FORMAT) || null,
    motifSortieIdCorrelation: values.motifSortieIdCorrelation || null,
    motifId: null,
  };
};
